<template>
  <div class="reporting-array">
    <div class="box">
      <ag-grid-vue style="width: 100%; height: 95%;"
                   class="ag-theme-alpine"
                   :column-defs="columnDefs"
                   :row-data="rowData"
                   :default-col-def="defaultColDef"
                   rowSelection="single"
                   @rowClicked="onSelectionRow"
                   @grid-ready="onGridReady"
                   @pagination-changed="onPaginationChanged"
                   :suppress-pagination-panel="true"
                   :pagination-page-size="paginationPageSize"
                   :pagination="true"
                   :alwaysShowVerticalScroll="true" />
    </div>
    <div v-if="rowData && rowData.length > 0" class="pagination-container">
      <pagination :value="page" :total-visible="7" :number-pages="nbPage" @onPage="changedPage" />
      <page-size :tab="linesPerPage" :value="paginationPageSize" @onPageSize="changedPageSize" />
    </div>
  </div>
</template>

<script>
  import { AgGridVue } from 'ag-grid-vue3'
  import { reportingArrayColDefs } from '@/utils/CONF'
  import { extendWithAuthorization, httpNip } from '@/http'
  import { saveAs } from 'file-saver'
  const { DateTime } = require('luxon')
  const zone = "Europe/Paris"
  const defaultLinesPerPage = [10, 20, 50, 100]
  const ONE_NUMBER = 1
  const TEN_NUMBER = 10


  export default {
    name: 'ReportingArray',
    components: { AgGridVue },
    props: {
      rows: {
        type: Array,
        default: () => ([])
      },
      displayKey: {
        type: String,
        default: 'SITE'
      },
      reportingType: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        gridApi: null,
        columnApi: null,
        columnDefs: reportingArrayColDefs,
        rowData: [],
        defaultColDef: { filter: 'agTextColumnFilter', floatingFilter: true, resizable: true, sortable: true },
        paginationPageSize: TEN_NUMBER,
        linesPerPage: defaultLinesPerPage,
        page: ONE_NUMBER,
        nbPage: ONE_NUMBER,
      }
    },

    methods: {
      async onGridReady(params) {
        this.rowData = this.rows
        this.gridApi = params.api
        this.columnApi = params.columnApi
      },

      onPaginationChanged() {
        if (this.gridApi) this.nbPage = this.gridApi.paginationGetTotalPages()
      },

      changedPage(e) {
        this.page = e
        this.gridApi.paginationGoToPage(this.page - ONE_NUMBER)
      },

      changedPageSize(e) {
        this.paginationPageSize = e
        this.gridApi.paginationSetPageSize(this.paginationPageSize)
      },

      async downloadFile(directory, fileName) {
        const params = {
          directory,
          fileName,
        }
        Object.keys(params).forEach(key => params[key] ?? delete params[key])
        this.loading = true
        try {
          const extendedHttp = await extendWithAuthorization(httpNip)
          const fileData = await extendedHttp.get('reporting/downloadFile', { searchParams: params }).json()
          console.log(fileData)
          saveAs(fileData.reportingFile, fileName)
        } catch (err) {
          this.$toast.error(this.$t('reporting.errors.search') + err)
        }
        this.loading = false
      },

      onSelectionRow() {
        const selectedRows = this.gridApi.getSelectedRows()
        this.downloadFile(this.reportingType, selectedRows[0].reportingFileName)
      },
    }
  }
</script>

<style scoped lang="scss">
  .reporting-array {
    position: relative;
    .box {
      padding: 10px 40px 30px 30px;
      box-shadow: 0 4px 20px rgb(0 0 0 / 10%);
      border-radius: 15px;
      max-height: 100%;
      height: -webkit-fill-available;

      .tools {
        display: flex;
        justify-content: space-between;
        align-items: center;


        .right {
          display: flex;
          align-items: center;
          column-gap: 5rem;

          .display-key-selector {
            display: flex;
            column-gap: 0.3rem;
            align-items: center;

            .v-svg {
              cursor: pointer;
              color: $primaryBlue;

              &.active {
                color: #448F94;
              }
            }
          }

          .button {
            padding: 6px 8px 6px 0;
            border-radius: 8px;
            @extend %font-body-semi-bold;
            font-size: 14px;
          }
        }
      }
    }

    .pagination-container {
      margin-top: 1.5rem;
    }
  }
</style>

<style lang="scss">
  .reporting-array {
    .ag-theme-alpine {
      .ag-overlay {
        top: 50px;
      }
    }
  }
  #content {
    position: relative;
    margin: 0 auto;
    height:100%;
  }
  #stuff {
    overflow-x:auto;
    top:30px;
    left:0;right:0;
    bottom:0;
    position:absolute;
  }
</style>
