<template>
  <Navbar />
  <div class="body">
    <loading :is-loading="isLoading" />
    <router-view />
  </div>
  <div class="version">{{ version }}</div>
</template>

<script>
  import Navbar from '@/components/navbar/NavBar.vue'
  import { mapActions, mapState } from 'vuex'

  export default {
    name: 'Webapp',
    components: {
      Navbar
    },
    data() {
      return {
        version: `v${process.env.VUE_APP_VERSION}`
      }
    },
    computed: {
      ...mapState('common', ['isLoading'])
    },
    async created() {
      await Promise.all([this.fetchMarketsWithNullableGDN()])
      const defaultContext = JSON.parse(localStorage.getItem('default-context'))
      if (defaultContext && Object.keys(this.$route.query).length === 0) {
        await this.$router.push({ name: 'analyse', query: defaultContext })
      }
    },

    methods: {
      ...mapActions('referential', ['fetchMarketsWithNullableGDN'])
    }
  }
</script>
<style lang="scss" scoped>
  .body {
    padding-top: 50px;
    padding-bottom: 25px;
    height: 100%;
  }

  .version {
    position: fixed;
    right: 0;
    bottom: 0;
  }
</style>
