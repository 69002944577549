<template>
  <modal class="import-recap" v-if="isVisible">
    <template #header>
      <div class="header">
        <div class="header-title">
          <v-svg name="confirm-import-file" :h="'6rem'" />
          <span>{{$t('exportBenefits.import.promotionSuccess.title')}}</span>
        </div>
        <v-svg class="pointer" :name="'close'" :h="'1rem'" @click="isVisible = !isVisible" />
      </div>
    </template>
    <template #body>
      <div class="content-title">{{$t('exportBenefits.import.promotionSuccess.summary')}} {{sites[0].importFileName}}</div>
      <section>
        <header>
          <div class="col">{{$t('exportBenefits.import.promotionSuccess.tab.month')}}</div>
          <div class="col">{{$t('exportBenefits.import.promotionSuccess.tab.perimeter')}}</div>
          <div class="col">{{$t('exportBenefits.import.promotionSuccess.tab.siteNumber')}}</div>
          <div class="col">{{$t('exportBenefits.import.promotionSuccess.tab.siteLabel')}}</div>
          <div class="col">{{$t('exportBenefits.import.promotionSuccess.tab.amountCAG')}}</div>
          <div class="col">{{$t('exportBenefits.import.promotionSuccess.tab.amountRI')}}</div>
          <div class="col">{{$t('exportBenefits.import.promotionSuccess.tab.amountBR')}}</div>
          <div class="col">{{$t('exportBenefits.import.promotionSuccess.tab.dateAndHours')}}</div>
        </header>
        <div class="row" v-for="(site, index) in sites" :key="index">
            <div class="col">{{site.integrationKey ? formatDate(site.integrationKey ,'MM/YYYY') : ''}}</div>
            <div class="col">{{site.perimeter && site.perimeter !== 'null' ? $t(`exportBenefits.export.types.${site.perimeter}`) : ''}}</div>
            <div class="col">{{site.site}}</div>
            <div class="col">{{site.businessUnit ? $t(`exportBenefits.export.types['${site.businessUnit}']`) : ''}}</div>
            <div class="col">{{parseFloat(site.benefitAmountWithTaxCAB.toString().replace(',', '.')).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}}</div>
            <div class="col">{{parseFloat(site.benefitAmountWithTaxRI.toString().replace(',', '.')).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}}</div>
            <div class="col">{{parseFloat(site.benefitAmountWithTaxBR.toString().replace(',', '.')).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}}</div>
            <div class="col">{{site.importDate}}</div>
        </div>
        <footer>
          <div class="col" />
          <div class="col">{{ $t('exportBenefits.import.promotionSuccess.total') }}</div>
          <div class="col" />
          <div class="col" />
          <div class="col">{{ totalAmount(sites, 'benefitAmountWithTaxCAB') }}</div>
          <div class="col">{{ totalAmount(sites, 'benefitAmountWithTaxRI') }}</div>
          <div class="col">{{ totalAmount(sites, 'benefitAmountWithTaxBR') }}</div>
          <div class="col" />
        </footer>
      </section>
    </template>
    <template #footer>
      <div class="footer-button">
        <v-button color="primary"
                  :active="true"
                  size="medium"
                  :label="$t('exportBenefits.import.promotionSuccess.okay')"
                  @click="isVisible = !isVisible" />
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/Modal'
import dateMixin from '@/mixins/date'

export default {
  name: 'ImportCalendarHistory',
  components: {
    Modal
  },
  mixins: [dateMixin],
  data() {
    return {
      isVisible: false,
       sites: []
    }
  },
  methods: {
    show(opts = []) {
      if(opts.length) this.sites = opts.sort((a,b) => a.site - b.site)
      this.isVisible = true
    },
    totalAmount(list, property) {
      let initialValue = 0
      let sum = 0
      sum = list.reduce(function(accumulator, curValue) {
        return accumulator + curValue[property]
      }, initialValue)
      return parseFloat(sum.toString().replace(',', '.')).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })
    }
  }
}

</script>
<style lang="scss" scoped>
.import-recap{
  .header{
    display: flex;
    justify-content: space-between;
    &-title{
      display: flex;
      align-items: center;
      font-size: 24px;
      font-weight: bold;
    }
    & span {
      line-height: 54px;
      border-bottom: 4px solid $primaryOrange;
    }
  }
  .content-title{
    font-size: 18px;
    line-height: 28px;
    font-weight: bold;
    margin-bottom: 12px;
    margin-top: 30px;
  }
  section{
    background: $gray;
    border: 1px solid $secondaryBlue;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    header, footer, .row{
      display: flex;
    }
    header{
      font-weight: bold;
      border-bottom: 1px solid white;
    }
    footer{
      font-weight: bold;
      border-top: 1px solid white;
    }
    .col{
      flex: 1;
      padding: 7px;
      line-height: 18px;
      text-align: center;
    }

    .col-bold{
      flex: 1;
      padding: 7px;
      line-height: 18px;
      text-align: center;
      font-weight: bold;
    }

  }
  .footer-button{
    text-align: -webkit-center;
    margin-top: 28px;
    button{
      border-radius: 8px;
      padding: 8px;
      font-weight: bold;
    }
  }
}
</style>
