<template>
  <div :class="['input-radio', { vertical }]">
    <div v-for="(value, i) in values" :key="i" class="radio-button" @click="$emit('update:modelValue', value)">
      <v-svg :name="value.id === modelValue?.id ? 'checked' : 'circle-empty'" h="1.5rem" />
      <div :class="['radio-label', { grey: value.id !== modelValue?.id }]">{{ traductionKey ? $t(`${traductionKey}.${value.id}`) : value.traduction }}</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'InputRadio',
    props: {
      modelValue: {
        type: [Object, null],
        required: true
      },
      values: {
        type: Array,
        required: true
      },
      traductionKey: {
        type: String,
        default: null
      },
      vertical: {
        type: Boolean,
        default: false
      }
    },
    emits: ['update:modelValue'],
  }
</script>

<style scoped lang="scss">
  .input-radio {
    display: flex;
    gap: 1rem;
    min-width: 260px;

    .radio-button {
      display: flex;
      align-items: center;
      cursor: pointer;
      user-select: none;

      .v-svg {
        color: $primaryBlue;
        margin-right: 0.1rem;
      }

      .radio-label {
        font-size: 13px;
        @extend %font-body;

        &.grey { color: $mediumGrey; }
      }
    }

    &.vertical {
      flex-direction: column;
      gap: 0.5rem;

      .v-svg { margin-right: 0.5rem; }
    }
  }
</style>
