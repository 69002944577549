<template>
  <div :class="[{disabled},'autocomplete']">
    <div class="input">
      <input class="input_text"
             type="text"
             :value="input"
             :placeholder="placeholder"
             @keyup.enter="onEnter"
             @keydown.tab.prevent="complete()"
             @keyup="onInputChange"
             @keydown="onSelectData"
             :disabled=disabled>
      <div class="input-list">
        <table v-show="filteredData.length">
          <tr v-for="(d, index) in filteredData" :key="d" @click="complete(d)">
            <td :class="{ active: searchIndex === index }">{{ d }}</td>
          </tr>
        </table>
      </div>
    </div>
    <v-button v-if="buttonLabel"
              :color="'primary'"
              :active="true"
              :label="buttonLabel"
              :size="'medium'"
              @click="onSelect"
              :disabled=disabled />
  </div>
</template>

<script>
  const ARROW_DOWN_KEYCODE = 40
  const ARROW_UP_KEYCODE = 38
  const ENTER_KEYCODE = 13
  const TAB_KEYCODE = 9

  export default {
    name: 'AutoComplete',
    props: {
      value: {
        type: String,
        default: ''
      },
      data: {
        type: Array,
        default: () => []
      },
      placeholder: {
        type: String,
        default: ''
      },
      buttonLabel: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    emits: ['onEnter'],
    data() {
      return {
        input: '',
        filteredData: [],
        searchIndex: 0
      }
    },
    created() {
      this.input = this.value || ''
    },
    methods: {
      complete(i) {
        this.input = i
        this.filteredData = []
      },
      onInputChange(e) {
        const isEnterKey = e.keyCode === ENTER_KEYCODE
        const isArrowDownKey = e.keyCode === ARROW_DOWN_KEYCODE
        const isArrowUpKey = e.keyCode === ARROW_UP_KEYCODE
        const isTabKey = e.keyCode === TAB_KEYCODE

        if (isEnterKey || isArrowDownKey || isArrowUpKey || isTabKey) {
          return
        }
        const search = e.target.value.toLowerCase()
        this.input = e.target.value
        this.searchIndex = 0

        if (this.input.length) {
          this.filteredData = this.data.filter(data => {
            return data.includes(search)
          })
        } else {
          this.filteredData = []
        }
      },
      onSelectData(e) {
        const isArrowDownKey = e.keyCode === ARROW_DOWN_KEYCODE
        const isArrowUpKey = e.keyCode === ARROW_UP_KEYCODE
        const isEnterKey = e.keyCode === ENTER_KEYCODE
        if (isArrowDownKey && this.searchIndex < this.filteredData.length - 1) {
          this.searchIndex++
        } else if (isArrowUpKey && this.searchIndex > 0) {
          this.searchIndex--
        } else if (isEnterKey) {
          this.onSelect()
        }
      },
      onSelect() {
        this.complete(this.filteredData.length ? this.filteredData[this.searchIndex] : this.input)
        this.$emit('onEnter', this.input)
        this.input = null
      }
    }

  }
</script>
<style lang="scss" scoped>
  .autocomplete {
    display: flex;
    align-items: center;

    &.disabled{
      pointer-events: none;

      .input {
        opacity: .5;
      }
    }
  }

  .input {
    margin: 0 10px;
  }

  .input_text {
    height: 31px;
    border: 1px solid $secondaryOrange;
    border-radius: 5px;
    padding-left: 10px;
  }

  .input-list {
    overflow: auto;
    max-height: 156px;
    border: 1px solid #EAEAEA;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, .2);
    position: absolute;
    background-color: $white;
  }

  table tr td {
    border-bottom: 1px solid #EAEAEA;
    font-weight: bold;
    padding: 10px 15px;

    &:last-child {
      border-bottom: none;
    }

    &.active {
      color: $primaryOrange;
    }
  }

  button {
    border-radius: 8px;
    text-transform: uppercase;
    font-weight: bold;
  }
</style>
