<template>
  <div class="checkbox">
    <input
      :id="id"
      type="checkbox"
      class="check"
      :checked="isChecked"
      :name="name"
      :value="label">
    <label :for="id" :class="[isChecked?'label-checked':'']"> {{ label }}</label>
  </div>
</template>

<script>
  export default {
    name: 'Checkbox',
    props: {
      label: {
        type: String,
        default: ''
      },
      id: {
        type: String,
        default: null
      },
      isChecked: {
        type: Boolean,
        default: true
      },
      name: {
        type: String,
        default: ''
      }
    }

  }
</script>
<style lang="scss" scoped>
  label {
    font-size: 13px;
    color: $darkGrey;
    display: flex;
    cursor: pointer;
    text-transform: capitalize;
    font-weight: unset;
  }

  input[type="checkbox"].check {
    display: none;
  }

  input[type="checkbox"].check + label::before {
    content: '';
    border: 1.5px solid $darkGrey;
    border-radius: 20%;
    margin-right: 0.3rem;
    padding-left: 0.1rem;
    padding-bottom: 0.1rem;
    height: 15px;
    width: 15px;
    display: flex;
    align-items: center;
  }

  input[type="checkbox"].check:checked + label::before {
    border: 1.5px solid $primaryBlue;
    content: url(../assets/svg/v.svg);
  }

  .label-checked {
    color: $primaryBlue;
  }

  .checkbox {
    height: 25px
  }
</style>
