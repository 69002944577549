<template>
  <div class="filterYear">
    <ul>
      <li class="navPrev">
        <button class="flex items-center" :disabled="tabYear.indexOf(value) === 0" @click="$emit('changedYear', value + 1)">
          <v-svg :name="'nav-prev'" :h="'0.6rem'" />
        </button>
      </li>

      <li v-for="item in items" :key="'year_' + item">
        <button :class="['year-item', { active: item === value }]" @click="$emit('changedYear', item)"> {{ item }}
        </button>
      </li>

      <li class="navNext">
        <button class="flex items-center" :disabled="tabYear.indexOf(value) === tabYear.length-1" @click="$emit('changedYear', value - 1)">
          <v-svg :name="'nav-next'" :h="'0.6rem'" />
        </button>
      </li>
    </ul>
  </div>
</template>
<script>
  export default {
    name: 'FilterYear',
    props: {
      value: {
        type: Number,
        required: true
      },
      tabYear: {
        type: Array,
        default: () => []
      },
      nbYearVisible: {
        type: Number,
        default: 0
      }
    },
    emits: ['changedYear'],
    data() {
      return {
        maxButtons: 0
      }
    },
    computed: {
      items() {
        if (this.tabYear.indexOf(this.value) > 0 && this.tabYear.indexOf(this.value) < this.tabYear.length - 1) {
          return this.getRange(this.tabYear.indexOf(this.value) - 1, this.tabYear.indexOf(this.value) + 1)
        } else if (this.tabYear.indexOf(this.value) === 0) {
          return this.getRange(0, 2)
        } else if (this.tabYear.indexOf(this.value) === this.tabYear.length - 1) {
          return this.getRange(this.tabYear.length - 3, this.tabYear.length - 1)
        } else {
          return []
        }
      }
    },
    methods: {
      getRange(from, to) {
        const range = []
        for (let i = from; i <= to; i++) range.push(this.tabYear[i])
        return range
      }
    }
  }
</script>

<style lang="scss" scoped>
  .filterYear {
    display: flex;
    justify-content: center;
    margin: 10px 0;

    ul {
      height: 29px;
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      border: 0.5px solid $primaryBlue;
      border-radius: 10px;

      li {
        height: 100%;

        &.navPrev button,
        &.navNext button {
          height: 100%;
          width: 50px;
          justify-content: center;
        }

        &.navPrev {
          button {
            border-bottom-left-radius: 10px;
            border-top-left-radius: 10px;
          }
        }

        &.navNext {
          button {
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
          }
        }

        button {
          height: 100%;
          cursor: pointer;
          background-color: white;
          color: $primaryBlue;
          border: 0.5px solid $primaryBlue;
          font-size: 11px;

          &:hover {
            background-color: $veryLightGrey;
          }

          &.active {
            background-color: $primaryBlue;
            color: white;
          }
        }
      }
    }
  }
</style>
