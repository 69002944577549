<template>
  <modal>
    <template #header>
      <div class="filter-header">
        <div class="filter-header-title">
          <v-svg :name="'search-modal'" :h="'6rem'" />
          <span>Recherche</span>
          <div class="reset">
            <v-button :color="'secondary'" :active="true" :label="'Reset'" @click="reset" />
          </div>
        </div>
        <v-svg class="pointer" :name="'close'" :h="'1rem'" @click="$emit('closeModalFilter')" />
      </div>
    </template>

    <template #body>
      <!-- CHECKBOX TYPES -->
      <div class="group-checkbox">
        <span :class="[{ error: formError }, 'label']">{{ $t('promotion.promotionSearch.typesLabel') }}<sup>*</sup> : </span>
        <div v-for="type in types" :key="type.id" class="checkbox">
          <input :id="type.id" v-model="formSearch.types" type="checkbox" :value="type" :disabled="type.id == 3" />
          <label :for="type.id">{{ type.name }}</label>
        </div>
      </div>

      <!-- ANNEE -->
      <FilterYear
        :value="formSearch.global.year"
        :tab-year="getYears"
        :nb-year-visible="3"
        @changedYear="formSearch.global.year = $event"
      />

      <!-- MANDATS -->
      <v-button-group>
        <v-button
          v-for="(mandat, index) in mandats"
          :key="index"
          :label="mandat.name"
          :active="mandat.name === formSearch.global.mandat"
          @btn="externalFilterChanged(mandat)"
        />
      </v-button-group>

      <form>
        <!-- LES AUTRES CHAMPS -->
        <div :class="[{ 'is-collapsed': collapsed }, 'group-field']">
          <div v-for="(fieldName, index) in formSearch.field" :key="index" class="field">
            <label :for="index">{{ $t('filters.' + index) }}</label>
            <div class="date-container" v-if="fieldPeriodDate.includes(index)">
              <date-picker
                v-model:value="date[index]"
                type="date"
                :range="true"
                format="DD/MM/YYYY"
                popup-class="mx-popup"
                class="mx-inputs"
                value-type="format"
                placeholder="dd/mm/yyyy"
                langString="fr"
                width="200px"
                @change="periodChange($event, index)"
              ></date-picker>
            </div>
            <input-text
              v-else
              v-model="formSearch.field[index]"
              :placeholder="$t('promotion.promotionSearch.research')"
              width="260px"
              show-eraser
            />
          </div>
        </div>
        <div class="show-more" @click="collapsed = !collapsed">
          <v-svg :name="collapsed ? 'plus' : 'less'" :h="'1rem'" />
          <span>{{ collapsed ? $t('promotion.promotionSearch.showMore') : $t('promotion.promotionSearch.showLess') }}</span>
        </div>
      </form>
    </template>

    <template #footer>
      <div class="filter-errors">
        <span v-if="formError">{{ $t('promotion.promotionSearch.errorsTypes') }}</span>
      </div>
      <div class="filter-footer">
        <v-button
          :color="'secondary'"
          :active="true"
          :label="$t('promotion.promotionSearch.cancel')"
          @click="$emit('closeModalFilter')"
        />
        <v-button
          :color="'primary'"
          :active="true"
          :label="$t('promotion.promotionSearch.startResearch')"
          @click="fetchPromotionFilter"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import FilterYear from '@/components/FilterYear'
  import Modal from '@/components/Modal'
  import moment from 'moment'
  import { mapActions, mapState } from 'vuex'
  import { promoSearchTypes, fieldsPeriodDate } from '@/utils/CONF'
  import { DateTime } from 'luxon'
  import DatePicker from 'vue-datepicker-next'
  import 'vue-datepicker-next/index.css'
  import 'vue-datepicker-next/locale/fr'

  const initSearch = {
    types: [],
    global: {
      year: parseInt(moment().format('YYYY')),
      mandat: 'Tous',
      mandatId: 0,
    },
    field: {
      discountContractCode: '',
      discountContractFirstPartnerBillingTypeLabel: '',
      promotionTypeCode: '',
      discountContractSecondPartnerBillingTypeLabel: '',
      orderSupplierDepartmentCode: '',
      commercialOperationLabel: '',
      orderSupplierCnuf: '',
      commercialOperationDate: { start: null, end: null },
      orderSupplierNegoGroupUid: '',
      commercialOperationThemeLabel: '',
      orderSupplierLabel: '',
      discountContractDate: { start: null, end: null },

      paymentSupplierDepartmentCode: '',
      paymentSupplierCnuf: '',
      paymentSupplierNegoGroupUid: '',
      discountContractBillingDate: { start: null, end: null },
      paymentSupplierLabel: '',
      discountContractTotalBillingAmount: '',
    },
  }

  export default {
    name: 'PromotionSearch',
    components: {
      Modal,
      FilterYear,
      DatePicker,
    },
    emits: ['closeModalFilter', 'changedFilter'],
    data() {
      return {
        types: promoSearchTypes,
        mandats: [
          { id: 0, name: this.$t('etatOffre.all') },
          { id: 2, name: this.$t('etatOffre.pointes') },
          { id: 1, name: this.$t('etatOffre.nonPointes') },
          { id: 5, name: this.$t('etatOffre.controller') },
          { id: 3, name: this.$t('etatOffre.factures') },
          { id: 6, name: this.$t('etatOffre.prefactures') },
          { id: 4, name: this.$t('etatOffre.litigesEnCours') },
        ],
        fieldPeriodDate: fieldsPeriodDate,
        formSearch: JSON.parse(JSON.stringify(initSearch)),
        collapsed: true,
        formError: false,
        date: [],
      }
    },
    computed: {
      ...mapState({
        maxYear: (state) => state.promotion.maxYear,
        filters: (state) => state.promotion.filters,
      }),
      getYears() {
        let years = []
        // 5 dernières années
        for (let index = 0; index < 5; index++) {
          years.push(parseInt(this.maxYear) - index)
        }
        return years
      },
    },
    created() {
      //Verifie si un filtre est deja present, si c'est le cas applique ce filter
      if (Object.keys(this.filters).length) {
        //on clone l'objet filters
        this.formSearch = JSON.parse(JSON.stringify(this.filters))
        for (const field of this.fieldPeriodDate) {
          this.formSearch.field[field].start = this.formSearch.field[field].start
            ? DateTime.fromISO(this.formSearch.field[field].start)
            : null
          this.formSearch.field[field].end = this.formSearch.field[field].end
            ? DateTime.fromISO(this.formSearch.field[field].end)
            : null
        }

        const fieldMore = [
          'paymentSupplierDepartmentCode',
          'paymentSupplierCnuf',
          'paymentSupplierNegoGroupUid',
          'discountContractBillingDate',
          'paymentSupplierLabel',
          'discountContractTotalBillingAmount',
        ]

        for (const field of fieldMore) {
          if (field === 'discountContractBillingDate') {
            if (this.formSearch.field[field].start !== null) {
              this.collapsed = false
              break
            }
            continue
          }
          if (this.formSearch.field[field] !== '') {
            this.collapsed = false
            break
          }
        }
      }
    },
    methods: {
      ...mapActions('promotion', ['fetchPromotion']),
      fetchPromotionFilter() {
        if (this.formSearch?.types.length) this.$emit('changedFilter', this.formSearch)
        else this.formError = true
      },
      externalFilterChanged(mandat) {
        this.formSearch.global.mandat = mandat.name
        this.formSearch.global.mandatId = mandat.id
      },
      reset() {
        this.formSearch = JSON.parse(JSON.stringify(initSearch))
        this.collapsed = true
      },
      periodChange(event, index) {
        this.formSearch.field[index].start = DateTime.fromFormat(event[0], 'dd/MM/yyyy')
        this.formSearch.field[index].end = DateTime.fromFormat(event[1], 'dd/MM/yyyy')
      },
    },
  }
</script>
<style lang="scss" scoped>
  //MODAL FILTER
  .filter {
    &-header {
      display: flex;
      justify-content: space-between;

      &-title {
        display: flex;
        align-items: center;
        font-size: 24px;
        font-weight: bold;
        width: 100%;

        & span {
          line-height: 54px;
          border-bottom: 4px solid $primaryOrange;
        }
        .reset {
          flex: 1;
          text-align: -webkit-right;
        }
      }
    }

    &-footer {
      justify-content: center;
      display: flex;

      & button {
        &:first-child {
          margin-right: 10px;
        }
      }
    }

    &-errors {
      min-height: 33px;
      text-align: center;
      font-style: normal;
      font-size: 12px;
      line-height: 18px;
      font-weight: bold;
      color: $darkRed;
    }
  }

  //INPUT CHECKBOX
  .group-checkbox {
    display: flex;
    justify-content: center;
    height: 29px;
    font-size: 12px;
    color: $darkGrey;

    & .checkbox {
      margin: 0 10px;
    }

    & label {
      height: 24px;
      font-weight: 500;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .label {
      font-weight: bold;

      &.error {
        color: $darkRed;
      }
    }

    & input[type='checkbox'] {
      display: none;
    }

    & input[type='checkbox'] + label::before {
      content: '';
      border: 1.5px solid $darkGrey;
      border-radius: 20%;
      margin-right: 0.3rem;
      padding-left: 0.1rem;
      padding-bottom: 0.1rem;
      height: 15px;
      width: 15px;
      display: flex;
      align-items: center;
    }

    & input[type='checkbox']:checked + label::before {
      border: 1.5px solid $primaryBlue;
      content: url(../../assets/svg/v.svg);
    }
  }

  form {
    //INPUT TEXT
    & .group-field {
      display: flex;
      flex-wrap: wrap;

      &.is-collapsed {
        & .field:nth-child(n + 13) {
          display: none;
        }
      }

      & .field:nth-child(11) {
        padding-bottom: 20px;
      }

      & .field:nth-child(12) {
        padding-bottom: 20px;
      }

      & .field:nth-child(13) {
        border-top: 1px solid $gray;
        padding-top: 20px;
      }

      & .field:nth-child(14) {
        border-top: 1px solid $gray;
        padding-top: 20px;
      }

      & .field {
        width: 50%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 5px;

        & label {
          width: 125px;
        }

        & input {
          border: 0.5px solid $primaryBlue;
          border-radius: 10px;
          width: 238px;
          height: 28px;
          padding-left: 10px;

          &::placeholder {
            color: $mediumGrey;
          }
        }
      }
    }

    .show-more {
      display: flex;
      align-items: center;
      color: $mediumGrey;
      width: 50%;
      justify-content: center;
      margin: auto;
      font-weight: bold;
      margin-top: 20px;
      padding-top: 10px;
      border-top: solid 1px $gray;
      cursor: pointer;

      & span {
        margin-left: 5px;
      }
    }
  }

  button {
    font-size: 14px;
    line-height: 21px;
    padding: 8px;
    border-radius: 8px;
    font-weight: bold;
  }

  .date-container {
    display: flex;
    width: 230px;
  }

  .mx-inputs::v-deep input {
    border: 0.5px solid #5fbfc6;
    border-radius: 10px;
    height: 28px;
  }
</style>
