<!-- Created By Vasse Alexandre -->
<template>
  <div class="billing-array">
    <ag-grid-vue style="width: 100%; height: 100%;"
                 class="ag-theme-alpine"
                 :context="context"
                 :column-defs="columnDefs"
                 :suppress-row-click-selection="true"
                 :row-data="rowData"
                 :default-col-def="defaultColDef"
                 :overlay-no-rows-template="overlayNoRowsTemplate"
                 :pagination-page-size="paginationPageSize"
                 :pagination="true"
                 :suppress-pagination-panel="true"
                 :row-selection="rowSelection"
                 :get-row-style="getRowStyle"
                 :suppress-drag-leave-hides-columns="true"
                 :default-excel-export-params="defaultExcelExportParams"
                 @pagination-changed="onPaginationChanged"
                 @grid-ready="onGridReady" />
    <optional-columns-selector v-model="optionalColumns" />
    <div v-if="showPagination" class="pagination-container">
      <pagination :value="page" :total-visible="7" :number-pages="nbPage" @onPage="changedPage" />
      <page-size :tab="[10, 20, 50, 100]" :value="paginationPageSize" @onPageSize="changedPageSize" />
    </div>
  </div>
</template>

<script>
  /* eslint-disable vue/no-unused-components */
  import { AgGridVue } from 'ag-grid-vue3'
  import { billingArrayColDefs } from '@/utils/CONF'
  import ArrayCheckbox from './billingArray/ArrayCheckbox'
  import ArrayThumbs from './billingArray/ArrayThumbs'
  import OptionalColumnsSelector from './billingArray/OptionalColumnsSelector'

  export default {
    name: 'BillingArray',
    components: {
      OptionalColumnsSelector,
      AgGridVue,
      ArrayCheckbox,
      ArrayThumbs
    },
    props: {
      rows: {
        type: Array,
        default: () => ([])
      },
      checkedOffers: {
        type: Array,
        default: () => ([])
      },
      amountKey: {
        type: String,
        default: 'benefitAmountWithoutTax'
      },
      isJustif: {
       type: Boolean,
       default: false
      }
    },
    emits: ['offer-clicked'],
    data() {
      return {
        context: null,
        optionalColumns: { orderSupplierCnuf: false, orderSupplierNegoGroupLabel: false, orderSupplierLabel: false, orderSupplierDepartmentCode: false },
        page: 1,
        nbPage: 1,
        showFilter: false,
        gridApi: null,
        columnApi: null,
        showPagination: true,
        paginationPageSize: 10,
        columnDefs: billingArrayColDefs,
        defaultExcelExportParams: { fileName: 'exportFactures.xlsx' },
        rowData: [],
        defaultColDef: { filter: 'agTextColumnFilter', floatingFilter: true, resizable: true, sortable: true },
        rowSelection: 'single',
        overlayNoRowsTemplate: '<div class="nodata"><div class="nodata-logo"></div><span>Lance une recherche pour afficher la donnée souhaitée !</span></div>',
        getRowStyle: null
      }
    },
    watch: {
      rows: {
        deep: true,
        handler() {
          this.rowData = this.rows
        }
      },
      optionalColumns: {
        deep: true,
        handler() {
          this.columnApi.setColumnVisible('orderSupplierCnuf', this.optionalColumns.orderSupplierCnuf)
          this.columnApi.setColumnVisible('orderSupplierNegoGroupLabel', this.optionalColumns.orderSupplierNegoGroupLabel)
          this.columnApi.setColumnVisible('orderSupplierLabel', this.optionalColumns.orderSupplierLabel)
          this.columnApi.setColumnVisible('orderSupplierDepartmentCode', this.optionalColumns.orderSupplierDepartmentCode)
        }
      },
      amountKey() {
        const hide = this.amountKey === 'benefitAmountWithTax' ? 'benefitAmountWithoutTax' : 'benefitAmountWithTax'
        this.columnApi.setColumnVisible(this.amountKey, true)
        this.columnApi.setColumnVisible(hide, false)
      }
    },
    beforeMount() {
      this.context = { componentParent: this }
    },
    methods: {
      async onGridReady(params) {
        this.rowData = this.rows
        this.gridApi = params.api
        this.columnApi = params.columnApi
        this.gridApi.sizeColumnsToFit()
      },

      onPaginationChanged() {
        if (this.gridApi) this.nbPage = this.gridApi.paginationGetTotalPages()
      },

      changedPage(e) {
        this.page = e
        this.gridApi.paginationGoToPage(this.page - 1)
      },

      changedPageSize(e) {
        this.paginationPageSize = e
        this.gridApi.paginationSetPageSize(this.paginationPageSize)
      },

      onOfferClick(discountContractCode) {
        this.$emit('offer-clicked', discountContractCode)
      }
    },
    created() {
      if (this.isJustif){
        this.columnDefs = this.columnDefs.slice(2)
      }
    }
  }
</script>

<style scoped lang="scss">
  .billing-array {
    position: relative;

    .ag-theme-alpine {
      padding: 25px 40px 30px 30px;
      box-shadow: 0 4px 20px rgb(0 0 0 / 10%);
      border-radius: 15px;
    }

    .optional-columns-selector {
      position: absolute;
      top: 35px;
      right: 7px;
    }

    .pagination-container {
      margin-top: 1.5rem;
    }
  }
</style>

<style lang="scss">
  .billing-array {
    .ag-theme-alpine {
      .ag-overlay {
        top: 50px;
      }
    }
  }
</style>
