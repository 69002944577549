<template>
  <div class="button-group">
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'VButtonGroup'
  }
</script>
<style lang="scss">
  .button-group {
    display: flex;
    justify-content: center;
    min-height: 29px;
    margin: 17px 0;

    & button:first-child {
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
    }

    & button:last-child {
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    }
  }
</style>
