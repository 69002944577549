import moment from 'moment'

export default {
    name: 'dateMixin',
    methods: {
        formatDate(date, format) {
            moment.locale('fr');
            return moment(date).format(format)
        },
    },
}
