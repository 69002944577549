<template>
  <button
    :class="[active ? 'button active ' + size + ' ' + color + ' '+ (reverse ? 'reverse': '' ):'button size ' + size + ' ' + color + ' ' + (reverse ? 'reverse': '')]"
    @click="$emit('btn')">
    <v-svg v-if="isLoading" :name="'spinner'" h="1.5rem" />
    <template v-else>
      <v-svg v-if="icon" :name="icon" :h="h" />
      {{ label }}
    </template>
  </button>
</template>

<script>
  export default {
    name: 'VButton',
    props: {
      label: {
        type: String,
        default: ''
      },
      active: {
        type: Boolean,
        default: false
      },
      color: {
        type: String,
        default: 'primary'
      },
      icon: {
        type: String,
        default: null
      },
      size: {
        type: String,
        default: 'small' //small, medium, large
      },
      reverse: {
        type: Boolean,
        default: false
      },
      h: {
        type: String,
        default: null
      },
      isLoading: {
        type: Boolean,
        default: false
      }
    },
    emits: ['btn']
  }
</script>

<style scoped lang="scss">
  .button {
    background-color: $white;
    font-size: 11px;
    line-height: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &.small {
      font-size: 11px;
      line-height: 16px;
    }

    &.medium {
      padding: 8px;
      font-size: 14px;
      line-height: 21px;
    }

    &.large {
      font-size: 18px;
      line-height: 27px;
    }

    & .v-svg {
      margin-right: 5px;
      margin-left: 6px;
    }

    &.primary {
      border: 0.5px solid $primaryBlue;
      color: $primaryBlue;
    }

    &.secondary {
      border: 0.5px solid $primaryOrange;
      color: $primaryOrange;
    }
  }

  .active {
    &.primary {
      background-color: $primaryBlue;
      color: $white;
    }

    &.secondary {
      background-color: $primaryOrange;
      color: $white;
    }
  }

  .reverse {
    flex-direction: row-reverse;
    pointer-events: none;
  }

  button:disabled,
  button[disabled] {
    border: unset !important;
    background-color: $gray !important;
    color: $mediumGrey;
    cursor: unset;
  }
</style>
