<template>
  <div class="billing-search">
    <div class="search-title">{{ $t('billing.search') }}</div>
    <div class="inputs">
      <div class="avantage field">
        <div class="label">{{ $t('billing.inputs.discountContractCode.label') }}</div>
        <input-text
          v-model="billingSearch.discountContractCode"
          :placeholder="$t('billing.inputs.discountContractCode.placeholder')"
          width="260px"
          show-eraser
        />
      </div>
      <div class="perimetre field">
        <div class="label">{{ $t('billing.inputs.perimeter.label') }}</div>
        <input-select
          v-model="billingSearch.perimeter"
          traduction-key="billing.inputs.perimeter.values"
          :values="perimeters"
          :placeholder="$t('billing.inputs.perimeter.placeholder')"
          show-eraser
          width="260px"
        />
      </div>
      <div class="orderSupplierCnuf field">
        <div class="label">{{ $t('billing.inputs.orderSupplierCnuf.label') }}</div>
        <input-text
          v-model="billingSearch.orderSupplierCnuf"
          width="260px"
          :placeholder="$t('billing.inputs.orderSupplierCnuf.placeholder')"
          show-eraser
        />
      </div>
      <div class="offer-type field">
        <div class="label">{{ $t('billing.inputs.promotionTypeCode.label') }}</div>
        <input-select
          v-model="billingSearch.promotionTypeCode"
          width="260px"
          traduction-key="billing.inputs.promotionTypeCode.values"
          :values="promotionTypeCodes"
          :placeholder="$t('billing.inputs.promotionTypeCode.placeholder')"
          show-eraser
        />
      </div>
      <div class="orderSupplierLabel field">
        <div class="label">{{ $t('billing.inputs.orderSupplierLabel.label') }}</div>
        <input-text
          v-model="billingSearch.orderSupplierLabel"
          width="260px"
          :placeholder="$t('billing.inputs.orderSupplierLabel.placeholder')"
          show-eraser
        />
      </div>
      <div class="orderSupplierDepartmentCode field">
        <div class="label">{{ $t('billing.inputs.orderSupplierDepartmentCode.label') }}</div>
        <input-select-auto-complete
          :value="billingSearch.orderSupplierDepartmentCode"
          :options="
            departments.map(
              (department) => department.orderSupplierDepartmentCode + ' - ' + department.orderSupplierDepartmentLabel
            )
          "
          :loading="loadingDepartments"
          :placeholder="$t('billing.inputs.orderSupplierDepartmentCode.placeholder')"
          width="260px"
          show-eraser
          @input-value="
            billingSearch.orderSupplierDepartmentCode = departments.find(
              (department) => department.orderSupplierDepartmentCode + ' - ' + department.orderSupplierDepartmentLabel === $event
            )
          "
        />
      </div>
      <div class="offer-start-date field">
        <div class="label">{{ $t('billing.inputs.discountContractStartDate.label') }}</div>
        <date-picker
          v-model:value="billingSearch.discountContractStartDateInput"
          type="date"
          clearable
          format="DD/MM/YYYY"
          popup-class="mx-popup"
          class="mx-inputs"
          value-type="format"
          placeholder="DD/MM/YYYY"
          langString="fr"
        ></date-picker>
      </div>
      <div class="offer-source field">
        <div class="label">{{ $t('billing.inputs.discountContractChannel.label') }}</div>
        <input-select
          v-model="billingSearch.discountContractChannel"
          width="260px"
          traduction-key="billing.inputs.discountContractChannel.values"
          :values="discountContractChannels"
          :placeholder="$t('billing.inputs.discountContractChannel.placeholder')"
          show-eraser
        />
      </div>
      <div class="block">
        <div class="offer-end-date field">
          <div class="label">{{ $t('billing.inputs.discountContractEndDate.label') }}</div>
          <date-picker
            v-model:value="billingSearch.discountContractEndDateInput"
            type="date"
            clearable
            format="DD/MM/YYYY"
            popup-class="mx-popup"
            class="mx-inputs"
            value-type="format"
            placeholder="DD/MM/YYYY"
            langString="fr"
          ></date-picker>
        </div>
        <div v-if="billingSearch?.discountContractStatusCode?.id === 3" class="facturation-date field">
          <div class="label">{{ $t('billing.inputs.discountContractBillingDate.label') }}</div>
          <date-picker
            v-model:value="billingSearch.discountContractBillingDateInput"
            type="date"
            clearable
            format="DD/MM/YYYY"
            popup-class="mx-popup"
            class="mx-inputs"
            value-type="format"
            placeholder="DD/MM/YYYY"
            langString="fr"
          ></date-picker>
        </div>
      </div>
      <div class="discountContractStatusCode field">
        <div class="label">{{ $t('billing.inputs.discountContractStatusCode.label') }}</div>
        <input-radio
          v-model="billingSearch.discountContractStatusCode"
          traduction-key="billing.inputs.discountContractStatusCode.values"
          :values="discountContractStatusCodes"
          vertical
        />
      </div>
    </div>
    <div class="footer">
      <div class="files">
        <div class="files-to-download-title">{{ $t('billing.filesToDownload.title') }}</div>
        <files-to-download />
      </div>
      <div class="buttons">
        <v-button :label="$t('billing.buttons.reset')" :active="true" color="secondary" @click="reset()" />
        <v-button :label="$t('billing.buttons.search')" :active="true" @click="search()" />
      </div>
    </div>
  </div>
</template>

<script>
  import { DateTime } from 'luxon'
  import { mapActions, mapState } from 'vuex'
  import FilesToDownload from './FilesToDownload'
  import DatePicker from 'vue-datepicker-next'
  import 'vue-datepicker-next/index.css'
  import 'vue-datepicker-next/locale/fr'

  const initData = {
    discountContractCode: null,
    perimeter: null,
    orderSupplierCnuf: null,
    promotionTypeCode: null,
    orderSupplierLabel: null,
    orderSupplierDepartmentCode: null,
    discountContractChannel: null,
    discountContractBillingDate: null,
    discountContractBillingDateInput: null, // DateInput fields are used to keep date in the component datepicker after reseach. It keeps the same date format as the one in the components.
    discountContractStartDate: null,
    discountContractStartDateInput: null,
    discountContractEndDate: null,
    discountContractEndDateInput: null,
    discountContractStatusCode: null,
  }

  export default {
    name: 'BillingSearch',
    components: { FilesToDownload, DatePicker },
    emits: ['search'],
    data() {
      return {
        loadingDepartments: false,
        billingSearch: { ...initData },
        perimeters: [{ id: 'ALIMENTAIRE' }, { id: 'NON-ALIMENTAIRE' }],
        promotionTypeCodes: [{ id: 'RI' }, { id: 'CAB' }, { id: 'BR' }],
        discountContractChannels: [{ id: 'HYPER' }, { id: 'SUPER' }],
        discountContractStatusCodes: [
          { id: 2, label: 'POINTE' },
          { id: 6, label: 'PREFACTURE' },
          { id: 3, label: 'FACTURE' },
        ],
      }
    },
    computed: {
      ...mapState('referential', ['departments']),

      today() {
        return DateTime.now().toISODate()
      },
    },
    async created() {
      this.loadingDepartments = true
      await this.fetchDepartments()
      this.loadingDepartments = false
    },
    methods: {
      ...mapActions('referential', ['fetchDepartments']),

      search() {
        let mandatoryFieldIsMissing = false
        if (this.billingSearch.discountContractBillingDateInput) {
          this.billingSearch.discountContractBillingDate = DateTime.fromFormat(
            this.billingSearch.discountContractBillingDateInput,
            'dd/MM/yyyy'
          ).toFormat('yyyy-MM-dd')
        }
        if (this.billingSearch.discountContractStartDateInput) {
          this.billingSearch.discountContractStartDate = DateTime.fromFormat(
            this.billingSearch.discountContractStartDateInput,
            'dd/MM/yyyy'
          ).toFormat('yyyy-MM-dd')
        }
        if (this.billingSearch.discountContractEndDateInput) {
          this.billingSearch.discountContractEndDate = DateTime.fromFormat(
            this.billingSearch.discountContractEndDateInput,
            'dd/MM/yyyy'
          ).toFormat('yyyy-MM-dd')
        }
        if (this.billingSearch?.discountContractStatusCode?.id === 3) {
          mandatoryFieldIsMissing =
            !this.billingSearch.perimeter ||
            !this.billingSearch.discountContractChannel ||
            !this.billingSearch.discountContractStatusCode
          if (mandatoryFieldIsMissing) return this.$toast.error(this.$t('billing.errors.nothingJustif'), { duration: 20000 })
        } else {
          this.billingSearch.discountContractBillingDate = null
          this.billingSearch.discountContractBillingDateInput = null
          mandatoryFieldIsMissing =
            (!this.billingSearch.discountContractCode?.length &&
              !this.billingSearch.orderSupplierCnuf?.length &&
              !this.billingSearch.discountContractEndDate) ||
            !this.billingSearch.perimeter ||
            !this.billingSearch.discountContractChannel ||
            !this.billingSearch.discountContractStatusCode
          if (mandatoryFieldIsMissing) return this.$toast.error(this.$t('billing.errors.nothing'), { duration: 20000 })
        }

        this.$emit('search', JSON.parse(JSON.stringify(this.billingSearch)))
      },

      reset() {
        this.billingSearch = { ...initData }
      },
    },
  }
</script>

<style scoped lang="scss">
  .billing-search {
    padding: 1rem;
    background: white;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 15px;

    .search-title,
    .files-to-download-title {
      padding-bottom: 0.2rem;
      border-bottom: 2px solid $primaryOrange;
      @extend %font-body-bold;
      width: fit-content;
      font-size: 18px;
    }

    .inputs {
      width: 80%;
      flex-wrap: wrap;
      margin: 0 auto;
      display: flex;
      gap: 1.7rem 7rem;

      .field {
        width: 44%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @extend %font-body-semi-bold;
      }

      .block {
        display: flex;
        flex-direction: column;
        width: 44%;
        row-gap: 1.7rem;

        .field {
          width: 100%;
        }
      }
    }

    .mx-datepicker {
      width: unset;
    }

    .mx-inputs::v-deep input {
      border: 0.5px solid #5fbfc6;
      border-radius: 10px;
      height: 28px;
      width: 260px;
    }

    .footer {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .files-to-download-title {
        margin-top: 2rem;
      }

      .files-to-download {
        margin-top: 1rem;
      }

      .buttons {
        display: flex;
        align-items: center;
        margin-top: 2rem;

        .button {
          padding: 8px;
          border-radius: 8px;
          @extend %font-body-semi-bold;
          font-size: 14px;
          margin-left: 1rem;
        }
      }
    }
  }
</style>
