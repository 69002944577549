import { extendWithAuthorization, httpNip } from '@/http'

const state = {
  departments: [],
  tracts: [],
  marketsWithNullableGDN: []
}

const getters = {
}

const mutations = {
  SET_DEPARTMENTS(state, departments) {
    state.departments = departments
  },

  SET_TRACTS(state, tracts) {
    state.tracts = tracts
  },
  SET_MARKETS_WITH_NULLABLE_GDN(state, marketsWithNullableGDN){
    state.marketsWithNullableGDN = marketsWithNullableGDN
  }
}

const actions = {
  async fetchDepartments({ _, commit }) {
    try {
      const extendedHttp = await extendWithAuthorization(httpNip)
      const data = await extendedHttp.get('referential/departments').json()
      const formattedDepartments = data.map(dep => ({ ...dep, paymentSupplierDepartmentCodeLabel: `${dep?.paymentSupplierDepartmentCode} - ${dep?.paymentSupplierDepartmentLabel}` }))
      commit('SET_DEPARTMENTS', formattedDepartments)
    } catch (error) {
      return error
    }
  },

  async fetchTracts({ _, commit }) {
    try {
      const extendedHttp = await extendWithAuthorization(httpNip)
      const data = await extendedHttp.get('referential/tracts').json()
      commit('SET_TRACTS', data)
    } catch (error) {
      return error
    }
  },

  async fetchMarketsWithNullableGDN({ _, commit }) {
    try {
      const extendedHttp = await extendWithAuthorization(httpNip)
      const data = await extendedHttp.get('referential/markets').json()
      commit('SET_MARKETS_WITH_NULLABLE_GDN', data)
    } catch (error) {
      return error
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
