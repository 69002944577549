<template>
  <Card :is-loading="isLoading || isLoadUpdateFinancingMode">
    <template #header>
      <div class="header-card-custom">
        <CardHeader :title="$t('promotionDetail.bloc.discountAndFinancing.title')" />
        <div class="actions">
          <v-svg
            v-if="!isEditModeFinancing && promotionDetail?.discountContractStatusCode === 1"
            @click="isEditModeFinancing = !isEditModeFinancing"
            name="edit-mode"
            h="1.5rem"
          />
          <v-svg v-if="isEditModeFinancing || isEditMode" @click="onClose" name="circle-close" h="2rem" />
          <v-svg v-if="isEditModeFinancing || isEditMode" @click="updateFinancingNip()" name="circle-validate" h="2rem" />
        </div>
      </div>
    </template>
    <template #body>
      <ul>
        <li>
          <span class="title">{{ $t('promotionDetail.bloc.discountAndFinancing.value') }}</span>
          <span class="flex-2"
            >{{ promotionDetail ? promotionDetail.promotionBenefit.benefitValue : '' }}
            {{
              promotionDetail && promotionDetail.promotionBenefit.benefitUnit
                ? getCodeValue('benefitUnit', promotionDetail.promotionBenefit.benefitUnit)
                : ''
            }}</span
          >
        </li>
        <li>
          <span class="title">{{ $t('promotionDetail.bloc.discountAndFinancing.constraint') }}</span>
          <span class="flex-2">{{ promotionDetail ? promotionDetail.promotionBenefit.benefitPurchaseConditionThreshold : '' }}</span>
        </li>
      </ul>
      <div class="card-subtitle">{{ $t('promotionDetail.bloc.discountAndFinancing.source') }}</div>
      <ul class="group">
        <li>
          <span class="title">{{ $t('promotionDetail.bloc.discountAndFinancing.finance') }} 1</span>
          <div class="flex flex-2 items-center">
            <span class="flex-1">{{
              promotionDetail && promotionDetail.promotionPartnerBilling.promotionFirstPartnerBillingValue
                ? `${promotionDetail.promotionPartnerBilling.promotionFirstPartnerBillingValue}%`
                : ''
            }}</span>
            <span class="flex-1 type">{{
              promotionDetail ? promotionDetail.promotionPartnerBilling.promotionFirstPartnerBillingTypeLabel : ''
            }}</span>
          </div>
        </li>
        <li>
          <span class="title">{{ $t('promotionDetail.bloc.discountAndFinancing.finance') }} 2</span>
          <div class="flex flex-2 items-center">
            <span class="flex-1">{{
              promotionDetail && promotionDetail.promotionPartnerBilling.promotionSecondPartnerBillingValue
                ? `${promotionDetail.promotionPartnerBilling.promotionSecondPartnerBillingValue}%`
                : ''
            }}</span>
            <span class="flex-1 type">{{
              promotionDetail ? promotionDetail.promotionPartnerBilling.promotionSecondPartnerBillingTypeLabel : ''
            }}</span>
          </div>
        </li>
      </ul>
      <div class="card-subtitle">{{ $t('promotionDetail.bloc.discountAndFinancing.nip') }}</div>
      <ul class="group">
        <li>
          <span class="title">{{ $t('promotionDetail.bloc.discountAndFinancing.finance') }} 1</span>
          <div v-if="!isEditModeFinancing && !isEditMode" class="flex flex-2 items-center">
            <span class="flex-1">{{
              promotionDetail && promotionDetail.discountPartnerBilling.discountFirstPartnerBillingValue
                ? `${promotionDetail.discountPartnerBilling.discountFirstPartnerBillingValue}%`
                : ''
            }}</span>
            <span class="flex-1 type">{{
              promotionDetail && promotionDetail.discountPartnerBilling.discountFirstPartnerBillingValue
                ? promotionDetail.discountPartnerBilling.discountFirstPartnerBillingTypeLabel
                : ''
            }}</span>
          </div>
          <div v-else class="flex flex-2 items-center edit-mode">
            <input-text v-model="financingNip.firstFinancing.value" fixed :input-type="'number'" width="70px" :min="0" :max="100" />

            <input-select
              v-model="financingNip.firstFinancing.type"
              :values="getFinancingTypes(financingNip.secondFinancing.type)"
              selection-key="label"
              :placeholder="'Aucun'"
              width="140px"
            />
          </div>
        </li>
        <li>
          <span class="title">{{ $t('promotionDetail.bloc.discountAndFinancing.finance') }} 2</span>
          <div v-if="!isEditModeFinancing && !isEditMode" class="flex flex-2 items-center">
            <span class="flex-1">{{
              promotionDetail && promotionDetail?.discountPartnerBilling?.discountSecondPartnerBillingValue
                ? `${promotionDetail.discountPartnerBilling.discountSecondPartnerBillingValue}%`
                : ''
            }}</span>
            <span class="flex-1 type">{{
              promotionDetail && promotionDetail?.discountPartnerBilling?.discountSecondPartnerBillingValue
                ? promotionDetail.discountPartnerBilling.discountSecondPartnerBillingTypeLabel
                : ''
            }}</span>
          </div>
          <div v-else class="flex flex-2 items-center edit-mode">
            <span> {{ (100 - financingNip.firstFinancing.value).toFixed(2).replace(/[.,]00$/, '') }} </span>
            <input-select
              v-model="financingNip.secondFinancing.type"
              :values="getFinancingTypes(financingNip.firstFinancing.type)"
              selection-key="label"
              :placeholder="'Aucun'"
              width="140px"
            />
          </div>
        </li>
      </ul>
    </template>
  </Card>
</template>

<script>
  import Card from '@/components/card/Card'
  import CardHeader from '@/components/card/CardHeader'
  import valueMixin from '@/mixins/value'
  import { globalProperties } from '@/main'
  export default {
    name: 'PromotionDetailDiscountFinancing',
    components: {
      Card,
      CardHeader
    },
    mixins: [valueMixin],
    props: {
      promotionDetail: {
        default: null,
        required: true
      },
      financingNip: {
        type: Object,
        default: {
          firstFinancing: {
            value: 0,
            oldValue: 0,
            type: null,
            oldType: null
          },
          secondFinancing: {
            value: 0,
            oldValue: 0,
            type: null,
            oldType: null
          }
        }
      },
      financingTypes: {
        type: Array,
        default: []
      },
      isLoading: {
        type: Boolean,
        default: false
      },
      isEditMode: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        isEditModeFinancing: false,
        isLoadUpdateFinancingMode: false
      }
    },
    methods: {
      onClose() {
        this.$emit('cancel')
        this.isEditModeFinancing = !this.isEditModeFinancing
      },
      async updateFinancingNip() {
        this.isLoadUpdateFinancingMode = true
        if (
          (100 - this.financingNip.firstFinancing.value > 0 && this.financingNip.secondFinancing.type === null) ||
          this.financingNip.firstFinancing.value < 0 ||
          this.financingNip.secondFinancing.value < 0 ||
          this.financingNip.firstFinancing.value > 100 ||
          this.financingNip.secondFinancing.value > 100
        ) {
          this.$toast.error(this.$t('promotionDetail.bloc.discountAndFinancing.updateError'))
          this.isLoadUpdateFinancingMode = false
        } else {
          try {
            const commentFirstFinancing =
              this.financingNip?.firstFinancing?.oldValue != this.financingNip?.firstFinancing?.value ||
              this.financingNip?.firstFinancing?.oldType?.label != this.financingNip?.firstFinancing?.type?.label
                ? '&rsaquo; Changement ' +
                  this.$t('promotionDetail.bloc.discountAndFinancing.finance') +
                  ' 1 : ' +
                  this.financingNip?.firstFinancing?.oldType.label +
                  ' (' +
                  this.financingNip?.firstFinancing?.oldValue +
                  '%) &rArr; ' +
                  (this.financingNip?.firstFinancing?.type?.label ?? 'Aucun') +
                  ' (' +
                  this.financingNip?.firstFinancing?.value +
                  '%) '
                : null

            const commentSecondFinancing =
              this.financingNip?.secondFinancing?.oldValue != 100 - (this.financingNip?.firstFinancing.value ?? 0) ||
              this.financingNip?.secondFinancing?.oldType != this.financingNip?.secondFinancing?.type
                ? '&rsaquo; Changement ' +
                  this.$t('promotionDetail.bloc.discountAndFinancing.finance') +
                  ' 2 : ' +
                  (this.financingNip.secondFinancing.oldType === null ? 'vide' : this.financingNip.secondFinancing.oldType.label) +
                  ' (' +
                  (100 - (this.financingNip?.firstFinancing.oldValue ?? 0)).toFixed(2).replace(/[.,]00$/, '') +
                  '%) &rArr; ' +
                  (this.financingNip?.secondFinancing?.type?.label ?? 'Aucun') +
                  ' (' +
                  (100 - (this.financingNip?.firstFinancing.value ?? 0)).toFixed(2).replace(/[.,]00$/, '') +
                  '%) '
                : null

            const body = {
              discountContractCode: this.promotionDetail?.discountContractCode,
              discountContractStatusCode: this.promotionDetail?.discountContractStatusCode,
              discountContractType: this.promotionDetail?.discountContractType,
              discountContractChannel: this.promotionDetail?.discountContractChannel,
              discountContractYear: this.promotionDetail?.discountContractYear,

              firstBillingOldValue: this.promotionDetail?.discountPartnerBilling?.discountFirstPartnerBillingValue ?? 0,
              firstBillingOldTypeCode: this.promotionDetail?.discountPartnerBilling?.discountContractFirstPartnerBillingTypeCode ?? null,
              firstBillingOldTypeLabel: this.promotionDetail?.discountPartnerBilling?.discountFirstPartnerBillingTypeLabel ?? null,

              firstBillingValue: this.financingNip?.firstFinancing?.value ?? 0,
              firstBillingTypeCode: this.financingNip?.firstFinancing?.type?.id ?? null,
              firstBillingTypeLabel: this.financingNip?.firstFinancing?.type?.label ?? null,

              secondBillingOldValue:
                100 - (this.promotionDetail?.discountPartnerBilling?.discountSecondPartnerBillingValue ?? 0) === 0
                  ? null
                  : 100 - (this.promotionDetail?.discountPartnerBilling?.discountFirstPartnerBillingValue ?? 0),
              secondBillingOldTypeCode: this.promotionDetail?.discountPartnerBilling?.discountContractSecondPartnerBillingTypeCode ?? null,
              secondBillingOldTypeLabel: this.promotionDetail?.discountPartnerBilling?.discountSecondPartnerBillingTypeLabel ?? null,

              secondBillingValue:
                100 - (this.financingNip?.firstFinancing.value ?? 0) === 0 ? null : 100 - (this.financingNip?.firstFinancing.value ?? 0),
              secondBillingTypeCode: this.financingNip?.secondFinancing?.type?.id ?? null,
              secondBillingTypeLabel: this.financingNip?.secondFinancing?.type?.label ?? null,

              commentFinancing: [commentFirstFinancing, commentSecondFinancing].filter(Boolean).join('<br/>'),
              userMatricule: globalProperties?.user?.uid
            }

            this.$emit('updateFinancing', body)
          } catch (error) {
            this.$toast.error(this.$t('message.error.messageGlobal'))
          } finally {
            this.isEditModeFinancing = !this.isEditModeFinancing
            this.isLoadUpdateFinancingMode = false
          }
        }
      },
      getFinancingTypes(fType) {
        return fType ? this.financingTypes.filter(type => type.id !== fType.id) : this.financingTypes
      }
    }
  }
</script>

<style lang="scss" scoped>
  .header-card-custom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .actions {
      display: flex;

      .v-svg {
        margin-right: 19px;
        cursor: pointer;
        &.svg-circle-close {
          color: $primaryOrange;
        }
        &.svg-circle-validate {
          color: $primaryBlue;
        }
      }
    }
  }

  .card {
    width: 32%;
  }

  .card-subtitle {
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    color: $primaryOrange;
    padding: 10px 19px 0;
    font-weight: bold;
  }

  ul.group {
    background-color: $veryLightGrey;
    border-radius: 15px;
  }

  ul li {
    min-height: 30px;
    display: flex;
    align-items: center;
    padding: 0 19px;

    & div {
      width: 100%;
      min-height: 30px;
    }

    &:nth-child(2n-1) {
      background-color: $veryLightGrey;
      border-radius: 15px;
    }

    .edit-mode {
      .input-select {
        margin-left: 2rem;
      }

      span {
        width: 70px;
        padding-left: 16px;
      }
    }

    & .type {
      color: $darkGrey;
    }

    & .title {
      flex: 1;
      font-weight: bold;
      text-transform: uppercase;
      margin-right: 10px;
      line-height: 20px;
    }

    & span:last-child {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 20px;

      & span {
        font-size: 12px;
        float: right;
      }
    }
  }
</style>
