<template>
  <div class="loyalty-amounts">
    <div class="header">
      <div class="loyalty-amounts-title">
        <v-svg name="loyalty" h="1.5rem" />
        {{ $t('loyaltyAmounts.title') }}
      </div>
      <v-svg name="question-circle" h="3rem" @mouseover="showInfoPopup = true" @mouseleave="showInfoPopup = false" />
      <info-popup v-if="showInfoPopup" :msg="$t('loyaltyAmounts.info')" />
    </div>
    <loyalty-amounts-search @search="launchSearch" />
    <loading v-if="loading" :is-loading="true" />
    <loyalty-amounts-array v-if="search && !loading"
                           :rows="arrayData"
                           :display-key="arrayDisplayKey"
                           @switchKey="arrayDisplayKey = $event" />
  </div>
  <confirm-dialog ref="confirmDialog" />
</template>

<script>
  import { extendWithAuthorization, httpNip } from '@/http'
  import LoyaltyAmountsSearch from '@/components/loyaltyAmounts/LoyaltyAmountsSearch'
  import LoyaltyAmountsArray from '@/components/loyaltyAmounts/LoyaltyAmountsArray'
  import InfoPopup from '../components/base/InfoPopup'

  export default {
    name: 'LoyaltyAmounts',
    components: { InfoPopup, LoyaltyAmountsArray, LoyaltyAmountsSearch },
    data() {
      return {
        arrayData: undefined,
        arrayDisplayKey: 'SITE',
        loading: false,
        search: undefined,
        showInfoPopup: false
      }
    },
    computed: {
      arrayRows() {
        return this.arrayDisplayKey === 'SITE' ? (this.arrayData?.[0]?.data ?? []) : (this.arrayData?.[1]?.data ?? [])
      }
    },
    methods: {
      async launchSearch(params) {
        this.loading = true

        const searchParams = {
          ...params,
          perimeter: params.perimeter?.id,
          discountContractChannel: params.discountContractChannel?.id,
          orderSupplierDepartmentCode: params.orderSupplierDepartmentCode,
          promotionTypeCode: params.promotionTypeCode?.id
        }

        Object.keys(searchParams).forEach(key => searchParams[key] ?? delete searchParams[key])

        try {
          const extendedHttp = await extendWithAuthorization(httpNip)
          this.arrayData = await extendedHttp.get('loyalty-amounts/search', { searchParams }).json()
          this.search = params
        } catch (err) {
          console.error(err)
          this.$toast.error(this.$t('loyaltyAmounts.errors.search'))
        }

        this.loading = false
      }
    }
  }
</script>

<style scoped lang="scss">
  .loyalty-amounts {
    padding: 2rem;
    position: relative;
    height: 100%;

    .header {
      height: 5%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 1rem;

      .loyalty-amounts-title {
        padding-bottom: 0.5rem;
        border-bottom: 4px solid $primaryOrange;
        display: flex;
        @extend %font-title-1;

        .svg-loyalty { margin-right: 0.8rem; }
      }

      .svg-question-circle { cursor: pointer; }

      .info-popup {
        position: absolute;
        top: 3rem;
        right: 1rem;
      }
    }

    .loyalty-amounts-search {
      margin-top: 1rem;
    }

    .loyalty-amounts-array {
      max-height: 100%;
      height: -webkit-fill-available;
      margin-top: 1rem;
    }
  }
</style>
