<template>
  <!-- eslint-disable vue/no-v-html -->
  <div :class="[`svg-${name}`, 'v-svg']" :style="{ height: h }" v-html="content" />
  <!--eslint-enable-->
</template>

<script>
  export default {
    name: 'VSvg',

    props: {
      h: {
        default: '1.5rem',
        type: String
      },
      name: {
        type: String,
        required: true
      }
    },

    data() {
      return {
        content: null
      }
    },

    watch: {
      name: {
        immediate: true,
        async handler() {
          const svgModule = await import(`!svg-inline-loader?classPrefix=true&idPrefix=true!@/assets/svg/${this.name}.svg`)
          this.content = svgModule.default
        }
      }
    }
  }
</script>

<style lang="scss">
  .dp-svg {
    display: inline-flex;
    justify-content: center;
    /*-webkit-tap-highlight-color : transparent;*/
    vertical-align: middle;
  }

  svg {
    height: 100%;
  }
</style>
