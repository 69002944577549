<template>
  <div class="navbar-item">
    <v-svg v-if="iconName" class="navbar-item-icon" :name="iconName" />
    <span> {{ name }} </span>
  </div>
</template>

<script>
import VSvg from '@/components/base/Svg'
export default {
  name: 'NavbarItem',
  components: {
    VSvg
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    iconName: {
        type: String,
      default: ''
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.navbar-item{
    display: flex;
    align-items: center;
    text-transform: uppercase;
    &-icon{
        margin: 5px;
    }
}
</style>
