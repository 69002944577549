import { getFirestore, collection, onSnapshot, orderBy, query } from 'firebase/firestore'

const db = getFirestore()

const state = {
  filesBilling: [],
  filesPromotionImport: [],
  filesPromotionExport: []

}

const getters = {}

const mutations = {
  SET_FILES_BILLING(state, filesBilling) {
    state.filesBilling = filesBilling
  },
  SET_FILES_PROMOTION_IMPORT(state, filesPromotionImport) {
    state.filesPromotionImport = filesPromotionImport
  },
  SET_FILES_PROMOTION_EXPORT(state, filesPromotionExport) {
    state.filesPromotionExport = filesPromotionExport
  }
}

const actions = {
  async fetchFilesBilling({ _ , commit }) {
    const q = query(collection(db, "FILES_BILLING"), orderBy("createdAt", 'desc'))
    onSnapshot(q, querySnap => {
      const files = []
      querySnap.forEach(doc => { files.push(doc.data()) })
      commit('SET_FILES_BILLING', files)
    })
  },

  async fetchFilesPromotionImport({ _ , commit }) {
    const q = query(collection(db, "FILES_PROMOTION_IMPORT"), orderBy("createdAt", 'desc'))
    onSnapshot(q, querySnap => {
      const files = []
      querySnap.forEach(doc => { files.push(doc.data()) })
      commit('SET_FILES_PROMOTION_IMPORT', files)
    })
  },

  async fetchFilesPromotionExport({ _ , commit }) {
    const q = query(collection(db, "FILES_PROMOTION_EXPORT"), orderBy("createdAt", 'desc'))
    onSnapshot(q, querySnap => {
      const files = []
      querySnap.forEach(doc => { files.push(doc.data()) })
      commit('SET_FILES_PROMOTION_EXPORT', files)
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
