<template>
  <div class="promotion">
    <PromotionSearch v-if="showFilter"
                     @changedFilter="fetchPromotionWithFilters"
                     @closeModalFilter="showFilter = false" />
    <v-button class="button-search"
              :size="'medium'"
              :icon="'search'"
              :h="'2rem'"
              :color="'primary'"
              :active="true"
              :label="$t('promotion.startResearch')"
              @click="showFilter = true" />

    <h1>
      <TitleBar class="promotion-title" :title="$t('promotion.title')" :icon-name="'promotion'" />
    </h1>

    <div class="tag-filter">
      <div class="tag-filter-group">
        <Tag v-for="filter in tabFiltersField"
             :key="filter"
             :title="$t('filters.' + filter)"
             :content="' : ' + getFilterField(filter)"
             :color="'secondaryOrange'"
             :fill="true"
             :round="true" />
      </div>
      <div class="tag-filter-group">
        <Tag v-for="filter in filters.types ? filters.types : []"
             :key="filter.id"
             :content="filter.name.toString()"
             :color="'primaryBlue'"
             :fill="true" />
        <Tag v-for="filter in tabFiltersGlobal"
             v-show="filter !== 'mandatId'"
             :key="filter"
             :content="filters.global[filter].toString()"
             :color="'primaryBlue'"
             :fill="true" />
      </div>
    </div>

    <div class="promotion-tab">
      <ag-grid-vue style="width: 100%; height: 100%"
                   class="ag-theme-alpine"
                   :column-defs="columnDefs"
                   :row-data="rowData"
                   :default-col-def="defaultColDef"
                   :overlay-no-rows-template="overlayNoRowsTemplate"
                   :pagination-page-size="paginationPageSize"
                   :pagination="true"
                   :suppress-pagination-panel="true"
                   :row-selection="rowSelection"
                   :get-row-style="getRowStyle"
                   :suppress-drag-leave-hides-columns="true"
                   :getContextMenuItems="getContextMenuItems"
                   @selection-changed="onSelectionRow"
                   @pagination-changed="onPaginationChanged"
                   @grid-ready="onGridReady"
                   @filter-changed="onFilterChanged" />
      <div class="params">
        <button class="button-item" @click="showParamsColumns = !showParamsColumns">
          <v-svg :name="'plus'" :h="'2rem'" />
        </button>
        <button class="button-item">
          <v-svg :name="'clear'" :h="'2rem'" @click="clearFiltersAggrid" />
        </button>
        <params-columns
          v-if="showParamsColumns"
          :show-modal="showParamsColumns"
          :name="'type'"
          :column-defs="columnDefs"
          @close-modal="showParamsColumns = false"
          @edit-columns="editParamsColumns" />
      </div>
    </div>
    <div v-if="rowData && rowData.length > 0" class="pagination-container">
      <pagination :value="page" :total-visible="7" :number-pages="nbPage" @onPage="changedPage" />
      <page-size :tab="[10, 20, 50, 100]" :value="paginationPageSize" @onPageSize="changedPageSize" />
    </div>
  </div>
</template>

<script>
  import Tag from '@/components/Tag'
  import PromotionSearch from '@/views/promotion/PromotionSearch.vue'
  import dateMixin from '@/mixins/date'
  import contractStatus from '@/utils/codeStatus.json'
  import { AgGridVue } from 'ag-grid-vue3'
  import { mapActions, mapState } from 'vuex'
  import { fieldsPeriodDate } from '@/utils/CONF'
  import { DateTime } from 'luxon'

  export default {
    name: 'Promotion',
    components: {
      AgGridVue,
      PromotionSearch,
      Tag
    },
    mixins: [dateMixin],
    props: ['isRefresh'],
    data() {
      return {
        page: 1,
        nbPage: 1,
        showFilter: false,
        gridApi: null,
        paginationPageSize: 50,
        columnDefs: [
          { headerName: this.$t('promotion.columnName.market'), field: 'market', width: 70 },
          { headerName: this.$t('promotion.columnName.channel'), field: 'channel', width: 70 },
          { headerName: this.$t('promotion.columnName.cnufRef'), field: 'cnufRef', width: 80 },
          { headerName: this.$t('promotion.columnName.supplierName'), field: 'supplierName', width: 165 },
          { headerName: this.$t('promotion.columnName.gdnRef'), field: 'gdnRef', width: 100 },
          { headerName: this.$t('promotion.columnName.promotionTyp'), field: 'typeAv', width: 75 },
          { headerName: this.$t('promotion.columnName.promotionNum'), field: 'nAvantage', width: 100 },
          { headerName: this.$t('promotion.columnName.tractName'), field: 'tractName', width: 120 },
          { headerName: this.$t('promotion.columnName.tractStart'), field: 'debutTract', width: 125,
            filterParams:{ textMatcher: ({value, filterText}) => this.formatDate(value, 'DD/MM/YYYY').includes(filterText) },
            valueFormatter: params => params.value ? this.formatDate(params.value, 'DD/MM/YYYY') : null
          },
          { headerName: this.$t('promotion.columnName.tractEnd'), field: 'finTract', width: 125,
            filterParams:{ textMatcher: ({value, filterText}) => this.formatDate(value, 'DD/MM/YYYY').includes(filterText) },
            valueFormatter: params => params.value ? this.formatDate(params.value, 'DD/MM/YYYY') : null
          },
          { headerName: this.$t('promotion.columnName.themeName'), field: 'nomTheme', width: 120 },
          { headerName: this.$t('promotion.columnName.promotionDateStart'), field: 'promotionDateStart', width: 125,
            filterParams:{ textMatcher: ({value, filterText}) => this.formatDate(value, 'DD/MM/YYYY').includes(filterText) },
            valueFormatter: params => params.value ? this.formatDate(params.value, 'DD/MM/YYYY') : null
          },
          { headerName: this.$t('promotion.columnName.promotionDateEnd'), field: 'promotionDateEnd', width: 125,
            filterParams:{ textMatcher: ({value, filterText}) => this.formatDate(value, 'DD/MM/YYYY').includes(filterText) },
            valueFormatter: params => params.value ? this.formatDate(params.value, 'DD/MM/YYYY') : null
          },
          { headerName: this.$t('promotion.columnName.fundingMethod1'), field: 'modeDeFin1', width: 100 },
          { headerName: this.$t('promotion.columnName.fundingMethod2'), field: 'modeDeFin2', width: 100 },
          { headerName: this.$t('promotion.columnName.nbCug'), field: 'nbreDeCug', width: 90 },

          /************************************************** */
          //POPUP AJOUT DE COLONNE
          //CONTRAT
          {
            groupId: 1,
            headerName: this.$t('promotion.columnName.contractRange'),
            field: 'rayonContrat',
            width: 100,
            hide: true
          },
          {
            groupId: 1,
            headerName: this.$t('promotion.columnName.contractCnuf'),
            field: 'cnufContrat',
            width: 100,
            hide: true
          },
          {
            groupId: 1,
            headerName: this.$t('promotion.columnName.contractGdn'),
            field: 'gdnContrat',
            width: 100,
            hide: true
          },
          {
            groupId: 1,
            headerName: this.$t('promotion.columnName.contractSupplierName'),
            field: 'nomFournisseurContrat',
            width: 100,
            hide: true
          },
          //FACTURATION
          {
            groupId: 2,
            headerName: this.$t('promotion.columnName.billingDate'),
            field: 'dateFacturation',
            width: 125,
            filterParams:{ textMatcher: ({value, filterText}) => this.formatDate(value, 'DD/MM/YYYY').includes(filterText) },
            valueFormatter: params => params.value ? this.formatDate(params.value, 'DD/MM/YYYY') : null,
            hide: true
          },
          {
            groupId: 2,
            headerName: this.$t('promotion.columnName.totalBillingAmount'),
            field: 'montantTotalFacturation',
            width: 100,
            valueFormatter: params => params.value?.toLocaleString("fr-FR", { style: "currency", currency: "EUR" }),
            hide: true
          }
          /************************************************** */
        ],
        rowData: [],
        defaultColDef: {
          filter: 'agTextColumnFilter',
          floatingFilter: true,
          resizable: true,
          sortable: true
        },
        rowSelection: 'single',
        overlayNoRowsTemplate:
          '<div class="nodata"><div class="nodata-logo"></div><span>Lance une recherche pour afficher la donnée souhaitée !</span></div>',
        showParamsColumns: false,
        getRowStyle: null
      }
    },
    computed: {
      ...mapState({
        promotions: state => state.promotion.promotions,
        filters: state => state.promotion.filters,
        filtersAggrid: state => state.promotion.filtersAggrid,
        isRefreshData : state => state.common.isRefreshData
      }),
      tabFiltersGlobal() {
        return this.filters.global ? this.tabFilters(this.filters.global) : []
      },
      tabFiltersField() {
        return this.filters.field ? this.tabFilters(this.filters.field) : []
      }
    },
    watch: {},
    async created() {
      this.getRowStyle = params => {
        switch (params.node.data.discountContractStatus) {
          case contractStatus.none.code:
            return { color: contractStatus.none.codeColor }
          case contractStatus.target.code:
            return { color: contractStatus.target.codeColor }
          case contractStatus.billing.code:
            return { color: contractStatus.billing.codeColor }
          case contractStatus.disputeDeclared.code:
            return { color: contractStatus.disputeDeclared.codeColor }
          case contractStatus.anomaly.code:
            return { color: contractStatus.anomaly.codeColor }
          case contractStatus.preBilling.code:
            return { color: contractStatus.preBilling.codeColor }
          case contractStatus.edit.code:
            return { color: contractStatus.edit.codeColor }
          case contractStatus.delete.code:
            return { color: contractStatus.delete.codeColor }
          case contractStatus.disable.code:
            return { color: contractStatus.disable.codeColor }
          default:
            return { color: contractStatus.none.codeColor }
        }
      }
      //check if there are filters and relaod promotion
      if(Object.keys(this.filters).length && this.isRefreshData){
        this.$store.commit('promotion/SET_PROMOTIONS', [])
        this.rowData = []
        await this.initTab()
        this.$store.commit('common/SET_REFRESH_DATA', false)
        await this.fetchPromotionWithFilters(this.filters)
      }
    },
    methods: {
      ...mapActions('promotion', ['fetchPromotion']),
      initTab() {
        this.promotions.forEach(element => {
          this.rowData.push({
            channel: element.discountContractChannel,
            market: element.orderSupplierDepartmentCode,
            cnufRef: element.orderSupplierCnuf,
            supplierName: element.orderSupplierLabel,
            gdnRef: element.orderSupplierNegoGroupUid,
            typeAv: element.promotionTypeCode,
            nAvantage: element.discountContractCode,
            tractName: element.commercialOperationLabel,
            debutTract: element.commercialOperationStartDate,
            finTract: element.commercialOperationEndDate,
            nomTheme: element.commercialOperationThemeLabel,
            promotionDateStart: element.discountContractStartDate,
            promotionDateEnd: element.discountContractEndDate,
            modeDeFin1: element.discountContractFirstPartnerBillingTypeLabel,
            modeDeFin2: element.discountContractSecondPartnerBillingTypeLabel,
            nbreDeCug: element.discountContractProductListCounter,
            discountContractStatus: element.discountContractStatusCode ? element.discountContractStatusCode : 0,

            // /************************************************** */
            // POPUP AJOUT DE COLONNE
            // //CONTRAT
            rayonContrat: element.paymentSupplierDepartmentCode ,
            cnufContrat: element.paymentSupplierCnuf,
            gdnContrat: element.paymentSupplierNegoGroupUid,
            nomFournisseurContrat: element.paymentSupplierLabel,
            // //FACTURATION
            dateFacturation: element.discountContractBillingDate,
            montantTotalFacturation: element.discountContractTotalBillingAmount ? Number.parseFloat(element.discountContractTotalBillingAmount) : null
            // /************************************************** */
          })
        })
        this.rowDataInit = this.rowData
      },
      async onGridReady(params) {
        await this.initTab()
        this.gridApi = params.api
        if (this.filtersAggrid) {
          this.gridApi.setFilterModel(this.filtersAggrid)
        }
      },
      onFilterChanged(params){
        const filterModel = params.api.getFilterModel()
        this.$store.commit('promotion/SET_FILTERS_AGGRID', filterModel)
      },
      clearFiltersAggrid(){
        this.$store.commit('promotion/SET_FILTERS_AGGRID', null)
        this.gridApi.setFilterModel(this.filtersAggrid)
      },
      async fetchPromotionWithFilters(e) {
        this.$store.commit('promotion/SET_FILTERS', e)
        this.showFilter = false
        this.$store.commit('common/SET_LOADING', true)
        await this.fetchPromotion()
        this.rowData = []
        await this.initTab()
        if (this.filtersAggrid) {
          this.gridApi.setFilterModel(this.filtersAggrid)
        }
        this.nbPage = this.gridApi.paginationGetTotalPages()
        this.$store.commit('common/SET_LOADING', false)
      },
      onPaginationChanged() {
        // Workaround for bug in events order
        if (this.gridApi) {
          this.nbPage = this.gridApi.paginationGetTotalPages()
        }
      },
      changedPage(e) {
        this.page = e
        this.gridApi.paginationGoToPage(this.page - 1)
      },
      changedPageSize(e) {
        this.paginationPageSize = e
        this.gridApi.paginationSetPageSize(this.paginationPageSize)
      },
      tabFilters(filters) {
        let tab = []
        for (const filter in filters) {
          if(fieldsPeriodDate.includes(filter)){
            if(filters[filter].start && filters[filter].end){
              tab.push(filter)
            }
          }else if(filters[filter]){
            tab.push(filter)
          }
        }
        return tab
      },
      onSelectionRow() {
        const selectedRows = this.gridApi.getSelectedRows()
        this.$router.push({
          name: 'PromotionDetail',
          query: {
            discountContractCode: selectedRows[0].nAvantage
          }
        })
      },
      editParamsColumns(item) {
        this.columnDefs.forEach(s => {
          if (s.field === item.field) {
            s.hide = !item.hide
          }
        })
      },
      getFilterField(field){
        if(fieldsPeriodDate.includes(field)){
          return this.filters.field[field].start.toFormat('dd/MM/yyyy') + ' au ' + this.filters.field[field].end.toFormat('dd/MM/yyyy')
        }
        return this.filters.field[field]
      },
      getContextMenuItems(params){
        return [
          {
            name: this.$t('message.openInNewTab'),
            action: () => {
              const routeData = this.$router.resolve({name: 'PromotionDetail', query: {discountContractCode: params.node.data.nAvantage}})
              window.open(routeData.href)
            }
          },
          'separator',
          'copy',
          'copyWithHeaders',
          'copyWithGroupHeaders',
          'separator',
          'csvExport',
          'excelExport'
        ]
      }
    }
  }
</script>

<style lang="scss">
  .promotion {
    margin: 0 20px;
    height: 100%;
    display: flex;
    flex-direction: column;

    &-title {
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 10px;
      padding: 30px 0 0 0;
    }

    &-tab {
      display: flex;
      flex-direction: row;
      height: 80%;
    }
  }

  .button-search {
    font-weight: bold;
    padding: 0px 30px 0px 15px;
    border-radius: 20px;
    position: fixed;
    top: 75px;
    right: 20px;

    & .v-svg {
      height: 3.5rem;
    }
  }

  .pagination-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .nodata {
    display: flex;
    align-items: center;
    border: 2px solid $secondaryBlue;
    padding: 15px 40px;
    border-radius: 15px;

    &-logo {
      background-image: url('../../assets/img/mark.png');
      background-repeat: no-repeat;
      height: 72px;
      width: 72px;
    }

    & span {
      font-family: 'Poppins';
      font-weight: bold;
      font-size: 18px;
      line-height: 27px;
      margin-left: 15px;
    }
  }

  .tag-filter-group {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .params{
    position: relative;
    .button-item {
      border: none;
      background: none;
      cursor: pointer;
      margin-top: 6px;
      color: $primaryOrange;
    }
  }
</style>
