<template>
  <div class="promotionDetail">
    <chicken-offer v-if="showChickenOffer" @close="showChickenOffer = false" />
    <div class="promotionDetail-header">
      <h1>
        <title-bar class="promotionDetail-title" :title="$t('promotionDetail.title')" :icon-name="'promotion'" />
        <v-svg
          v-if="promotionDetail && promotionDetail.discountContractType === 'POULET' && isMandat()"
          class="pointer"
          :name="'chicken-offer'"
          :h="'6rem'"
          @click="showChickenOffer = true"
        />
      </h1>
      <span class="history">
        <v-button
          :label="
            showHistory ? $t('promotionDetail.bloc.historic.closeHistoric') : $t('promotionDetail.bloc.historic.displayHistoric')
          "
          :active="true"
          icon="time"
          h="1rem"
          @click="toggleHistory()"
        />
      </span>
    </div>
    <div class="promotionDetail-content">
      <span v-if="promotionDetail" class="promotionDetail-info">
        <span class="promotionDetail-info-num">
          {{ `N° ${promotionDetail.discountContractCode}` }}
        </span>
        {{ `- ${promotionDetail.promotion.promotionType.tableOfValuesCode} - ${promotionDetail.discountContractChannel}` }}
        <div v-if="promotionDetail.hasCheckedAnomaly === true" class="anomaly">
          Anomalie : mandat à contrôler avec les données sources
        </div>
      </span>

      <!-- HISTORIQUE -->
      <Card v-if="showHistory" :is-loading="isLoadHistoric">
        <template #header>
          <div class="promotionDetail-history-header">
            <CardHeader :title="$t('promotionDetail.bloc.historic.title')" />
            <div @click="toggleShowMore()">
              <span class="promotionDetail-history-header-see-more">
                {{ showMore ? $t('promotionDetail.bloc.historic.seeLess') : $t('promotionDetail.bloc.historic.seeMore') }}
              </span>
            </div>
            <span v-if="historicList.length > 1" class="promotionDetail-history-header-bulle"> {{ historicList.length }}</span>
            <span class="promotionDetail-history-header-close" @click="toggleHistory()">
              <v-svg :name="'cross'" :h="'0.9rem'" />
            </span>
          </div>
        </template>
        <template #body>
          <ul class="promotionDetail-history-header-list">
            <li class="history-column">
              <span>{{ $t('promotionDetail.bloc.historic.pointing') }} </span>
            </li>
            <li class="history-column">
              <span>{{ $t('promotionDetail.bloc.historic.updatedCUNFAndGDN') }}</span>
            </li>
            <li class="history-column">
              <span>{{ $t('promotionDetail.bloc.historic.discountAndFunding') }}</span>
            </li>
            <li class="history-column">
              <span>{{ $t('promotionDetail.bloc.historic.inovice') }} </span>
            </li>
            <li class="history-column">
              <span>{{ $t('promotionDetail.bloc.historic.dispute') }} </span>
            </li>
          </ul>
          <div style="overflow-y: auto; max-height: 300px">
            <div v-for="(item, index) in historicLimitedList" :key="index">
              <ul class="promotionDetail-history-list">
                <li class="history-column">
                  <span>{{
                    item.checkingChangeTracking
                      ? `${$t('promotionDetail.bloc.historic.the')} ${formatDate(
                          item.checkingChangeTracking.changetrackingdate.value,
                          'DD/MM/YYYY'
                        )}`
                      : ''
                  }}</span>
                  <span>{{ item.checkingChangeTracking ? item.checkingChangeTracking.changetrackingcomment : '' }}</span>
                  <span :class="{ tag: item.checkingChangeTracking }">{{
                    item.checkingChangeTracking ? item.checkingChangeTracking.changetrackinguser : ''
                  }}</span>
                </li>
                <li class="history-column">
                  <span
                    >{{
                      item.order_supplierChangeTracking
                        ? `${$t('promotionDetail.bloc.historic.the')} ${formatDate(
                            item.order_supplierChangeTracking.changetrackingdate.value,
                            'DD/MM/YYYY'
                          )}`
                        : ''
                    }}
                  </span>
                  <span
                    v-html="item.order_supplierChangeTracking ? item.order_supplierChangeTracking.changetrackingcomment : ''"
                  ></span>
                  <span :class="{ tag: item.order_supplierChangeTracking }">{{
                    item.order_supplierChangeTracking ? item.order_supplierChangeTracking.changetrackinguser : ''
                  }}</span>
                </li>
                <li class="history-column">
                  <span
                    >{{
                      item.financingChangeTracking
                        ? `${$t('promotionDetail.bloc.historic.the')} ${formatDate(
                            item.financingChangeTracking.changetrackingdate.value,
                            'DD/MM/YYYY'
                          )}`
                        : ''
                    }}
                  </span>
                  <span v-html="item.financingChangeTracking ? item.financingChangeTracking.changetrackingcomment : ''"></span>
                  <span :class="{ tag: item.financingChangeTracking }">{{
                    item.financingChangeTracking ? item.financingChangeTracking.changetrackinguser : ''
                  }}</span>
                </li>
                <li class="history-column">
                  <span
                    >{{
                      item.billingChangeTracking
                        ? `${$t('promotionDetail.bloc.historic.the')} ${formatDate(
                            item.billingChangeTracking.changetrackingdate.value,
                            'DD/MM/YYYY'
                          )}`
                        : ''
                    }}
                  </span>
                  <span>
                    <span>{{
                      item.billingChangeTracking ? item.billingChangeTracking.changetrackingdiscountcontractchannel + ' | ' : ''
                    }}</span>
                    <span>{{ item.billingChangeTracking ? item.billingChangeTracking.changetrackingcomment : '' }}</span>
                  </span>
                  <span :class="{ tag: item.billingChangeTracking !== null }">{{
                    item.billingChangeTracking !== null ? item.billingChangeTracking.changetrackinguser : ''
                  }}</span>
                </li>
                <li class="history-column">
                  <span
                    >{{
                      item.disputeChangeTracking
                        ? `${$t('promotionDetail.bloc.historic.the')} ${formatDate(
                            item.disputeChangeTracking.changetrackingdate.value,
                            'DD/MM/YYYY'
                          )}`
                        : ''
                    }}
                  </span>
                  <span>{{ item.disputeChangeTracking ? item.disputeChangeTracking.changetrackingcomment : '' }}</span>
                  <span :class="{ tag: item.disputeChangeTracking }">{{
                    item.disputeChangeTracking ? item.disputeChangeTracking.changetrackinguser : ''
                  }}</span>
                </li>
              </ul>
              <hr v-if="index === 0 && historicLimitedList.length > 1" class="separator" />
            </div>
          </div>
        </template>
      </Card>

      <!-- STATUS -->
      <div class="promotionDetail-status" v-if="promotionDetail">
        <v-button
          v-if="promotionDetail.discountContractStatusCode === 97 || promotionDetail.discountContractStatusCode === 98"
          class="simple"
          :label="promotionDetail.discountContractStatusCode === 97 ? $t('statusOffer.deleted') : $t('statusOffer.disabled')"
          :reverse="true"
          :active="true"
          :icon="'checked'"
          :h="'1rem'"
        />

        <v-button-group v-else>
          <v-button
            v-for="(mandat, index) in status"
            :key="index"
            :label="mandat.name"
            :reverse="true"
            :active="mandat.isActive"
            :icon="mandat.isActive ? 'checked' : ''"
            :h="'1rem'"
          />
        </v-button-group>
      </div>
      <!-- DETAIL AVANTAGE -->
      <div class="bloc-1">
        <!-- THEME ET TRACT -->
        <Card :is-loading="isLoadPromotionDetail">
          <template #header>
            <CardHeader :title="$t('promotionDetail.bloc.theme.title')" />
          </template>
          <template #body>
            <ul>
              <li>
                <span class="title">{{ $t('promotionDetail.bloc.theme.buyer') }}</span>
                <span class="flex-2">{{ promotionDetail ? promotionDetail.promotion.promotionOwner : '' }}</span>
              </li>
              <li>
                <span class="title">{{ $t('promotionDetail.bloc.theme.type') }}</span>
                <span class="flex-2">{{ promotionDetail ? promotionDetail.promotion.promotionType.tableOfValuesCode : '' }}</span>
              </li>
              <li>
                <span class="title">{{ $t('promotionDetail.bloc.theme.typeOffer') }}</span>
                <span class="flex-2">{{
                  promotionDetail ? promotionDetail.promotion.promotionType.promotionTypeLabel : ''
                }}</span>
              </li>
              <li>
                <span class="title">{{ $t('promotionDetail.bloc.theme.tract') }}</span>
                <span v-if="promotionDetail" :title="promotionDetail.commercialOperation.commercialOperationLabel" class="flex-2">
                  {{ promotionDetail.commercialOperation.commercialOperationLabel }}</span
                >
              </li>
              <li>
                <span class="title">{{ $t('promotionDetail.bloc.theme.tractDate') }}</span>
                <span
                  v-if="
                    promotionDetail &&
                    promotionDetail.commercialOperation.commercialOperationStartDate &&
                    promotionDetail.commercialOperation.commercialOperationEndDate
                  "
                  class="flex-2"
                >
                  {{
                    `Du ${formatDate(
                      promotionDetail.commercialOperation.commercialOperationStartDate.value,
                      'DD/MM/YYYY'
                    )} au ${formatDate(promotionDetail.commercialOperation.commercialOperationEndDate.value, 'DD/MM/YYYY')}`
                  }}
                </span>
              </li>
              <li>
                <span class="title">{{ $t('promotionDetail.bloc.theme.theme') }}</span>
                <span class="flex-2">{{ promotionDetail ? promotionDetail.commercialOperationTheme.themeLabel : '' }}</span>
              </li>
              <li>
                <span class="title">{{ $t('promotionDetail.bloc.theme.promotionDate') }}</span>
                <span
                  v-if="promotionDetail && promotionDetail.discountContractStartDate && promotionDetail.discountContractEndDate"
                  class="flex-2"
                >
                  {{
                    `${formatDate(promotionDetail.discountContractStartDate.value, 'DD/MM/YYYY')} au ${formatDate(
                      promotionDetail.discountContractEndDate.value,
                      'DD/MM/YYYY'
                    )}`
                  }}</span
                >
              </li>
              <li>
                <span class="title">{{ $t('promotionDetail.bloc.theme.wording') }}</span>
                <span v-if="promotionDetail" :title="promotionDetail.promotion.promotionLabel" class="flex-2">{{
                  promotionDetail.promotion.promotionLabel
                }}</span>
              </li>
            </ul>
          </template>
        </Card>
        <!-- REMISE ET FINANCEMENT -->
        <promotion-detail-discount-financing
          @update-financing="updateFinancing"
          :is-loading="isLoadPromotionDetail"
          :promotion-detail="promotionDetail"
          :financingNip="financingNip"
          :financingTypes="financingTypes"
        />
        <!-- FOURNISSEUR DE PAIEMENT -->
        <Card :is-loading="isLoadPromotionDetail">
          <template #header>
            <CardHeader :title="$t('promotionDetail.bloc.paymentProvider.title')" />
          </template>
          <template #body>
            <ul>
              <li>
                <span class="title">{{ $t('promotionDetail.bloc.paymentProvider.paymentMarket') }}</span>
                <span class="flex-2"
                  >{{ promotionDetail ? promotionDetail.paymentSupplier.paymentSupplierDepartmentCode : '' }} ({{
                    promotionDetail ? promotionDetail.paymentSupplier.paymentSupplierDepartmentLabel : ''
                  }})</span
                >
              </li>
              <li>
                <span class="title">{{ $t('promotionDetail.bloc.paymentProvider.name') }}</span>
                <span class="flex-2">{{ promotionDetail ? promotionDetail.paymentSupplier.paymentSupplierLabel : '' }}</span>
              </li>
              <li>
                <span class="title">{{ $t('promotionDetail.bloc.paymentProvider.paymentCnuf') }}</span>
                <span class="flex-2">{{ promotionDetail ? promotionDetail.paymentSupplier.paymentSupplierCnuf : '' }}</span>
              </li>
              <li>
                <span class="title">{{ $t('promotionDetail.bloc.paymentProvider.paymentNegoGroup') }}</span>
                <span class="flex-2">{{
                  `${promotionDetail ? promotionDetail.paymentSupplier.paymentSupplierNegoGroupUid : ''} - ${
                    promotionDetail ? promotionDetail.paymentSupplier.paymentSupplierNegoGroupLabel : ''
                  }`
                }}</span>
              </li>
              <li>
                <span class="title">{{ $t('promotionDetail.bloc.paymentProvider.npaSimulation') }}</span>
                <span class="flex-2">{{
                  promotionDetail ? promotionDetail.paymentSupplier.paymentSupplierSimulationCode : ''
                }}</span>
              </li>
            </ul>
          </template>
        </Card>
        <!-- FOURNISSEUR DE COMMANDE -->
        <promotion-detail-order-provider
          @update-order-provider="updateOrderProvider"
          :is-loading="isLoadPromotionDetail"
          :promotion-detail="promotionDetail"
          :orderSupplierNewValues="orderSupplierNewValues"
          :isMarketWithNullableGDN="isMarketWithNullableGDN"
        />
        <!--STATUT -->
        <Card :is-loading="isLoadPromotionDetail">
          <template #header>
            <CardHeader :title="$t('promotionDetail.bloc.status.title')" />
          </template>
          <template #body>
            <ul>
              <li>
                <span class="title">{{ $t('promotionDetail.bloc.status.offer') }}</span>
                <span class="flex-2">
                  {{ promotionDetail ? promotionDetail.promotion.promotionStatusLabel : '' }}
                  <span v-if="promotionDetail && promotionDetail.promotionStatusUpdateDate.tableOfValuesCode">{{
                    formatDate(promotionDetail.promotionStatusUpdateDate.tableOfValuesCode.value, 'DD/MM/YYYY')
                  }}</span>
                </span>
              </li>
              <li>
                <span class="title">{{ $t('promotionDetail.bloc.status.reception') }}</span>
                <span v-if="promotionDetail && promotionDetail.discountContractReceptionDate" class="flex-2">{{
                  formatDate(promotionDetail.discountContractReceptionDate.value, 'DD/MM/YYYY')
                }}</span>
              </li>
              <li>
                <span class="title">{{ $t('promotionDetail.bloc.status.dateTarget') }}</span>
                <span v-if="promotionDetail && promotionDetail.discountContractCheckingDate" class="flex-2">
                  {{ formatDate(promotionDetail.discountContractCheckingDate.value, 'DD/MM/YYYY') }}</span
                >
              </li>
            </ul>
          </template>
        </Card>
        <!-- FACTURATION -->
        <Card :is-loading="isLoadPromotionDetail">
          <template #header>
            <CardHeader :title="$t('promotionDetail.bloc.billing.title')" />
          </template>
          <template #body>
            <ul v-if="promotionDetail && [3, 4, 6].includes(promotionDetail.discountContractStatusCode)" style="min-height: 30px">
              <div class="column">
                <div class="flex items-center billing">
                  <span class="title flex-1">{{ $t('promotionDetail.bloc.billing.statutDate') }}</span>
                  <div class="flex flex-2 items-center">
                    <span class="flex-2">{{
                      promotionDetail && promotionDetail.discountContractBillingStatutAndDate
                        ? promotionDetail.discountContractBillingStatutAndDate
                        : ''
                    }}</span>
                  </div>
                </div>
                <!--<div v-if="promotionDetail && promotionDetail.discountContractChannel ==='GIGOGNE'" class="flex items-center billing">
                  <span class="title flex-1">{{ $t('promotionDetail.bloc.billing.montantTotal') }}</span>
                  <div class="flex flex-2 items-center">
                    <span class="flex-1">{{ `${promotionDetail &&  promotionDetail.discountContractTotalBillingAmount ? Number.parseFloat(promotionDetail.discountContractTotalBillingAmount).toLocaleString("fr-FR", { style: "currency", currency: "EUR" }) : ''}` }}</span>
                  </div>
                </div>-->
              </div>
              <div class="billing-container flex items-center">
                <div
                  v-if="
                    promotionDetail &&
                    promotionDetail.discountContractBillingHyper &&
                    promotionDetail.discountContractChannel === 'HYPER'
                  "
                  :class="[{ gigogne: promotionDetail.discountContractChannel === 'GIGOGNE' }, 'billing-container-item']"
                >
                  <span class="title hyper"> {{ promotionDetail.discountContractBillingHyper.discountContractChannel }}</span>
                  <span class="date">PreFact {{ checkPrefact(promotionDetail.discountContractBillingHyper) }} </span>
                  <span class="date" v-if="[3, 4].includes(promotionDetail.discountContractStatusCode)"
                    >Fact
                    {{
                      promotionDetail.discountContractBillingHyper.discountContractBillingDate
                        ? formatDate(promotionDetail.discountContractBillingHyper.discountContractBillingDate.value, 'DD/MM/YYYY')
                        : ''
                    }}
                  </span>
                  <span class="billing-amount">{{
                    promotionDetail.discountContractBillingHyper.discountContractBillingAmount
                      ? Number.parseFloat(
                          promotionDetail.discountContractBillingHyper.discountContractBillingAmount
                        ).toLocaleString('fr-FR', {
                          style: 'currency',
                          currency: 'EUR',
                        })
                      : ''
                  }}</span>
                </div>
                <!--<div v-if="promotionDetail && promotionDetail.discountContractChannel ==='GIGOGNE'" class="vertical" />-->
                <div
                  v-if="
                    promotionDetail &&
                    promotionDetail.discountContractBillingSuper &&
                    promotionDetail.discountContractChannel === 'SUPER'
                  "
                  class="billing-container-item"
                >
                  <span class="title super">{{ promotionDetail.discountContractBillingSuper.discountContractChannel }} </span>
                  <span class="date">PreFact {{ checkPrefact(promotionDetail.discountContractBillingSuper) }} </span>
                  <span class="date" v-if="[3, 4].includes(promotionDetail.discountContractStatusCode)"
                    >Fact
                    {{
                      promotionDetail.discountContractBillingSuper.discountContractBillingDate
                        ? formatDate(promotionDetail.discountContractBillingSuper.discountContractBillingDate.value, 'DD/MM/YYYY')
                        : ''
                    }}
                  </span>
                  <span class="billing-amount">{{
                    promotionDetail.discountContractBillingSuper.discountContractBillingAmount
                      ? Number.parseFloat(
                          promotionDetail.discountContractBillingSuper.discountContractBillingAmount
                        ).toLocaleString('fr-FR', {
                          style: 'currency',
                          currency: 'EUR',
                        })
                      : ''
                  }}</span>
                </div>
              </div>
            </ul>
            <div v-else class="teasing">
              <span>{{ $t('promotionDetail.bloc.billing.teasing') }}</span>
            </div>
          </template>
        </Card>
      </div>

      <!-- DECLARER UN LITIGE -->
      <promotion-detail-dispute
        :is-loading="isLoadPromotionDetail"
        v-if="
          promotionDetail &&
          (promotionDetail.discountContractStatusCode === 3 || promotionDetail.discountContractStatusCode === 4)
        "
        :promotion-detail="promotionDetail"
        @declare-dispute="loadDataPromotion"
      />

      <!-- LISTE DE CUGS -->
      <Card class="cugList" :is-loading="isLoadCugList">
        <template #header>
          <CardHeader :title="$t('promotionDetail.bloc.cugList.title') + ' (' + listCugs.length + ')'" />
        </template>
        <template #body>
          <div class="input-cug">
            <span>{{ $t('promotionDetail.bloc.cugList.youCanEnterCugs') }}</span>
            <AutoComplete
              :button-label="isPointed ? '' : $t('promotionDetail.bloc.cugList.point')"
              :data="listCugs"
              :placeholder="'Saisie du CUG'"
              @onEnter="checkCug"
              :disabled="!isMandat()"
            />
            <div v-if="messageCugList" class="info" style="color: $primaryOrange">
              <v-svg :name="'warning'" :h="'0.9rem'" />
              <span>{{ messageCugList }}</span>
            </div>
          </div>
          <ul>
            <li class="title">
              <div :class="[{ disabled: !isMandat() }, 'flex', 'column', 'items-center']">
                <input id="all" type="checkbox" class="check" :checked="isAllCugSelected" @change="selectAllCug" /><label
                  :for="'all'"
                />
              </div>
              <div>{{ $t('promotionDetail.bloc.cugList.cug') }}</div>
              <div>{{ $t('promotionDetail.bloc.cugList.ean') }}</div>
              <div class="lg-column">{{ $t('promotionDetail.bloc.cugList.label') }}</div>
              <div>{{ $t('promotionDetail.bloc.cugList.market') }}</div>
              <div>{{ $t('promotionDetail.bloc.cugList.gdn') }}</div>
              <div>{{ $t('promotionDetail.bloc.cugList.group') }}</div>
            </li>
            <ul class="cugs">
              <li v-for="product in promotionProductList" :key="product.cug">
                <div :class="[{ disabled: !isMandat() }, 'flex', 'column', 'items-center']">
                  <input
                    :id="product.cug"
                    v-model="selectedCugs"
                    type="checkbox"
                    class="check"
                    :about="product.cug"
                    :name="product.cug"
                    :value="product.cug"
                    :checked="product.isProductChecked"
                    @change="select"
                  /><label :for="product.cug" />
                </div>
                <div>{{ product.cug }}</div>
                <div>{{ product.eanCode }}{{ product.eanKey }}</div>
                <div class="lg-column">{{ product.productLabel }}</div>
                <div>{{ `${product.orderSupplierDepartmentCode} - ${product.orderSupplierDepartmentLabel}` }}</div>
                <div>{{ `${product.orderSupplierNegoGroupUid} - ${product.orderSupplierNegoGroupLabel}` }}</div>
                <div>{{ product.productGroupNumber }}</div>
              </li>
            </ul>
          </ul>
        </template>
      </Card>
      <div class="flex">
        <!-- COMMENTAIRES -->
        <Card class="comment flex-2" style="margin-right: 2%" :is-loading="isLoadComments">
          <template #header>
            <CardHeader :title="$t('promotionDetail.bloc.comment.title')" />
          </template>
          <template #body>
            <div class="comment-content">
              <!-- COMMENTAIRES ANOMALIES -->
              <div class="comment-type anomaly">
                <h3 class="uppercase">{{ $t('promotionDetail.bloc.comment.anomaly.title') }}</h3>
                <div class="anomaly-list">
                  <span v-if="listCommentAnomaly.length"
                    >{{ $t('promotionDetail.bloc.comment.anomaly.allCommentAnomaly') }} ( {{ listCommentAnomaly.length }} )</span
                  >
                  <span v-else>{{ $t('promotionDetail.bloc.comment.anomaly.emptyCommentAnomaly') }}</span>
                  <ul v-if="listCommentAnomaly.length">
                    <li v-for="(com, index) in listCommentAnomaly" :key="com.discountcontractcommentsid">
                      <div v-if="index" class="separator" />
                      <div class="item">
                        <div>
                          <div class="info">
                            <div class="typeCom">
                              {{ $t(`promotionDetail.bloc.comment.anomaly.subCommentType.${com.subcommenttype1}`) }}
                            </div>
                            <div
                              v-if="com.subcommenttype1 !== 'CRP' && com.subcommenttype1 !== 'OTHERS' && com.subcommenttype2"
                              class="supplement"
                            >
                              {{ $t(`promotionDetail.bloc.comment.anomaly.subCommentType.${com.subcommenttype2}`) }}
                            </div>
                            <div v-if="com.subcommenttype1 === 'CRP'" class="supplement">
                              {{ formatDate(com.subcommenttype2, 'DD/MM/YYYY') }}
                            </div>
                            <div v-if="com.commentupdatedate && com.commentupdatedate.value" class="date">
                              {{ $t('promotionDetail.bloc.comment.anomaly.modified') }}
                              {{ formatDate(com.commentupdatedate.value, 'DD/MM/YYYY') }} par {{ com.commentuser }}
                            </div>
                            <div v-else class="date">
                              {{ $t('promotionDetail.bloc.comment.anomaly.created') }}
                              {{ formatDate(com.commentcreationdate.value, 'DD/MM/YYYY') }} par {{ com.commentuser }}
                            </div>
                          </div>
                          <p class="com">{{ com.comment }}</p>
                        </div>
                        <div
                          v-if="formatDate(com.commentcreationdate.value, 'YYYY-MM-DD') === formatDate(new Date(), 'YYYY-MM-DD')"
                          class="buttons"
                        >
                          <v-svg :name="'remove'" :h="'1rem'" @click="deleteCom(com.discountcontractcommentsid, 'ANOMALY')" />
                          <v-svg :name="'edit'" :h="'1rem'" @click="buildEditCommentAnomaly(com)" />
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="anomaly-addComment">
                  <div class="addComment-title"
                    ><h3>{{ $t('promotionDetail.bloc.comment.anomaly.subTitle') }}</h3></div
                  >
                  <div class="sub-comment-container">
                    <div class="sub-comment">
                      <input-select
                        v-model="typeSelected"
                        :values="types"
                        traduction-key="promotionDetail.bloc.comment.anomaly.subCommentType"
                        secondary
                        :placeholder="null"
                        :disabled="!isNewCommentAnomaly"
                      />
                      <div class="label">
                        <span>{{ $t('promotionDetail.bloc.comment.anomaly.subCommentTypeLabel') }}</span>
                      </div>
                    </div>

                    <div
                      v-if="typeSelected.id === 'REDO_CONTRACT' || typeSelected.id === 'CONTRACT_OK'"
                      class="sub-comment children"
                    >
                      <input-select
                        v-model="subCommentSelected"
                        :values="typeSelected.children"
                        traduction-key="promotionDetail.bloc.comment.anomaly.subCommentType"
                        secondary
                        :placeholder="null"
                        :disabled="!isNewCommentAnomaly"
                      />
                      <div class="label">
                        <span> {{ $t('promotionDetail.bloc.comment.anomaly.subCommentLabel') }} </span>
                      </div>
                    </div>
                    <div v-if="typeSelected.id === 'CRP'">
                      <date-picker
                        v-model:value="crpDate"
                        type="date"
                        format="DD/MM/YYYY"
                        popup-class="mx-popup"
                        class="mx-inputs secondary"
                        value-type="format"
                        placeholder="DD/MM/YYYY"
                        langString="fr"
                        :disabled="!isNewCommentAnomaly"
                      ></date-picker>
                    </div>
                  </div>

                  <div class="text-area-anomaly">
                    <input
                      v-if="subCommentSelected.comment || typeSelected.id === 'CRP'"
                      type="text"
                      :placeholder="
                        typeSelected.id === 'CRP'
                          ? `CRP - ${crpDate ? formatDate(crpDate, 'DD/MM/YYYY') : 'jj/mm/aaaa'}:`
                          : `${subCommentSelected.comment}:`
                      "
                      disabled
                      :style="{ width: `${subCommentSelected.width}px` }"
                      class="placeholder-input"
                    />
                    <textarea
                      id="anomaly"
                      v-model="newCommentAnomaly"
                      name="anomaly"
                      rows="5"
                      maxlength="500"
                      :placeholder="subCommentSelected.comment || typeSelected.id === 'CRP' ? '' : 'Saisir un commentaire'"
                      :style="{
                        'text-indent': `${
                          subCommentSelected.comment
                            ? subCommentSelected.width
                            : typeSelected.id === 'CRP'
                            ? typeSelected.width
                            : 0
                        }px`,
                      }"
                      :disabled="!typeSelected || typeSelected === {} || (!crpDate && typeSelected.id === 'CRP')"
                    />
                  </div>

                  <div class="action-button">
                    <div />
                    <div class="buttons">
                      <v-button
                        v-if="!isNewCommentAnomaly"
                        :color="'secondary'"
                        :active="true"
                        :label="$t('promotionDetail.bloc.comment.cancel')"
                        :size="'medium'"
                        @click="cancelEdit()"
                      />

                      <v-button
                        :color="'primary'"
                        :active="true"
                        :label="
                          isNewCommentAnomaly
                            ? $t('promotionDetail.bloc.comment.validate')
                            : $t('promotionDetail.bloc.comment.edit')
                        "
                        :size="'medium'"
                        :disabled="
                          (typeSelected.id === 'CRP' && !crpDate) || (typeSelected.id !== 'CRP' && newCommentAnomaly === '')
                        "
                        @click="
                          isNewCommentAnomaly || listCommentAnomaly.length === 0
                            ? addNewComment(newCommentAnomaly, 'ANOMALY', typeSelected, subCommentSelected, crpDate)
                            : updateComment(commentAnomalySid, newCommentAnomaly, 'ANOMALY')
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!-- COMMENTAIRES FOURNISSEUR -->
              <div class="comment-type supplier">
                <div class="comment-title">
                  <h3 class="uppercase">{{ $t('promotionDetail.bloc.comment.supplier.title') }}</h3>
                  {{ `(500 ${$t('promotionDetail.bloc.comment.remainingCharacters')})` }}
                  <span>{{ $t('promotionDetail.bloc.comment.lastEditableComment') }}</span>
                </div>
                <div>
                  <textarea
                    v-if="isNewCommentSupplier || listCommentSupplier.length === 0"
                    id="supplier"
                    v-model="newCommentSupplier"
                    name="supplier"
                    rows="5"
                    maxlength="500"
                  />
                  <textarea
                    v-else
                    id="supplier"
                    v-model="listCommentSupplier[commentSupplierPosition].comment"
                    name="supplier"
                    rows="5"
                    maxlength="500"
                    :disabled="
                      formatDate(listCommentSupplier[commentSupplierPosition].commentupdatedate.value, 'YYYY-MM-DD') !==
                      formatDate(new Date(), 'YYYY-MM-DD')
                    "
                  />
                  <div class="action-button">
                    <div class="tags">
                      <Tag
                        v-for="(com, index) in listCommentSupplier"
                        :key="com.discountcontractcommentsid"
                        class="pointer"
                        :content="formatDate(com.commentupdatedate.value, 'DD/MM/YYYY')"
                        :color="'primaryBlue'"
                        :round="true"
                        :fill="index === commentSupplierPosition"
                        @click=";(commentSupplierPosition = index), (isNewCommentSupplier = false)"
                      />
                      <Tag
                        v-if="
                          !isNewCommentSupplier &&
                          listCommentSupplier.length &&
                          formatDate(listCommentSupplier[0].commentupdatedate.value, 'YYYY-MM-DD') !==
                            formatDate(new Date(), 'YYYY-MM-DD')
                        "
                        class="pointer"
                        :content="'+'"
                        :color="'primaryBlue'"
                        :round="true"
                        @click=";(isNewCommentSupplier = true), (commentSupplierPosition = null)"
                      />
                    </div>
                    <div class="buttons">
                      <v-button
                        :color="'secondary'"
                        :active="true"
                        :label="$t('promotionDetail.bloc.comment.delete')"
                        :size="'medium'"
                        :disabled="isNewCommentSupplier || listCommentSupplier.length === 0"
                        @click="deleteCom(listCommentSupplier[commentSupplierPosition].discountcontractcommentsid, 'SUPPLIER')"
                      />
                      <v-button
                        :color="'primary'"
                        :active="true"
                        :label="$t('promotionDetail.bloc.comment.validate')"
                        :size="'medium'"
                        :disabled="
                          ((isNewCommentSupplier || listCommentSupplier.length === 0) && newCommentSupplier === '') ||
                          (listCommentSupplier[commentSupplierPosition] &&
                            (listCommentSupplier[commentSupplierPosition].comment === '' ||
                              formatDate(listCommentSupplier[commentSupplierPosition].commentupdatedate.value, 'YYYY-MM-DD') !=
                                formatDate(new Date(), 'YYYY-MM-DD')))
                        "
                        @click="
                          isNewCommentSupplier || listCommentSupplier.length === 0
                            ? addNewComment(newCommentSupplier, 'SUPPLIER')
                            : updateComment(
                                listCommentSupplier[commentSupplierPosition].discountcontractcommentsid,
                                listCommentSupplier[commentSupplierPosition].comment,
                                'SUPPLIER'
                              )
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!-- COMMENTAIRES ANOMALIE CUG -->
              <div class="comment-type anomalyCug">
                <div class="comment-title">
                  <h3 class="uppercase">{{ $t('promotionDetail.bloc.comment.cugAnomaly.title') }}</h3>
                  {{ `(1500 ${$t('promotionDetail.bloc.comment.remainingCharacters')})` }}
                  <span>{{ $t('promotionDetail.bloc.comment.lastEditableComment') }}</span>
                </div>
                <div>
                  <textarea
                    v-if="isNewCommentCugAnomaly || listCommentCugAnomaly.length === 0"
                    id="dispute"
                    v-model="newCommentCugAnomaly"
                    name="dispute"
                    rows="5"
                    maxlength="1500"
                  />
                  <textarea
                    v-else
                    id="cugAnomaly"
                    v-model="listCommentCugAnomaly[commentCugAnomalyPosition].comment"
                    name="cugAnomaly"
                    rows="5"
                    maxlength="1500"
                    :disabled="
                      formatDate(listCommentCugAnomaly[commentCugAnomalyPosition].commentupdatedate.value, 'YYYY-MM-DD') !==
                      formatDate(new Date(), 'YYYY-MM-DD')
                    "
                  />
                  <div class="action-button">
                    <div class="tags">
                      <Tag
                        v-for="(com, index) in listCommentCugAnomaly"
                        :key="com.discountcontractcommentsid"
                        class="pointer"
                        :content="formatDate(com.commentupdatedate.value, 'DD/MM/YYYY')"
                        :color="'primaryBlue'"
                        :round="true"
                        :fill="index === commentCugAnomalyPosition"
                        @click=";(commentCugAnomalyPosition = index), (isNewCommentCugAnomaly = false)"
                      />
                      <Tag
                        v-if="
                          !isNewCommentCugAnomaly &&
                          listCommentCugAnomaly.length &&
                          formatDate(listCommentCugAnomaly[0].commentupdatedate.value, 'YYYY-MM-DD') !==
                            formatDate(new Date(), 'YYYY-MM-DD')
                        "
                        class="pointer"
                        :content="'+'"
                        :color="'primaryBlue'"
                        :round="true"
                        @click=";(isNewCommentCugAnomaly = true), (commentCugAnomalyPosition = null)"
                      />
                    </div>
                    <div class="buttons">
                      <v-button
                        :color="'secondary'"
                        :active="true"
                        :label="$t('promotionDetail.bloc.comment.delete')"
                        :size="'medium'"
                        :disabled="isNewCommentCugAnomaly || listCommentCugAnomaly.length === 0"
                        @click="
                          deleteCom(listCommentCugAnomaly[commentCugAnomalyPosition].discountcontractcommentsid, 'SKU_ANOMALY')
                        "
                      />
                      <v-button
                        :color="'primary'"
                        :active="true"
                        :label="$t('promotionDetail.bloc.comment.validate')"
                        :size="'medium'"
                        :disabled="
                          ((isNewCommentCugAnomaly || listCommentCugAnomaly.length === 0) && newCommentCugAnomaly === '') ||
                          (listCommentCugAnomaly[commentCugAnomalyPosition] &&
                            (listCommentCugAnomaly[commentCugAnomalyPosition].comment === '' ||
                              formatDate(
                                listCommentCugAnomaly[commentCugAnomalyPosition].commentupdatedate.value,
                                'YYYY-MM-DD'
                              ) != formatDate(new Date(), 'YYYY-MM-DD')))
                        "
                        @click="
                          isNewCommentCugAnomaly || listCommentCugAnomaly.length === 0
                            ? addNewComment(newCommentCugAnomaly, 'SKU_ANOMALY')
                            : updateComment(
                                listCommentCugAnomaly[commentCugAnomalyPosition].discountcontractcommentsid,
                                listCommentCugAnomaly[commentCugAnomalyPosition].comment,
                                'SKU_ANOMALY'
                              )
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!-- COMMENTAIRES LITIGES -->
              <div v-if="listCommentDispute.length" class="comment-type dispute">
                <div class="comment-title">
                  <h3 class="uppercase">{{ $t('promotionDetail.bloc.comment.dispute.title') }}</h3>
                  {{ `(1500 ${$t('promotionDetail.bloc.comment.remainingCharacters')})` }}
                  <span>{{ $t('promotionDetail.bloc.comment.lastEditableComment') }}</span>
                </div>
                <div>
                  <textarea
                    v-if="isNewCommentDispute || listCommentDispute.length === 0"
                    id="dispute"
                    v-model="newCommentDispute"
                    name="dispute"
                    rows="5"
                    maxlength="500"
                  />
                  <textarea
                    v-else
                    id="dispute"
                    v-model="listCommentDispute[commentDisputePosition].comment"
                    name="dispute"
                    rows="5"
                    maxlength="500"
                    :disabled="
                      listCommentDispute[commentDisputePosition].subcommenttype1 === 'AUTO' ||
                      formatDate(listCommentDispute[commentDisputePosition].commentupdatedate.value, 'YYYY-MM-DD') !==
                        formatDate(new Date(), 'YYYY-MM-DD')
                    "
                  />
                  <div class="action-button">
                    <div class="tags">
                      <Tag
                        v-for="(com, index) in listCommentDispute"
                        :key="com.discountcontractcommentsid"
                        class="pointer"
                        :content="formatDate(com.commentupdatedate.value, 'DD/MM/YYYY - HH[h]mm')"
                        :color="'primaryBlue'"
                        :round="true"
                        :fill="index === commentDisputePosition"
                        @click=";(commentDisputePosition = index), (isNewCommentDispute = false)"
                      />
                      <Tag
                        v-if="
                          !isNewCommentDispute && listCommentDispute.length && listCommentDispute[0].subcommenttype1 === 'AUTO'
                        "
                        class="pointer"
                        :content="'+'"
                        :color="'primaryBlue'"
                        :round="true"
                        @click=";(isNewCommentDispute = true), (commentDisputePosition = null)"
                      />
                    </div>
                    <div class="buttons">
                      <v-button
                        :color="'secondary'"
                        :active="true"
                        :label="$t('promotionDetail.bloc.comment.delete')"
                        :size="'medium'"
                        :disabled="
                          isNewCommentDispute ||
                          listCommentDispute.length === 0 ||
                          (listCommentDispute[commentDisputePosition]
                            ? listCommentDispute[commentDisputePosition].subcommenttype1 === 'AUTO'
                            : true)
                        "
                        @click="deleteCom(listCommentDispute[commentDisputePosition].discountcontractcommentsid, 'DISPUTE')"
                      />
                      <v-button
                        :color="'primary'"
                        :active="true"
                        :label="$t('promotionDetail.bloc.comment.validate')"
                        :size="'medium'"
                        :disabled="
                          ((isNewCommentDispute || listCommentDispute.length === 0) && newCommentDispute === '') ||
                          (listCommentDispute[commentDisputePosition] &&
                            (listCommentDispute[commentDisputePosition].comment != '' ||
                              formatDate(listCommentDispute[commentDisputePosition].commentupdatedate.value, 'YYYY-MM-DD') !=
                                formatDate(new Date(), 'YYYY-MM-DD')))
                        "
                        @click="
                          isNewCommentDispute || listCommentDispute.length === 0
                            ? addNewComment(newCommentDispute, 'DISPUTE')
                            : updateComment(
                                listCommentDispute[commentDisputePosition].discountcontractcommentsid,
                                listCommentDispute[commentDisputePosition].comment,
                                'DISPUTE'
                              )
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Card>
        <!-- VALIDATION -->
        <Card class="validation flex-1" :is-loading="isLoadValidation">
          <template #header>
            <CardHeader :title="$t('promotionDetail.bloc.validation.title')" />
          </template>
          <template #body>
            <div v-if="!isMandat()" class="teasing">
              <span>{{ $t('promotionDetail.bloc.validation.warningValidation') }}</span>
            </div>
            <div v-else class="validation-content">
              <label for="validationDate">{{ $t('promotionDetail.bloc.validation.dateReceipt') }}</label>
              <date-picker
                v-model:value="pointDate"
                type="date"
                format="DD/MM/YYYY"
                popup-class="mx-popup"
                class="mx-inputs input-date"
                value-type="format"
                placeholder="DD/MM/YYYY"
                langString="fr"
                :disabled="
                  !!promotionDetail &&
                  (Number(promotionDetail.discountContractStatusCode) > 1 ||
                    (!Number(promotionDetail.discountContractStatusCode) < 2 && !isAllCugSelected))
                "
                :clearable="
                  !(
                    !!promotionDetail &&
                    (Number(promotionDetail.discountContractStatusCode) > 1 ||
                      (!Number(promotionDetail.discountContractStatusCode) < 2 && !isAllCugSelected))
                  )
                "
              ></date-picker>
              <span v-if="promotionDetail ? !Number(promotionDetail.discountContractStatusCode) < 2 : true">{{
                $t('promotionDetail.bloc.validation.infoAllChecked')
              }}</span>
            </div>
          </template>
          <template #footer>
            <div class="validation-footer">
              <v-button
                :color="'primary'"
                :disabled="
                  !isAllCugSelected ||
                  pointDate == null ||
                  (promotionDetail ? ![1, 2, 5].includes(Number(promotionDetail.discountContractStatusCode)) : false) ||
                  (promotionDetail &&
                    !isMarketWithNullableGDN &&
                    (promotionDetail.orderSupplier.orderSupplierNegoGroupUid === null ||
                      promotionDetail.orderSupplier.orderSupplierLabel === null))
                "
                :active="true"
                :label="
                  promotionDetail && Number(promotionDetail.discountContractStatusCode) > 1
                    ? $t('promotionDetail.bloc.validation.depoint')
                    : $t('promotionDetail.bloc.validation.point')
                "
                :size="'medium'"
                @click="
                  promotionDetail.discountContractCodeGigogneCheckable && Number(promotionDetail.discountContractStatusCode) === 1
                    ? (isPopupGigogne = true)
                    : pointOrDepoint()
                "
              />
            </div>
          </template>
        </Card>
        <promotion-detail-validation
          v-if="isPopupGigogne"
          @close="isPopupGigogne = false"
          @pointGigogne="pointGigogne"
          :discount-contract-code="promotionDetail.discountContractCode"
          :discount-contract-code-gigogne="promotionDetail.discountContractCodeGigogneCheckable"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { HYPER, SUPER } from '../utils/CONF'
  import PromotionDetailDiscountFinancing from '@/components/promotionDetail/PromotionDetailDiscountFinancing'
  import PromotionDetailOrderProvider from '@/components/promotionDetail/PromotionDetailOrderProvider'
  import PromotionDetailDispute from '@/components/promotionDetail/PromotionDetailDispute'
  import PromotionDetailValidation from '@/components/promotionDetail/PromotionDetailValidation'
  import AutoComplete from '@/components/AutoComplete'
  import Card from '@/components/card/Card'
  import CardHeader from '@/components/card/CardHeader'
  import ChickenOffer from '@/components/promotionDetail/ChickenOffer'
  import Tag from '@/components/Tag'
  import { subTypesComment, financingTypes } from '@/utils/CONF'
  import { inject } from 'vue'
  import { mapActions, mapState } from 'vuex'
  import dateMixin from '../mixins/date'
  import valueMixin from '../mixins/value'
  import { extendWithAuthorization, httpNip } from '@/http'
  import DatePicker from 'vue-datepicker-next'
  import 'vue-datepicker-next/index.css'
  import 'vue-datepicker-next/locale/fr'
  import { DateTime } from 'luxon'

  const POINTED_ID = 2
  export default {
    name: 'PromotionDetail',
    components: {
      Card,
      CardHeader,
      AutoComplete,
      Tag,
      ChickenOffer,
      PromotionDetailDispute,
      PromotionDetailDiscountFinancing,
      PromotionDetailOrderProvider,
      DatePicker,
      PromotionDetailValidation,
    },
    mixins: [dateMixin, valueMixin],
    data() {
      return {
        userMatricule: null,
        showChickenOffer: false,

        //PROMOTION DETAIL
        isLoadPromotionDetail: true,

        //HISTORIQUE
        isLoadHistoric: true,
        showHistory: false,
        showMore: false,
        historicList: [],
        historicLimitedList: [],

        //STATUS
        status: [
          { id: 1, name: this.$t('statusOffer.create'), isActive: false },
          { id: 2, name: this.$t('statusOffer.target'), isActive: false },
          { id: 6, name: this.$t('statusOffer.preBilling'), isActive: false },
          { id: 3, name: this.$t('statusOffer.billing'), isActive: false },
          { id: 4, name: this.$t('statusOffer.dispute'), isActive: false },
        ],

        //LISTE DES CUGS
        isLoadCugList: true,
        isAllCugSelected: false,
        listCugs: [],
        selectedCugs: [],
        messageCugList: '',

        //COMMENTAIRES
        isLoadComments: true,
        NEW_COMMENT: 999,

        typeSelected: {},
        subCommentSelected: {},
        types: subTypesComment,

        listCommentAnomaly: [],
        listCommentSupplier: [],
        listCommentCugAnomaly: [],
        listCommentDispute: [],

        commentSupplierPosition: 0,
        commentCugAnomalyPosition: 0,
        commentDisputePosition: 0,
        commentAnomalySid: null,

        newCommentSupplier: '',
        newCommentCugAnomaly: '',
        newCommentDispute: '',
        newCommentAnomaly: '',
        crpDate: null,

        isNewCommentSupplier: false,
        isNewCommentCugAnomaly: false,
        isNewCommentDispute: false,
        isNewCommentAnomaly: true,

        //VALIDATION
        isLoadValidation: false,
        pointDate: null,
        isPopupGigogne: false,

        //Update financing mode
        financingTypes: [],
        financingNip: {
          firstFinancing: {
            value: 0,
            oldValue: 0,
            type: null,
            oldType: null,
          },
          secondFinancing: {
            value: 0,
            oldValue: 0,
            type: null,
            oldType: null,
          },
        },

        //Update order supplier
        orderSupplierNewValues: {
          cnuf: null,
          oldCnuf: null,
          cif: null,
          oldCif: null,
          gdn: null,
          oldGdn: null,
        },
        isMarketWithNullableGDN: false,

        profileRoles: null,
      }
    },
    computed: {
      ...mapState({
        promotionDetail: (state) => state.promotionDetail.promotionDetail,
        promotionProductList: (state) => state.promotionDetail.promotionProductList,
        promotionComments: (state) => state.promotionDetail.promotionComments,
      }),
      numberOfComments() {
        return (
          this.listCommentAnomaly.length +
          this.listCommentCugAnomaly.length +
          this.listCommentSupplier.length +
          this.listCommentDispute.length
        )
      },
      ...mapState('referential', ['marketsWithNullableGDN']),
      isPointed() {
        return this.status.find((stat) => stat.id === POINTED_ID)?.isActive
      },
    },
    watch: {
      typeSelected(newValue) {
        if (this.isNewCommentAnomaly) {
          this.newCommentAnomaly = ''
          this.subCommentSelected = newValue.children ? newValue.children[0] : {}
        }
      },
      subCommentSelected() {
        if (this.isNewCommentAnomaly) this.newCommentAnomaly = ''
      },
    },
    async created() {
      this.userMatricule = inject('userInfos').uid
      this.typeSelected = this.types.find((item) => item.id === 'CRP')
      this.resetStore()
      this.loadDataPromotion()
      this.profileRoles = inject('roles')['nip-sec']
    },
    methods: {
      ...mapActions('promotionDetail', [
        'fetchPromotionDetail',
        'fetchBillingHistoric',
        'fetchPromotionProductList',
        'fetchComments',
        'addComment',
        'setComment',
        'deleteComment',
        'setPoint',
        'setDePoint',
        'resetStore',
      ]),

      checkPrefact(discountContractBilling) {
        if (
          this.promotionDetail.discountContractChannel === 'GIGOGNE' &&
          (this.promotionDetail.discountContractBillingSuper.discountContractPreBillingDate ||
            this.promotionDetail.discountContractBillingHyper.discountContractPreBillingDate)
        ) {
          return discountContractBilling.discountContractPreBillingDate
            ? this.formatDate(discountContractBilling.discountContractPreBillingDate.value, 'DD/MM/YYYY')
            : ''
        }
        return discountContractBilling.discountContractPreBillingDate
          ? this.formatDate(discountContractBilling.discountContractPreBillingDate.value, 'DD/MM/YYYY')
          : ''
      },

      async updateFinancing(e) {
        this.isLoadPromotionDetail = true
        try {
          const extendedHttp = await extendWithAuthorization(httpNip)
          await extendedHttp.put('promotionDetail/financing', { json: e })
          await this.loadDataPromotionBlocs()
        } catch (err) {
          console.error(err)
          this.$toast.error(this.$t('message.errors.messageGlobal'))
          this.isLoadPromotionDetail = false
        }
      },

      async updateOrderProvider(e) {
        this.isLoadPromotionDetail = true
        try {
          const extendedHttp = await extendWithAuthorization(httpNip)
          await extendedHttp.put('promotionDetail/orderSupplier', { json: e })
          await this.loadDataPromotionBlocs()
        } catch (err) {
          console.error(err)
          this.$toast.error(this.$t('message.errors.messageGlobal'))
          this.isLoadPromotionDetail = false
        }
      },

      loadDataPromotion() {
        this.loadDataPromotionBlocs()

        this.isLoadCugList = true
        this.fetchPromotionProductList(this.$route.query.discountContractCode)
          .then(async () => {
            this.selectedCugs = []
            this.listCugs = []
            await this.promotionProductList.forEach((product) => {
              this.listCugs.push(product.cug)
              if (product.isProductChecked) {
                this.selectedCugs.push(product.cug)
              }
            })
            this.isAllCugSelected = this.selectedCugs.length === this.promotionProductList.length
          })
          .finally(() => (this.isLoadCugList = false))

        this.isLoadComments = true
        this.fetchComments(this.$route.query.discountContractCode)
          .then(() => {
            this.listCommentAnomaly = this.getComments('ANOMALY')
            this.listCommentSupplier = this.getComments('SUPPLIER')
            this.listCommentCugAnomaly = this.getComments('SKU_ANOMALY')
            this.listCommentDispute = this.getComments('DISPUTE')
          })
          .finally(() => (this.isLoadComments = false))
      },

      async loadDataPromotionBlocs() {
        this.isLoadPromotionDetail = true
        await this.fetchPromotionDetail(this.$route.query.discountContractCode)
          .then(() => {
            if (Number(this.promotionDetail?.discountContractStatusCode) > 1) {
              //PB date de facture
              this.pointDate = this.formatDate(this.promotionDetail?.discountContractReceptionDate?.value, 'DD/MM/YYYY')
            }

            //barre de progression status
            let active = true
            for (const st of this.status) {
              st.isActive = active
              if (st.id === 2 && this.promotionDetail?.discountContractStatusCode === 5) {
                active = false
              } else if (st.id === this.promotionDetail?.discountContractStatusCode) {
                active = false
              }
            }
            //FinancingType
            if (this.financingTypes.length === 0)
              this.financingTypes = financingTypes.filter(
                (item) => item?.disabled !== this.promotionDetail.promotion.promotionType.tableOfValuesCode
              )
            // Init for updated variables for financing mode
            this.financingNip.firstFinancing.value =
              this.promotionDetail?.discountPartnerBilling?.discountFirstPartnerBillingValue + ''
            this.financingNip.firstFinancing.oldValue = this.financingNip.firstFinancing.value
            this.financingNip.secondFinancing.oldValue = this.financingNip.secondFinancing.value
            this.financingNip.firstFinancing.type =
              financingTypes.find(
                (type) => type.id === this.promotionDetail?.discountPartnerBilling?.discountContractFirstPartnerBillingTypeCode
              ) || null
            this.financingNip.secondFinancing.type =
              financingTypes.find(
                (type) => type.id === this.promotionDetail?.discountPartnerBilling?.discountContractSecondPartnerBillingTypeCode
              ) || null
            this.financingNip.firstFinancing.oldType = this.financingNip.firstFinancing.type
            this.financingNip.secondFinancing.oldType =
              financingTypes.find(
                (type) => type.id === this.promotionDetail?.discountPartnerBilling?.discountContractSecondPartnerBillingTypeCode
              ) || null

            // Init for updated variables for order supplier
            this.orderSupplierNewValues.cnuf = this.promotionDetail?.orderSupplier?.orderSupplierCnuf
            this.orderSupplierNewValues.oldCnuf = this.promotionDetail?.orderSupplier?.orderSupplierCnuf
            this.orderSupplierNewValues.cif = this.promotionDetail?.orderSupplier?.orderSupplierCif
            this.orderSupplierNewValues.oldCif = this.promotionDetail?.orderSupplier?.orderSupplierCif
            this.orderSupplierNewValues.gdn = this.promotionDetail?.orderSupplier?.orderSupplierNegoGroupUid
            this.orderSupplierNewValues.oldGdn = this.promotionDetail?.orderSupplier?.orderSupplierNegoGroupUid

            this.isMarketWithNullableGDN =
              this.marketsWithNullableGDN.find(
                (market) => market?.marketsid === this.promotionDetail?.orderSupplier?.orderSupplierDepartmentCode
              ) !== undefined
          })
          .finally(() => {
            this.isLoadPromotionDetail = false
            this.isLoadValidation = false
          })
      },

      /***************
       * COMMENTAIRE *
       ***************/

      async addNewComment(comment, type, subCommentType1 = null, subCommentType2 = null, crpDate = null) {
        this.isLoadComments = true
        this.addCom(comment, type, subCommentType1, subCommentType2, crpDate)
          .then(() => {
            this.loadCommentType(type)
            this.cancelEdit()
          })
          .finally(() => (this.isLoadComments = false))
      },

      async updateComment(id, comment, type) {
        this.isLoadComments = true
        await this.updateCom(id, comment)
          .then(() => this.loadCommentType(type))
          .finally(() => {
            this.isLoadComments = false
            if (type === 'ANOMALY') {
              this.commentAnomalySid = null
              this.cancelEdit()
            }
          })
      },

      addCom(comment, type, subCommentType1 = null, subCommentType2 = null, crpDate = null) {
        crpDate = crpDate
          ? DateTime.fromFormat(crpDate, 'dd/MM/yyyy').toFormat('yyyy-MM-dd')
          : DateTime.local().toFormat('yyyy-MM-dd')
        return new Promise((resolve, reject) => {
          if (subCommentType2 && Object.keys(subCommentType2).length !== 0)
            comment = subCommentType2.comment.concat(' ').concat(comment)
          this.addComment({
            discountContractCode: this.$route.query.discountContractCode,
            userMatricule: this.userMatricule,
            comment,
            type,
            subCommentType1: subCommentType1 && Object.keys(subCommentType1).length !== 0 ? subCommentType1.id : subCommentType1,
            subCommentType2:
              subCommentType2 && Object.keys(subCommentType2).length !== 0 ? subCommentType2.id : crpDate ? crpDate : null,
          })
            .then(() => resolve())
            .catch((error) => reject(error))
        })
      },
      updateCom(id, comment) {
        return new Promise((resolve, reject) => {
          this.isLoadComments = true
          this.setComment({
            id,
            comment,
            discountContractCode: this.$route.query.discountContractCode,
          })
            .then(() => resolve())
            .catch((error) => reject(error))
        })
      },
      deleteCom(id, type) {
        this.isLoadComments = true
        this.deleteComment(id)
          .then(() => this.loadCommentType(type))
          .finally(() => (this.isLoadComments = false))
      },
      buildEditCommentAnomaly(commentObj) {
        this.isNewCommentAnomaly = false
        this.typeSelected = subTypesComment.find((item) => item.id === commentObj.subcommenttype1)
        if (this.typeSelected.id !== 'OTHERS' && this.typeSelected.id !== 'CRP')
          this.subCommentSelected = this.typeSelected.children.find((item) => item.id === commentObj.subcommenttype2)
        if (this.typeSelected.id === 'CRP') this.crpDate = commentObj.subcommenttype2
        if (this.typeSelected.id === 'OTHERS' || this.typeSelected.id === 'CRP' || this.subCommentSelected.id === 'OTHERS')
          this.newCommentAnomaly = commentObj.comment
        else this.newCommentAnomaly = commentObj.comment.split(this.subCommentSelected.comment)[1]
        this.commentAnomalySid = commentObj.discountcontractcommentsid
      },
      cancelEdit() {
        this.typeSelected = this.types.find((item) => item.id === 'CRP')
        this.subCommentSelected = {}
        this.newCommentAnomaly = ''
        this.isNewCommentAnomaly = true
        this.crpDate = null
      },
      loadCommentType(type) {
        switch (type) {
          case 'SUPPLIER':
            this.listCommentSupplier = this.getComments('SUPPLIER')
            break
          case 'ANOMALY':
            this.listCommentAnomaly = this.getComments('ANOMALY')
            break
          case 'SKU_ANOMALY':
            this.listCommentCugAnomaly = this.getComments('SKU_ANOMALY')
            break
          case 'DISPUTE':
            this.listCommentDispute = this.getComments('DISPUTE')
            break
        }
      },
      getComments(type) {
        const index = this.promotionComments.findIndex((com) => com.commentType === type)
        if (index != -1) return this.promotionComments[index].comment.filter((com) => com.iscommentvisible)
        return []
      },

      /**************
       * VALIDATION *
       **************/

      async updateDiscountContractCheckedOrUnchecked(
        pathName,
        { discountContractCode, duplicateDiscountContractCode, discountContractChannel }
      ) {
        const extendedHttp = await extendWithAuthorization(httpNip)
        let body = {
          discountContractCode,
          duplicateDiscountContractCode,
          pointingDate: DateTime.fromFormat(this.pointDate, 'dd/MM/yyyy').toFormat('yyyy-MM-dd'),
          userMatricule: this.userMatricule,
          discountContractType: this.promotionDetail.discountContractType,
          discountContractChannel,
          discountContractYear: this.promotionDetail.discountContractYear,
          orderSupplierDepartmentCode: this.promotionDetail.orderSupplier.orderSupplierDepartmentCode,
        }
        return extendedHttp.put(pathName, { json: body })
      },

      async pointOrDepoint() {
        this.isLoadValidation = true
        this.isLoadComments = true
        try {
          await this.saveComments()
          const pathName =
            Number(this.promotionDetail.discountContractStatusCode) > 1 ? 'promotionDetail/depoint' : 'promotionDetail/point'

          const params = {
            discountContractCode: this.promotionDetail.discountContractCode,
            duplicateDiscountContractCode: this.promotionDetail.discountContractCode,
            discountContractChannel: this.promotionDetail.discountContractChannel,
          }
          await this.updateDiscountContractCheckedOrUnchecked(pathName, params)

          this.$store.commit('common/SET_REFRESH_DATA', true)
          this.$router.push({ name: 'Promotion' })

          this.cancelEdit()
        } catch (err) {
          this.$toast.error(this.$t('message.error.messageGlobal'))
        } finally {
          this.isLoadValidation = false
          this.isLoadComments = false
        }
      },

      async pointGigogne(isGigogneOffer) {
        this.isLoadValidation = true
        this.isLoadComments = true
        try {
          this.isPopupGigogne = false

          await this.saveComments()

          const { discountContractCodeGigogneCheckable, discountContractCode, discountContractChannel } = this.promotionDetail

          if (isGigogneOffer) {
            await this.updateDiscountContractCheckedOrUnchecked('promotionDetail/point', {
              discountContractCode: discountContractCodeGigogneCheckable,
              duplicateDiscountContractCode: discountContractCode,
              discountContractChannel: discountContractChannel === HYPER ? SUPER : HYPER,
            })
          }

          await this.updateDiscountContractCheckedOrUnchecked('promotionDetail/point', {
            discountContractCode,
            duplicateDiscountContractCode: discountContractCode,
            discountContractChannel,
          })

          this.$store.commit('common/SET_REFRESH_DATA', true)
          this.$router.push({ name: 'Promotion' })
          this.cancelEdit()
        } catch (err) {
          this.$toast.error(this.$t('message.error.messageGlobal'))
        }
        this.isLoadValidation = false
        this.isLoadComments = false
      },

      async saveComments() {
        try {
          const promiseCommentAnomaly =
            (this.typeSelected.id === 'CRP' && !this.crpDate) || (this.typeSelected.id !== 'CRP' && this.newCommentAnomaly === '')
              ? true
              : this.isNewCommentAnomaly || this.listCommentAnomaly.length === 0
              ? this.addCom(this.newCommentAnomaly, 'ANOMALY', this.typeSelected, this.subCommentSelected, this.crpDate)
              : this.updateCom(this.commentAnomalySid, this.newCommentAnomaly)
          const promiseCommentSupplier =
            this.isNewCommentSupplier || this.listCommentSupplier.length === 0
              ? this.newCommentSupplier === ''
                ? true
                : this.addCom(this.newCommentSupplier, 'SUPPLIER')
              : this.updateCom(
                  this.listCommentSupplier[this.commentSupplierPosition].discountcontractcommentsid,
                  this.listCommentSupplier[this.commentSupplierPosition].comment
                )
          const promiseCommentCugAnomaly =
            this.isNewCommentCugAnomaly || this.listCommentCugAnomaly.length === 0
              ? this.newCommentCugAnomaly === ''
                ? true
                : this.addCom(this.newCommentCugAnomaly, 'SKU_ANOMALY')
              : this.updateCom(
                  this.listCommentCugAnomaly[this.commentCugAnomalyPosition].discountcontractcommentsid,
                  this.listCommentCugAnomaly[this.commentCugAnomalyPosition].comment
                )
          const promiseCommentDispute =
            this.isNewCommentDispute || this.listCommentDispute.length === 0
              ? this.newCommentDispute === ''
                ? true
                : this.addCom(this.newCommentDispute, 'DISPUTE')
              : this.updateCom(
                  this.listCommentDispute[this.commentDisputePosition].discountcontractcommentsid,
                  this.listCommentDispute[this.commentDisputePosition].comment
                )

          await Promise.all([promiseCommentAnomaly, promiseCommentSupplier, promiseCommentCugAnomaly, promiseCommentDispute])
        } catch (error) {
          this.$toast.error(this.$t('message.error.messageGlobal'))
        }
      },

      /**************
       * LISTE CUGs *
       **************/

      checkCug(e) {
        this.messageCugList = ''
        if (this.listCugs.includes(e)) {
          this.selectedCugs.includes(e)
            ? (this.messageCugList = this.$t('promotionDetail.bloc.cugList.message.CugHasAlreadyWasPointed'))
            : this.selectedCugs.push(e)
        } else {
          this.messageCugList = e + ' - ' + this.$t('promotionDetail.bloc.cugList.message.cugNotPresentInTheList')
        }
        this.select()
      },

      selectAllCug() {
        if (this.isAllCugSelected) {
          this.selectedCugs = []
          this.isAllCugSelected = false
        } else {
          this.selectedCugs = []
          this.promotionProductList.forEach((product) => this.selectedCugs.push(product.cug))
          this.isAllCugSelected = true
        }
      },

      select() {
        this.isAllCugSelected = this.selectedCugs.length === this.promotionProductList.length
      },

      /**************
       * HISTORIQUE *
       **************/

      toggleShowMore() {
        this.showMore = !this.showMore
        this.historicLimitedList = this.showMore ? this.historicList : this.historicList.slice(0, 1)
      },

      async toggleHistory() {
        this.showHistory = !this.showHistory
        if (this.showHistory) {
          await this.fetchBillingHistoric(this.$route.query.discountContractCode)
            .then((response) => {
              this.historicList = response
              this.historicLimitedList = this.historicList.slice(0, 1)
            })
            .finally(() => (this.isLoadHistoric = false))
        } else {
          this.historicList = []
          this.isLoadHistoric = true
          this.historicLimitedList = []
          this.showMore = false
        }
      },

      isMandat() {
        return (
          this.promotionDetail?.discountPartnerBilling?.discountContractFirstPartnerBillingTypeCode === 'MA' ||
          this.promotionDetail?.discountPartnerBilling?.discountContractSecondPartnerBillingTypeCode === 'MA'
        )
      },
    },
  }
</script>

<style lang="scss" scoped>
  .promotionDetail {
    padding: 20px 42px;

    &-info {
      font-size: 16px;
      line-height: 24px;
      color: $darkGrey;
      display: flex;
      align-items: center;

      .anomaly {
        color: $darkRed;
        border: solid 2px $darkRed;
        border-radius: 10px;
        padding: 5px;
        margin-left: 20px;
        font-weight: bold;
      }

      &-num {
        font-size: 18px;
        font-weight: bold;
      }
    }

    &-status {
      display: flex;
      justify-content: center;
      .simple {
        border-radius: 8px;
        text-transform: uppercase;
        font-weight: bold;
        min-height: 29px;
        margin: 17px 0;
      }
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h1 {
        display: flex;
        align-items: center;
      }
    }

    &-title {
      font-weight: bold;
      font-size: 18px;
      margin: 6px 20px 10px 22px;
    }

    &-content {
      margin-top: 18px;
    }

    &-history-header {
      display: flex;
      align-items: center;

      &-title {
        font-weight: 700;
        font-size: 18px;
        margin: 6px 20px 10px 22px;
      }

      &-see-more {
        font-style: normal;
        background: transparent;
        border: none;
        font-weight: bold;
        font-size: 12px;
        line-height: 18px;
        color: $primaryBlue;
        text-transform: uppercase;
        cursor: pointer;
      }

      &-see-more :hover {
        cursor: pointer;
        text-decoration: underline;
      }

      &-close {
        background: transparent;
        border: none;
        margin: 19px 21px 0 auto;
        cursor: pointer;
      }

      &-bulle {
        background: url('../assets/svg/bulle.svg');
        width: 22px;
        height: 20px;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        margin-left: 10px;
        font-weight: bold;
      }
    }

    &-history-list {
      display: flex;
      justify-content: space-between;
      margin: 9px 38px;
      padding-bottom: 17px;

      & li {
        display: flex;
        flex-direction: column;

        & :nth-child(1) {
          font-size: 13px;
          line-height: 20px;
          color: $mediumGrey;
        }

        & :nth-child(2) {
          font-weight: bold;
          font-size: 13px;
          line-height: 20px;
        }
      }
    }

    &-history-header-list {
      display: flex;
      justify-content: space-between;
      margin: 9px 38px;
      padding-bottom: 17px;

      & li {
        display: flex;
        flex-direction: column;

        & :nth-child(1) {
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 18px;
          display: flex;
          align-items: center;
          color: $darkGrey;
        }
      }
    }

    & .bloc-1 {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .header-card-custom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .actions {
          display: flex;

          .v-svg {
            margin-right: 19px;
            cursor: pointer;
            &.svg-circle-close {
              color: $primaryOrange;
            }
            &.svg-circle-validate {
              color: $primaryBlue;
            }
          }
        }
      }

      & .card {
        width: 32%;
      }

      & ul li {
        min-height: 30px;
        display: flex;
        align-items: center;
        padding: 0 19px;

        & div {
          width: 100%;
          min-height: 30px;
        }

        &:nth-child(2n-1) {
          background-color: $veryLightGrey;
          border-radius: 15px;
        }

        .edit-mode {
          .input-select {
            margin-left: 2rem;
          }

          span {
            width: 70px;
            padding-left: 16px;
          }
        }

        & .type {
          color: $darkGrey;
        }

        & .title {
          flex: 1;
          font-weight: bold;
          text-transform: uppercase;
          margin-right: 10px;
          line-height: 20px;
        }

        & span:last-child {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          line-height: 20px;

          & span {
            font-size: 12px;
            float: right;
          }
        }
      }

      .fa {
        background-color: $white;
      }
    }

    & .error {
      text-align: center;
      font-weight: bold;
      color: $red;
    }

    .billing-container {
      display: flex;
      justify-content: space-around;
      padding: 1rem 0;

      .billing-container-item {
        text-align: center;
        display: flex;
        flex-direction: column;
        width: 100%;
        &.gigogne {
          border-right: solid 0.2rem #a8a8a8;
        }

        .title {
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 28px;

          &.super {
            color: $darkOrange;
          }
          &.hyper {
            color: $primaryBlue;
          }
        }

        .date {
          font-size: 10px;
          color: $mediumGrey;
        }

        .billing-amount {
          font-weight: bold;
        }
      }
    }

    & .group-type {
      padding-left: 190px;
      width: 33em;
      min-height: 79px;
    }
  }

  .cugList {
    min-height: 175px;

    & .input-cug {
      margin: 0 19px;
      display: flex;
      align-items: center;

      & .info {
        display: flex;
        margin-left: 10px;
        color: $primaryOrange;
        font-size: 9px;

        & span {
          margin-left: 5px;
          color: $mediumGrey;
        }
      }
    }

    & ul.cugs {
      max-height: 535px;
      overflow: auto;

      .disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    & li {
      display: flex;
      align-items: center;
      text-align: center;
      margin: 20px 0;

      &.title {
        font-weight: bold;
        margin-bottom: 0;

        .disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }

      & div {
        flex: 1;

        &.lg-column {
          flex: 2;
        }
      }
    }
  }

  input[type='checkbox'].check {
    display: none;
  }

  input[type='checkbox'].check + label::before {
    content: '';
    border: 1.5px solid $darkGrey;
    border-radius: 20%;
    margin-right: 0.3rem;
    padding-left: 0.1rem;
    padding-bottom: 0.1rem;
    height: 15px;
    width: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  input[type='checkbox'].check:checked + label::before {
    border: 1.5px solid $primaryBlue;
    content: url(../assets/svg/v.svg);
  }

  .comment {
    &-content {
      padding: 0 19px;
      font-size: 14px;
      & .anomaly {
        &-list {
          & ul {
            background-color: $veryLightGrey;
            box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
            border-radius: 20px;
            margin: 8px 0;
            padding: 0 20px;
            & li .separator {
              width: 70%;
              margin: auto;
              height: 1px;
              background: $primaryBlue;
            }
            & li .item {
              display: flex;
              align-items: center;
              padding: 20px 0;
              justify-content: space-between;
              & .info {
                display: flex;
                font-size: 12px;
                & div {
                  padding-right: 10px;
                }
                & .typeCom {
                  font-weight: bold;
                  text-transform: uppercase;
                }
                & .supplement {
                  font-weight: bold;
                  text-transform: uppercase;
                  color: $primaryBlue;
                }
                & .date {
                  font-size: 9px;
                  color: $mediumGrey;
                }
              }
              & p {
                margin: 5px 0 0 0;
              }
              & .buttons {
                display: flex;
                color: $primaryOrange;
                & .v-svg {
                  cursor: pointer;
                }
                & .v-svg:first-child {
                  margin: 0 10px;
                }
              }
            }
          }
        }

        &-addComment {
          .sub-comment-container {
            display: flex;
            padding: 2px;
            align-items: center;
            margin-bottom: 0.5rem;
            height: 30px;

            .sub-comment {
              display: flex;
              margin-right: 1rem;
              align-items: center;
              height: 100%;

              &.children {
                .input-select {
                  width: 280px;
                }
              }

              .input-select {
                width: 180px;
              }

              .list {
                margin-right: 0.5rem;
                border-color: #ffdcc3;
                border-radius: 5px;
                height: 100%;
              }

              .label {
                color: #a8a8a8;
                font-size: 9px;
                margin-left: 0.5rem;
              }
            }
          }

          .text-area-anomaly {
            position: relative;
            display: block;

            .placeholder-input {
              all: unset;
              position: absolute;
              top: 5px;
              left: 5px;
              font-size: 14px;
              @extend %font-body;
            }

            textarea {
              min-width: 300px;
              padding: 5px;
              font-size: 14px;
              @extend %font-body;
            }
          }
        }
      }
    }

    & .comment-type {
      margin-bottom: 20px;

      .anomaly-list {
        span {
          font-weight: bold;
          margin-left: 0.5rem;
        }
        ul {
          max-height: 300px;
          overflow-y: scroll;
        }
      }

      & .comment-title {
        display: flex;
        align-items: center;

        & h3 {
          margin-right: 5px;
        }

        & span {
          color: $mediumGrey;
          font-size: 9px;
          margin-left: 5px;
        }
      }

      & .action-button {
        display: flex;
        justify-content: space-between;

        & .tags {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        }

        & .buttons {
          display: flex;
          align-items: flex-start;
          margin-top: 7px;

          & button {
            margin-left: 10px;
            font-weight: bold;
            text-transform: uppercase;
            border-radius: 8px;
          }
        }
      }
    }
  }

  .validation {
    height: 230px;

    &-content {
      display: flex;
      flex-direction: column;
      padding: 0 19px;

      & label {
        font-weight: unset;
      }

      & input {
        max-width: fit-content;
      }

      & span {
        margin-top: 10px;
        font-size: 10px;
      }

      .input-date {
        width: fit-content;
      }
    }

    &-footer {
      flex-direction: row-reverse;
      display: flex;
      padding: 19px;
    }

    button {
      border-radius: 8px;
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  .history {
    display: flex;
    align-items: center;
    padding: 8px;
    font-size: 14px;
    background: $primaryBlue;
    border-radius: 8px;
    height: fit-content;
    line-height: 21px;
  }

  .history-column {
    flex: 1;

    & .tag {
      border-radius: 10px;
      border: 1px solid $secondaryOrange;
      width: fit-content;
      padding: 2px 9px;
      font-size: 9px;
    }
  }

  .history-header-list {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: $darkGrey;
  }

  .teasing {
    width: 100%;
    text-align: center;
    padding-top: 0.5rem;
    color: $mediumGrey;
  }

  .error-message {
    text-align: center;
    color: $darkRed;
  }

  .mx-inputs::v-deep input {
    border: 0.5px solid #5fbfc6;
    border-radius: 10px;
  }

  .secondary {
    border: 0.5px solid $secondaryOrange;
  }
</style>
