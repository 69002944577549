<template>
  <modal>
    <template #header>
      <div class="chicken-header">
        <div class="chicken-title">
          <v-svg name="chicken-offer" h="6rem" />
          <div class="chicken-title-text">
            <span>{{$t('promotionDetail.chicken.title')}}</span>
            <span>{{$t('promotionDetail.chicken.infoTitle')}}</span>
          </div>
        </div>
        <v-svg class="pointer" name="close" h="1rem" @click="$emit('close')" />
      </div>
    </template>

    <template #body>
      <modal v-if="showChickenAnomaly">
        <template #header>
          <div class="chicken-header">
            <div class="chicken-title">
              <v-svg name="chicken-offer" h="6rem" />
              <div class="chicken-title-text">
                <span>{{$t('promotionDetail.chicken.titleAnomaly')}}</span>
              </div>
            </div>
            <v-svg class="pointer" name="close" h="1rem" @click="showChickenAnomaly = false" />
          </div>
        </template>
        <template #body>
          <p>{{$t('promotionDetail.chicken.infoAnomaly')}}</p>
        </template>
        <template #footer>
          <div class="buttons">
            <v-button color="primary" :active="true" :label="$t('promotionDetail.chicken.export')" size="medium"
              :isLoading="isExportLoading" @click="!isExportLoading ? callExportAnomaly() : ''" />
          </div>
        </template>
      </modal>
      <div class="chicken-content">
        <div class="process">
          <span class="num">1.</span>
          <div class="content">
            <h3>{{$t('promotionDetail.chicken.export')}}</h3>
            <p>{{$t('promotionDetail.chicken.infoExport')}}</p>
            <v-button color="primary" :active="true" :label="$t('promotionDetail.chicken.export')" size="medium"
              :isLoading="isExportLoading" @click="!isExportLoading ? callExport() : ''" />
          </div>
        </div>
        <div class="process">
          <span class="num">2.</span>
          <div class="content">
            <h3>{{$t('promotionDetail.chicken.import')}}</h3>
            <p>{{$t('promotionDetail.chicken.infoImport')}}</p>
            <input-file @file-updated="file = $event" :file-name="file ? file.name : null"></input-file>
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="buttons">
        <v-button color="secondary" :active="true" :label="$t('promotionDetail.chicken.cancel')" size="medium"
          @click="$emit('close')" />
        <v-button color="primary" :active="true" :label="$t('promotionDetail.chicken.validate')" size="medium"
          :isLoading="isImportLoading" @click="!isImportLoading ? validateImport() : ''" />
      </div>
    </template>
  </modal>
</template>

<script>
import { mapState } from 'vuex'
import Modal from '@/components/Modal'
import { exportChickenOffer } from '@/utils/export/exportChickenOffer'
import { extendWithAuthorization, httpNip } from '@/http'

export default {
  name: 'ChickenOffer',
  components: {
    Modal
  },
  data() {
    return {
      isExportLoading: false,
      isImportLoading: false,
      file: null,
      showChickenAnomaly: false,
      dataAnomaly: null
    }
  },
  computed: {
    ...mapState({
      promotionDetail: state => state.promotionDetail.promotionDetail,
    }),
  },
  methods: {
    async callExport() {
      this.isExportLoading = true
      let searchParams = {
        discountContractCode: this.$route.query.discountContractCode
      }
      const extendedHttp = await extendWithAuthorization(httpNip)
      await extendedHttp.get(`promotionDetail/chickenOffer`, { searchParams }).json()
        .then((res) => {
          const nameExport = `config_Poulet_${this.promotionDetail.orderSupplier.orderSupplierDepartmentCode}_${this.$route.query.discountContractCode}_${this.promotionDetail.discountContractChannel}.xlsx`
          const nameSheet = this.$route.query.discountContractCode
          exportChickenOffer(res, nameExport, nameSheet)
        })
        .catch(() => this.$toast.error(this.$t('message.error.messageGlobal')))
        .finally(() => this.isExportLoading = false)
    },
    async callExportAnomaly() {
      this.isExportLoading = true
      const nameExport = `config_Poulet_${this.promotionDetail.orderSupplier.orderSupplierDepartmentCode}_${this.$route.query.discountContractCode}_${this.promotionDetail.discountContractChannel}_Anomalie.xlsx`
      const nameSheet = this.$route.query.discountContractCode
      exportChickenOffer(this.dataAnomaly, nameExport, nameSheet, true)
      this.isExportLoading = false
    },
    async validateImport() {
      this.isImportLoading = true
      const formData = new FormData()
      formData.append('file', this.file)
      const extendedHttp = await extendWithAuthorization(httpNip)
      await extendedHttp.post(`promotionDetail/chickenOffer/upload`, { body: formData }).json()
        .then((res) => {
          if (res.dataAnomaly) {
            this.dataAnomaly = res.dataAnomaly
            this.showChickenAnomaly = true
          } else {
            this.$toast.success(this.$t('promotionDetail.chicken.importSuccess'))
            this.$store.commit('common/SET_REFRESH_DATA', true)
            this.$router.push({ name: 'Promotion' })
          }
        })
        .catch(() => this.$toast.error(this.$t('promotionDetail.chicken.importFail')))
        .finally(() => this.isImportLoading = false)
    }
  }
}
</script>
<style lang="scss" scoped>
.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

button {
  border-radius: 8px;
  margin: 0 5px;
  width: 85px;
  justify-content: center;
}

.chicken {
  &-header {
    display: flex;
    justify-content: space-between;
  }

  &-title {
    display: flex;
    font-size: 16px;
    align-items: center;

    &-text {
      display: flex;
      flex-direction: column;
    }

    & span:first-child {
      line-height: 44px;
      font-size: 24px;
      font-weight: bold;
      border-bottom: 4px solid $primaryOrange;
      width: fit-content;
      margin-bottom: 10px;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    widows: 100%;

    .num {
      font-size: 48px;
      font-weight: bold;
      line-height: 48px;
      margin-right: 15px;
      width: 40px;
      color: $primaryOrange;
    }

    .process {
      display: flex;
      flex: 1;
      flex-direction: row;

      h3 {
        height: 48px;
        display: flex;
        align-items: flex-end;
      }

      p {
        line-height: 20px;
      }

      .content {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }
  }
}
</style>
