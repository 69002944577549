<!-- Created By Vasse Alexandre -->
<template>
  <div class="array-checkbox">
    <v-svg v-if="checkable" :name="checked ? 'checkbox-filled' : 'checkbox-empty'" h="1rem" @click="invokeParentMethod" />
  </div>
</template>

<script>
  export default {
    name: 'ArrayCheckbox',
    computed: {
      checkable() {
        return this.params.data.isPaymentSupplierExists
      },
      checked() {
        return this.params.context?.componentParent?.checkedOffers?.includes(this.params.data?.discountContractCode)
      }
    },
    methods: {
      invokeParentMethod() {
        this.params.context?.componentParent?.onOfferClick(this.params.data?.discountContractCode)
      }
    }
  }
</script>

<style scoped lang="scss">
  .array-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    .v-svg { margin-top: -1rem; }
  }
</style>
