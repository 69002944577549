<!-- Created By Vasse Alexandre -->
<template>
  <popup-modal ref="popup">
    <v-svg name="confirmFacturation" h="6rem" />
    <div class="confirm-title">{{ title }}</div>
    <div class="message">{{ message }}</div>
    <div class="btns">
      <v-button color="secondary" active :label="cancelButton" @click="cancel" />
      <v-button color="primary" active :label="okButton" @click="confirm" />
    </div>
  </popup-modal>
</template>

<script>
  export default {
    name: 'ConfirmDialog',
    data: () => ({
      title: 'Confirmation',
      message: "Confirmez vous l'action ?",
      okButton: 'CONFIRMER',
      cancelButton: 'ANNULER',
      resolvePromise: undefined,
      rejectPromise: undefined,
    }),

    methods: {
      show(opts = {}) {
        if (opts.title) this.title = opts.title
        if (opts.message) this.message = opts.message
        if (opts.okButton) this.okButton = opts.okButton
        if (opts.cancelButton) this.cancelButton = opts.cancelButton

        this.$refs.popup.open()

        return new Promise((resolve, reject) => {
          this.resolvePromise = resolve
          this.rejectPromise = reject
        })
      },

      confirm() {
        this.$refs.popup.close()
        this.resolvePromise(true)
      },

      cancel() {
        this.$refs.popup.close()
        this.resolvePromise(false)
      }
    }
  }
</script>

<style scoped lang="scss">
  .svg-confirmFacturation {
    position: absolute;
    top: 0;
    left: 0.5rem;
  }

  .confirm-title {
    display: flex;
    align-items: center;
    @extend %font-body-bold;
    font-size: 26px;
    line-height: 26px;
    margin: 0 0 2rem 2rem;
  }

  .message {
    font-size: 15px;
    max-width: 600px;
    text-align: center;
  }

  .btns {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    gap: 1rem;

    .button {
      padding: 8px;
      border-radius: 8px;
    }
  }
</style>
