<template>
  <div class="card-header">
    <h2>
      <TitleBar class="promotionDetail-title" :title="title" />
    </h2>
  </div>
</template>

<script>
  export default {
    name: 'CardHeader',
    props: {
      title: {
        type: String,
        default: ''
      }
    }
  }
</script>
<style lang="scss" scoped>
  .card-header {
    margin: 10px 19px;
  }
</style>
