<template>
  <div class="input-text" :style="{ width }">
    <input
      :type="inputType"
      :placeholder="placeholder"
      :value="modelValue"
      @input="updateModelValue($event.target.value)"
      @blur="formatModelValue"
      :min="min"
      :max="max"
    />
    <v-svg v-if="showEraser" name="erase" h="1.5rem" @click="clearModelValue" />
  </div>
</template>

<script>
  export default {
    name: 'InputText',
    props: {
      modelValue: {
        type: [String, Number, null],
        required: true,
      },
      placeholder: {
        type: [String, null],
        default: 'Entrer une valeur',
      },
      inputType: {
        type: String,
        default: 'text',
      },
      showEraser: {
        type: Boolean,
        default: false,
      },
      width: {
        type: String,
        default: '100%',
      },
      min: {
        type: Number,
        default: null,
      },
      max: {
        type: Number,
        default: null,
      },
      fixed: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      updateModelValue(value) {
        // Allow immediate input of numbers or text
        this.$emit('update:modelValue', value)
      },
      formatModelValue() {
        // Format the model value with 2 decimal places on blur
        if (this.fixed) {
          this.$emit('update:modelValue', Number(this.modelValue).toFixed(2))
        }
      },
      clearModelValue() {
        this.$emit('update:modelValue', null)
      },
    },
  }
</script>

<style scoped lang="scss">
  .input-text {
    display: flex;
    align-items: center;

    input[type='text'],
    input[type='number'] {
      border: 0.5px solid $primaryBlue;
      border-radius: 10px;
      outline: none;
      padding: 5px 0 5px 15px;
      @extend %font-body;
      color: #333333;
      font-size: 13px;
      height: 28px;
      width: 100%;
    }

    .svg-erase {
      margin-top: 3px;
      margin-left: 0.2rem;
      cursor: pointer;
    }
  }
</style>
