<template>
  <div class="reporting-search">
    <div class="search-title">{{ $t('reporting.search') }}</div>
    <div class="inputs">
      <div class="period field">
        <div class="label">{{ $t('reporting.fields.reportingYear.label') }}</div>
        <div class="date-container">
          <date-picker
            v-model:value="reportingSearch.reportingYear"
            type="year"
            format="YYYY"
            class="mx-inputs"
            value-type="format"
            popup-class="mx-popup"
            :placeholder="$t('reporting.fields.reportingYear.placeholder')"
            langString="fr"
            width="200px"
          ></date-picker>
        </div>
      </div>
      <div class="tract field">
        <div class="label">{{ $t('reporting.fields.reportingTableBoardType.label') }}</div>
        <input-select-auto-complete
          :value="reportingSearch.reportingTableBoardType"
          :options="reportingTableBoardTypes"
          :loading="loadingTableBoardTypes"
          :placeholder="$t('reporting.fields.reportingTableBoardType.placeholder')"
          @input-value="reportingSearch.reportingTableBoardType = reportingTableBoardTypes.find((TDB) => TDB === $event)"
          width="260px"
          show-eraser
        />
      </div>
      <div class="period field">
        <div class="label">{{ $t('reporting.fields.reportingStartDate.label') }}</div>
        <div class="date-container">
          <date-picker
            v-model:value="reportingSearch.reportingStartDate"
            type="date"
            format="DD/MM/YYYY"
            class="mx-inputs"
            value-type="format"
            popup-class="mx-popup"
            :placeholder="$t('reporting.fields.reportingStartDate.placeholder')"
            langString="fr"
            width="200px"
            @change="periodChange"
          ></date-picker>
        </div>
      </div>
      <div class="period field">
        <div class="label">{{ $t('reporting.fields.reportingEndDate.label') }}</div>
        <div class="date-container">
          <date-picker
            v-model:value="reportingSearch.reportingEndDate"
            type="date"
            format="DD/MM/YYYY"
            class="mx-inputs"
            value-type="format"
            popup-class="mx-popup"
            :placeholder="$t('reporting.fields.reportingEndDate.placeholder')"
            langString="fr"
            width="200px"
            :disabled="disabled"
            :disabled-date="enabledUntilLastDayOfTheYear"
            @change="periodChange"
          ></date-picker>
        </div>
      </div>

    </div>
    <div class="footer">
        <v-button :label="$t('reporting.buttons.reset')" :active="true" color="secondary" @click="reset()" />
        <v-button :label="$t('reporting.buttons.search')" :active="true" @click="search()" />
    </div>
  </div>
</template>

<script>
  import { DateTime } from 'luxon'
  import { mapActions, mapState } from 'vuex'
  import DatePicker from 'vue-datepicker-next'
  import 'vue-datepicker-next/index.css'
  import 'vue-datepicker-next/locale/fr'
  import { extendWithAuthorization, httpNip } from '@/http'

  const initData = {
    reportingTableBoardType: null,
    reportingStartDate: null,
    reportingEndDate: null,
    reportingYear: DateTime.now().year.toString(),
  }

  export default {
    name: 'ReportingSearch',
    components: { DatePicker },
    emits: ['search'],
    data() {
      return {
        loadingTableBoardTypes: false,
        reportingSearch: { ...initData },
        disabled: true,
        reportingTableBoardTypes: [],
      }
    },

    async created() {
      await this.loadReportingTableBoardTypes()
    },

    methods: {
      async loadReportingTableBoardTypes() {
        this.loadingTableBoardTypes = true
        const extendedHttp = await extendWithAuthorization(httpNip)
        this.reportingTableBoardTypes = await extendedHttp.get('reporting/getDirectoriesTitle').json()
        this.loadingTableBoardTypes = false
      },

      periodChange() {
        if (this.reportingSearch.reportingStartDate){
          this.disabled = false
        } else {
          this.reportingSearch.reportingEndDate = null
          this.disabled = true
        }
      },

      reset() {
        this.reportingSearch = { ...initData }
        this.disabled = true
      },

      enabledUntilLastDayOfTheYear(date) {
        const selectedStartDate = DateTime.fromFormat(this.reportingSearch.reportingStartDate, "dd/MM/yyyy").toJSDate()
        return date < selectedStartDate
      },

      search() {
        const datesMandatoryFields =
          this.reportingSearch.reportingStartDate &&
          this.reportingSearch.reportingEndDate &&
          this.reportingSearch.reportingTableBoardType &&
          this.reportingSearch.reportingYear

        if (!datesMandatoryFields) {
          return this.$toast.error(this.$t('reporting.errors.mandatoryFields'))
        }

        this.$emit('search', JSON.parse(JSON.stringify(this.reportingSearch)))
      },

    },
  }
</script>

<style scoped lang="scss">
  .reporting-search {
    padding: 1rem;
    background: white;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 15px;

    .search-title,
    .files-to-download-title {
      padding-bottom: 0.2rem;
      border-bottom: 2px solid $primaryOrange;
      @extend %font-body-bold;
      width: fit-content;
      font-size: 18px;
    }

    .inputs {
      width: 80%;
      flex-wrap: wrap;
      margin: 0 auto;
      display: flex;
      gap: 1.7rem 7rem;

      .field {
        width: 44%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @extend %font-body-semi-bold;
      }

      .block {
        display: flex;
        flex-direction: column;
        width: 44%;
        row-gap: 1.7rem;

        .field {
          width: 100%;
        }
      }
    }

    .mx-datepicker {
      width: unset;
    }

    .mx-inputs::v-deep input {
      border: 0.5px solid #5fbfc6;
      border-radius: 10px;
      height: 28px;
      width: 260px;
    }

    .footer {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      margin-top: 2%;

      .button {
        padding: 8px;
        border-radius: 8px;
        @extend %font-body-semi-bold;
        font-size: 14px;
        margin-left: 1rem;
      }
    }
  }
</style>
