<template>
  <div v-if="showModal" id="popup" class="popup">
    <div>
      <button class="close-button" @click="$emit('close-modal')">
        <v-svg :name="'cross'" :h="'0.9rem'" />
      </button>
    </div>

    <div class="box">
      <div class="group">
        <div class="title">Contrat</div>
        <V-Checkbox
          v-for="(item, index) in columnDefs.filter(s => s.groupId === 1)"
          :id="item.field"
          :key="index"
          :label="item.headerName"
          :is-checked="!item.hide"
          @change="$emit('edit-columns', item)" />
      </div>

      <div class="group">
        <div class="title">Facturation</div>
        <V-Checkbox
          v-for="(item, index) in columnDefs.filter(s => s.groupId === 2)"
          :id="item.field"
          :key="index"
          :label="item.headerName"
          :is-checked="!item.hide"
          @change="$emit('edit-columns', item)" />
      </div>
    </div>
  </div>
</template>

<script>
  import VCheckbox from '@/components/Checkbox'

  export default {
    name: 'ParamsColumns',
    components: {
      VCheckbox
    },
    props: {
      columnDefs: {
        type: Array,
        default: () => []
      },
      showModal: {
        type: Boolean,
        default: false
      }
    },
    emits: ['edit-columns', 'close-modal']
  }

</script>
<style scoped lang="scss">
  @import "~@/styles/_colors";

  .popup {
    position: absolute;
    width: 35rem;
    display: flex;
    flex-direction: row-reverse;
    height: 164px;
    right: 34px;
    background: $white;
    box-shadow: 4px 4px 20px 3px rgb(0 0 0 / 10%);
    border-radius: 14px;
    margin-top: 60px;
    margin-right: 25px;
    top: 0;
  }

  .close-button {
    background-color: $white;
    color: $darkGrey;
    border: none;
    margin: 15px;
    cursor: pointer;
  }

  .box {
    display: flex;
  }

  .group {
    width: 14rem;
    padding-top: 21px;
  }

  #popup::after {
    content: "";
    position: absolute;
    height: 25px;
    width: 25px;
    top: -20px;
    left: 95%;
    background-color: $white;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    box-shadow: 4px 4px 20px 3px rgb(0 0 0 / 10%);
    border: none;
  }

  .title {
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    font-style: normal;
    font-weight: bold;
    font-size: 13.33px;
    line-height: 11px;
  }
</style>
