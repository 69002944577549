<!-- Created By Vasse Alexandre -->
<template>
  <div class="array-thumbs">
    <v-svg :name="up ? 'thumbs-up' : 'thumbs-down'" h="1.3rem" />
  </div>
</template>

<script>
  export default {
    name: 'ArrayThumbs',
    computed: {
      up() {
        return this.params.data?.isPaymentSupplierExists
      }
    }
  }
</script>

<style scoped lang="scss">
  .array-thumbs {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;

    .v-svg { margin-left: 0.5rem; }
  }
</style>
