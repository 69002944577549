import InjectBaseComponents from '@/components/base'
import { getAnalytics, setUserId } from 'firebase/analytics'
import { createApp as createVueApp } from 'vue'
import App from './App.vue'
import Forbidden from './Forbidden.vue'
import router from './router'
import i18n from './i18n'
import store from './store'
import '../node_modules/ag-grid-community/dist/styles/ag-grid.css'
import '../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css'
import moment from 'moment'
import Toaster from '@meforma/vue-toaster'

/** AG-GRID ENTERPRISE */
import 'ag-grid-enterprise'
import { LicenseManager } from 'ag-grid-enterprise'
LicenseManager.setLicenseKey(
  'CompanyName=AUCHAN RETAIL SERVICES,LicensedGroup=PerfWeb,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-029238,SupportServicesEnd=7_July_2023_[v2]_MTY4ODY4NDQwMDAwMA==a3331a9e00b404e507106d8330a55671'
)
/********************* */

export function createApp({ firebaseUser, user, roles }) {
  const analytics = getAnalytics()
  setUserId(analytics, user.uid)

  const app = createVueApp(App)
    .provide('analytics', analytics)
    .provide('user', firebaseUser)
    .provide('userInfos', user)
    .provide('roles', roles)
    .use(router)
    .use(i18n)
    .use(store)
    .use(moment)
    .use(Toaster)

  InjectBaseComponents(app)
  app.mount('#app')
}

export function createForbiddenApp({ firebaseUser, userInfos, roles }) {
  const app = createVueApp(Forbidden).provide('user', firebaseUser).provide('userInfos', userInfos).provide('roles', roles)

  InjectBaseComponents(app)
  app.mount('#app')
}
