<template>
  <modal>
    <template #header>
      <h2>
        <TitleBar class="promotionDetail-title" :title="$t('promotionDetail.bloc.validation.message.title')" />
      </h2>
    </template>
    <template #body>
      <div class="title-validation">
        {{ $t('promotionDetail.bloc.validation.message.messageGigogne') }}
      </div>
      <input-radio
        v-model="offer"
        :values="offers"
        vertical
      />
    </template>
    <template #footer>
      <div class="footer-button">
        <v-button
          color="secondary"
          :active="true"
          :label="$t('promotionDetail.bloc.dispute.cancel')"
          size="medium"
          @click="$emit('close')"
        />
        <v-button
          color="primary"
          :active="true"
          :label="$t('promotionDetail.bloc.comment.validate')"
          size="medium"
          :disabled="!offer"
          @click="$emit('pointGigogne', offer.id==='gigogne')"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '@/components/Modal'
  export default {
    name: 'PromotionDetailValidation',
    components: {
      Modal,
    },
    props: {
      discountContractCode: {
        type: String,
        require: true,
      },
      discountContractCodeGigogne: {
        type: String,
        require: true,
      },
    },
    data() {
      return {
        offer: null,
        offers: [
          { id: 'gigogne', traduction: this.$t('promotionDetail.bloc.validation.message.gigogne', { discountContractCode: this.discountContractCode, discountContractCodeGigogne: this.discountContractCodeGigogne}) },
          { id: 'onlyOneOffer', traduction: this.$t('promotionDetail.bloc.validation.message.onlyOneOffer', { discountContractCode: this.discountContractCode}) },
        ]
      }
    },
    methods: {

    },
  }
</script>
<style lang="scss">
.title-validation{
  margin-bottom: 0.5rem;
}
.footer-button{
  display: flex;
  flex-direction: row;
  gap:10px;
  justify-content: flex-end;
  button {
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 8px;
  }
}
</style>