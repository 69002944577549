<!-- Created By Vasse Alexandre -->
<template>
  <div class="files-to-download">
    <div v-if="!filesBilling.length" class="no-files">{{ $t('billing.filesToDownload.noFiles') }}</div>
    <div v-for="(file, i) in filesBilling" :key="i" class="file">
      <div class="label">{{ fileLabel(file) }}</div>
      <v-svg :name="file.status === 'ERROR' ? 'error' : file.status === 'PENDING' ? 'spinner' : 'download'" h="1.5rem" @click="downloadFile(file)" />
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import { mapActions, mapState } from 'vuex'
  import { extendWithAuthorization, httpNip } from '@/http'
  import { saveAs } from 'file-saver'

  export default {
    name: 'FilesToDownload',
    computed: {
      ...mapState('files', ['filesBilling'])
    },
    async created() {
      await this.fetchFilesBilling()
    },
    methods: {
      ...mapActions('files', ['fetchFilesBilling']),

      fileLabel(file) {
        let label = `${this.$t(`billing.filesToDownload.types.${file.billingType}`)} Du ${moment(file.createdAt).format('DD/MM/YYYY à HH:mm')} - ${file.discountContractChannel} | ${file.perimeter}`
        if (file?.discountContractEndDate) label += ` | Date fin contrat: ${moment(file.discountContractEndDate).format('DD/MM/YYYY')}`
        if (file?.orderSupplierCnuf) label += ` | CNUF ${file.orderSupplierCnuf}`
        if (file?.discountContractCode?.length === 1) label += ` | AVANTAGE ${file.discountContractCode}`
        return label
      },

      async downloadFile(file) {
        if (file.status === 'PENDING') return
        const extendedHttp = await extendWithAuthorization(httpNip)
        const { url } = await extendedHttp.get(`common/file-signed-url`, { searchParams: { path: file.filepath } }).json()
        saveAs(url, file.filename)
      }
    }
  }
</script>

<style scoped lang="scss">
  .files-to-download {
    background-color: $secondaryBlue;
    text-transform: uppercase;
    padding: 15px 40px;
    border: 1px solid $secondaryBlue;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 15px;

    .no-files {
      @extend %font-body-semi-bold;
      font-size: 12px;
    }

    .file {
      display: grid;
      column-gap: 2rem;
      grid-template-columns: 1fr auto;
      align-items: center;
      position: relative;

      &:not(:first-child) {
        margin-top: 1rem;
      }

      &:not(:last-child) {
        &::after {
          display: block;
          border-right: 1px white;
          content: '';
          height: 1px;
          width: 110%;
          background-color: white;
          position: absolute;
          bottom: -0.5rem;
          left: -5%;
          border-radius: 1px;
        }
      }

      .label {
        @extend %font-body-semi-bold;
        font-size: 12px;
      }

      .svg-download {
        cursor: pointer;
        color: white;
      }

      .svg-error { pointer-events: none; }
    }
  }
</style>
