<template>
  <div class="pagination">
    <ul>
      <li class="navPrev">
        <button class="flex" :disabled="value === 1" @click="$emit('onPage', value - 1)">
          <v-svg :name="'nav-prev'" :h="'0.5rem'" />
        </button>
      </li>

      <li v-for="(item, index) in items" :key="'paging_' + index">
        <button :disabled="isNaN(Number(item))" :class="['pagination-item', { active: item === value }]" @click="$emit('onPage', item)">{{ item }}</button>
      </li>

      <li class="navNext">
        <button class="flex" :disabled="value === numberPages" @click="$emit('onPage', value + 1)">
          <v-svg :name="'nav-next'" :h="'0.5rem'" />
        </button>
      </li>
    </ul>
  </div>
</template>
<script>
  export default {
    //PAGINATION
    name: 'Pagination',
    props: {
      value: {
        type: Number,
        required: true
      },
      totalVisible: {
        type: Number,
        default: 0
      },
      numberPages: {
        type: Number,
        default: 0
      }
    },
    emits: ['onPage'],
    data() {
      return {
        maxButtons: 0
      }
    },
    computed: {
      items() {
        const maxLength = this.totalVisible

        if (this.numberPages <= maxLength || maxLength < 1) {
          return this.getRange(1, this.numberPages)
        }

        const even = maxLength % 2 === 0 ? 1 : 0
        const left = Math.floor(maxLength / 2)
        const right = this.numberPages - left + 1 + even

        if (this.value > left && this.value < right) {
          const start = this.value - left + 2
          const end = this.value + left - 2 - even

          return [1, '...', ...this.getRange(start, end), '...', this.numberPages]
        } else if (this.value === left) {
          const end = this.value + left - 1 - even

          return [...this.getRange(1, end), '...', this.numberPages]
        } else if (this.value === right) {
          const start = this.value - left + 1

          return [1, '...', ...this.getRange(start, this.numberPages)]
        } else {
          return [...this.getRange(1, left), '...', ...this.getRange(right, this.numberPages)]
        }
      }
    },
    methods: {
      getRange(from, to) {
        const range = []

        from = from > 0 ? from : 1

        for (let i = from; i <= to; i++) range.push(i)

        return range
      }
    }
  }
</script>

<style lang="scss" scoped>
  .pagination {
    display: flex;

    ul {
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      border: 0.5px solid $primaryBlue;
      border-radius: 10px;

      li {
        height: 100%;

        &.navPrev button,
        &.navNext button {
          height: 100%;
          width: 50px;
          justify-content: center;
        }

        &.navPrev {
          button {
            border-bottom-left-radius: 10px;
            border-top-left-radius: 10px;
          }
        }

        &.navNext {
          button {
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
          }
        }

        button {
          padding: 10px 10px;
          cursor: pointer;
          background-color: white;
          color: $primaryBlue;
          border: 0.5px solid $primaryBlue;
          font-size: 11px;

          &:hover {
            background-color: $veryLightGrey;
          }

          &.active {
            background-color: $primaryBlue;
            color: white;
          }
        }
      }
    }
  }
</style>
