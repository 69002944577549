export default {
  name: 'disputeMixin',
  methods: {
    sum(rows) {
      let total = {
        benefitAmountWithTax: 0,
        benefitAmountWithoutTax: 0,
        benefitTax: 0,
        benefitQuantity: 0,
        salesQuantity: 0
      }
      for (const row of rows) {
        total.benefitAmountWithTax += Number(row.benefitAmountWithTax)
        total.benefitAmountWithoutTax += row.vatRate
          ? Number(row.benefitAmountWithTax) / (1 + Number(row.vatRate) / 100)
          : Number(row.benefitAmountWithoutTax)
        total.benefitTax += row.vatRate ? ((Number(row.benefitAmountWithTax) / (Number(row.vatRate) + 100)) * Number(row.vatRate)) : Number(row.benefitTax)
        total.benefitQuantity += row.updateBenefitQuantity || row.updateBenefitQuantity === 0 ? Number(row.updateBenefitQuantity) : Number(row.benefitQuantity)
        total.salesQuantity += row.updateSalesQuantity || row.updateSalesQuantity === 0 ? Number(row.updateSalesQuantity) : Number(row.salesQuantity)
      }
      total.benefitAmountWithTax = total.benefitAmountWithTax.toFixed(2)
      total.benefitAmountWithoutTax = total.benefitAmountWithoutTax.toFixed(2)
      total.benefitTax = total.benefitTax.toFixed(2)
      return total
    },
  },
}
