<template>
  <div class="reporting">
    <div class="header">
      <div class="reporting-title">
        <v-svg name="dashboard" h="1.2rem" />
        {{ $t('reporting.title') }}
      </div>
      <v-svg name="question-circle" h="3rem" @mouseover="showInfoPopup = true" @mouseleave="showInfoPopup = false" />
      <info-popup v-if="showInfoPopup" :msg="$t('reporting.info')" />
    </div>
    <reporting-search @search="launchSearch"  />
    <loading v-if="loading" :is-loading="true" />
    <reporting-array v-if="search && !loading"
                           :reportingType ="reportingType"
                           :rows="arrayData"
                           :display-key="arrayDisplayKey"
                           @switchKey="arrayDisplayKey = $event" />

  </div>
</template>

<script>
  import { extendWithAuthorization, httpNip } from '@/http'
  import Modal from '@/components/Modal'
  import ReportingSearch from '@/components/reporting/ReportingSearch'
  import ReportingArray from '@/components/reporting/ReportingArray'


  export default {
    name: 'Reporting',
    components: { ReportingSearch, ReportingArray, Modal },
    data() {
      return {
        search: undefined,
        arrayData: [],
        reportingType: null,
        loading: false,
        showInfoPopup: false
      }
    },
    computed: {
    },
    created() {
    },
    mounted() {
    },
    methods: {
      async launchSearch(params) {
        this.loading = true
        const searchParams = {
          reportingTableBoardType: params.reportingTableBoardType,
          reportingStartDate: params.reportingStartDate,
          reportingEndDate: params.reportingEndDate,
          reportingYear: params.reportingYear
        }
        this.reportingType =  params.reportingTableBoardType
        Object.keys(searchParams).forEach(key => searchParams[key] ?? delete searchParams[key])
        try {
          const extendedHttp = await extendWithAuthorization(httpNip)
          this.arrayData = await extendedHttp.get('reporting/searchFiles', { searchParams }).json()
          this.search = params
        } catch (err) {
          console.error(err)
          this.$toast.error(this.$t('reporting.errors.search'))
        }
        this.loading = false
      }
    },
  }
</script>

<style scoped lang="scss">
  .reporting {
    padding: 2rem;
    position: relative;
    height: 100%;

    .header {
      height: 5%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 1rem;

      .reporting-title {
        padding-bottom: 0.5rem;
        border-bottom: 4px solid $primaryOrange;
        display: flex;
        @extend %font-title-1;

        .svg-reporting { margin-right: 0.8rem; }
      }

      .svg-question-circle { cursor: pointer; }

      .info-popup {
        position: absolute;
        top: 3rem;
        right: 1rem;
      }
    }

    .reporting-search {
      margin-top: 1rem;
    }

    .reporting-array {
      max-height: 100%;
      height: -webkit-fill-available;
      margin-top: 1rem;
    }
  }
</style>
