<template>
  <div class="promotion-detail-dispute">
    <Card :is-loading="isLoading">
      <template #header><CardHeader :title="$t('promotionDetail.bloc.dispute.title')" /></template>
      <template #body>
        <div class="content">
          <span :class="['content-title', { hyper: promotionDetail.discountContractChannel === 'HYPER' }]">
            {{ promotionDetail.discountContractChannel }}
          </span>
          <template v-if="promotionDetail.discountContractStatusCode === 3">
            <v-button
              :color="'primary'"
              :active="true"
              :label="$t('promotionDetail.bloc.dispute.disputeDeclared')"
              :size="'medium'"
              @click="showPopup = !showPopup"
            />
          </template>
          <template v-else-if="promotionDetail.discountContractStatusCode === 4">
            <span class="dispute-date"
              >Date de déclaration du litige :
              {{ formatDate(promotionDetail.discountContractDisputeDate.value, 'DD/MM/YYYY') }}</span
            >
            <div class="buttons">
              <v-button
                :color="'primary'"
                :active="true"
                :label="$t('promotionDetail.bloc.dispute.proofToRedo')"
                :size="'medium'"
                @click="
                  $router.push({
                    name: 'DisputeJustificative',
                    query: {
                      discountContractCode: promotionDetail.discountContractCode,
                      channel: promotionDetail.discountContractChannel,
                    },
                  })
                "
              />
              <v-button
                :color="'primary'"
                :active="true"
                :label="$t('promotionDetail.bloc.dispute.statementToRedo')"
                :size="'medium'"
                @click="
                  $router.push({
                    name: 'DisputeStatement',
                    query: {
                      discountContractCode: promotionDetail.discountContractCode,
                      channel: promotionDetail.discountContractChannel,
                    },
                  })
                "
              />
            </div>
          </template>
        </div>
      </template>
    </Card>
    <modal v-if="showPopup">
      <template #header>
        <div class="dispute-popup-header">
          <div class="dispute-popup-header-title">
            <v-svg :name="'dispute'" :h="'6rem'" />
            <span>{{ $t('promotionDetail.bloc.dispute.popup.title') }}</span>
          </div>
          <v-svg class="pointer" :name="'close'" :h="'1rem'" @click="showPopup = !showPopup" />
        </div>
      </template>
      <template #body>
        <div class="dispute-popup-body">
          <span>{{ $t('promotionDetail.bloc.dispute.popup.declareContractDispute') }}</span>
          <span>{{ $t('promotionDetail.bloc.dispute.popup.declareBeginDateDispute') }}</span>
          <date-picker
            v-model:value="disputeDate"
            type="date"
            format="DD/MM/YYYY"
            popup-class="mx-popup"
            class="mx-inputs"
            value-type="format"
            placeholder="DD/MM/YYYY"
            langString="fr"
            clearable
          ></date-picker>
        </div>
      </template>
      <template #footer>
        <div class="dispute-popup-footer">
          <v-button
            :color="'secondary'"
            :active="true"
            :label="$t('promotionDetail.bloc.dispute.cancel')"
            :size="'medium'"
            @click="showPopup = !showPopup"
          />
          <v-button
            :is-loading="isBeingDeclared"
            :color="'primary'"
            :active="true"
            :disabled="disputeDate === null || isBeingDeclared"
            :label="$t('promotionDetail.bloc.dispute.validate')"
            :size="'medium'"
            @click="declareDispute"
          />
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
  import Card from '@/components/card/Card'
  import CardHeader from '@/components/card/CardHeader'
  import Modal from '@/components/Modal'
  import { globalProperties } from '@/main'
  import { extendWithAuthorization, httpNip } from '@/http'
  import dateMixin from '../../mixins/date'
  import DatePicker from 'vue-datepicker-next'
  import 'vue-datepicker-next/index.css'
  import 'vue-datepicker-next/locale/fr'
  export default {
    name: 'PromotionDetailDispute',
    components: {
      Card,
      CardHeader,
      Modal,
      DatePicker,
    },
    mixins: [dateMixin],
    props: {
      promotionDetail: {
        type: Object,
        required: true,
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        showPopup: false,
        isBeingDeclared: false,
        disputeDate: null,
      }
    },
    methods: {
      async declareDispute() {
        try {
          this.isBeingDeclared = true
          let body = {
            userCode: globalProperties?.user?.uid,
            discountContractCode: this.promotionDetail.discountContractCode,
            discountContractDisputeDate: this.disputeDate,
            discountContractChannel: this.promotionDetail.discountContractChannel,
            discountContractBillingDate: this.formatDate(this.promotionDetail.discountContractBillingDate.value, 'DD/MM/YYYY'),
            discountContractTotalBillingAmount: (
              Math.round(Number.parseFloat(this.promotionDetail.discountContractTotalBillingAmount) * 100) / 100
            ).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' }),
            perimeter: this.promotionDetail.perimeter,
            discountContractEndDate: this.formatDate(this.promotionDetail.discountContractEndDate.value, 'DD/MM/YYYY'),
            orderSupplierCnuf: this.promotionDetail.orderSupplier.orderSupplierCnuf,
            discountContractStatusCode: this.promotionDetail.discountContractStatusCode,
          }
          const extendedHttp = await extendWithAuthorization(httpNip)
          await extendedHttp.put('dispute', { json: body }).then(() => {
            this.$emit('declareDispute')
            this.showPopup = !this.showPopup
          })
        } catch (error) {
          this.isBeingDeclared = false
          this.$toast.error(this.$t('message.error.messageGlobal'))
        }
      },
    },
  }
</script>
<style lang="scss">
  .promotion-detail-dispute {
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      &-title {
        color: $primaryOrange;
        font-weight: bold;
        font-size: 18px;
        &.hyper {
          color: $primaryBlue;
        }
      }
      .dispute-date {
        color: $mediumGrey;
      }
      .buttons {
        display: flex;
        flex-direction: columns;
        button {
          margin-right: 10px;
        }
      }
    }
    button {
      margin: 10px 0 20px 0;
      border-radius: 10px;
      font-weight: bold;
      text-transform: uppercase;
    }
    .dispute-popup {
      &-header {
        display: flex;
        justify-content: space-between;

        &-title {
          display: flex;
          align-items: center;
          font-size: 24px;
          font-weight: bold;
          width: 100%;

          & span {
            line-height: 54px;
            border-bottom: 4px solid $primaryOrange;
          }
        }
      }
      &-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        span {
          padding-bottom: 7px;
        }
        .v-calendar {
          margin: 10px 0;
          display: unset !important;
          .calendar {
            position: fixed !important;
            top: unset !important;
            left: unset !important;
            width: unset !important;
          }
        }
      }
      &-footer {
        justify-content: center;
        display: flex;
        & button {
          &:first-child {
            margin-right: 10px;
          }
        }
      }
    }
  }
  .mx-inputs::v-deep input {
    border: 0.5px solid $secondaryOrange;
    border-radius: 10px;
    height: 28px;
    width: 260px;
  }
</style>
