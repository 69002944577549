<template>
  <div tabindex="0" @blur="opened = false" :class="['input-select', { opened, secondary, disabled, 'with-eraser': showEraser, loading }]" :style="{ width }">
    <div class="custom-select" @click="opened = !opened">
      <div :class="['current-value', { placeholder: !modelValue?.[selectionKey] }]" :title="!modelValue?.[selectionKey] ? placeholder : traductionKey ? $t(`${traductionKey}.${modelValue?.[selectionKey]}`) : combined ? modelValue?.[selectionKey] +' - '+ modelValue?.[displayKey] : modelValue?.[displayKey ?? selectionKey]">
        {{ !modelValue?.[selectionKey] ? placeholder : traductionKey ? $t(`${traductionKey}.${modelValue?.[selectionKey]}`) : combined ? modelValue?.[selectionKey] +' - '+ modelValue?.[displayKey] : modelValue?.[displayKey ?? selectionKey] }}
      </div>
      <v-svg v-if="loading" name="spinner-blue" h="1.3rem" />
      <v-svg v-else name="arrow-up" h="1rem" :class="{ rotate: opened }" />
    </div>
    <div v-if="opened" class="list">
      <div v-if="placeholder?.length && modelValue?.[selectionKey]" class="item item-placeholder" @click="select(null)">
        {{ placeholder }}
      </div>
      <div v-for="(item, i) in values"
           :key="i"
           :class="['item', { selected: item?.[selectionKey] === modelValue?.[selectionKey] }]"
           @click="select(item)"
           :title="traductionKey ? $t(`${traductionKey}.${item[selectionKey]}`) : combined ? item[selectionKey] +' - '+ item[displayKey] : item[displayKey ?? selectionKey]">
        {{ traductionKey ? $t(`${traductionKey}.${item[selectionKey]}`) : combined ? item[selectionKey] +' - '+ item[displayKey] : item[displayKey ?? selectionKey] }}
      </div>
    </div>
    <v-svg v-if="showEraser" name="erase" h="1.5rem" @click="$emit('update:modelValue', null)" />
  </div>
</template>

<script>
  export default {
    name: 'InputSelect',
    props: {
      modelValue: {
        type: [Object, null],
        required: true
      },
      values: {
        type: Array,
        required: true
      },
      selectionKey: {
        type: String,
        default: 'id'
      },
      displayKey: {
        type: [String, null],
        default: null
      },
      placeholder: {
        type: [String, null],
        default: 'Selectionner une valeur'
      },
      showEraser: {
        type: Boolean,
        default: false
      },
      traductionKey: {
        type: [String, null],
        default: null
      },
      secondary: {
        type: Boolean,
        default: false
      },
      width: {
        type: String,
        default: '100%'
      },
      loading: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      combined: {
        type: Boolean,
        default: false
      }
    },
    emits: ['update:modelValue', 'update'],
    data() {
      return {
        opened: false
      }
    },
    methods: {
      select(item) {
        this.$emit('update:modelValue', item)
        this.$emit('update')
        this.opened = false
      }
    }
  }
</script>

<style scoped lang="scss">
  .input-select {
    display: flex;
    align-items: center;
    position: relative;

    .custom-select {
      height: 28px;
      border: 0.5px solid $primaryBlue;
      padding: 5px 10px 5px 15px;
      border-radius: 10px;
      @extend %font-body;
      color: $darkGrey;
      font-size: 13px;
      cursor: pointer;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      position: relative;
      background: $white;

      .current-value {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .placeholder {
        color: $mediumGrey;
      }

      .svg-arrow-up {
        color: $primaryBlue;
        border-radius: 0 10px 10px 0;
        margin-left: 0.5rem;

        &.rotate { transform: rotate(180deg); }
      }

      .svg-spinner-blue {
        position: absolute;
        right: 5px;
      }
    }

    .list {
      position: absolute;
      top: 25px;
      left: 0;
      border-right: 1px solid $primaryBlue;
      border-bottom: 1px solid $primaryBlue;
      border-left: 1px solid $primaryBlue;
      box-shadow: 0 15px 20px rgb(0 0 0 / 15%);
      border-radius: 5px;
      width: 100%;
      max-height: 170px;
      overflow-y: auto;
      background-color: white;
      font-size: 13px;
      @extend %font-body;
      cursor: pointer;
      z-index: 10;

      .item {
        padding: 5px 5px 5px 15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover { background-color: $secondaryBlue; }

        &.selected {
          color: white;
          background-color: $primaryBlue;
        }
      }
    }

    .svg-erase {
      margin-top: 3px;
      margin-left: 0.2rem;
      cursor: pointer;
    }

    &.opened {
      .custom-select { border-radius: 10px 10px 0 0;}

      .list { border-radius: 0 0 10px 10px; }
    }


    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $primaryBlue;
    }

    &.secondary {
      .custom-select {
        border: 0.5px solid $primaryOrange;

        .svg-arrow-up { color: $primaryOrange; }
      }

      .list {
        border-right: 1px solid $primaryOrange;
        border-bottom: 1px solid $primaryOrange;
        border-left: 1px solid $primaryOrange;

        .item {
          &:hover { background-color: $secondaryOrange; }

          &.selected { background-color: $primaryOrange; }
        }
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: $primaryOrange;

      }
    }

    &.disabled {
      pointer-events: none;

      .custom-select {
        background-color: $disabledGrey;

        .svg-arrow-up { color: #A8A8AF; }
      }
    }

    &.loading { pointer-events: none; }

    &.with-eraser {
      .list { width: calc(100% - 28px); }
    }
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $primaryBlue;
  }
</style>
