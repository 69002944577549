<template>
  <div tabindex="0" class="v-calendar" @blur="calendarIsOpen = false">
    <div class="custom-v-calendar">
      <div :class="[{ opened: calendarIsOpen }, 'button-calendar']" @click="calendarIsOpen = !calendarIsOpen">
        <div :class="['current-value', { placeholder: !startDate }]">
          {{ startDate ? startDate.toFormat('dd/MM/yyyy') + ' au ' + endDate.toFormat('dd/MM/yyyy') : placeholder }}
        </div>
        <v-svg name="calendar" h="1rem" />
      </div>
      <v-svg v-if="showEraser" name="erase" h="1.5rem" @click="reset()" />
    </div>
    <div v-if="calendarIsOpen" class="calendar">
      <div class="month">
        <v-svg name="chevron-left" h="1rem" @click="shiftMonth(-1)" />
        <div class="current-month">{{ displayedMonth.toFormat('MMMM yyyy', { locale: 'fr' }) }}</div>
        <v-svg name="chevron-right" h="1rem" @click="shiftMonth(1)" />
      </div>
      <div class="calendar-days">
        <div v-for="(dayLabel, index) of days" :key="`${calendarId}:dayLabel#${index}`" class="cell label">
          {{ dayLabel }}
        </div>
        <div
          v-for="day in emptyCells"
          :key="`${calendarId}:previousMonthDay#${day}`"
          :class="['cell other-month', cssClasses(day, -1)]"
          @click="selectDay(day, -1)"
        >
          <div class="number">{{ day }}</div>
        </div>
        <div v-for="day in displayedMonth.daysInMonth" :key="`${calendarId}:day#${day}#`" :class="['cell', cssClasses(day)]" @click="selectDay(day)">
          <div class="number">{{ day }}</div>
        </div>
        <div
          v-for="day in daysOfNextMonth"
          :key="`${calendarId}:nextMonthDay#${day}`"
          :class="['cell other-month', cssClasses(day, 1)]"
          @click="selectDay(day, 1)"
        >
          <div class="number">{{ day }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { DateTime, Info } from 'luxon'

  const days = Info.weekdays('narrow', 'fr').map(day => day.replace('.', ''))
  let calendarId = 0

  export default {
    name: 'VCalendar',
    props: {
      startDate: {
        type: Object,
        default: undefined
      },
      endDate: {
        type: Object,
        default: undefined
      },
      showEraser: {
        type: Boolean,
        default: false
      },
      placeholder: {
        type: String,
        default: 'jj/mm/aaaa au jj/mm/aaaa'
      }
    },
    emits: ['period-change'],
    data() {
      return {
        days,
        calendarId: ++calendarId,
        calendarIsOpen: false,
        justOpened: false,
        displayedMonth: this?.startDate?.set({ day: 1 }) ?? DateTime.now().set({ day: 1 }),
        lastSelectableDay: DateTime.local(),
        selectedStartDate: null,
        selectedEndDate: null,
        manualSelection: false,
        toDay: DateTime.now()
      }
    },
    computed: {
      emptyCells() {
        const cells = []
        for (let i = this.displayedMonth.weekday - 1; i > 0; i--) {
          cells.push(this.daysOfPreviousMonth - i + 1)
        }
        return cells
      },
      daysOfPreviousMonth() {
        return this.displayedMonth.minus({ months: 1 }).daysInMonth
      },
      daysOfNextMonth() {
        return 7 - ((this.displayedMonth.daysInMonth + this.emptyCells.length) % 7)
      }
    },
    watch: {
      calendarIsOpen() {
        if (this.calendarIsOpen) {
          this.justOpened = true
          if (this.selectedStartDate === null) this.selectDefaultDay()
        } else {
          this.manualSelection = false
        }
      },
      displayedMonth() {
        if (!this.manualSelection && !this.selectedStartDate) this.selectDefaultDay()
      }
    },
    methods: {
      cssClasses(day, shiftMonth = 0) {
        const date = this.displayedMonth.plus({ month: shiftMonth }).set({ day })
        return {
          selected: this.dateIsSelected(date),
          start: date.hasSame(this.selectedStartDate, 'day'),
          end: date.hasSame(this.selectedEndDate, 'day'),
          disabled: false //!this.dateIsSelectable(date)
        }
      },
      shiftMonth(shift) {
        this.displayedMonth = this.displayedMonth.plus({ month: shift })
      },
      selectDay(day, shiftMonth = 0) {
        this.manualSelection = true
        const selectedDay = this.displayedMonth.plus({ month: shiftMonth }).set({ day })
        // if (!this.dateIsSelectable(selectedDay)) return

        this.shiftMonth(shiftMonth)
        if (this.selectedEndDate !== this.selectedStartDate || this.justOpened) {
          this.selectedStartDate = selectedDay
          this.selectedEndDate = selectedDay
        } else if (this.selectedStartDate > selectedDay) {
          this.selectedEndDate = this.selectedStartDate
          this.selectedStartDate = selectedDay
        } else {
          this.selectedEndDate = selectedDay
        }
        this.emit(this.selectedStartDate, this.selectedEndDate)
        this.justOpened = false
      },
      selectDefaultDay() {
        this.selectedStartDate = this.displayedMonth.set({ day: this.toDay.day })
        this.selectedEndDate = this.displayedMonth.set({ day: this.toDay.day })
        this.emit(this.selectedStartDate, this.selectedEndDate)
      },
      validateDates() {
        this.$emit('period-change', { start: this.selectedStartDate, end: this.selectedEndDate })
        this.calendarIsOpen = false
      },
      dateIsSelected(date) {
        return date >= this.selectedStartDate && date <= this.selectedEndDate
      },
      dateIsSelectable(date) {
        return date <= this.lastSelectableDay
      },
      reset() {
        this.calendarIsOpen = false
        this.selectedStartDate = null
        this.selectedEndDate = null
        this.$emit('period-change', { start: undefined, end: undefined })
      },
      emit(startDate, endDate) {
        this.$emit('period-change', { start: startDate, end: endDate })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .v-calendar {
    display: flex;
    align-items: center;
    position: relative;
    min-width: 260px;

    .custom-v-calendar {
      @include flex(center, space-between);
      width: 100%;

      .svg-erase {
        margin-top: 3px;
        margin-left: 0.2rem;
        cursor: pointer;
      }
    }

    .button-calendar {
      @include flex(center, space-between);
      @extend %font-body;
      background: transparent;
      border: 0.5px solid $primaryBlue;
      border-radius: 10px;
      padding: 5px 10px 5px 15px;
      cursor: pointer;
      font-size: 13px;
      width: calc(100% - 28px);

      &.opened {
        background-color: $white;
        border: 1px solid $primaryBlue;
        border-radius: 10px 10px 0 0;
      }

      .placeholder {
        color: $mediumGrey;
      }

      .v-svg {
        color: $primaryBlue;
        border-radius: 0 10px 10px 0;
        margin-left: 0.5rem;
      }
    }

    .calendar {
      position: absolute;
      top: 25px;
      left: 0;
      z-index: 99999;
      background-color: $white;
      border: 1px solid $primaryBlue;
      box-shadow: 0 15px 20px rgb(0 0 0 / 15%);
      width: calc(100% - 28px);
      border-top: none;
      border-radius: 0 0 10px 10px;
      padding: 0.2rem;

      .month {
        @include flex;
        @extend %font-body-bold;
        font-size: 12px;
        margin: 0.5em;
        text-transform: capitalize;

        .current-month {
          @include flex;
          min-width: 60%;
        }

        .v-svg {
          cursor: pointer;
          &.disabled {
            color: $mediumGrey;
            cursor: not-allowed;
            pointer-events: none;
          }
        }
      }

      .calendar-days {
        display: grid;
        grid-template-columns: repeat(7, 32px);
        row-gap: 0.2rem;
        justify-items: center;

        .cell {
          @include flex;
          user-select: none;
          text-transform: capitalize;
          transition: all 200ms;
          width: 100%;

          &.label {
            font-weight: bold;
            height: 25px;
            width: 25px;
          }

          &:not(.label) {
            cursor: pointer;
          }

          &.other-month {
            color: $mediumGrey;
          }

          &.disabled {
            color: $mediumGrey;
            cursor: not-allowed;
          }

          .number {
            @include flex;
            border-radius: 50%;
            height: 25px;
            width: 25px;
          }

          &.selected {
            position: relative;

            .number {
              color: $white;
            }

            &.start,
            &.end {
              .number {
                background: $primaryBlue;
              }
            }

            &:not(.start):before {
              content: '';
              background-color: lighten($primaryBlue, 20%);
              position: absolute;
              height: 100%;
              left: 0;
              width: 50%;
              z-index: -1;
            }

            &:not(.end):after {
              content: '';
              background-color: lighten($primaryBlue, 20%);
              position: absolute;
              height: 100%;
              right: 0;
              width: 50%;
              z-index: -1;
            }
          }
        }
      }
      .actions {
        margin-top: 1rem;
        @include flex(center, flex-end);

        .validate {
          @include flex;
          transition: 200ms;
          background: transparent;
          border: 1px solid $primaryBlue;
          border-radius: 0.5rem;
          color: $primaryBlue;
          cursor: pointer;
          outline: none;

          &:hover {
            background-color: $primaryBlue;
            color: $white;
          }
          .v-svg {
            color: $primaryBlue;
            border-radius: 0 10px 10px 0;
            margin-left: 0.3rem;
          }
        }
      }
    }
  }
</style>
