import Vuex from 'vuex'
import promotion from './promotion.store'
import common from './common.store'
import promotionDetail from './promotionDetail.store'
import files from './files.store'
import referential from './referential.store'

const stores = {
  promotion,
  promotionDetail,
  common,
  files,
  referential
}
const state = {}
const getters = {}
const mutations = {}
const actions = {}

const modules = Object.entries(stores).reduce((modules, [name, store]) => {
  modules[name] = {
    namespaced: true,
    ...store
  }
  return modules
}, {})

const store = new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules
})

export default store
