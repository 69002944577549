<template>
  <div class="dispute">
    <h1>
      <title-bar class="promotionDetail-title" :title="$t('dispute.proofToRedo.title')" />
    </h1>
    <Card :is-loading="isLoadDispute">
      <template #header>
        <div class="flex items-center">
          <CardHeader :title="$t('dispute.proofToRedo.proofOfTheOfferOnMarket', { discountContractCode: $route.query.discountContractCode })" />
          <div class="supplier-info flex items-center">
            <span class="title">PAIEMENT: </span>
            <div class="flex column">
              <div>
                RAYON <span class="result">{{ data?.paymentSupplierDepartmentCode }}</span>
              </div>
              <div>
                CNUF <span class="result">{{ data?.paymentSupplierCnuf }}</span>
              </div>
              <div>
                GDN <span class="result"> {{ data?.paymentSupplierNegoGroupUid }}</span>
              </div>
            </div>
          </div>
          <div class="supplier-info flex items-center">
            <span class="title">COMMANDE: </span>
            <div class="flex">
              <div class="content">
                <div>
                  MARCHÉ <span class="result">{{ data?.orderSupplier?.orderSupplierDepartmentCode }}</span>
                </div>
                <div>
                  CNUF <span class="result">{{ data?.orderSupplier?.orderSupplierCnuf }}</span>
                </div>
              </div>
              <div class="content">
                <div>
                  CIF <span class="result">{{ data?.orderSupplier?.orderSupplierCif }}</span>
                </div>
                <div>
                  GDN <span class="result"> {{ data?.orderSupplier?.orderSupplierNegoGroupUid }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #body>
        <DisputeJustificativeArray :data="data" />
      </template>
    </Card>
  </div>
</template>

<script>
  import Card from '@/components/card/Card'
  import CardHeader from '@/components/card/CardHeader'
  import DisputeJustificativeArray from '@/components/dispute/DisputeJustificativeArray'
  import { extendWithAuthorization, httpNip } from '@/http'
  export default {
    name: 'DisputeJustificative',
    components: {
      Card,
      CardHeader,
      DisputeJustificativeArray
    },
    data() {
      return {
        data: null,
        rowData: [],
        isLoadDispute: true
      }
    },

    async created() {
      try {
        const extendedHttp = await extendWithAuthorization(httpNip)
        const dataDispute = await extendedHttp
          .get('dispute', { searchParams: { discountContractCode: this.$route.query.discountContractCode, channel: this.$route.query.channel } })
          .json()
        if (dataDispute.length === 1) {
          this.data = dataDispute[0]
          this.rowData = dataDispute[0].billingDetails
        } else {
          this.$toast.error(this.$t('dispute.errorData'))
        }
      } catch (error) {
        this.$toast.error(this.$t('dispute.globalError'))
      } finally {
        this.isLoadDispute = false
      }
    }
  }
</script>

<style scoped lang="scss">
  .dispute {
    padding: 2rem;
    height: 95%;

    .card {
      height: 100%;
    }
  }

  .supplier-info {
    display: flex;
    align-items: center;
    border: 1px solid $secondaryBlue;
    border-radius: 15px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    font-size: 10px;
    padding: 1em 2em;
    margin: 1em;

    .title {
      margin-right: 2em;
    }

    .result {
      font-weight: bold;
    }
    .content {
      display: flex;
      flex-direction: column;
      margin-right: 2em;
    }
  }
</style>
