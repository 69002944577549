import { createRouter, createWebHistory } from 'vue-router'
import Billing from '../views/Billing'
import LoyaltyAmounts from '../views/LoyaltyAmounts'
import Promotion from '../views/promotion/Promotion.vue'
import PromotionDetail from '../views/PromotionDetail.vue'
import ExportBenefits from '../views/ExportBenefits.vue'
import Reporting from '../views/Reporting.vue'
import DisputeJustificative from '../views/dispute/DisputeJustificative.vue'
import DisputeStatement from '../views/dispute/DisputeStatement.vue'

const routes = [
  {
    path: '/',
    name: 'Promotion',
    component: Promotion
  },
  {
    path: '/details-avantage',
    name: 'PromotionDetail',
    component: PromotionDetail
  },
  {
    path: '/dispute/justificative',
    name: 'DisputeJustificative',
    component: DisputeJustificative
  },
  {
    path: '/dispute/statement',
    name: 'DisputeStatement',
    component: DisputeStatement
  },
  {
    path: '/export-avantages',
    name: 'ExportBenefits',
    component: ExportBenefits
  },
  {
    path: '/billing',
    name: 'Billing',
    component: Billing
  },
  {
    path: '/loyalty-amounts',
    name: 'LoyaltyAmounts',
    component: LoyaltyAmounts
  },
  {
    path: '/reporting',
    name: 'Reporting',
    component: Reporting
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
