<template>
  <v-svg name="calendar" />
</template>

<script>
export default {
  name: 'SvgCalendar'
}
</script>

<style lang="scss" scoped>
.v-svg {
  color: $primaryOrange;
}
</style>
