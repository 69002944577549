<template>
  <modal class="import-calendar">
    <template #header>
      <div class="header">
        <div></div>
        <div>
          <v-button-group>
            <v-button v-for="(mandat, index) in mandats"
                      :key="index"
                      :label="mandat.name"
                      size="medium"
                      :active="mandat.name === selectedType"
                      @btn="selectedType = mandat.name" />
          </v-button-group>
        </div>
        <v-svg class="pointer" name="close" h="1rem" @click="$emit('closeModalImportHistory')" />
      </div>
    </template>
    <template #body>
      <ag-grid-vue v-if="selectedType === 'HYPER'"
                   style="width: 100%; height: 100%"
                   class="ag-theme-alpine"
                   :columnDefs="columnHyper"
                   :default-col-def="defaultColDef"
                   @grid-ready="onGridReady"
                   :rowModelType="'serverSide'" />
      <ag-grid-vue v-else
                   style="width: 100%; height: 100%"
                   class="ag-theme-alpine"
                   :columnDefs="columnSuper"
                   :default-col-def="defaultColDef"
                   @grid-ready="onGridReady"
                   :rowModelType="'serverSide'" />
    </template>
    <template #footer></template>
  </modal>
</template>

<script>
import Modal from '@/components/Modal'
import SvgCalendar from './SvgCalendar'
import { AgGridVue } from 'ag-grid-vue3'
import { extendWithAuthorization, httpNip } from '@/http'
import dateMixin from '@/mixins/date'
import { importCalendarHistoryTypes, importCalendarHistoryArrayColDefSuper, importCalendarHistoryArrayColDefHyper, importCalendarHistoryColDefDefault  } from '@/utils/CONF'

export default {
  name: 'ImportCalendarHistory',
  components: {
    Modal,
    AgGridVue,
    SvgCalendar
  },
  mixins: [dateMixin],
  data() {
    return {
      selectedType: 'HYPER',
      mandats: importCalendarHistoryTypes,
      gridApi: null,
      defaultColDef: importCalendarHistoryColDefDefault,
      columnSuper: importCalendarHistoryArrayColDefSuper,
      columnHyper: importCalendarHistoryArrayColDefHyper
    }
  },
  methods: {
    async onGridReady(params) {
      this.gridApi = params.api
      this.gridApi.sizeColumnsToFit()
      const datasource = this.createServerSideDatasource()
      params.api.setServerSideDatasource(datasource)
    },

    createServerSideDatasource() {
      return {
        getRows: async params => {
          const dateFormat = 'DD/MM/YYYY  HH[h]mm'
          const extendedHttp = await extendWithAuthorization(httpNip)
          extendedHttp.get(`historic/promotion-import/${this.selectedType.toLowerCase()}`).json()
            .then( data => {
              let rows = []
              data.forEach(element => {
                if(this.selectedType === 'HYPER') {
                  rows.push({
                    alimentaireAuchanFR: element.alimentaireAuchanFR ? this.formatDate(element.alimentaireAuchanFR.value, dateFormat) : null,
                    alimentaireChronodrive: element.alimentaireChronodrive ? this.formatDate(element.alimentaireChronodrive.value, dateFormat) : null,
                    alimentaireLuxembourg: element.alimentaireLuxembourg ? this.formatDate(element.alimentaireLuxembourg.value, dateFormat) : null,
                    integrationKey: element.integrationKey ? this.formatDate(element.integrationKey, 'MMM YYYY') : null,
                    nonAlimentaireAuchanFR: element.nonAlimentaireAuchanFR ? this.formatDate(element.nonAlimentaireAuchanFR.value, dateFormat) : null,
                    nonAlimentaireChronodrive: element.nonAlimentaireChronodrive ? this.formatDate(element.nonAlimentaireChronodrive.value, dateFormat) : null,
                    nonAlimentaireLuxembourg: element.nonAlimentaireLuxembourg ? this.formatDate(element.nonAlimentaireLuxembourg.value, dateFormat) : null,
                  })
                }
                else {
                  rows.push({
                    integrationKey: element.integrationKey,
                    senegalProcessingTimestamp: element.senegalProcessingTimestamp ? this.formatDate(element.senegalProcessingTimestamp.value, dateFormat) : null
                  })
                }

              })
              params.success({ rowData: rows })
            })
            .catch(() => params.fail())
        }
      }
    },
  }
}

</script>
<style lang="scss" scoped>
.import-calendar {
  .header {
    @include flex(center, space-between);
  }
}

.ag-theme-alpine::v-deep {
  .ag-header-row:not(:first-child) .ag-header-cell {
    border-top: unset;
  }

  .ag-header {
    background-color: $veryLightGrey !important;
    border: 1px solid $secondaryBlue !important;
    border-radius: 15px;

    .ag-header-cell,
    .ag-header-group-cell {
      justify-content: center;
    }

    .ag-header-group-cell-label {
      justify-content: center;
      color: $primaryBlue;
    }

    .ag-header-cell-label {
      justify-content: center;
      text-align: center;
    }

    .ag-floating-filter-body {
      position: inherit;
      width: 80%;
    }
  }

  .cell-center {
    text-align: center;
  }

  .border-solid {
    border-right: solid $secondaryBlue !important;
  }
  .ag-cell{
    padding-left: 8px;
    padding-right: 8px;
  }
}
</style>
