<!-- Created By Vasse Alexandre -->
<template>
  <div class="optional-columns-selector">
    <v-svg name="plus" h="1.5rem" @click="showPopup = !showPopup" />
    <div v-if="showPopup" class="popup">
      <v-svg name="cross" h="0.8rem" @click="showPopup = false" />
      <div class="popup-title">Elements de références</div>
      <div class="list">
        <div v-for="(value, item, i) of modelValue" :key="i" class="item" @click="$emit('update:modelValue', { ...modelValue, [item]: !value })">
          <v-svg :name="value ? 'checkbox-filled' : 'checkbox-empty'" h="1rem" />
          <div :class="['label', { active: value }]">{{ $t(`billing.optionalColumns.${item}`) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'OptionalColumnsSelector',
    props: {
      modelValue: {
        type: Object,
        default: () => ({ orderSupplierCnuf: false, orderSupplierNegoGroupLabel: false, orderSupplierLabel: false, orderSupplierDepartmentCode: false })
      }
    },
    emits: ['update:modelValue'],
    data() {
      return {
        showPopup: false
      }
    }
  }
</script>

<style scoped lang="scss">
  .optional-columns-selector {
    position: relative;

    .svg-plus {
      cursor: pointer;
      color: $primaryOrange;
    }

    .popup {
      position: absolute;
      background-color: white;
      box-shadow: 4px 4px 20px 3px rgb(0 0 0 / 10%);
      border-radius: 14px;
      min-width: 300px;
      right: 8px;
      top: 30px;
      padding: 18px 30px;

      .svg-cross {
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;
      }

      .popup-title {
        @extend %font-body-bold;
        font-size: 13px;
      }

      .list {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        .item {
          display: flex;
          margin-top: 0.5rem;
          cursor: pointer;

          .label {
            margin-left: 0.5rem;

            &.active { color: $primaryBlue; }
          }
        }
      }
    }
  }
</style>
