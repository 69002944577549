<template>
  <div :class="['tag', color, round ? 'round' : '', fill ? 'fill' : '']">
    <span class="tag-title">{{ title }} </span>
    <span> {{ content }} </span>
  </div>
</template>

<script>
export default {
    name: 'Tag',
    props: {
      title: {
        type: String,
        default: ''
      },
      content: {
        type: String,
        default: ''
      },
      color: {
        type: String,
        default: ''
      },
      round: {
        type: Boolean,
        default: false
      },
      fill: {
        type: Boolean,
        default: false
      }
    }

  }
</script>
<style lang="scss" scoped>
  .tag {
    font-size: 12px;
    line-height: 18px;
    padding: 5px 10px;
    margin: 7px;
    border-radius: 6px;

    &.round {
      border-radius: 20px;;
    }

    &-title {
      font-weight: bold;
    }

    &.fill.secondaryOrange {
      background-color: $secondaryOrange;
    }

    &.fill.primaryBlue {
      background-color: $primaryBlue;
      color: $white;
    }

    &.secondaryOrange {
      border: 1px solid $secondaryOrange;
    }

    &.primaryBlue {
      border: 1px solid $primaryBlue;
    }
  }
</style>
