<template>
  <div class="title-bar">
    <v-svg v-if="iconName" :name="iconName" :h="'2rem'" />
    <span>{{ title }}</span>
  </div>
</template>

<script>
export default {
  name: 'TitleBar',
  props: {
    title: {
      type: String,
      default: ''
    },
    iconName: {
        type: String,
        default: ''
    }
  },
}
</script>

<style lang="scss" scoped>
.title-bar{
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 3px solid $primaryOrange;
  width: fit-content;
  .v-svg{
    margin: 10px 10px 10px 0;
    display: flex;
  }
}
h2{
  .title-bar{
    border-bottom: 2px solid $primaryOrange;
  }
}
</style>
