const state = {
  isLoading: false,
  isRefreshData: false
}

const mutations = {
  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading
  },
  SET_REFRESH_DATA(state, isRefreshData) {
    state.isRefreshData = isRefreshData
  }
}

const actions = {}

const getters = {}

export default {
  state,
  getters,
  mutations,
  actions
}
