import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'

export const exportChickenOffer = (dataChickenOffer, nameExport, nameSheet, isAnomaly = false) => {
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet(nameSheet)

  //locked file
  worksheet.protect()

  let columns = [
    { header: 'N° Mag Interne', key: 'siteSid', hidden: true },
    { header: 'N° Mag', key: 'site' },
    { header: 'Nom Magasin', key: 'siteLabel', width: 60 },
    { header: 'CNUF', key: 'orderSupplierCnuf' },
    { header: 'CIF', key: 'orderSupplierCif' },
    { header: 'Groupe de négociation', key: 'orderSupplierNegoGroupUid', width: 20 },
    { header: 'N° Avantage', key: 'discountContractCode', width: 20 }
  ]

  //check if there is an anomaly in data
  if (isAnomaly) {
    columns.forEach(element => {
      element.key = element.key.toLocaleLowerCase()
    })
    columns.push({ header: 'Anomalie', key: 'isinerror' })
  }

  worksheet.columns = columns
  worksheet.addRows(dataChickenOffer)

  // unlock column CNUF, CIF and GDN
  worksheet.getColumn(4).protection = { locked: false, lockText: false }
  worksheet.getColumn(5).protection = { locked: false, lockText: false }
  worksheet.getColumn(6).protection = { locked: false, lockText: false }
  if (isAnomaly) { worksheet.getColumn(8).protection = { locked: false, lockText: false } }

  workbook.xlsx.writeBuffer().then(data => {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
    saveAs(blob, nameExport)
  })
}