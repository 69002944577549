import { DateTime } from 'luxon'

export const HYPER = 'HYPER'
export const SUPER = 'SUPER'
export const subTypesComment = [
  {
    id: 'CRP',
    name: 'CRP',
    width: 130
  },
  {
    id: 'REDO_CONTRACT',
    name: 'Contrat à refaire',
    children: [
      {
        id: 'MISSING_SKU',
        name: 'CUG Manquant',
        comment: 'Refaire contrat avec CUG Manquant',
        width: 262
      },
      {
        id: 'BAD_SKU',
        name: 'CUG erroné',
        comment: 'Refaire contrat avec les (N°) CUGS',
        width: 250
      },
      {
        id: 'OPE_COM_DATES',
        name: 'Dates de tracts ou de thèmes',
        comment: 'Refaire contrat avec les bonnes dates de tract ou de thème',
        width: 426
      },
      {
        id: 'SUPPLIER_SIGN',
        name: 'Signature fournisseur',
        comment: 'Refaire contrat avec signature fournisseur',
        width: 303
      },
      {
        id: 'MECANIC',
        name: 'Mécanique',
        comment: 'Refaire contrat avec la bonne mécanique',
        width: 301
      },
      {
        id: 'CONSTRAINT',
        name: 'Contrainte',
        comment: 'Refaire contrat avec la bonne contraite',
        width: 284
      },
      {
        id: 'LABEL',
        name: 'Libellé',
        comment: 'Refaire contrat avec le bon libellé',
        width: 243
      },
      {
        id: 'FINANCING_RATE',
        name: 'Taux de COFI',
        comment: 'Refaire contrat avec bon taux de COFI',
        width: 274
      },
      {
        id: 'SUPPLIER',
        name: 'CNUF/ Nom fournisseur',
        comment: 'Refaire contrat avec le bon CNUF/ Nom fournisseur',
        width: 364
      },
      {
        id: 'DISCOUNT',
        name: 'Remise',
        comment: 'Refaire contrat avec la bonne remise',
        width: 268
      },
      {
        id: 'THEME',
        name: 'Thème',
        comment: 'Refaire contrat avec le bon thème',
        width: 248
      },
      {
        id: 'OTHERS',
        name: 'Autre',
        comment: '',
        width: 10
      }
    ]
  },
  {
    id: 'CONTRACT_OK',
    name: 'Ok contrat',
    children: [
      {
        id: 'OPE_COM_DATES',
        name: 'Dates de tracts ou de thème',
        comment: 'Ok contrat, modifier dates de tract ou de thème',
        width: 343
      },
      {
        id: 'CONSTRAINT',
        name: 'Contrainte',
        comment: 'Ok contrat, modifier contrainte',
        width: 224
      },
      {
        id: 'LABEL',
        name: 'Libellé',
        comment: 'Ok contrat, modifier libellé',
        width: 192
      },
      {
        id: 'DISCOUNT',
        name: 'Remise',
        comment: 'ok contrat, modifier montant de la remise',
        width: 300
      },
      {
        id: 'OTHERS',
        name: 'Autre',
        comment: '',
        width: 10
      }
    ]
  },
  {
    id: 'OTHERS',
    name: 'Autre'
  }
]
export const promoSearchTypes = [
  { id: 1, name: 'Hyper' },
  { id: 2, name: 'Super' }
]

export const channels = [
  {
    id: 'HYPER',
    name: 'Hyper'
  },
  {
    id: 'SUPER',
    name: 'Super'
  }
]

export const listBUExport = [
  {
    id: 'ALL_BU',
    name: 'ALL_BU',
    channel: 'ALL'
  },
  {
    id: 'AUCHAN_LUXEMBOURG',
    name: 'AUCHAN_LUXEMBOURG',
    channel: 'HYPER'
  },
  {
    id: 'CHRONODRIVE',
    name: 'CHRONODRIVE',
    channel: 'HYPER'
  },
  {
    id: 'AUCHAN_FR',
    name: 'AUCHAN.FR',
    channel: 'HYPER'
  },
  {
    id: 'AUCHAN_SENEGAL',
    name: 'AUCHAN_SENEGAL',
    channel: 'SUPER'
  }
]

export const listBUImport = [
  {
    id: 'AUCHAN_LUXEMBOURG',
    name: 'AUCHAN_LUXEMBOURG',
    channel: 'HYPER'
  },
  {
    id: 'CHRONODRIVE',
    name: 'CHRONODRIVE',
    channel: 'HYPER'
  },
  {
    id: 'AUCHAN_FR',
    name: 'AUCHAN.FR',
    channel: 'HYPER'
  },
  {
    id: 'AUCHAN_SENEGAL',
    name: 'AUCHAN_SENEGAL',
    channel: 'SUPER'
  }
]

export const promotionTypes = [
  {
    id: 'BR',
    name: 'BR',
  },
  {
    id: 'CAB',
    name: 'CAB',
  },
  {
    id: 'RI',
    name: 'RI',
  },
]

export const promotionPerimeters = [
  {
    id: 'ALIMENTAIRE',
    name: 'Alimentaire',
  },
  {
    id: 'NON-ALIMENTAIRE',
    name: 'Non alimentaire',
  }
]

// START BILLING ARRAY CONST ------------------------------------------------------------------------------------------------
export const billingArrayColDefs = [
  {
    headerName: 'COCHER OFFRES',
    field: 'blank',
    filter: false,
    width: 85,
    cellRenderer: 'ArrayCheckbox'
  },
  {
    headerName: 'EXISTANCE PAF NPA',
    field: 'isPaymentSupplierExists',
    filter: false,
    width: 85,
    cellRenderer: 'ArrayThumbs'
  },
  { headerName: 'ANNEE', field: 'discountContractYear', width: 85, sort: 'asc' },
  { headerName: 'N° RAYON CONTRAT', field: 'paymentSupplierDepartmentCode', width: 85 },
  { headerName: 'CNUF CONTRAT', field: 'paymentSupplierCnuf', width: 85, sort: 'asc' },
  { headerName: 'GDN CONTRAT', field: 'paymentSupplierNegoGroupLabel', width: 150 },
  { headerName: 'LIBELLE FOURNISSEUR', field: 'paymentSupplierLabel', width: 150 },

  { headerName: 'TYPE AVANTAGE', field: 'promotionTypeCode', width: 85 },
  { headerName: 'N° AVANTAGE', field: 'discountContractCode', width: 100, sort: 'asc' },
  { headerName: 'TRACT', field: 'commercialOperationLabel', width: 150 },
  {
    headerName: 'DATE DEBUT',
    field: 'discountContractStartDate',
    sort: 'asc',
    width: 100,
    valueFormatter: params => params.value ? DateTime.fromISO(params.value)?.toFormat('dd/MM/yyyy') : ''
  },
  {
    headerName: 'DATE FIN',
    field: 'discountContractEndDate',
    width: 100,
    valueFormatter: params => params.value ? DateTime.fromISO(params.value)?.toFormat('dd/MM/yyyy') : ''
  },
  { headerName: 'CNUF DE COMMANDE', field: 'orderSupplierCnuf', width: 85, hide: true },
  { headerName: 'GDN DE COMMANDE', field: 'orderSupplierNegoGroupLabel', width: 150, hide: true },
  { headerName: 'LIBELLE FOURNISSEUR REF', field: 'orderSupplierLabel', width: 150, hide: true },
  { headerName: 'MARCHE', field: 'orderSupplierDepartmentCode', width: 150, hide: true },
  {
    headerName: 'MONTANT A FACTURER',
    field: 'benefitAmountWithoutTax',
    hide: true,
    width: 100,
    valueFormatter: params => params.value?.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })
  },
  {
    headerName: 'MONTANT A FACTURER',
    field: 'benefitAmountWithTax',
    width: 100,
    valueFormatter: params => params.value?.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })
  }
]
// END BILLING ARRAY CONST ------------------------------------------------------------------------------------------------

// START LOYALTY AMOUNTS ARRAY CONST ------------------------------------------------------------------------------------------------
export const loyaltyAmountsArrayColDefs = [
  {
    headerName: 'N° AVANTAGE',
    field: 'discountContractCode',
    rowGroup: true,
    enableRowGroup: true,
    width: 100,
  },
  {
    headerName: 'CUG',
    field: 'cug',
    enableRowGroup: true,
    width: 100,
  },
  {
    headerName: 'MARCHE',
    field: 'orderSupplierDepartmentCode',
    enableRowGroup: true,
    width: 60,
    // valueFormatter: params => {
    //   if (!params.node.group) return ''
    //   return params.node?.childrenAfterAggFilter?.[0].data?.orderSupplierDepartmentCode ?? ''
    // }
  },
  {
    headerName: 'SITE',
    field: 'site',
    enableRowGroup: true,
    width: 60,
  },
  {
    headerName: 'LIBELLE DE SITE',
    field: 'siteLabel',
    enableRowGroup: true,
    width: 220,
  },
  {
    headerName: 'SOURCE',
    field: 'discountContractChannel',
    width: 90,
    // valueFormatter: params => {
    //   if (!params.node.group) return ''
    //   return params.node?.childrenAfterAggFilter?.[0].data?.discountContractChannel?.slice(0,3) ?? ''
    // }
  },
  {
    headerName: 'DEBUT AVANTAGE',
    field: 'discountContractStartDate',
    width: 120,
    valueFormatter: params => {
      if (!params.value) return ''
      return params.value ? DateTime.fromISO(params.value)?.toFormat('dd/MM/yyyy') : ''
    }
  },
  {
    headerName: 'FIN AVANTAGE',
    field: 'discountContractEndDate',
    width: 120,
    valueFormatter: params => {
      if (!params.value) return ''
      return params.value ? DateTime.fromISO(params.value)?.toFormat('dd/MM/yyyy') : ''
      // if (!params.node.group) return ''
      // const date = params.node?.childrenAfterAggFilter?.[0].data?.commercialOperationEndDate
      // return date ? DateTime.fromISO(date)?.toFormat('dd/MM/yyyy') : ''
    }
  },
  {
    headerName: 'DEBUT TRACT',
    field: 'commercialOperationStartDate',
    width: 120,
    valueFormatter: params => {
      if (!params.value) return ''
      return params.value ? DateTime.fromISO(params.value)?.toFormat('dd/MM/yyyy') : ''
    }
  },
  {
    headerName: 'FIN TRACT',
    field: 'commercialOperationEndDate',
    width: 120,
    valueFormatter: params => {
      if (!params.value) return ''
      return params.value ? DateTime.fromISO(params.value)?.toFormat('dd/MM/yyyy') : ''
    // if (!params.node.group) return ''
    // const date = params.node?.childrenAfterAggFilter?.[0].data?.commercialOperationEndDate
    // return date ? DateTime.fromISO(date)?.toFormat('dd/MM/yyyy') : ''
    }
  },
  {
    headerName: 'CNUF DE COMMANDE',
    field: 'orderSupplierCnuf',
    width: 80,
    valueFormatter: params => {
      if (!params.node.group) return ''
      return params.node?.childrenAfterAggFilter?.[0].data?.orderSupplierCnuf ?? ''
    }
  },
  {
    headerName: 'GDN DE COMMANDE',
    field: 'orderSupplierNegoGroupUid',
    width: 100,
    valueFormatter: params => {
      if (!params.node.group) return params.data.id
      return params.node?.childrenAfterAggFilter?.[0].data?.orderSupplierNegoGroupUid ?? ''
    }
  },
  {
    headerName: 'NOM FOURNISSEUR DE COMMANDE',
    field: 'orderSupplierLabel',
    width: 145,
    enableRowGroup: true,
    wrapText: true,
    valueFormatter: params => {
      if (!params.node.group) return params.data.label
      return params.node?.childrenAfterAggFilter?.[0].data?.orderSupplierLabel ?? ''
    }
  },
  {
    headerName: 'MANDAT',
    field: 'mandateCostWithTax',
    width: 100,
    aggFunc: 'sum',
    valueFormatter: params => params.value?.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })
  },
  {
    headerName: 'MANDAT',
    field: 'mandateCostWithoutTax',
    width: 100,
    hide: true,
    aggFunc: 'sum',
    valueFormatter: params => params.value?.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })
  },
  {
    headerName: 'COOP',
    field: 'cooperationCostWithTax',
    width: 100,
    aggFunc: 'sum',
    valueFormatter: params => params.value?.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })
  },
  {
    headerName: 'COOP',
    field: 'cooperationCostWithoutTax',
    width: 100,
    hide: true,
    aggFunc: 'sum',
    valueFormatter: params => params.value?.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })
  },
  {
    headerName: 'NON FINANCE',
    field: 'unfundedCostWithTax',
    width: 80,
    aggFunc: 'sum',
    valueFormatter: params => params.value?.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })
  },
  {
    headerName: 'NON FINANCE',
    field: 'unfundedCostWithoutTax',
    width: 80,
    hide: true,
    aggFunc: 'sum',
    valueFormatter: params => params.value?.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })
  },
  {
    headerName: 'PRIX D\'ACHAT',
    field: 'purchasePriceCostWithTax',
    width: 100,
    aggFunc: 'sum',
    valueFormatter: params => params.value?.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })
  },
  {
    headerName: 'PRIX D\'ACHAT',
    field: 'purchasePriceCostWithoutTax',
    width: 100,
    hide: true,
    aggFunc: 'sum',
    valueFormatter: params => params.value?.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })
  },
  {
    headerName: 'COUT TOTAL',
    field: 'benefitAmountWithTax',
    width: 100,
    aggFunc: params => {
      let total = 0

      params.values.forEach(value => {
        total += parseFloat(value)
      })
      return total
    },
    valueFormatter: params => {
      let x = parseFloat(params.value)
      return x?.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
  },
  {
    headerName: 'COUT TOTAL',
    field: 'benefitAmountWithoutTax',
    width: 100,
    aggFunc: 'sum',
    hide: true,
    valueFormatter: params => params.value?.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })
  },
  {
    headerName: 'QUANT. TOTALE',
    field: 'salesQuantity',
    aggFunc: 'sum',
    width: 80,
    valueFormatter: params => params.value?.toLocaleString("fr-FR")
  },
  {
    headerName: 'QUANT. REMISEE',
    field: 'benefitQuantity',
    aggFunc: 'sum',
    width: 80,
    valueFormatter: params => params.value?.toLocaleString("fr-FR")
  }
]
// END LOYALTY AMOUNTS ARRAY CONST ------------------------------------------------------------------------------------------------

// START LOYALTY AMOUNTS EXPORT COLUMNS CONST ------------------------------------------------------------------------------------------------
export const loyaltyAmountsExportColumns = displayKey => [
  {
    header: 'Marché',
    key: 'orderSupplierDepartmentCode'
  },
  {
    header: 'Libellé Marché',
    key: 'orderSupplierDepartmentLabel'
  },
  {
    header: 'Réseau',
    key: 'discountContractChannel'
  },
  {
    header: 'Source',
    key: 'promotionReferencingSystem'
  },
  {
    header: 'Type Avantage',
    key: 'promotionTypeCode'
  },
  {
    header: 'N° Avantage',
    key: 'discountContractCode'
  },
  {
    header: 'CUG',
    key: 'cug'
  },
  {
    header: 'Début Avantage',
    key: 'discountContractStartDate'
  },
  {
    header: 'Fin Avantage',
    key: 'discountContractEndDate'
  },
  {
    header: 'Début Tract',
    key: 'commercialOperationStartDate'
  },
  {
    header: 'Fin Tract',
    key: 'commercialOperationEndDate'
  },
  {
    header: 'Libellé Tract',
    key: 'commercialOperationLabel'
  },
  {
    header: 'CNUF de commande',
    key: 'orderSupplierCnuf'
  },
  {
    header: 'GDN de commande',
    key: 'orderSupplierNegoGroupUid'
  },
  {
    header: 'Libellé Fournisseur',
    key: 'orderSupplierLabel'
  },
  {
    header: 'Type Remise',
    key: 'benefitUnitLabel'
  },
  {
    header: 'Valeur Remise',
    key: 'benefitValue'
  },
  {
    header: 'Contrainte',
    key: 'benefitPurchaseConditionThreshold'
  },
  {
    header: displayKey === 'SITE' ? 'Site' : 'Cug',
    key: displayKey === 'SITE' ? 'site' : 'id',
  },
  {
    header: displayKey === 'SITE' ? 'Label Site' : 'Label Cug',
    key: displayKey === 'SITE' ? 'siteLabel' : 'label',
  },
  {
    header: 'Code TVA',
    key: 'vatRate'
  },
  {
    header: 'Mandat HT',
    key: 'mandateCostWithoutTax',
  },
  {
    header: 'Mandat TTC',
    key: 'mandateCostWithTax'
  },
  {
    header: 'Coop HT',
    key: 'cooperationCostWithoutTax',
  },
  {
    header: 'Coop TTC',
    key: 'cooperationCostWithTax',
  },
  {
    header: 'Non Financé HT',
    key: 'unfundedCostWithoutTax'
  },
  {
    header: 'Non Financé TTC',
    key: 'unfundedCostWithTax'
  },
  {
    header: 'Prix d\'achat HT',
    key: 'purchasePriceCostWithoutTax',
  },
  {
    header: 'Prix d\'achat TTC',
    key: 'purchasePriceCostWithTax'
  },
  {
    header: 'Cout Total HT',
    key: 'benefitAmountWithoutTax'
  },
  {
    header: 'Cout Total TTC',
    key: 'benefitAmountWithTax'
  },
  {
    header: 'Quant. Remisée',
    key: 'benefitQuantity'
  },
  {
    header: 'Quant. Totale',
    key: 'salesQuantity'
  }
]
// END LOYALTY AMOUNTS EXPORT COLUMNS CONST ------------------------------------------------------------------------------------------------

export const importCalendarHistoryTypes = [
  { id: 0, name: 'HYPER' },
  { id: 2, name: 'SUPER' }
]

export const importCalendarHistoryArrayColDefSuper = [
  { headerName: "Tracts", field: 'integrationKey' },
  { headerName: "Auchan Sénégal", field: 'senegalProcessingTimestamp', cellClass: 'cell-center', width: 100 },
]

export const importCalendarHistoryColDefDefault = {
  filter: 'agTextColumnFilter',
  floatingFilter: true,
  resizable: true,
  sortable: true
}

export const importCalendarHistoryArrayColDefHyper = [
  {
    headerGroupComponent: 'SvgCalendar',
    children: [
      { headerName: 'Mois', field: 'integrationKey', width: 130 }
    ]
  },
  {
    headerName: 'Alimentaire',
    children: [
      { headerName: "Auchan Luxembourg", field: 'alimentaireLuxembourg' },
      { headerName: 'Chronodrive', field: 'alimentaireChronodrive' },
      { headerName: 'Auchan.fr', field: 'alimentaireAuchanFR', cellClass: 'border-solid' },
    ]
  },
  {
    headerName: 'Non alimentaire',
    children: [
      { headerName: "Auchan Luxembourg", field: 'nonAlimentaireLuxembourg' },
      { headerName: 'Chronodrive', field: 'nonAlimentaireChronodrive' },
      { headerName: 'Auchan.fr', field: 'nonAlimentaireAuchanFR' },
    ]
  },
]

// ------------- Promotion Details

export const financingTypes = [
  {
    id: "MA",
    label: "Mandat"
  },
  {
    id: "NF",
    label: "Non financé"
  },
  {
    id: "PA",
    label: "Prix d'achat",
    disabled: "CAB"
  },
  {
    id: "CO",
    label: "Coopération",
    disabled: "RI"
  }
]

// ------------- Promotion Search

export const fieldsPeriodDate = ['commercialOperationDate', 'discountContractDate', 'discountContractBillingDate']

// ------------- LITIGE -------------
export const disputeJustificativeArrayColDefs = [
  { headerName: 'N° AVANTAGE', field: 'discountContractCode', hide: true, cellClass: 'redFont' },
  { headerName: 'CUG LIBELLÉ', field: 'product', rowGroup: true, hide: true, cellClass: 'redFont' },
  { headerName: 'N° MAGASIN LIBELLÉ', field: 'site', width: 300, sort: 'asc', cellClass: 'redFont' },
  {
    headerName: 'QUANT. REMISÉES',
    field: 'benefitQuantity',
    width: 100,
    type: 'valueColumn',
    cellClassRules: {
      'cell-changed': params =>
        params?.data?.isEdited?.includes(params.colDef.field) ||
        params?.data?.updateBenefitQuantity ||
        params?.data?.updateBenefitQuantit === 0 ||
        params?.node?.allLeafChildren?.some(child => child?.data?.isEdited?.includes(params.colDef.field) || child?.data?.updateBenefitQuantity)
    },
    aggFunc: params => {
      let sum = 0
      params.values.forEach(function (value) {
        sum += Number(value)
      })
      return Number.isInteger(sum) ? sum : 'ERR'
    },
    valueGetter: params => {
      if (params?.data?.updateBenefitQuantity || params?.data?.updateBenefitQuantity === 0) {
        params.data.benefitQuantity = params.data.updateBenefitQuantity
        params.data.updateBenefitQuantity = null
        if (!params.data.isEdited) params.data.isEdited = []
        if (!params.data.isEdited.includes(params.colDef.field)) params.data.isEdited.push(params.colDef.field)
      }
      return Number.isInteger(params.data.benefitQuantity) ? params.data.benefitQuantity : 'ERR'
    },
    valueSetter: params => {
      //to make sure user entered number only
      let newValInt = parseInt(params.newValue)
      let valueChanged = params.data.benefitQuantity !== newValInt
      if (valueChanged) {
        params.data.benefitQuantity = isNaN(newValInt) ? params.oldValue : newValInt
      }
      return valueChanged
    }
  },
  {
    headerName: 'QUANT. TOT. TRACT',
    field: 'salesQuantity',
    width: 100,
    type: 'valueColumn',
    cellClassRules: {
      'cell-changed': params =>
        params?.data?.isEdited?.includes(params.colDef.field) ||
        params?.data?.updateSalesQuantity ||
        params?.data?.updateSalesQuantity === 0 ||
        params?.node?.allLeafChildren?.some(child => child?.data?.isEdited?.includes(params.colDef.field) || child?.data?.updateSalesQuantity)
    },
    aggFunc: params => {
      let sum = 0
      params.values.forEach(function (value) {
        sum += Number(value)
      })
      return Number.isInteger(sum) ? sum : 'ERR'
    },
    valueGetter: params => {
      if (params?.data?.updateSalesQuantity || params?.data?.updateSalesQuantity === 0) {
        params.data.salesQuantity = params.data.updateSalesQuantity
        params.data.updateSalesQuantity = null
        if (!params.data.isEdited) params.data.isEdited = []
        if (!params.data.isEdited.includes(params.colDef.field)) params.data.isEdited.push(params.colDef.field)
      }
      return Number.isInteger(params.data.salesQuantity) ? params.data.salesQuantity : 'ERR'
    },
    valueSetter: params => {
      //to make sure user entered number only
      let newValInt = parseInt(params.newValue)
      let valueChanged = params.data.salesQuantity !== newValInt
      if (valueChanged) {
        params.data.salesQuantity = isNaN(newValInt) ? params.oldValue : newValInt
      }
      return valueChanged
    }
  },
  { headerName: 'TAUX TVA RECTIFIÉ', field: 'vatRate', width: 155, cellRenderer: 'ArrayInputSelect', cellEditorPopup: true },
  {
    headerName: 'COÛT REMISÉ HT',
    field: 'benefitAmountWithoutTax',
    width: 100,
    aggFunc: params => {
      let sum = 0
      params.values.forEach(function (value) {
        sum += Number(value)
      })
      return sum.toFixed(2)
    },
    valueGetter: params =>
      params.node.rowPinned === 'bottom'
        ? params.data.benefitAmountWithoutTax
        : (Number(params.data.benefitAmountWithTax) / (1 + Number(params.data.vatRate) / 100)).toFixed(2)
  },
  {
    headerName: 'COÛT REMISÉ TVA',
    field: 'benefitTax',
    width: 100,
    aggFunc: 'sum',
    aggFunc: params => {
      let sum = 0
      params.values.forEach(function (value) {
        sum += Number(value)
      })
      return sum.toFixed(2)
    },
    valueGetter: params =>
      params.node.rowPinned === 'bottom'
        ? params.data.benefitTax
        : ((Number(params.data.benefitAmountWithTax) / (Number(params.data.vatRate) + 100)) * Number(params.data.vatRate)).toFixed(2)
  },
  {
    headerName: 'COÛT REMISÉ TTC',
    field: 'benefitAmountWithTax',
    width: 100,
    aggFunc: 'sum',
    aggFunc: params => {
      let sum = 0
      params.values.forEach(function (value) {
        sum += Number(value)
      })
      return sum.toFixed(2)
    },
    valueGetter: params => Number(params.data.benefitAmountWithTax).toFixed(2)
  },
  {
    headerName: 'PLAFOND (%)',
    field: 'paymentSupplierStake',
    width: 100,
    cellRenderer: params => params.api.getRowNode(0).data.paymentSupplierStake
  },
  { headerName: 'QUANTITÉ REMISÉE MODIFIÉ', field: 'updateBenefitQuantity', hide: true, cellClass: 'greenBg' },
  { headerName: 'QUANTITÉ TOTALE MODIFIÉ', field: 'updateSalesQuantity', hide: true, cellClass: 'greenBg' }
]

export const disputeColumnValueKey = {
  'N° AVANTAGE' : 'discountContractCode',
  'CUG LIBELLÉ' : 'product',
  'N° MAGASIN LIBELLÉ' : 'site',
  'QUANT. REMISÉES' : 'benefitQuantity',
  'QUANT. TOT. TRACT' : 'salesQuantity',
  'TAUX TVA RECTIFIÉ' : 'vatRate',
  'COÛT REMISÉ HT' : 'benefitAmountWithoutTax',
  'COÛT REMISÉ TVA' : 'benefitTax',
  'COÛT REMISÉ TTC' : 'benefitAmountWithTax',
  'PLAFOND (%)' : 'paymentSupplierStake',
  'QUANTITÉ REMISÉE MODIFIÉ' : 'updateBenefitQuantity',
  'QUANTITÉ TOTALE MODIFIÉ' : 'updateSalesQuantity',
  'COÛT REMISÉ TTC MODIFIÉ' : 'updateBenefitAmountWithTax'
}

export const txTva = [
  {
    value: '0%',
    name: '0',
  },
  {
    value: '5,5%',
    name: '5.5',
  },
  {
    value: '10%',
    name: '10',
  },
  {
    value: '20,00%',
    name: '20',
  }
]

export const disputeStatementArrayColDefs = [
  { headerName: 'N° AVANTAGE', field: 'discountContractCode', hide: true, cellClass: 'redFont' },
  { headerName: 'CUG LIBELLÉ', field: 'product', rowGroup: true, hide: true, cellClass: 'redFont' },
  { headerName: 'N° MAGASIN LIBELLÉ', field: 'site', width: 300, sort: 'asc', cellClass: 'redFont' },
  {
    headerName: 'QUANT. REMISÉES',
    field: 'benefitQuantity',
    width: 100,
    type: 'valueColumn',
    cellClassRules: {
      'cell-changed': params =>
        params?.data?.isEdited?.includes(params.colDef.field) ||
        params?.data?.updateBenefitQuantity ||
        params?.data?.updateBenefitQuantity === 0 ||
        params?.node?.allLeafChildren?.some(child => child?.data?.isEdited?.includes(params.colDef.field) || child?.data?.updateBenefitQuantity)
    },
    aggFunc: params => {
      let sum = 0
      params.values.forEach(function (value) {
        sum += Number(value)
      })
      return Number.isInteger(sum) ? sum : 'ERR'
    },
    valueGetter: params => {
      if (params?.data?.updateBenefitQuantity || params?.data?.updateBenefitQuantity === 0) {
        params.data.benefitQuantity = params.data.updateBenefitQuantity
        params.data.updateBenefitQuantity = null
        if (!params.data.isEdited) params.data.isEdited = []
        if (!params.data.isEdited.includes(params.colDef.field)) params.data.isEdited.push(params.colDef.field)
      }
      return Number.isInteger(params.data.benefitQuantity) ? params.data.benefitQuantity : 'ERR'
    },
    valueSetter: params => {
      //to make sure user entered number only
      let newValInt = parseInt(params.newValue)
      let valueChanged = params.data.benefitQuantity !== newValInt
      if (valueChanged) {
        params.data.benefitQuantity = isNaN(newValInt) ? params.oldValue : newValInt
      }
      return valueChanged
    }
  },
  {
    headerName: 'QUANT. TOT. TRACT',
    field: 'salesQuantity',
    width: 100,
    type: 'valueColumn',
    cellClassRules: {
      'cell-changed': params =>
        params?.data?.isEdited?.includes(params.colDef.field) ||
        params?.data?.updateSalesQuantity ||
        params?.data?.updateSalesQuantity === 0 ||
        params?.node?.allLeafChildren?.some(child => child?.data?.isEdited?.includes(params.colDef.field) || child?.data?.updateSalesQuantity)
    },
    aggFunc: params => {
      let sum = 0
      params.values.forEach(function (value) {
        sum += Number(value)
      })
      return Number.isInteger(sum) ? sum : 'ERR'
    },
    valueGetter: params => {
      if (params?.data?.updateSalesQuantity || params?.data?.updateSalesQuantity === 0) {
        params.data.salesQuantity = params.data.updateSalesQuantity
        params.data.updateSalesQuantity = null
        if (!params.data.isEdited) params.data.isEdited = []
        if (!params.data.isEdited.includes(params.colDef.field)) params.data.isEdited.push(params.colDef.field)
      }
      return Number.isInteger(params.data.salesQuantity) ? params.data.salesQuantity : 'ERR'
    },
    valueSetter: params => {
      //to make sure user entered number only
      let newValInt = parseInt(params.newValue)
      let valueChanged = params.data.salesQuantity !== newValInt
      if (valueChanged) {
        params.data.salesQuantity = isNaN(newValInt) ? params.oldValue : newValInt
      }
      return valueChanged
    }
  },
  { headerName: 'TAUX TVA', field: 'vatRate', width: 155, cellRenderer: params => params.api.getRowNode(0).data.vatRate },
  {
    headerName: 'COÛT REMISÉ HT',
    field: 'benefitAmountWithoutTax',
    width: 100,
    aggFunc: params => {
      let sum = 0
      params.values.forEach(function (value) {
        sum += Number(value)
      })
      return sum.toFixed(2)
    },
    valueGetter: params =>
      params.node.rowPinned === 'bottom'
        ? params.data.benefitAmountWithoutTax
        : (Number(params.data.benefitAmountWithTax) / (1 + Number(params.data.vatRate) / 100)).toFixed(2)
  },
  {
    headerName: 'COÛT REMISÉ TVA',
    field: 'benefitTax',
    width: 100,
    aggFunc: params => {
      let sum = 0
      params.values.forEach(function (value) {
        sum += Number(value)
      })
      return sum.toFixed(2)
    },
    valueGetter: params =>
      params.node.rowPinned === 'bottom'
        ? params.data.benefitTax
        : ((Number(params.data.benefitAmountWithTax) / (Number(params.data.vatRate) + 100)) * Number(params.data.vatRate)).toFixed(2)
  },
  {
    headerName: 'COÛT REMISÉ TTC',
    field: 'benefitAmountWithTax',
    width: 100,
    type: 'valueColumn',
    cellClassRules: {
      'cell-changed': params =>
        params?.data?.isEdited?.includes(params.colDef.field) ||
        params?.data?.updateBenefitAmountWithTax ||
        params?.data?.updateBenefitAmountWithTax === 0 ||
        params?.node?.allLeafChildren?.some(child => child?.data?.isEdited?.includes(params.colDef.field) || child?.data?.updateBenefitAmountWithTax)
    },
    aggFunc: params => {
      let sum = 0
      params.values.forEach(function (value) {
        sum += Number(value)
      })
      return !isNaN(sum) ? sum.toFixed(2) : 'ERR'
    },
    valueGetter: params => {
      if (params?.data?.updateBenefitAmountWithTax || params?.data?.updateBenefitAmountWithTax === 0) {
        params.data.benefitAmountWithTax = params.data.updateBenefitAmountWithTax
        params.data.updateBenefitAmountWithTax = null
        if (!params.data.isEdited) params.data.isEdited = []
        if (!params.data.isEdited.includes(params.colDef.field)) params.data.isEdited.push(params.colDef.field)
      }
      return !isNaN(params.data.benefitAmountWithTax) ? Number(params.data.benefitAmountWithTax).toFixed(2) : 'ERR'
    },
    valueSetter: params => {
      //to make sure user entered number only
      let newValInt = Number(params.newValue).toFixed(2)
      let valueChanged = Number(params.data.benefitAmountWithTax).toFixed(2) !== newValInt
      if (valueChanged) {
        params.data.benefitAmountWithTax = newValInt ? newValInt : params.oldValue
      }
      return valueChanged
    }
  },
  {
    headerName: 'PLAFOND (%)',
    headerComponent: 'ArrayButtonStake',
    field: 'paymentSupplierStake',
    width: 100,
    cellClassRules: {
      'cell-changed': params => params.api.context.paymentSupplierStake
    },
    cellRenderer: params =>
      params.api.context.paymentSupplierStake ? params.api.context.paymentSupplierStake : params.api.getRowNode(0).data.paymentSupplierStake
  },
  { headerName: 'QUANTITÉ REMISÉE MODIFIÉ', field: 'updateBenefitQuantity', hide: true, cellClass: 'greenBg' },
  { headerName: 'QUANTITÉ TOTALE MODIFIÉ', field: 'updateSalesQuantity', hide: true, cellClass: 'greenBg' },
  { headerName: 'COÛT REMISÉ TTC MODIFIÉ', field: 'updateBenefitAmountWithTax', hide: true, cellClass: 'greenBg' }
]

// START REPORTING ARRAY CONST ------------------------------------------------------------------------------------------------
export const reportingArrayColDefs = [
  {
    headerName: 'Date du rapport',
    field: 'reportingDate',
    width: 200,
    valueFormatter: params => params.value ? DateTime.fromFormat(params.value, 'yyyyMMdd')?.toFormat('dd/MM/yyyy') : '',
    sort: 'desc'
  },
  {
    headerName: 'Nom du fichier',
    field: 'reportingFileName',
    width: 600
  }
]
