<template>
  <div class="card">
    <div>
      <slot name="header" />
    </div>
    <div class="body">
      <Loading :is-loading="isLoading" />
      <div>
        <slot name="body" />
      </div>
      <div>
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Card',
    props: {
      isLoading: {
        type: Boolean,
        default: false
      }
    }
  }
</script>
<style lang="scss" scoped>
  .card {
    display: flex;
    flex-direction: column;
    background: $white;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    margin: 22.5px 0;
    font-size: 13px;

    & .body {
      position: relative;
      font-size: 12px;
    }
  }


</style>
