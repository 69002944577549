import { extendWithAuthorization, httpNip } from '@/http'

const state = {
  promotionDetail: null,
  historic: null,
  promotionProductList: [],
  promotionComments: null
}

const mutations = {
  SET_PROMOTION_DETAIL(state, promotionDetail) {
    state.promotionDetail = promotionDetail
  },

  SET_PROMOTION_HISTORIC(state, historic) {
    state.historic = historic
  },

  SET_PROMOTION_PRODUCT_LIST(state, promotionProductList) {
    state.promotionProductList = promotionProductList
  },

  SET_PROMOTION_COMMENTS(state, promotionComments) {
    state.promotionComments = promotionComments
  },

  DELETE_PROMOTION_COMMENT(state, id) {
    let isFound = false
    for (const typeCom of state.promotionComments) {
      for (const com of typeCom.comment) {
        if (com.discountcontractcommentsid === id) {
          com.iscommentvisible = false
          isFound = true
          break
        }
      }
      if (isFound) break
    }
  },
  RESET_STORE(state) {
    state.promotionDetail = null
    state.historic = null
    state.promotionProductList = []
    state.promotionComments = null
  }

}

const actions = {
    async fetchPromotionDetail({ commit }, discountContractCode) {
      try {
        const extendedHttp = await extendWithAuthorization(httpNip)
        const datas = await extendedHttp
          .get(`promotionDetail?discountContractCode=` + discountContractCode)
          .json()
        commit('SET_PROMOTION_DETAIL', datas[0].data)
        return datas
      } catch (error) {
        return error
      }
    },
    async fetchBillingHistoric({ commit }, discountContractCode)  {
      try {
        const extendedHttp = await extendWithAuthorization(httpNip)
        const datas = await extendedHttp
          .get(`historic/bill?discountContractCode=` + discountContractCode)
          .json()
        commit('SET_PROMOTION_HISTORIC', datas)
        return datas
      } catch (error) {
        return error
      }
    },
    async fetchPromotionProductList({ commit }, discountContractCode) {
      try {
        const extendedHttp = await extendWithAuthorization(httpNip)
        const datas = await extendedHttp
          .get(`promotionDetail/productList?discountContractCode=` + discountContractCode)
          .json()
        commit('SET_PROMOTION_PRODUCT_LIST', datas[0].data.promotionProductList)
        return datas
      } catch (error) {
        return error
      }
    },
    async fetchComments({ commit }, discountContractCode) {
      try {
        const extendedHttp = await extendWithAuthorization(httpNip)
        const datas = await extendedHttp
          .get(`promotionDetail/comment?discountContractCode=` + discountContractCode)
          .json()
        commit('SET_PROMOTION_COMMENTS', datas)
        return datas
      } catch (error) {
        return error
      }
    },
    async addComment({ commit }, {discountContractCode, userMatricule, comment, type, subCommentType1 = null, subCommentType2 = null}) {
      try {
        const body = {
          discountContractCode,
          userMatricule,
          comment,
          type,
          subCommentType1,
          subCommentType2
        }
        const extendedHttp = await extendWithAuthorization(httpNip)
        const datas = await extendedHttp
          .post(`promotionDetail/comment`, {json: body})
          .json()
        commit('SET_PROMOTION_COMMENTS', datas)
        return datas
      } catch (error) {
        return error
      }
    },
    async setComment({ commit }, {id, comment, discountContractCode}) {
      try {
        const body = {
          "discountContractCode": discountContractCode,
          "comment": comment
        }
        const extendedHttp = await extendWithAuthorization(httpNip)
        const datas = await extendedHttp
          .put(`promotionDetail/comment/` + id, { json: body })
          .json()
        commit('SET_PROMOTION_COMMENTS', datas)
        return datas
      } catch (error) {
        return error
      }
    },
    async deleteComment({ commit }, id) {
      try {
        const extendedHttp = await extendWithAuthorization(httpNip)
        const datas = await extendedHttp
          .delete(`promotionDetail/comment/` + id)
          .json()
        await commit('DELETE_PROMOTION_COMMENT', id)
        return datas
      } catch (error) {
        return error
      }
    },
  resetStore({commit}) {
    commit('RESET_STORE')
  }
}

const getters = {}

export default {
  state,
  getters,
  mutations,
  actions
}
