<template>
  <div class="navbar">
    <router-link id="logo" to="/" class="logo" tag="div">
      <v-svg :name="'logo'" :h="'3rem'" />
      <!-- <img alt="Vue logo" width="200" src="@/assets/img/logo.png"> -->
    </router-link>
    <nav>
      <router-link to="/"><NavbarItem :name="$t('navbar.promotion')" :icon-name="'promotion'" /></router-link>
      <!-- <router-link to="/non-recus"><NavbarItem :name="$t('navbar.notReceived')" :iconName="'checkmark'"/></router-link> -->
      <router-link to="/export-avantages"><NavbarItem :name="$t('navbar.exportBenefits')" :icon-name="'export'" /></router-link>
      <router-link to="/loyalty-amounts"><NavbarItem :name="$t('navbar.loyaltyAmounts')" icon-name="loyalty" /></router-link>
      <router-link to="/billing"><NavbarItem :name="$t('navbar.invoices')" :icon-name="'invoice'" /></router-link>
      <router-link to="/reporting"><NavbarItem :name="$t('navbar.reports')" :icon-name="'dashboard'"/></router-link>
    </nav>
    <div class="navRight">
      <nav>
        <NavbarItem class="column" :name="$t('navbar.setting')" :icon-name="'setting'" />
        <NavbarItem class="column" :name="$t('navbar.alert')" :icon-name="'alert'" />
        <NavbarItem class="column pointer" :name="$t('navbar.logout')" :icon-name="'logout'" @click="logOut" />
      </nav>
    </div>
  </div>
</template>

<script>
import NavbarItem from '@/components/navbar/NavBarItem.vue'
import {inject} from "vue"
import {logout} from "../../main"
export default {
  name: 'Navbar',
  components: {
    NavbarItem
  },
  data() {
    return {
    }
  },
  methods: {
    isSuperAdminProduction(roles){
      if (['production'].includes(process.env.VUE_APP_BUILD_ENV)) {
        return roles.includes('super-admin')
      } else {
        return true
      }
    },
    logOut() {
      logout()
    }
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  position: fixed;
  top: 0;
  z-index: 100;

  background: linear-gradient(90deg, white 70%, #2CC7D1 99.91%);;
  border-radius: 0 0 15px 15px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);

  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 2em;

  .logo {
    cursor: pointer;
    margin-right: 30px;
  }

  nav {
    display: flex;
    width: 100%;
    height: 100%;
    font-size: 12px;
    a:hover {
      text-decoration: none;
    }
    a:focus {
      text-decoration: none;
    }
    a {
      font-weight: bold;
      margin: 0 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      text-decoration: none;
      color: $darkGrey;
      &:hover{
        color: $primaryOrange;
        border-bottom: 3px solid $primaryOrange;
      }
      &.router-link-exact-active {
        color: $primaryOrange;
        border-bottom: 3px solid $primaryOrange;
      }
    }
  }
}
.navRight {
  nav .column {
      margin: 0 10px;
      font-weight: bold;
      color: white;
      font-size: 10px;
  }
}
.pointer {
  cursor: pointer;
}
</style>
