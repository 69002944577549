<template>
  <div class="pageSize">
    <ul>
      <li v-for="(item, index) in tab" :key="'pagingSize_' + index">
        <button :class="['pagination-item', { active: item === value }]" @click="$emit('onPageSize', item)">
          {{ item }}
        </button>
      </li>
    </ul>
  </div>
</template>
<script>
  export default {
    name: 'PageSize',
    props: {
      tab: {
        type: Array,
        default: () => []
      },
      value: {
        type: Number,
        required: true
      }
    },
    emits: ['onPageSize'],
    data() {
      return {}
    },
    computed: {},
    methods: {}
  }
</script>

<style lang="scss" scoped>
  .pageSize {
    display: flex;

    ul {
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      border: 0.5px solid $primaryBlue;
      border-radius: 6px;

      li {
        height: 100%;

        &:first-child button {
          border-radius: 6px 0px 0px 6px;
        }

        &:last-child button {
          border-radius: 0px 6px 6px 0px;
        }

        button {
          padding: 10px 10px;
          cursor: pointer;
          background-color: white;
          color: $primaryBlue;
          border: 0.5px solid $primaryBlue;
          font-size: 11px;

          &:hover {
            background-color: $veryLightGrey;
          }

          &.active {
            background-color: $primaryBlue;
            color: white;
          }
        }
      }
    }
  }
</style>
