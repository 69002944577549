<!-- Created By Vasse Alexandre -->
<template>
  <div class="info-popup">
    <div class="popup-title">Informations</div>
    <div class="info" v-html="msg" />
  </div>
</template>

<script>
  export default {
    name: 'InfoPopup',
    props: {
      msg: {
        type: String,
        required: true
      }
    }
  }
</script>

<style scoped lang="scss">
  .info-popup {
    padding: 10px 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    background-color: white;
    width: 350px;
    z-index: 99999;

    .popup-title {
      font-size: 16px;
      @extend %font-body-bold;
      border-bottom: 2px solid $primaryOrange;
      width: fit-content;
      margin-bottom: 0.5rem;
    }

    .info {
      font-size: 13px;
    }
  }
</style>
