import { globalProperties } from '@/main'
  import ky from 'ky'

export const httpNip = ky.create({prefixUrl: process.env.VUE_APP_BACKEND_NIP, timeout: 180000})

export const extendWithAuthorization = async http => {
  const token = await globalProperties.firebaseUser.getIdToken()
  const headers = {
    Authorization: `Bearer ${ token }`,
    application: process.env.VUE_APP_APP_NAME
  }

  return http.extend({
    headers
  })
}
