import { extendWithAuthorization, httpNip } from '@/http'
import moment from 'moment'
import { fieldsPeriodDate } from '@/utils/CONF'

const MONTH_TO_YEAR_N1 = 6

const state = {
  maxYear: parseInt(moment().format('MM')) > MONTH_TO_YEAR_N1 ? parseInt(moment().format('YYYY')) + 1 : parseInt(moment().format('YYYY')),
  filters: {},
  filtersAggrid: null,
  promotions: []
}

const mutations = {
  SET_FILTERS(state, filters) {
    state.filters = filters
  },
  SET_FILTERS_AGGRID(state, filtersAggrid) {
    state.filtersAggrid = filtersAggrid
  },
  SET_PROMOTIONS(state, promotions) {
    state.promotions = promotions
  }
}

const actions = {
  async fetchPromotion({ state, commit }) {
    let datas

    let searchParams = {
      year: state.filters.global.year,
      discountContractChannel: state.filters.types.map(type => { return type.name.toUpperCase() }),
      discountContractStatusCode: state.filters.global.mandat === 'Tous' ? 0 : state.filters.global.mandatId
    }

    const filtersObject = Object.keys(state.filters.field)
      .filter(key => {
        if(fieldsPeriodDate.includes(key)){
          if(state.filters.field[key].start && state.filters.field[key].end){
            return true
          }
        }else if(state.filters.field[key]){
          return true
        }
        return false
      })
      .reduce((obj, key) => {
        if(fieldsPeriodDate.includes(key)){
          obj[key] = JSON.stringify({start:state.filters.field[key].start.toFormat('yyyy-MM-dd'),end:state.filters.field[key].end.toFormat('yyyy-MM-dd')})
        }else{
          obj[key] = state.filters.field[key].toUpperCase()
        }
        return obj
      }, {})

    Object.assign(searchParams, filtersObject)

    try {
      const extendedHttp = await extendWithAuthorization(httpNip)
      datas = await extendedHttp.get(`promotion`, { searchParams }).json()
      commit('SET_PROMOTIONS', datas)
    } catch (error) {
      return error
    }
  }
}

const getters = {}

export default {
  state,
  getters,
  mutations,
  actions
}
