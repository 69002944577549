<!-- Created By Vasse Alexandre -->
<template>
  <transition name="fade">
    <div v-if="isVisible" class="popup-modal">
      <div class="window">
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'PopupModal',
    data: () => ({
      isVisible: false,
    }),
    methods: {
      open() {
        this.isVisible = true
      },

      close() {
        this.isVisible = false
      }
    }
  }
</script>

<style scoped lang="scss">
  .popup-modal {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    z-index: 200;
  }

  .window {
    position: relative;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
    background: #fff;
    width: 60vw;
    margin-left: auto;
    margin-right: auto;
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
