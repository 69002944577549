<!-- Created By Vasse Alexandre -->
<template>
  <div class="loyalty-amounts-search">
    <div class="search-title">{{ $t('loyaltyAmounts.search') }}</div>
    <div class="inputs">
      <div class="avantage field">
        <div class="label">{{ $t('loyaltyAmounts.inputs.discountContractCode.label') }}</div>
        <input-text
          v-model="loyaltyAmountsSearch.discountContractCode"
          :placeholder="$t('loyaltyAmounts.inputs.discountContractCode.placeholder')"
          width="260px"
          show-eraser
        />
      </div>
      <div class="perimeter field">
        <div class="label">{{ $t('loyaltyAmounts.inputs.perimeter.label') }}</div>
        <input-select
          v-model="loyaltyAmountsSearch.perimeter"
          traduction-key="loyaltyAmounts.inputs.perimeter.values"
          :values="perimeters"
          :placeholder="$t('loyaltyAmounts.inputs.perimeter.placeholder')"
          show-eraser
          width="260px"
        />
      </div>
      <div class="cug field">
        <div class="label">{{ $t('loyaltyAmounts.inputs.cug.label') }}</div>
        <input-text
          v-model="loyaltyAmountsSearch.cug"
          :placeholder="$t('loyaltyAmounts.inputs.cug.placeholder')"
          width="260px"
          show-eraser
        />
      </div>
      <div class="offer-source field">
        <div class="label">{{ $t('loyaltyAmounts.inputs.discountContractChannel.label') }}</div>
        <input-select
          v-model="loyaltyAmountsSearch.discountContractChannel"
          width="260px"
          traduction-key="loyaltyAmounts.inputs.discountContractChannel.values"
          :values="discountContractChannels"
          show-eraser
          :placeholder="$t('loyaltyAmounts.inputs.discountContractChannel.placeholder')"
        />
      </div>
      <div class="orderSupplierCnuf field">
        <div class="label">{{ $t('loyaltyAmounts.inputs.orderSupplierCnuf.label') }}</div>
        <input-text
          v-model="loyaltyAmountsSearch.orderSupplierCnuf"
          width="260px"
          :placeholder="$t('loyaltyAmounts.inputs.orderSupplierCnuf.placeholder')"
          show-eraser
        />
      </div>
      <div class="offer-type field">
        <div class="label">{{ $t('loyaltyAmounts.inputs.promotionTypeCode.label') }}</div>
        <input-select
          v-model="loyaltyAmountsSearch.promotionTypeCode"
          width="260px"
          traduction-key="loyaltyAmounts.inputs.promotionTypeCode.values"
          :values="promotionTypeCodes"
          :placeholder="$t('loyaltyAmounts.inputs.promotionTypeCode.placeholder')"
          show-eraser
        />
      </div>
      <div class="orderSupplierDepartmentCode field">
        <div class="label">{{ $t('loyaltyAmounts.inputs.orderSupplierDepartmentCode.label') }}</div>
        <input-select-auto-complete
          :value="loyaltyAmountsSearch.orderSupplierDepartment"
          :options="
            departments.map(
              (department) => department.orderSupplierDepartmentCode + ' - ' + department.orderSupplierDepartmentLabel
            )
          "
          :loading="loadingDepartments"
          :placeholder="$t('loyaltyAmounts.inputs.orderSupplierDepartmentCode.placeholder')"
          @input-value="
            loyaltyAmountsSearch.orderSupplierDepartment = departments.find(
              (department) => department.orderSupplierDepartmentCode + ' - ' + department.orderSupplierDepartmentLabel === $event
            )
          "
          width="260px"
          show-eraser
        />
      </div>
      <div class="period field">
        <div class="label">{{ $t('loyaltyAmounts.inputs.period.label') }}</div>
        <div class="date-container">
          <date-picker
            v-model:value="date"
            type="date"
            :range="true"
            format="DD/MM/YYYY"
            class="mx-inputs"
            value-type="format"
            popup-class="mx-popup"
            :placeholder="$t('loyaltyAmounts.inputs.period.placeholder')"
            langString="fr"
            width="200px"
            @change="periodChange"
          ></date-picker>
        </div>
      </div>
      <div class="tract field">
        <div class="label">{{ $t('loyaltyAmounts.inputs.commercialOperationLabel.label') }}</div>
        <input-select-auto-complete
          :value="loyaltyAmountsSearch.commercialOperationLabel"
          :options="tracts.map((tract) => tract.id)"
          :loading="loadingTracts"
          :placeholder="$t('loyaltyAmounts.inputs.commercialOperationLabel.placeholder')"
          @input-value="loyaltyAmountsSearch.commercialOperationLabel = tracts.find((tract) => tract.id === $event)"
          width="260px"
          show-eraser
        />
      </div>
    </div>
    <div class="footer">
      <v-button :label="$t('loyaltyAmounts.buttons.reset')" :active="true" color="secondary" @click="reset()" />
      <v-button :label="$t('loyaltyAmounts.buttons.search')" :active="true" @click="search()" />
    </div>
  </div>
</template>

<script>
  import { DateTime } from 'luxon'
  import { mapActions, mapState } from 'vuex'
  import { channels, promotionPerimeters, promotionTypes } from '@/utils/CONF'
  import DatePicker from 'vue-datepicker-next'
  import 'vue-datepicker-next/scss/index.scss'
  import 'vue-datepicker-next/locale/fr'

  const initData = {
    discountContractCode: null,
    perimeter: null,
    orderSupplierCnuf: null,
    promotionTypeCode: null,
    orderSupplierDepartment: null,
    discountContractChannel: null,
    discountContractStartDate: null,
    discountContractEndDate: null,
    commercialOperationLabel: null,
    commercialOperation: null,
    orderSupplierDepartmentCode: null,
    cug: null,
  }

  export default {
    name: 'LoyaltyAmountsSearch',
    emits: ['search'],
    components: {
      DatePicker,
    },
    data() {
      return {
        loadingDepartments: false,
        loadingTracts: false,
        loyaltyAmountsSearch: { ...initData },
        perimeters: promotionPerimeters,
        promotionTypeCodes: promotionTypes,
        discountContractChannels: channels,
        date: [this.loyaltyAmountsSearch?.discountContractStartDate, this.loyaltyAmountsSearch?.discountContractEndDate],
      }
    },
    computed: {
      ...mapState('referential', ['departments', 'tracts']),
    },
    async created() {
      await Promise.all([this.loadDepartments(), this.loadTracts()])
    },
    methods: {
      ...mapActions('referential', ['fetchDepartments', 'fetchTracts']),

      async loadDepartments() {
        this.loadingDepartments = true
        await this.fetchDepartments()
        this.loadingDepartments = false
      },

      async loadTracts() {
        this.loadingTracts = true
        await this.fetchTracts()
        this.loadingTracts = false
      },

      search() {
        const datesMandatoryFields =
          this.loyaltyAmountsSearch.discountContractStartDate &&
          this.loyaltyAmountsSearch.discountContractEndDate &&
          this.loyaltyAmountsSearch.perimeter &&
          this.loyaltyAmountsSearch.discountContractChannel
        this.loyaltyAmountsSearch.orderSupplierDepartmentCode = this.loyaltyAmountsSearch.orderSupplierDepartment
          ? Number(this.loyaltyAmountsSearch.orderSupplierDepartment.orderSupplierDepartmentCode)
          : ''
        this.loyaltyAmountsSearch.commercialOperationLabel = this.loyaltyAmountsSearch.commercialOperation
          ? this.loyaltyAmountsSearch.commercialOperation.commercialOperationLabel
          : ''

        if (!this.loyaltyAmountsSearch.discountContractCode && !datesMandatoryFields) {
          return this.$toast.error(this.$t('loyaltyAmounts.errors.mandatoryFields'))
        }

        this.$emit('search', JSON.parse(JSON.stringify(this.loyaltyAmountsSearch)))
      },

      reset() {
        this.loyaltyAmountsSearch = { ...initData }
      },

      periodChange(event) {
        this.loyaltyAmountsSearch.discountContractStartDate = event?.[0]
          ? DateTime.fromFormat(event[0], 'dd/MM/yyyy').toFormat('yyyy-MM-dd')
          : null
        this.loyaltyAmountsSearch.discountContractEndDate = event?.[1]
          ? DateTime.fromFormat(event[1], 'dd/MM/yyyy').toFormat('yyyy-MM-dd')
          : null
      },
    },
  }
</script>

<style scoped lang="scss">
  .loyalty-amounts-search {
    padding: 1rem;
    background: $white;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 15px;

    .search-title,
    .files-to-download-title {
      padding-bottom: 0.2rem;
      border-bottom: 2px solid $primaryOrange;
      @extend %font-body-bold;
      width: fit-content;
      font-size: 18px;
    }

    .inputs {
      width: 80%;
      flex-wrap: wrap;
      margin: 0 auto;
      display: flex;
      gap: 1.7rem 7rem;

      .field {
        width: 44%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @extend %font-body-semi-bold;
      }
    }

    .footer {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;

      .button {
        padding: 8px;
        border-radius: 8px;
        @extend %font-body-semi-bold;
        font-size: 14px;
        margin-left: 1rem;
      }
    }
  }
  .date-container {
    display: flex;
    width: 260px;
  }

  .mx-inputs::v-deep input {
    border: 0.5px solid #5fbfc6;
    border-radius: 10px;
    height: 28px;
  }
</style>
