<template>
  <div class="stake">
    <span>Plafond</span>
    <v-svg class="pointer" name="edit-mode" @click="showPopup" />
  </div>
</template>

<script>
export default {
  name: 'ChickenOffer',
  methods: {
    showPopup(){
      this.params.context.componentParent.showPopup()
    }
  }
}
</script>
<style lang="scss" scoped>
.stake{
  display: flex;
  align-items: center;
  span{
    margin-right: 2px;
  }
}
button {
  border-radius: 8px;
}
</style>
