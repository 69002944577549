<template>
  <!-- FOURNISSEUR DE COMMANDE -->
  <Card :is-loading="isLoading || isLoadUpdateOrderSupplier">
    <template #header>
      <div class="header-card-custom">
        <CardHeader :title="$t('promotionDetail.bloc.orderProvider.title')" />
        <div class="actions">
          <v-svg v-if="!isEditModeOrderSupplier && isCreatedStatus()" @click="isEditModeOrderSupplier = !isEditModeOrderSupplier" name="edit-mode" h="1.5rem" />
          <v-svg v-if="isEditModeOrderSupplier || isEditMode" @click="onClose" name="circle-close" h="2rem" />
          <v-svg v-if="isEditModeOrderSupplier || isEditMode" @click="updateOrderSupplier()" name="circle-validate" h="2rem" />
        </div>
      </div>
    </template>
    <template #body>
      <ul>
        <li>
          <span class="title">{{ $t('promotionDetail.bloc.orderProvider.orderMarket') }}</span>
          <span class="flex-2">{{ promotionDetail ? promotionDetail.orderSupplier.orderSupplierDepartmentCode : '' }} ({{ promotionDetail ? promotionDetail.orderSupplier.orderSupplierDepartmentLabel : '' }})</span>
        </li>
        <li>
          <span class="title">{{ $t('promotionDetail.bloc.orderProvider.name') }}</span>
          <span class="flex-2">{{ promotionDetail ? promotionDetail.orderSupplier.orderSupplierLabel : '' }}</span>
        </li>
        <li>
          <span class="title">{{ $t('promotionDetail.bloc.orderProvider.orderCnuf') }}</span>
          <span v-if="!isEditModeOrderSupplier && !isEditMode" class="flex-2">{{ promotionDetail ? promotionDetail.orderSupplier.orderSupplierCnuf : '' }}</span>
          <input-text v-else
                      v-model="orderSupplierNewValues.cnuf"
                      width="40%" />
        </li>
        <li>
          <span class="title">{{ $t('promotionDetail.bloc.orderProvider.orderCif') }}</span>
          <span v-if="!isEditModeOrderSupplier && !isEditMode" class="flex-2">{{ promotionDetail ? promotionDetail.orderSupplier.orderSupplierCif : '' }}</span>
          <input-text v-else
                      v-model="orderSupplierNewValues.cif"
                      width="40%" />
        </li>
        <li>
          <span class="title">{{ $t('promotionDetail.bloc.orderProvider.orderGroupNego') }}</span>
          <span v-if="!isEditModeOrderSupplier && !isEditMode" class="flex-2">{{ `${promotionDetail ? promotionDetail.orderSupplier.orderSupplierNegoGroupUid : ''} - ${promotionDetail ? promotionDetail.orderSupplier.orderSupplierNegoGroupLabel : ''}` }}</span>
          <input-text v-else
                      v-model="orderSupplierNewValues.gdn"
                      width="40%" />
        </li>
        <li v-if="promotionDetail && !isMarketWithNullableGDN && (promotionDetail.orderSupplier.orderSupplierNegoGroupUid === null || promotionDetail.orderSupplier.orderSupplierLabel === null)" class="error-message">{{ $t('promotionDetail.bloc.orderProvider.mandatoryAttributeMessage') }}</li>
      </ul>
    </template>
  </Card>
</template>
<script>
import Card from '@/components/card/Card'
import CardHeader from '@/components/card/CardHeader'
import { globalProperties } from '@/main'
export default {
  name: 'PromotionDetailOrderProvider',
  components: {
    Card,
    CardHeader
  },
  props: {
    promotionDetail: {
      default: null,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    orderSupplierNewValues: {
      type: Object,
      default: {
          cnuf: null,
          oldCnuf: null,
          cif: null,
          oldCif: null,
          gdn: null,
          oldGdn: null
      }
    },
    isMarketWithNullableGDN: {
      type: Boolean,
      default: false,
    },
    isEditMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoadUpdateOrderSupplier: false,
      isEditModeOrderSupplier: false,
    }
  },
  methods:{
    isCreatedStatus() {
      return this.promotionDetail?.discountContractStatusCode === 1
    },
    onClose(){
      this.$emit('cancel')
      this.isEditModeOrderSupplier = !this.isEditModeOrderSupplier
    },
    async updateOrderSupplier() {
      this.isLoadUpdateOrderSupplier = true
      if(this.orderSupplierNewValues.cnuf.length !== 5) {
        this.$toast.error(this.$t('promotionDetail.bloc.orderProvider.updateErrorSupplierCnuf'))
        this.isLoadUpdateOrderSupplier = false
      }
      else if(this.orderSupplierNewValues.cif.length !== 3) {
        this.$toast.error(this.$t('promotionDetail.bloc.orderProvider.updateErrorSupplierCif'))
        this.isLoadUpdateOrderSupplier = false
      }
      else {
        try {
          const commentCNUF = this.orderSupplierNewValues.cnuf != this.orderSupplierNewValues.oldCnuf ?
            '&rsaquo; Changement '+(this.$t('promotionDetail.bloc.orderProvider.orderCnuf')) + ' : '
            + this.orderSupplierNewValues.oldCnuf + ' &rArr; ' + this.orderSupplierNewValues.cnuf : null

          const commentCIF = this.orderSupplierNewValues.cif != this.orderSupplierNewValues.oldCif ?
            '&rsaquo; Changement '+(this.$t('promotionDetail.bloc.orderProvider.orderCif')) + ' : '
            + this.orderSupplierNewValues.oldCif + ' &rArr; ' + this.orderSupplierNewValues.cif : null

          const commentGDN = this.orderSupplierNewValues.gdn != this.orderSupplierNewValues.oldGdn ?
            '&rsaquo; Changement '+(this.$t('promotionDetail.bloc.orderProvider.orderGroupNego')) + ' : '
            + this.orderSupplierNewValues.oldGdn + ' &rArr; ' + this.orderSupplierNewValues.gdn : null

          console.log(commentCNUF + commentCIF + commentGDN)

          const body = {
            updatedOrderSupplierCnuf: this.orderSupplierNewValues.cnuf,
            updatedOrderSupplierCif: this.orderSupplierNewValues.cif,
            updatedOrderSupplierGdn: this.orderSupplierNewValues.gdn,
            orderSupplierDepartmentCode: this.promotionDetail?.orderSupplier.orderSupplierDepartmentCode,
            discountContractCode: this.promotionDetail?.discountContractCode,
            discountContractStatusCode: this.promotionDetail?.discountContractStatusCode,
            discountContractType: this.promotionDetail?.discountContractType,
            discountContractChannel: this.promotionDetail?.discountContractChannel,
            discountContractYear: this.promotionDetail?.discountContractYear,
            discountContractStartDate: this.promotionDetail?.discountContractStartDate?.value,
            commentUpdateOrderSupplier: [commentCNUF, commentCIF, commentGDN].filter(Boolean).join('<br/>'),
            userMatricule: globalProperties?.user?.uid
          }
          this.$emit('updateOrderProvider', body)
        } catch (error) {
          if(error.response.status === 400){
            this.$toast.error(this.$t('message.error.dataIncorrect'))
          }else{
            this.$toast.error(this.$t('message.error.messageGlobal'))
          }
        } finally {
          this.isEditModeOrderSupplier = !this.isEditModeOrderSupplier
          this.isLoadUpdateOrderSupplier = false
        }
      }
    },
  }
}
</script>


<style lang="scss" scoped>
.header-card-custom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .actions {
    display: flex;

    .v-svg {
      margin-right: 19px;
      cursor: pointer;
      &.svg-circle-close { color: $primaryOrange }
      &.svg-circle-validate{ color: $primaryBlue }
    }
  }
}

.card {
  width: 32%;
}

ul
li {
  min-height: 30px;
  display: flex;
  align-items: center;
  padding: 0 19px;

  & div {
    width: 100%;
    min-height: 30px;
  }

  &:nth-child(2n-1) {
    background-color: $veryLightGrey;
    border-radius: 15px;
  }

  .edit-mode {
    .input-select {
      margin-left: 2rem;
    }

    span{
      width: 70px;
      padding-left: 16px;
    }
  }

  & .type {
    color: $darkGrey;
  }

  & .title {
    flex: 1;
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 10px;
    line-height: 20px;
  }

  & span:last-child {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 20px;

    & span {
      font-size: 12px;
      float: right;
    }
  }
}
</style>