<template>
  <div tabindex="0" :class="['v-calendar', { secondary }]">
    <div class="custom-v-calendar">
      <div v-if="!disabled" :class="[{ opened: calendarIsOpen }, 'button-calendar']" @click="calendarIsOpen = !calendarIsOpen">
        <div :class="['current-value', { placeholder: !modelValue }]">{{ getDate() }}</div>
        <v-svg name="calendar" h="1rem" />
      </div>
      <div v-else class="disabled button-calendar">
        <div v-if="modelValue"> {{ formatDate(modelValue, 'DD/MM/YYYY') }} </div>
        <div v-else> jj/mm/aaaa </div>
        <v-svg name="calendar" h="1rem" />
      </div>
      <v-svg v-if="showEraser" name="erase" h="1.5rem" @click="reset()" />
    </div>
    <div v-if="!disabled && calendarIsOpen" class="calendar">
      <div class="month">
        <v-svg name="chevron-left" h="1rem" @click="shiftMonth(-1)" />
        <div class="current-month">{{ displayedMonth.toFormat('MMMM yyyy', { locale: 'fr' }) }}</div>
        <v-svg name="chevron-right" h="1rem" @click="shiftMonth(1)" />
      </div>
      <div class="calendar-days">
        <div v-for="(dayLabel, index) of days" :key="`${calendarId}:dayLabel#${index}`" class="cell label">
          {{ dayLabel }}
        </div>
        <div
          v-for="day in emptyCells"
          :key="`${calendarId}:previousMonthDay#${day}`"
          :class="['cell other-month', cssClasses(day, -1)]"
          @click="selectDay(day, -1)"
        >
          <div class="number">{{ day }}</div>
        </div>
        <div v-for="day in displayedMonth.daysInMonth" :key="`${calendarId}:day#${day}#`" :class="['cell', cssClasses(day)]" @click="selectDay(day)">
          <div class="number">{{ day }}</div>
        </div>
        <div
          v-for="day in daysOfNextMonth"
          :key="`${calendarId}:nextMonthDay#${day}`"
          :class="['cell other-month', cssClasses(day, 1)]"
          @click="selectDay(day, 1)"
        >
          <div class="number">{{ day }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { DateTime, Info } from 'luxon'
  import dateMixin from '@/mixins/date'
  import date from '../../mixins/date'
  import moment from 'moment'

  const days = Info.weekdays('narrow', 'fr').map(day => day.replace('.', ''))
  let calendarId = 0

  export default {
    name: 'InputDateCalendar',
    mixins: [dateMixin],
    props: {
      modelValue: {
        type: [String, null],
        default: undefined
      },
      showEraser: {
        type: Boolean,
        default: false
      },
      placeholder: {
        type: String,
        default: 'jj/mm/aaaa'
      },
      secondary: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      maxDate: {
        type: Date,
        default: null
      }
    },
    emits: ['update:modelValue'],
    data() {
      return {
        startDate: DateTime.local().set({ day: 1 }),
        days,
        calendarId: ++calendarId,
        calendarIsOpen: false,
        justOpened: false,
        displayedMonth: DateTime.local().set({ day: 1 }),
        lastSelectableDay: DateTime.local(),
        selectedDate: null,
        manualSelection: false,
        toDay: DateTime.now()
      }
    },
    computed: {
      emptyCells() {
        const cells = []
        for (let i = this.displayedMonth.weekday - 1; i > 0; i--) {
          cells.push(this.daysOfPreviousMonth - i + 1)
        }
        return cells
      },

      daysOfPreviousMonth() {
        return this.displayedMonth.minus({ months: 1 }).daysInMonth
      },

      daysOfNextMonth() {
        return 7 - ((this.displayedMonth.daysInMonth + this.emptyCells.length) % 7)
      }
    },
    watch: {
      calendarIsOpen() {
        if (this.calendarIsOpen) {
          this.justOpened = true
          this.displayedMonth = this.selectedDate ? this.selectedDate.set({ day: 1 }) : this.startDate.set({ day: 1 })
          if (this.selectedDate === null) this.selectDefaultDay()
        } else {
          this.manualSelection = false
        }
      },
      displayedMonth() {
        if (!this.manualSelection && !this.selectedDate) this.selectDefaultDay()
      }
    },
    methods: {
      cssClasses(day, shiftMonth = 0) {
        const date = this.displayedMonth.plus({ month: shiftMonth }).set({ day })
        return {
          selected: shiftMonth === 0 ? date.hasSame(this.selectedDate, 'day') : this.dateIsSelected(date),
          start: date.hasSame(this.selectedDate, 'day'),
          disabled: this.maxDate ? moment(this.maxDate).isBefore(moment(date.toISODate())) : false //!this.dateIsSelectable(date)
        }
      },

      shiftMonth(shift) {
        this.displayedMonth = this.displayedMonth.plus({ month: shift })
      },

      selectDay(day, shiftMonth = 0) {
        this.manualSelection = true
        const selectedDay = this.displayedMonth.plus({ month: shiftMonth }).set({ day })
        // if (!this.dateIsSelectable(selectedDay)) return
        if (moment(this.maxDate).isBefore(moment(selectedDay.toISODate()))) return
        this.shiftMonth(shiftMonth)
        this.selectedDate = selectedDay
        this.$emit('update:modelValue', this.selectedDate.toISODate())
        this.calendarIsOpen = false
      },

      selectDefaultDay() {
        this.selectedDate = this.displayedMonth.set({ day: this.toDay.day })
        this.$emit('update:modelValue', this.selectedDate.toISODate())
      },

      dateIsSelected(date) {
        return date >= this.selectedDate
      },

      dateIsSelectable(date) {
        return date <= this.lastSelectableDay
      },

      reset() {
        this.calendarIsOpen = false
        this.selectedDate = null
        this.$emit('update:modelValue', this.selectedDate)
      },
      getDate() {
        return this.modelValue ? DateTime.fromISO(this.modelValue).toFormat('dd/MM/yyyy') : this.placeholder
      }
    }
  }
</script>

<style lang="scss" scoped>
  .v-calendar {
    display: flex;
    align-items: center;
    position: relative;
    min-width: 260px;

    .custom-v-calendar {
      @include flex(center, space-between);
      width: 100%;

      .svg-erase {
        margin-top: 3px;
        margin-left: 0.2rem;
        cursor: pointer;
      }
    }

    .button-calendar {
      @include flex(center, space-between);
      @extend %font-body;
      background: transparent;
      border: 0.5px solid $primaryBlue;
      border-radius: 10px;
      padding: 5px 10px 5px 15px;
      cursor: pointer;
      font-size: 13px;
      width: calc(100% - 28px);

      &.disabled {
        background-color: $disabledGrey;
        border-radius: 10px;
        cursor: default;
      }

      &.opened {
        background-color: $white;
        border: 1px solid $primaryBlue;
        border-radius: 10px 10px 0 0;
      }

      .placeholder {
        color: $mediumGrey;
      }

      .v-svg {
        color: $primaryBlue;
        border-radius: 0 10px 10px 0;
        margin-left: 0.5rem;
      }
    }

    .calendar {
      position: absolute;
      top: 25px;
      left: 0;
      z-index: 99999;
      background-color: $white;
      border: 1px solid $primaryBlue;
      box-shadow: 0 15px 20px rgb(0 0 0 / 15%);
      width: calc(100% - 28px);
      border-top: none;
      border-radius: 0 0 10px 10px;
      padding: 0.2rem;

      .month {
        @include flex;
        @extend %font-body-bold;
        font-size: 12px;
        margin: 0.5em;
        text-transform: capitalize;

        .current-month {
          @include flex;
          min-width: 60%;
        }

        .v-svg {
          cursor: pointer;
          &.disabled {
            color: $mediumGrey;
            cursor: not-allowed;
            pointer-events: none;
          }
        }
      }

      .calendar-days {
        display: grid;
        grid-template-columns: repeat(7, 32px);
        row-gap: 0.2rem;
        justify-items: center;

        .cell {
          @include flex;
          user-select: none;
          text-transform: capitalize;
          transition: all 200ms;
          width: 100%;

          &.label {
            font-weight: bold;
            height: 25px;
            width: 25px;
          }

          &:not(.label) {
            cursor: pointer;
          }

          &.other-month {
            color: $mediumGrey;
          }

          &.disabled {
            color: $mediumGrey;
            cursor: not-allowed;
          }

          .number {
            @include flex;
            border-radius: 50%;
            height: 25px;
            width: 25px;
          }

          &.selected {
            position: relative;

            .number {
              color: $white;
            }

            &.start,
            &.end {
              .number {
                background: $primaryBlue;
              }
            }
          }
        }
      }
      .actions {
        margin-top: 1rem;
        @include flex(center, flex-end);

        .validate {
          @include flex;
          transition: 200ms;
          background: transparent;
          border: 1px solid $primaryBlue;
          border-radius: 0.5rem;
          color: $primaryBlue;
          cursor: pointer;
          outline: none;

          &:hover {
            background-color: $primaryBlue;
            color: $white;
          }
          .v-svg {
            color: $primaryBlue;
            border-radius: 0 10px 10px 0;
            margin-left: 0.3rem;
          }
        }
      }
    }
    &.secondary {
      .button-calendar {
        border: 0.5px solid $primaryOrange;

        &.opened {
          border: 1px solid $primaryOrange;
        }

        .v-svg {
          color: $primaryOrange;
        }
      }

      .calendar {
        border: 1px solid $primaryOrange;

        .cell {
          &.selected {
            &.start,
            &.end {
              .number {
                background: $primaryOrange;
              }
            }
          }
        }
        .actions {
          .validate {
            border: 1px solid $primaryOrange;
            color: $primaryOrange;

            &:hover {
              background-color: $primaryOrange;
            }
            .v-svg {
              color: $primaryOrange;
            }
          }
        }
      }
    }
  }
</style>
