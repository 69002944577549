<!-- Created By Vasse Alexandre -->
<template>
  <div class="loyalty-amounts-array">
    <div class="box">
      <div class="tools">
        <div class="radio-button" @click="onTaxSwitch">
          <v-svg :name="amountsWithoutTax ? 'checked' : 'circle-empty'" h="1.5rem" />
          <div class="radio-label">{{ $t('loyaltyAmounts.buttons.amountsWithoutTax') }}</div>
        </div>
        <div class="right">
          <div class="display-key-selector">
            <!--<v-svg :class="{ active: displayKey === 'SITE' }" name="site-circle" :h="displayKey === 'SITE' ? '2rem' : '1.6rem'" @click="$emit('switchKey', 'SITE')" />-->
            <!--<v-svg :class="{ active: displayKey === 'CUG' }" name="cug-circle" :h="displayKey === 'CUG' ? '2rem' : '1.6rem'" @click="$emit('switchKey', 'CUG')" />-->
          </div>
          <v-button icon="excel"
                    h="1.2rem"
                    :label="$t('loyaltyAmounts.buttons.export')"
                    active
                    @click="exportRows" />
        </div>
      </div>
      <ag-grid-vue style="width: 100%; height: 95%;"
                   class="ag-theme-alpine"
                   :column-defs="columnDefs"
                   :suppress-row-click-selection="true"
                   :row-data="rowData"
                   :rowGroupPanelShow="rowGroupPanelShow"
                   :default-col-def="defaultColDef"
                   :overlay-no-rows-template="overlayNoRowsTemplate"
                   :pagination-page-size="paginationPageSize"
                   :pagination="true"
                   :suppress-pagination-panel="true"
                   :row-selection="rowSelection"
                   :get-row-style="getRowStyle"
                   :suppress-drag-leave-hides-columns="true"
                   :suppress-agg-func-in-header="true"
                   @pagination-changed="onPaginationChanged"
                   @grid-ready="onGridReady"
                   :alwaysShowVerticalScroll="true" />



        <!-- <ag-grid-vue style="width: 100%; height: 100%;"
                   class="ag-theme-alpine"
                   :column-defs="columnDefs"
                   :suppress-row-click-selection="true"
                   :row-data="rowData"
                   :default-col-def="defaultColDef"
                   :overlay-no-rows-template="overlayNoRowsTemplate"
                   :pagination-page-size="paginationPageSize"
                   :pagination="true"
                   :suppress-pagination-panel="true"
                   :row-selection="rowSelection"
                   :get-row-style="getRowStyle"
                   :suppress-drag-leave-hides-columns="true"
                   :suppress-agg-func-in-header="true"
                   :auto-group-column-def="autoGroupColumnDef"
                   @pagination-changed="onPaginationChanged"
                   @grid-ready="onGridReady" /> -->
    </div>
    <div v-if="showPagination" class="pagination-container">
      <pagination :value="page" :total-visible="7" :number-pages="nbPage" @onPage="changedPage" />
      <page-size :tab="[10, 20, 50, 100]" :value="paginationPageSize" @onPageSize="changedPageSize" />
    </div>
  </div>
</template>

<script>
  import { AgGridVue } from 'ag-grid-vue3'
  import { loyaltyAmountsArrayColDefs, loyaltyAmountsExportColumns } from '@/utils/CONF'
  import ExcelJS from 'exceljs'
  import { saveAs } from 'file-saver'
  import moment from "moment";
  const { DateTime } = require('luxon')
  const zone = "Europe/Paris"

  export default {
    name: 'LoyaltyAmountsArray',
    components: { AgGridVue },
    props: {
      rows: {
        type: Array,
        default: () => ([])
      },
      displayKey: {
        type: String,
        default: 'SITE'
      }
    },
    emits: ['switchKey'],
    data() {
      return {
        amountsWithoutTax: false,
        page: 1,
        nbPage: 1,
        showFilter: false,
        gridApi: null,
        columnApi: null,
        showPagination: true,
        paginationPageSize: 10,
        columnDefs: loyaltyAmountsArrayColDefs,
        rowData: [],
        defaultColDef: { filter: 'agTextColumnFilter', floatingFilter: true, resizable: true, sortable: true },
        rowSelection: 'single',
        overlayNoRowsTemplate: '<div class="nodata"><div class="nodata-logo"></div><span>Lance une recherche pour afficher la donnée souhaitée !</span></div>',
        getRowStyle: null,
        rowGroupPanelShow: 'always'
        // autoGroupColumnDef: { header: 'N° AVANTAGE', suppressCount: true, width: 150 }
      }
    },
    watch: {
      rows: {
        deep: true,
        handler() {
          this.rowData = this.rows
        }
      }
    },
    methods: {
      async onGridReady(params) {
        this.rowData = this.rows
        this.gridApi = params.api
        this.columnApi = params.columnApi
      },

      onPaginationChanged() {
        if (this.gridApi) this.nbPage = this.gridApi.paginationGetTotalPages()
      },

      changedPage(e) {
        this.page = e
        this.gridApi.paginationGoToPage(this.page - 1)
      },

      changedPageSize(e) {
        this.paginationPageSize = e
        this.gridApi.paginationSetPageSize(this.paginationPageSize)
      },

      onTaxSwitch() {
        this.amountsWithoutTax = !this.amountsWithoutTax

        const [showKey, hideKey] = this.amountsWithoutTax ? ['WithoutTax', 'WithTax'] : ['WithTax', 'WithoutTax']
        const columns = ['mandateCost', 'cooperationCost', 'unfundedCost', 'purchasePriceCost', 'benefitAmount']

        for (const column of columns) {
          this.columnApi.setColumnVisible(`${column}${showKey}`, true)
          this.columnApi.setColumnVisible(`${column}${hideKey}`, false)
        }
      },

      exportRows() {
        const now = DateTime.now().setZone(zone)
        const workbook = new ExcelJS.Workbook()
        const worksheet = workbook.addWorksheet('montantFideliteExport')

        worksheet.columns = loyaltyAmountsExportColumns(this.displayKey)

        worksheet.addRows(this.rows)

        const headerRow = worksheet.getRow(0)

        headerRow.alignment = { vertical: 'bottom', horizontal: 'center' }
        headerRow.font = { bold: true }
        headerRow.eachCell(cell => cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } })

        worksheet.columns.forEach(column => {
          const lengths = column.values.map(v => v.toString().length)
          column.width = Math.max(...lengths.filter(v => typeof v === 'number'))
        })

        const cellNoTax = worksheet.getColumn('benefitAmountWithoutTax')
        cellNoTax.eachCell((c, rowNumber) => {rowNumber != 1 ? c.value = Number(c.value) : c.value = c.value})
        const cellWithTax = worksheet.getColumn('benefitAmountWithTax')
        cellWithTax.eachCell((c, rowNumber) => { rowNumber != 1 ? c.value = Number(c.value) : c.value = c.value})

        const cellDiscountContractStartDate = worksheet.getColumn('discountContractStartDate')
        cellDiscountContractStartDate.eachCell((c, rowNumber) => {rowNumber != 1 ? c.value = moment(c.value.toString()).format('DD/MM/YYYY') : c.value = c.value})
        const cellDiscountContractEndDate = worksheet.getColumn('discountContractEndDate')
        cellDiscountContractEndDate.eachCell((c, rowNumber) => { rowNumber != 1 ? c.value = moment(c.value.toString()).format('DD/MM/YYYY') : c.value = c.value})

        const cellCommercialOperationStartDate = worksheet.getColumn('commercialOperationStartDate')
        cellCommercialOperationStartDate.eachCell((c, rowNumber) => {rowNumber != 1 ? c.value = moment(c.value.toString()).format('DD/MM/YYYY') : c.value = c.value})
        const cellCommercialOperationEndDate = worksheet.getColumn('commercialOperationEndDate')
        cellCommercialOperationEndDate.eachCell((c, rowNumber) => { rowNumber != 1 ? c.value = moment(c.value.toString()).format('DD/MM/YYYY') : c.value = c.value})


        workbook.xlsx.writeBuffer().then(data => {
          const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
          saveAs(blob, `montantFideliteExport_${now.toFormat('dd-LL-yyyy_HH-mm-ss')}.xlsx`)
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .loyalty-amounts-array {
    position: relative;
    .box {
      padding: 10px 40px 30px 30px;
      box-shadow: 0 4px 20px rgb(0 0 0 / 10%);
      border-radius: 15px;
      max-height: 100%;
      height: -webkit-fill-available;

      .tools {
        display: flex;
        justify-content: space-between;
        align-items: center;


        .right {
          display: flex;
          align-items: center;
          column-gap: 5rem;

          .display-key-selector {
            display: flex;
            column-gap: 0.3rem;
            align-items: center;

            .v-svg {
              cursor: pointer;
              color: $primaryBlue;

              &.active {
                color: #448F94;
              }
            }
          }

          .button {
            padding: 6px 8px 6px 0;
            border-radius: 8px;
            @extend %font-body-semi-bold;
            font-size: 14px;
          }
        }

        .radio-button {
          display: flex;
          align-items: center;
          cursor: pointer;
          user-select: none;
          padding: 0.5rem 0;

          .v-svg {
            color: $primaryBlue;
            margin-right: 0.5rem;
          }

          .radio-label {
            font-size: 12px;
            @extend %font-body-semi-bold;
          }
        }
      }
    }

    .pagination-container {
      margin-top: 1.5rem;
    }
  }
</style>

<style lang="scss">
  .loyalty-amounts-array {
    .ag-theme-alpine {
      .ag-overlay {
        top: 50px;
      }
    }
  }
  #content {
    position: relative;
    margin: 0 auto;
    height:100%;
  }
  #stuff {
    overflow-x:auto;
    top:30px;
    left:0;right:0;
    bottom:0;
    position:absolute;
  }
</style>
