export default {
  "navbar": {
    "promotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avantage"])},
    "notReceived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non reçus"])},
    "exportBenefits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export avantages"])},
    "loyaltyAmounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montants fidélité"])},
    "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factures"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapports"])},
    "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerte"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])}
  },
  "promotion": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avantages commerciaux"])},
    "startResearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lancer une recherche"])},
    "columnName": {
      "market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marche"])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal"])},
      "gdnRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GDN de commande"])},
      "promotionTyp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type Av"])},
      "promotionNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° Avantage"])},
      "tractName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom tract"])},
      "tractStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Début tract"])},
      "tractEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin tract"])},
      "themeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom theme"])},
      "fundingMethod1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode de fin 1"])},
      "fundingMethod2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode de fin 2"])},
      "nbCug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nbre de cug"])},
      "contractRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rayon paiement"])},
      "contractCnuf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CNUF paiement"])},
      "contractGdn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GDN paiement"])},
      "contractSupplierName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom fournisseur paiement"])},
      "promotionDateStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date début avantage"])},
      "promotionDateEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date fin avantage"])},
      "billingDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date facturation"])},
      "totalBillingAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant total facturation"])},
      "cnufRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CNUF de commande"])},
      "supplierName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom Fournisseur de commande"])}
    },
    "promotionSearch": {
      "showMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher plus"])},
      "showLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher moins"])},
      "research": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
      "startResearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je lance la recherche"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
      "typesLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réseau"])},
      "errorsTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le champ réseau est obligatoire"])}
    }
  },
  "promotionDetail": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails avantage"])},
    "bloc": {
      "theme": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tract & Thème"])},
        "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acheteur"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "typeOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'offre"])},
        "tract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tract/Campagne"])},
        "tractDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de tract"])},
        "theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thème"])},
        "promotionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date avantage"])},
        "wording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libellé"])}
      },
      "discountAndFinancing": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remise et financement"])},
        "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur"])},
        "constraint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrainte"])},
        "finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financé"])},
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])},
        "nip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NIP"])},
        "updateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les valeurs doivent être comprises entre 0 et 100."])}
      },
      "paymentProvider": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseur de paiement"])},
        "paymentMarket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marché paiement"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "paymentCnuf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CNUF paiement"])},
        "paymentNegoGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe négo. paiement"])},
        "npaSimulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° simulation NPA"])}
      },
      "orderProvider": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseur de commande"])},
        "orderMarket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marché de commande"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom fournisseur de commande"])},
        "orderCnuf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CNUF de commande"])},
        "orderGroupNego": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GDN de commande"])},
        "orderCif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CIF commande"])},
        "updateErrorSupplierCnuf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le CNUF doit être sur 5 caractères"])},
        "updateErrorSupplierCif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le CIF doit être sur 3 caractères"])},
        "mandatoryAttributeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Le fournisseur et le GDN doivent être renseignés pour pouvoir pointer l'offre."])}
      },
      "status": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut & Dates"])},
        "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut source"])},
        "reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réception"])},
        "dateTarget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de pointage"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturation"])},
        "GIGONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIGONE"])},
        "PreBilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préfacturation le "])},
        "EURO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€"])}
      },
      "billing": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturation"])},
        "statutDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut et date"])},
        "montantTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant total"])},
        "teasing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible à partir de la préfacturation"])}
      },
      "historic": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique"])},
        "the": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le"])},
        "statutDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut et date"])},
        "montantTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant total"])},
        "pointing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POINTAGE"])},
        "updatedCUNFAndGDN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MODIFICATIONS APPORTÉES AU CNUF/GDN"])},
        "discountAndFunding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REMISES ET FINANCEMENTS"])},
        "inovice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FACTURÉ"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LITIGE"])},
        "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir plus"])},
        "seeLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir moins"])},
        "displayHistoric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VOIR L'HISTORIQUE"])},
        "closeHistoric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FERMER L'HISTORIQUE"])}
      },
      "cugList": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des CUGs"])},
        "youCanEnterCugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez également saisir les CUGs un à un : "])},
        "cug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUG"])},
        "ean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EAN"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libellé"])},
        "market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marché"])},
        "gdn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GDN"])},
        "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe"])},
        "point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pointer"])},
        "message": {
          "cugNotPresentInTheList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUG rentré non présent dans la liste"])},
          "CugHasAlreadyWasPointed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le CUG a déjà été pointé"])}
        }
      },
      "dispute": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Litige"])},
        "disputeDeclared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclarer un litige"])},
        "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "proofToRedo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Justificatif à refaire"])},
        "statementToRedo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relevé à refaire"])},
        "disputeHandling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement du litige"])},
        "popup": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclarer le contrat en litige ?"])},
          "declareContractDispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de déclarer le contrat en litige ?"])},
          "declareBeginDateDispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour cela, vous devez déclarer la date du début du litige :"])}
        }
      },
      "comment": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
        "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "lastEditableComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seul le dernier commentaire est modifiable"])},
        "remainingCharacters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["caractère restants"])},
        "anomaly": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires anomalies"])},
          "allCommentAnomaly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les commentaires anomalies"])},
          "emptyCommentAnomaly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a pas de commentaire anomalie"])},
          "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créé le"])},
          "modified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifié le"])},
          "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ajoute un nouveau commentaire anomalie"])},
          "subCommentTypeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je choisis un commentaire type"])},
          "subCommentLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je choisis un supplément de commentaire"])},
          "subCommentType": {
            "OTHERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])},
            "REDO_CONTRACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrat à refaire"])},
            "CRP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CRP"])},
            "CONTRACT_OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok contrat"])},
            "MISSING_SKU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUG Manquant"])},
            "BAD_SKU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUG erroné"])},
            "OPE_COM_DATES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dates de tracts ou de thèmes"])},
            "SUPPLIER_SIGN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature fournisseur"])},
            "MECANIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mécanique"])},
            "CONSTRAINT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrainte"])},
            "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libellé"])},
            "FINANCING_RATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux de COFI"])},
            "SUPPLIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CNUF/ Nom fournisseur"])},
            "DISCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remise"])},
            "THEME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thème"])}
          }
        },
        "paymentSupplierLabel": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseur"])}
        },
        "cugAnomaly": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anomalie CUG"])}
        },
        "dispute": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Litiges"])},
          "characteristicsDispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caractéristiques du litige"])}
        },
        "supplier": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseur"])}
        }
      },
      "validation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation"])},
        "dateReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de réception"])},
        "infoAllChecked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez cocher tous les CUG et renseigner la date de réception du contrat afin de valider l’avantage."])},
        "point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pointer"])},
        "depoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépointer"])},
        "warningValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pointage est impossible car l'offre n'est pas en mandat"])},
        "message": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre Gigogne"])},
          "messageGigogne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'offre selectionné est une offre Gigogne, voulez-vous :"])},
          "gigogne": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pointer les 2 offres HYPER/SUPER (", _interpolate(_named("discountContractCode")), " + ", _interpolate(_named("discountContractCodeGigogne")), ")"])},
          "onlyOneOffer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pointer uniquement l’offre sélectionnée (", _interpolate(_named("discountContractCode")), ")"])}
        }
      }
    },
    "chicken": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Split poulet"])},
      "infoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traiter l'avantage poulet"])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter"])},
      "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer"])},
      "infoExport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export du fichier avec le n° d'avantage et le total des magasins. Fichier à alimenter avec les données fournisseurs correctes"])},
      "infoImport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permet le split fournisseur"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANNULER"])},
      "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])},
      "importSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le fichier a été importé avec succès"])},
      "importFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'import a été tenu en échec, veuillez vérifier votre fichier et rééssayer"])},
      "titleAnomaly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anomalies : fichier de rejet"])},
      "infoAnomaly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le fichier importé comporte des anomalies, cliquez sur \"exporter\" pour le télécharger"])}
    }
  },
  "exportBenefits": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export et import des avantages"])},
    "export": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter"])},
      "choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez : "])},
      "erase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer"])},
      "isMandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" est obligatoire"])},
      "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre fichier est en cours d'export"])},
      "fields": {
        "discountContractChannel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source de l'offre"])},
        "promotionBusinessUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BU"])},
        "promotionTypeCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'offre"])},
        "promotionPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période"])},
        "promotionPerimeter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Périmètre"])},
        "promotionTract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRACT"])}
      },
      "types": {
        "HYPER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyper"])},
        "SUPER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super"])},
        "CRP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CRP"])},
        "ALL_BU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les BUs"])},
        "ALIMENTAIRE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alimentaire"])},
        "NON-ALIMENTAIRE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non alimentaire"])},
        "BR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BR"])},
        "CAB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAB"])},
        "RI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RI"])},
        "AUCHAN_LUXEMBOURG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auchan Luxembourg"])},
        "CHRONODRIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chronodrive"])},
        "AUCHAN_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auchan.fr"])},
        "AUCHAN.FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auchan.fr"])},
        "AUCHAN_SENEGAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auchan Sénégal"])}
      },
      "status": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut Exporter"])},
        "PENDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EN COURS"])},
        "DONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TERMINÉ"])},
        "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERREUR"])}
      }
    },
    "import": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer"])},
      "importDataEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer des données vides"])},
      "differentFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier différent des paramètres choisis"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre fichier a été importé avec succès"])},
      "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre fichier est en cours d'import"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur c'est produite, veuillez réessayer"])},
      "fields": {
        "exampleOfField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field"])},
        "fileChoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir un fichier "])}
      },
      "status": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut Importer"])},
        "PENDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EN COURS"])},
        "ANOMALY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANOMALIE"])},
        "DONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TERMINÉ"])},
        "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERREUR"])}
      },
      "promotionSuccess": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import avantage réussi !"])},
        "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récapitulatif de mon import : "])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOTAL"])},
        "okay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D'ACCORD"])},
        "tab": {
          "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois"])},
          "perimeter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Périmètre"])},
          "siteNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° de site"])},
          "siteLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libellé de site"])},
          "amountCAG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant de Cagnotte"])},
          "amountRI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant RI"])},
          "amountBR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant BR"])},
          "dateAndHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date et Heures"])}
        }
      },
      "message": {
        "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de données présents dans le fichier."])},
        "ERROR_NB_COLUMN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre de colonne ne correspond pas à l'export"])}
      }
    },
    "fields": {
      "discountContractChannel": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source de l'offre"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une source"])}
      },
      "promotionBusinessUnit": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BU"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un BU"])}
      },
      "promotionTypeCode": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'offre"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un type d'offre"])}
      },
      "promotionPeriod": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une période"])}
      },
      "promotionPerimeter": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Périmètre"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un périmètre"])}
      },
      "promotionTract": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRACT"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un TRACT"])}
      }
    }
  },
  "loyaltyAmounts": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montants Fidélité"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche"])},
    "buttons": {
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
      "amountsWithoutTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les coûts hors taxe"])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter les données"])}
    },
    "inputs": {
      "discountContractCode": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° Avantage"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer un numéro"])}
      },
      "cug": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cug"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer un cug"])}
      },
      "ean": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ean"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer un ean"])}
      },
      "perimeter": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Périmètre"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un périmètre"])},
        "values": {
          "ALIMENTAIRE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alimentaire"])},
          "NON-ALIMENTAIRE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non Alimentaire"])}
        }
      },
      "orderSupplierCnuf": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CNUF de commande"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer un code CNUF"])}
      },
      "promotionTypeCode": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'offre"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un type d'offre"])},
        "values": {
          "RI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RI"])},
          "CAB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAB"])},
          "BR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BR"])}
        }
      },
      "orderSupplierDepartmentCode": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marché"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un marché"])}
      },
      "discountContractChannel": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source de l'offre"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une source"])},
        "values": {
          "HYPER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hypermarché"])},
          "SUPER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supermarché"])}
        }
      },
      "themeStartDate": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thème à partir de"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "period": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une période"])}
      },
      "commercialOperationLabel": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tract"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un TRACT"])}
      }
    },
    "errors": {
      "mandatoryFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir un N° d'avantage ou une période avec un marché ou le périmètre"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors de la recherche, veuillez réessayer"])}
    }
  },
  "billing": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturation"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour effectuer une recherche, vous devez soit : <br> - Renseigner le numéro de l’offre <br> - Renseigner le CNUF <br> - Renseigner une date de fin"])},
    "showWithoutTaxAmounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les coûts hors taxe"])},
    "checkEveryOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cocher toutes les offres"])},
    "moreDetailImportHisto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour plus de détail : voir l'historique import."])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réseau"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "buttons": {
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
      "generatePreInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer la préfacturation"])},
      "generateInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer la facturation"])},
      "generateJustificative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer le justificatif"])},
      "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RETOUR"])}
    },
    "filesToDownload": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichiers à télécharger"])},
      "noFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun fichier à télécharger"])},
      "types": {
        "preInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préfact"])},
        "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fact"])},
        "justificative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Justif fact"])},
        "compta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compta"])}
      }
    },
    "modal": {
      "justificativeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhaitez-vous générer un justificatif ?"])},
      "justificativeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir générer un justificatif déjà facturé ?"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Souhaitez-vous ", _interpolate(_named("type")), " l’avantage ?"])},
      "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Êtes-vous sûr de ", _interpolate(_named("type")), " le ou les avantages sélectionnés ? ", _interpolate(_named("nextSentence"))])},
      "billingSesame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La facturation sera transmise à Sésame et ne sera plus modifiable !"])}
    },
    "optionalColumns": {
      "orderSupplierCnuf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CNUF de commande"])},
      "orderSupplierNegoGroupLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GDN de commande"])},
      "orderSupplierLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libellé fournisseur de commande"])},
      "orderSupplierDepartmentCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marché"])}
    },
    "inputs": {
      "discountContractCode": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° Avantage"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer un numéro"])}
      },
      "perimeter": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Périmètre"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un périmètre"])},
        "values": {
          "ALIMENTAIRE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alimentaire"])},
          "NON-ALIMENTAIRE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non Alimentaire"])}
        }
      },
      "orderSupplierCnuf": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CNUF de commande"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer un code CNUF"])}
      },
      "promotionTypeCode": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'offre"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un type d'offre"])},
        "values": {
          "RI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RI"])},
          "CAB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAB"])},
          "BR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BR"])}
        }
      },
      "orderSupplierLabel": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseur de commande"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer un fournisseur"])}
      },
      "orderSupplierDepartmentCode": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marché"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un marché"])},
        "values": {
          "Market1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marché 1"])},
          "Market2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marché 2"])},
          "Market3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marché 3"])}
        }
      },
      "discountContractBillingDate": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de facturation"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "discountContractChannel": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source de l'offre"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une source"])},
        "values": {
          "HYPER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hypermarché"])},
          "SUPER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supermarché"])}
        }
      },
      "discountContractStartDate": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début de l'offre"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "discountContractStatusCode": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "values": {
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lancer la préfacturation"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lancer la facturation"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Génèrer un justif déjà facturé"])}
        }
      },
      "discountContractEndDate": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin de l'offre"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      }
    },
    "errors": {
      "errorDetected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur a été détectée !"])},
      "messageErrorDetected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas intégré les fichiers pour les associés pour :"])},
      "nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir au moins un N° Avantage ou un CNUF ou une Date de Fin d'Offre avec obligatoirement le périmètre, la source de l'offre et le statut."])},
      "nothingJustif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir au moins une Date de Facturation, le périmètre, la source de l'offre et le statut."])},
      "supplierSearchMissingField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir un code CNUF et/ou selectionner un type d'offre"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors de la recherche, veuillez réessayer."])},
      "generate-prefact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors de la génèration de la préfacturation, veuillez réessayer."])},
      "generate-facturation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors de la génèration de la facturation, veuillez réessayer."])},
      "generate-justificative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors de la génèration d'un justificatif déjà facturé, veuillez réessayer."])}
    }
  },
  "type": {
    "hyper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyper"])},
    "super": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super"])},
    "gigogne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gigogne"])}
  },
  "reporting": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapports"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour effectuer une recherche, vous devez : <br> - Renseigner une Date Début d'extraction <br > - Renseigner une Date Fin d'extraction <br> - Sélectionner un type de TDB"])},
    "fields": {
      "reportingStartDate": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début d'extraction"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une de début"])}
      },
      "reportingEndDate": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin d'extraction"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une Date de fin"])}
      },
      "reportingTableBoardType": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de tableau de bord"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un type de TDB"])}
      },
      "reportingYear": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une année"])}
      }
    },
    "errors": {
      "mandatoryFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir tout les champs"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors de la recherche, veuillez réessayer"])}
    },
    "buttons": {
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])}
    }
  },
  "etatOffre": {
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous"])},
    "pointes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandats pointés"])},
    "nonPointes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandats non pointés"])},
    "controller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandats à controler"])},
    "factures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandats facturés "])},
    "prefactures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandats préfacturés"])},
    "litigesEnCours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandats en litiges en cours"])}
  },
  "filters": {
    "discountContractChannel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal"])},
    "orderSupplierDepartmentCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marché"])},
    "orderSupplierCnuf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cnuf de commande"])},
    "orderSupplierLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom fournisseur de commande"])},
    "orderSupplierNegoGroupUid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GDN de commande"])},
    "promotionTypeCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type AV"])},
    "discountContractCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° Avantage"])},
    "commercialOperationLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom Tract"])},
    "commercialOperationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période Tract"])},
    "commercialOperationThemeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom thème"])},
    "discountContractFirstPartnerBillingTypeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode de fin 1"])},
    "discountContractSecondPartnerBillingTypeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode de fin 2"])},
    "paymentSupplierDepartmentCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rayon de paiement"])},
    "paymentSupplierCnuf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CNUF paiement"])},
    "paymentSupplierNegoGroupUid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GDN de paiement"])},
    "paymentSupplierLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom fournisseur paiement"])},
    "discountContractDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période avantage"])},
    "discountContractBillingDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période facturation"])},
    "discountContractTotalBillingAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant total facturation"])}
  },
  "notification": {
    "tooMuchData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["résultats trouvés mais 1000 résultats sont visibles. Relance une recherche pour affiner tes résultats !"])}
  },
  "statusOffer": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créé"])},
    "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pointé"])},
    "preBilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préfacturé"])},
    "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturé"])},
    "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Litige"])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactivé"])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimé"])}
  },
  "message": {
    "error": {
      "messageGlobal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue veuillez réessayer ultérieurement"])},
      "dataIncorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les données saisies sont incorrects"])}
    },
    "success": {
      "messageGlobal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès"])}
    },
    "openInNewTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir dans un nouvel onglet"])}
  },
  "inputFile": {
    "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcourir"])},
    "noFileSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun fichier sélectionné."])}
  },
  "dispute": {
    "declareDispute": {
      
    },
    "proofToRedo": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Litige - Justificatif à refaire"])},
      "proofOfTheOfferOnMarket": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Justificatif de l'offre ", _interpolate(_named("discountContractCode"))])}
    },
    "statementToRedo": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Litige - Relevé à refaire"])},
      "statementOfTheOfferOnMarket": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Relevé de l'offre ", _interpolate(_named("discountContractCode"))])}
    },
    "dataError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les données présentes dans les colonnes N°Avantage, Cug Libellé et N°Magasin Libellé ont été modifié. Elles ne doivent pas être modifié pour que l'import foncitonne."])},
    "lengthError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre de lignes des données importés ne correspondent pas au nombre de lignes du tableau"])},
    "globalError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors de la récupération des données, veuillez réessayer ultérieurement."])},
    "importMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seules les colonnes quantités en vert sont modifiables (format nombre entier). Les 3 premières colonnes en police rouge ne doivent surtout pas etre modifiées."])}
  }
}