<template>
  <Card>
    <template #header>
      <div class="header-title">
        <CardHeader :title="$t('exportBenefits.export.status.title')" />
      </div>
    </template>
    <template #body> 
      <div v-for="(file, i) in filesPromotionExport" :key="i" class="file">
        <div class="name">
          <span>{{ file.filename }}</span>
          <span class="date">{{ createAt(file.createdAt) }}</span>
        </div>
        <div class="status" :style="{ color: colorStatus(file.status) }">{{ $t('exportBenefits.import.status.'+file.status) }}</div>
        <div class="icon">
          <v-svg @click="downloadFile(file)" class="pointer" :name="iconName(file)" h="1.5rem" />
        </div>
      </div>

    </template>
  </Card>
</template>

<script>
import Card from '@/components/card/Card'
import CardHeader from '@/components/card/CardHeader'
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
import { extendWithAuthorization, httpNip } from '@/http'
import { saveAs } from 'file-saver'

export default {
  name: 'PromotionExportStatus',
  components: {
    Card,
    CardHeader
  },
  computed: {
    ...mapState('files', ['filesPromotionExport'])
  },
  async created() {
    await this.fetchFilesPromotionExport()
  },
  methods: {
    ...mapActions('files', ['fetchFilesPromotionExport']),
    createAt(date){
      return moment(date).format('DD/MM/YYYY à HH:mm')
    },
    async downloadFile(file) {
      if (file.status === 'DONE'){
        const extendedHttp = await extendWithAuthorization(httpNip)
        const { url } = await extendedHttp.get(`common/file-signed-url`, { searchParams: { path: file.filepath } }).json()
        saveAs(url, file.name)
      }
    },
    colorStatus(status){
      switch (status) {
        case 'DONE':
          return '#B1CC33'
        case 'PENDING':
          return '#F3914A'
        case 'ERROR':
          return '#BE1818'
      }
    },
    iconName(file){
      switch (file.status) {
        case 'DONE':
          return 'download'
        case 'PENDING':
          return 'spinner-blue'
        case 'ERROR':
          return 'error'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.file{
  display: flex;
  padding: 10px 19px;
  align-items: center;
  &:nth-child(even) {
    background: $veryLightGrey;
  }
  .name{
    display: flex;
    word-wrap: break-word;
    width: 70%;
    flex-direction: column;
    font-weight: bold;

    .date{
      margin-top: 5px;
      font-weight: normal;
    }
  }
  .status{
    width: 20%;
  }
  .status, .icon{
    margin: 0 10px;
    font-weight: bold;
    text-align: center;
  }

  .icon{
    color: $secondaryBlue;
    width: 10%;
  }
}
</style>
