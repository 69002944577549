<template>
  <div class="autocomplete" :style="{ width }">
    <div class="content">
      <div :class="['custom-select',{'border-bottom': isOpened}]" @click="test">
        <input
          ref="dropdown"
          v-model="search"
          :title="search"
          type="text"
          class="select-input"
          tabindex="-1"
          :placeholder=placeholder
          @input="onInput($event.target.value)"
          @blur="isOpened = false"
          @keyup.enter="select"
          @keydown.down="onDown"
          @keydown.up="onUp"
          @keyup.esc="isOpened = false"
        />
        <v-svg v-if="loading" name="spinner-blue" h="1.3rem" />
        <v-svg v-else name="arrow-up" h="1rem" :class="{ rotate: isOpened }"/>
      </div>
      <transition name="fade" mode="in-out">
        <ul v-if="isOpened" class="options-list">
          <li v-for="(option, i) in filteredItems" :title="option" :key="i" :class="{ selected: i === selected }" @mouseenter="selected = i" @mousedown="select">
            {{
              option
            }}
          </li>
        </ul>
      </transition>
    </div>
    <v-svg v-if="showEraser" name="erase" h="1.5rem" @click="search=''; $emit('inputValue', null)" />
  </div>
</template>

<script>
  export default {
    name: 'InputSelectAutoComplete',
    props: {
      value: {
        type: Object,
        default: null
      },
      width: {
        type: String,
        default: '100%'
      },
      loading: {
        type: Boolean,
        default: false
      },
      showEraser: {
        type: Boolean,
        default: false
      },
      placeholder:{
        type: String,
        default: ''
      },
      options: {
        type: Array,
        required: true
      }
    },
    emits: ['inputValue'],
    data() {
      return {
        isOpened: false,
        selected: null,
        search: ''
      }
    },
    watch: { 
      value: function() {
        if(!this.value) this.search = ''
      }
    },
    computed: {
      filteredItems() {
        const condition = new RegExp(this.search, 'i')
        return this.options.filter(item => item.match(condition) || (item + '').match(condition))
      }
    },
    methods: {
      test(){
        this.isOpened =!this.isOpened
      },
      onInput(value) {
        this.isOpened = !!value || value !== undefined
        if (!this.isOpened) {
          this.select()
        }
        this.selected = null
      },
      select() {
        const selectedOption = this.filteredItems[this.selected]
        if (selectedOption) {
          this.$emit('inputValue', selectedOption)
          this.search = selectedOption
          this.isOpened = false
          this.selected = null
        } else {
          this.isOpened = true
        }
      },
      onDown() {
        console.log('down')
        if (!this.isOpened) {
          return
        }
        this.selected = (this.selected + 1) % this.filteredItems.length
      },
      onUp() {
        if (!this.isOpened) {
          return
        }
        this.selected = this.selected - 1 < 0 ? this.filteredItems.length - 1 : this.selected - 1
      },
    },
  }
</script>

<style lang="scss" scoped>
  .autocomplete {
    display: flex;
    flex-direction: row;
    align-items: center;
    .content{
      flex: 1;
      position: relative;
      .custom-select{
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 28px;
        border: 0.5px solid $primaryBlue;
        padding: 5px 10px 5px 15px;
        border-radius: 10px;
        @extend %font-body;
        color: $darkGrey;
        font-size: 13px;
        cursor: pointer;
        user-select: none;
        justify-content: space-between;
        width: 100%;
        position: relative;
        &.border-bottom{
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
          border-bottom: 0;
        }
        .select-input{
          border: none;
          cursor: pointer;
          width: 100%;
          &:focus {
            outline-width: 0;
          }
          &::placeholder{
            color: $mediumGrey;
          }
        }
        .svg-arrow-up{
          color: $primaryBlue;
          margin-left: 0.5rem;
          &.rotate { transform: rotate(180deg); }
        }
      }
      ul.options-list {
        font-size: 13px;
        position: absolute;
        z-index: 15;
        list-style-type: none;
        border-right: 1px solid $primaryBlue;
        border-bottom: 1px solid $primaryBlue;
        border-left: 1px solid $primaryBlue;
        box-shadow: 0 15px 20px rgb(0 0 0 / 15%);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        width: 100%;
        max-height: 170px;
        overflow-y: auto;
        background-color: white;
        @extend %font-body;
        cursor: pointer;
        li{
          padding: 5px 5px 5px 15px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &:hover{ background-color: $secondaryBlue; }
          &.selected{ background-color: $secondaryBlue; }
        }
      }
    }
    .svg-erase{
      margin-top: 3px;
      margin-left: 0.2rem;
      cursor: pointer;
    }
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $primaryBlue;
  }
</style>
