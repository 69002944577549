<template>
  <div class="exportBenefits">
    <div class="exportBenefits-header">
      <TitleBar class="exportBenefits-title" :title="$t('exportBenefits.title')" :icon-name="'export'" />
    </div>
    <div class="exportBenefits-content">
      <import-calendar-history v-if="showImportCalendarHistory" @closeModalImportHistory="showImportCalendarHistory = false" />
      <div class="bloc">
        <Card>
          <template #header>
            <CardHeader :title="$t('exportBenefits.export.title')" />
          </template>
          <template #body>
            <div class="group-field">
              <div class="field">
                <label>{{ $t('exportBenefits.fields.discountContractChannel.label') }}</label>
                <input-select
                  v-model="exportSearch.discountContractChannel"
                  :values="exportSearch.discountContractChannels"
                  traduction-key="exportBenefits.export.types"
                  :placeholder="$t('exportBenefits.fields.discountContractChannel.placeholder')"
                  width="260px"
                  show-eraser
                />
              </div>
              <div class="field">
                <label>{{ $t('exportBenefits.fields.promotionBusinessUnit.label') }}</label>
                <input-select
                  v-model="exportSearch.promotionBusinessUnit"
                  :values="getBuByChannel"
                  traduction-key="exportBenefits.export.types"
                  :placeholder="$t('exportBenefits.fields.promotionBusinessUnit.placeholder')"
                  width="260px"
                  show-eraser
                />
              </div>
              <div class="field">
                <label>{{ $t('exportBenefits.fields.promotionTypeCode.label') }}</label>
                <input-select
                  v-model="exportSearch.promotionTypeCode"
                  :values="exportSearch.promotionTypeCodes"
                  traduction-key="exportBenefits.export.types"
                  :placeholder="$t('exportBenefits.fields.promotionTypeCode.placeholder')"
                  width="260px"
                  show-eraser
                />
              </div>
              <div v-if="exportSearch.promotionBusinessUnit?.[propertyId] === 'ALL_BU'" class="field">
                <label>{{ $t('exportBenefits.fields.promotionPeriod.label') }}</label>

                <div class="date-container">
                  <date-picker
                    v-model:value="date[index]"
                    type="date"
                    :range="true"
                    format="DD/MM/YYYY"
                    popup-class="mx-popup"
                    class="mx-inputs"
                    value-type="format"
                    :placeholder="$t('exportBenefits.fields.promotionPeriod.placeholder')"
                    langString="fr"
                    width="200px"
                    @change="periodChange($event, exportSearch)"
                  ></date-picker>
                </div>
              </div>
              <div
                v-if="
                  exportSearch.promotionBusinessUnit &&
                  exportSearch.promotionBusinessUnit?.[propertyId] != 'ALL_BU' &&
                  exportSearch.discountContractChannel?.[propertyId] === 'HYPER'
                "
                class="field"
              >
                <label>{{ $t('exportBenefits.fields.promotionPeriod.label') }}</label>
                <input-select
                  v-model="exportSearch.promotionPeriod"
                  :values="getMonths"
                  :placeholder="$t('exportBenefits.fields.promotionPeriod.placeholder')"
                  width="260px"
                  show-eraser
                />
              </div>
              <div
                v-if="
                  exportSearch?.promotionBusinessUnit?.[propertyId] != 'ALL_BU' &&
                  exportSearch.discountContractChannel?.[propertyId] === 'SUPER'
                "
                class="field"
              >
                <label>{{ $t('exportBenefits.fields.promotionTract.label') }}</label>
                <input-select-auto-complete
                  :value="exportSearch.promotionTract"
                  :options="getFilteredTracts(exportSearch.promotionTractPeriod).map((tract) => tract.id)"
                  :loading="isLoadTracts"
                  :placeholder="$t('exportBenefits.fields.promotionTract.placeholder')"
                  width="260px"
                  show-eraser
                  @input-value="exportSearch.promotionTract = tracts.find((tract) => tract.id === $event)"
                />
              </div>
              <div
                v-if="
                  exportSearch?.promotionBusinessUnit?.[propertyId] != 'ALL_BU' &&
                  exportSearch.discountContractChannel?.[propertyId] === 'SUPER'
                "
                class="field"
              >
                <label>{{ $t('exportBenefits.fields.promotionPeriod.label') }}</label>
                <input-select
                  v-model="exportSearch.promotionTractPeriod"
                  :values="getFilteredTracts(exportSearch.promotionTract, true, true)"
                  :placeholder="$t('exportBenefits.fields.promotionPeriod.placeholder')"
                  display-key="value"
                  :loading="isLoadTracts"
                  width="260px"
                  show-eraser
                />
              </div>
              <div class="field">
                <label>{{ $t('exportBenefits.fields.promotionPerimeter.label') }}</label>
                <input-select
                  v-model="exportSearch.promotionPerimeter"
                  :values="exportSearch.promotionPerimeters"
                  traduction-key="exportBenefits.export.types"
                  :placeholder="$t('exportBenefits.fields.promotionPerimeter.placeholder')"
                  width="260px"
                  show-eraser
                />
              </div>
            </div>
          </template>
          <template #footer>
            <div class="card-footer">
              <v-button :color="'primary'" :active="true" :label="$t('exportBenefits.export.title')" @click="exportData()" />
            </div>
          </template>
        </Card>
        <Card>
          <template #header>
            <div class="header-title">
              <CardHeader :title="$t('exportBenefits.import.title')" />
              <v-svg name="calendar" h="1.6rem" @click="showImportCalendarHistory = true" />
            </div>
          </template>
          <template #body>
            <div class="group-field">
              <div class="group-field">
                <div class="field">
                  <label>{{ $t('exportBenefits.fields.discountContractChannel.label') }}</label>
                  <input-select
                    v-model="importSearch.discountContractChannel"
                    :values="importSearch.discountContractChannels"
                    traduction-key="exportBenefits.export.types"
                    :placeholder="$t('exportBenefits.fields.discountContractChannel.placeholder')"
                    width="260px"
                    show-eraser
                  />
                </div>
                <div class="field">
                  <label>{{ $t('exportBenefits.fields.promotionBusinessUnit.label') }}</label>
                  <input-select
                    v-model="importSearch.promotionBusinessUnit"
                    :values="getBuByChannelImport"
                    traduction-key="exportBenefits.export.types"
                    :placeholder="$t('exportBenefits.fields.promotionTypeCode.placeholder')"
                    width="260px"
                    show-eraser
                  />
                </div>
                <div
                  v-if="
                    importSearch.promotionBusinessUnit?.[propertyId] === 'ALL_BU' &&
                    importSearch.discountContractChannel?.[propertyId] === 'HYPER'
                  "
                  class="field"
                >
                  <label>{{ $t('exportBenefits.fields.promotionPeriod.label') }}</label>

                  <div class="date-container">
                    <date-picker
                      v-model:value="date[index]"
                      type="date"
                      :range="true"
                      popup-class="mx-popup"
                      format="DD/MM/YYYY"
                      class="mx-inputs"
                      value-type="format"
                      :placeholder="$t('exportBenefits.fields.promotionPeriod.placeholder')"
                      langString="fr"
                      width="200px"
                      @change="periodChange($event, importSearch)"
                    ></date-picker>
                  </div>
                </div>
                <div
                  v-if="
                    importSearch.promotionBusinessUnit &&
                    importSearch.promotionBusinessUnit?.[propertyId] != 'ALL_BU' &&
                    importSearch.discountContractChannel?.[propertyId] === 'HYPER'
                  "
                  class="field"
                >
                  <label>{{ $t('exportBenefits.fields.promotionPeriod.label') }}</label>
                  <input-select
                    v-model="importSearch.promotionPeriod"
                    :values="getMonths"
                    :placeholder="$t('exportBenefits.fields.promotionPeriod.placeholder')"
                    width="260px"
                    show-eraser
                  />
                </div>
                <div v-if="importSearch.discountContractChannel?.[propertyId] === 'SUPER'" class="field">
                  <label>{{ $t('exportBenefits.fields.promotionTract.label') }}</label>
                  <input-select-auto-complete
                    :value="importSearch.promotionTract"
                    :options="getFilteredTracts(importSearch.promotionTractPeriod).map((tract) => tract.id)"
                    :loading="isLoadTracts"
                    :placeholder="$t('exportBenefits.fields.promotionTract.placeholder')"
                    width="260px"
                    show-eraser
                    @input-value="importSearch.promotionTract = tracts.find((tract) => tract.id === $event)"
                  />
                </div>
                <div v-if="importSearch.discountContractChannel?.[propertyId] === 'SUPER'" class="field">
                  <label>{{ $t('exportBenefits.fields.promotionPeriod.label') }}</label>
                  <input-select
                    v-model="importSearch.promotionTractPeriod"
                    :values="getFilteredTracts(importSearch.promotionTract, true)"
                    :placeholder="$t('exportBenefits.fields.promotionPeriod.placeholder')"
                    display-key="value"
                    :loading="isLoadTracts"
                    width="260px"
                    show-eraser
                  />
                </div>
                <div class="field">
                  <label>{{ $t('exportBenefits.fields.promotionPerimeter.label') }}</label>
                  <input-select
                    v-model="importSearch.promotionPerimeter"
                    :values="importSearch.promotionPerimeters"
                    traduction-key="exportBenefits.export.types"
                    :placeholder="$t('exportBenefits.fields.promotionPerimeter.placeholder')"
                    width="260px"
                    show-eraser
                  />
                </div>
              </div>
              <div class="field">
                <label>{{ $t('exportBenefits.import.fields.fileChoice') }}</label>
                <input-file :file-name="file ? file.name : null" @file-updated="file = $event" />
              </div>
            </div>
          </template>
          <template #footer>
            <div class="card-footer">
              <v-button
                :color="'primary'"
                :active="true"
                :label="$t('exportBenefits.import.title')"
                @click="checkErrors(importSearch) ? $toast.error(checkErrors(importSearch)) : importData()"
              />
              <v-button
                v-if="!checkErrors(importSearch)"
                :color="'secondary'"
                :active="true"
                :label="$t('exportBenefits.import.importDataEmpty')"
                @click="importData(true)"
              />
            </div>
          </template>
        </Card>
      </div>
      <div v-show="filesPromotionExport.length || filesPromotionImport.length" class="bloc">
        <promotion-export-status />
        <promotion-import-status @selectFilePromotionImport="$refs.summary.show($event.amount)" />
      </div>
    </div>
  </div>
  <promotion-import-summary ref="summary" />
</template>

<script>
  import PromotionExportStatus from '@/components/exportBenefits/PromotionExportStatus'
  import PromotionImportStatus from '@/components/exportBenefits/PromotionImportStatus'
  import PromotionImportSummary from '@/components/exportBenefits/PromotionImportSummary'
  import Card from '@/components/card/Card'
  import CardHeader from '@/components/card/CardHeader'
  import ImportCalendarHistory from '@/components/exportBenefits/ImportCalendarHistory'
  import { DateTime } from 'luxon'
  import { channels, listBUExport, listBUImport, promotionPerimeters, promotionTypes } from '../utils/CONF'
  import { mapActions, mapState } from 'vuex'
  import { extendWithAuthorization, httpNip } from '@/http'
  import moment from 'moment'
  import { globalProperties } from '@/main'
  import DatePicker from 'vue-datepicker-next'
  import 'vue-datepicker-next/index.css'
  import 'vue-datepicker-next/locale/fr'

  const rangeOfMonth = 24

  const initData = {
    discountContractChannel: null,
    discountContractChannels: channels,
    promotionBusinessUnit: null,
    promotionBusinessUnits: listBUExport,
    promotionTypeCode: null,
    promotionTypeCodes: promotionTypes,
    promotionPeriod: null,
    promotionPeriods: [],
    promotionPerimeter: null,
    promotionPerimeters: promotionPerimeters,
    promotionTractPeriod: null,
    promotionTractPeriods: [],
    promotionTract: null,
    promotionTracts: [],
    selectedStartDate: null,
    selectedEndDate: null,
  }

  export default {
    name: 'ExportBenefits',
    components: {
      Card,
      CardHeader,
      ImportCalendarHistory,
      PromotionImportStatus,
      PromotionExportStatus,
      PromotionImportSummary,
      DatePicker,
    },
    data() {
      return {
        selected: '',
        exportSearch: { ...initData },
        importSearch: { ...initData },
        startDate: null,
        endDate: null,
        showImportCalendarHistory: false,
        isLoadTracts: true,
        propertyId: 'id',
        propertyChannel: 'channel',
        file: null,
        date: [],
      }
    },

    computed: {
      ...mapState('referential', ['tracts']),
      ...mapState('files', ['filesPromotionExport', 'filesPromotionImport']),
      getMonths() {
        let givenDateTime = new Date()
        let currentDate = new Date()
        givenDateTime = givenDateTime.setMonth(givenDateTime.getMonth() - rangeOfMonth)
        let createdDate = new Date(givenDateTime)
        createdDate.setDate(1)
        let dateAndYearList = [
          {
            id: createdDate.toLocaleString('fr', { month: 'long', year: 'numeric' }),
            value: new Date(createdDate),
          },
        ]
        while (createdDate.setMonth(createdDate.getMonth() + 1) < currentDate) {
          dateAndYearList.unshift({
            id: createdDate.toLocaleString('fr', { month: 'long', year: 'numeric' }),
            value: new Date(createdDate),
          })
        }
        return dateAndYearList
      },

      getBuByChannel() {
        return this.exportSearch.discountContractChannel
          ? listBUExport.filter(
              (bu) => bu.channel === this.exportSearch.discountContractChannel[this.propertyId] || bu.channel === 'ALL'
            )
          : listBUExport
      },

      getBuByChannelImport() {
        return this.importSearch.discountContractChannel
          ? listBUImport.filter((bu) => bu.channel === this.importSearch.discountContractChannel[this.propertyId])
          : listBUImport
      },
    },

    watch: {
      'exportSearch.discountContractChannel'() {
        this.exportSearch.promotionBusinessUnit = null
        if (this.exportSearch.discountContractChannel?.[this.propertyId] === 'SUPER') this.loadTracts()
      },

      'importSearch.discountContractChannel'() {
        this.importSearch.promotionBusinessUnit = null
        if (this.importSearch.discountContractChannel?.[this.propertyId] === 'SUPER') this.loadTracts()
      },
    },

    methods: {
      ...mapActions('referential', ['fetchTracts']),

      async exportData() {
        let getError = this.checkErrors(this.exportSearch)
        if (getError) this.$toast.error(getError)
        else {
          const searchParams = this.createSearchParams(this.exportSearch)
          const fileName = this.createFileName(searchParams)
          searchParams.filename = fileName
          const extendedHttp = await extendWithAuthorization(httpNip)
          extendedHttp
            .get(`promotion/export`, { searchParams })
            .json()
            .catch(() => this.$toast.error(this.$t('message.error.messageGlobal')))
          this.$toast.success(this.$t('exportBenefits.export.pending'))
          this.exportSearch = { ...initData }
        }
      },

      async importData(isEmpty = false) {
        const searchParams = this.createSearchParams(this.importSearch)

        searchParams.startDate = moment(searchParams.startDate).format('YYMMDD')
        searchParams.endDate = moment(searchParams.endDate).format('YYMMDD')

        let isGoodFileName = true
        for (const property in searchParams) {
          if (property === 'discountContractChannel') continue
          if (!(this.file && this.file.name.includes('_' + searchParams[property]))) {
            isGoodFileName = false
            break
          }
        }

        if (!isGoodFileName && !isEmpty) {
          this.$toast.error(this.$t('exportBenefits.import.differentFile'))
        } else {
          const integrationKey =
            searchParams.discountContractChannel === 'HYPER'
              ? moment(searchParams.startDate, 'YYMMDD').format('YYYYMM')
              : `${moment(searchParams.startDate, 'YYMMDD').format('YYYYMMDD')}_${moment(searchParams.endDate, 'YYMMDD').format(
                  'YYYYMMDD'
                )}_${searchParams.promotionTract}`
          const params = `importUser=${globalProperties?.user?.uid}&perimeter=${searchParams.promotionPerimeter}&businessUnit=${searchParams.promotionBusinessUnit}&integrationKey=${integrationKey}&isDataEmpty=${isEmpty}`
          const formData = new FormData()
          formData.append('file', this.file)
          const extendedHttp = await extendWithAuthorization(httpNip)
          extendedHttp
            .post(`promotion/import?${params}`, { body: formData })
            .json()
            .catch(() => this.$toast.error(this.$t('exportBenefits.import.error')))
          this.$toast.success(this.$t('exportBenefits.import.pending'))
          this.importSearch = { ...initData }
          this.file = null
          document.getElementById('file-input').value = ''
        }
      },

      periodChange(event, search) {
        this.startDate = event[0] ? DateTime.fromFormat(event[0], 'dd/MM/yyyy') : null
        this.endDate = event[1] ? DateTime.fromFormat(event[1], 'dd/MM/yyyy') : null
        search.selectedStartDate = event[0] ? DateTime.fromFormat(event[0], 'dd/MM/yyyy').toFormat('dd/MM/yyyy') : null
        search.selectedEndDate = event[1] ? DateTime.fromFormat(event[1], 'dd/MM/yyyy').toFormat('dd/MM/yyyy') : null
      },

      async loadTracts() {
        this.isLoadTracts = true
        await this.fetchTracts()
        this.isLoadTracts = false
      },

      getFilteredTracts(paramTract, isTract = false, isExport = false) {
        if (paramTract) {
          let listTracts = this.tracts.filter((tract) => tract[this.propertyId] === paramTract[this.propertyId])
          if (isTract && listTracts.length === 1) {
            isExport
              ? (this.exportSearch.promotionTractPeriod = paramTract)
              : (this.importSearch.promotionTractPeriod = paramTract)
          }
          return listTracts
        }
        return this.tracts
      },

      checkErrors(search) {
        if (!search.discountContractChannel)
          return this.$t('exportBenefits.fields.discountContractChannel.label') + this.$t('exportBenefits.export.isMandatory')

        if (!search.promotionBusinessUnit)
          return this.$t('exportBenefits.fields.promotionBusinessUnit.label') + this.$t('exportBenefits.export.isMandatory')

        if (search.promotionBusinessUnit[this.propertyId] === 'ALL_BU' && (!search.selectedStartDate || !search.selectedEndDate))
          return this.$t('exportBenefits.fields.promotionPeriod.label') + this.$t('exportBenefits.export.isMandatory')

        if (
          search.discountContractChannel[this.propertyId] === 'HYPER' &&
          search.promotionBusinessUnit[this.propertyId] !== 'ALL_BU' &&
          !search.promotionPeriod
        )
          return this.$t('exportBenefits.fields.promotionPeriod.label') + this.$t('exportBenefits.export.isMandatory')

        if (
          search.discountContractChannel[this.propertyId] === 'SUPER' &&
          search.promotionBusinessUnit[this.propertyId] !== 'ALL_BU'
        ) {
          if (!search.promotionTractPeriod)
            return this.$t('exportBenefits.fields.promotionPeriod.label') + this.$t('exportBenefits.export.isMandatory')
          else if (!search.promotionTract)
            return this.$t('exportBenefits.fields.promotionTract.label') + this.$t('exportBenefits.export.isMandatory')
        }
        if (
          search.discountContractChannel[this.propertyId] === 'HYPER' &&
          search.promotionBusinessUnit[this.propertyId] !== 'ALL_BU' &&
          !search.promotionPerimeter
        )
          return this.$t('exportBenefits.fields.promotionPerimeter.label') + this.$t('exportBenefits.export.isMandatory')

        return false
      },

      createSearchParams(search) {
        let searchParams = {
          discountContractChannel: search.discountContractChannel.id,
          promotionBusinessUnit: search.promotionBusinessUnit.name,
          promotionPerimeter: search.promotionPerimeter ? search.promotionPerimeter.id : null,
          promotionTypeCode: search.promotionTypeCode ? search.promotionTypeCode.id : null,
          promotionTract: search.promotionTract ? search.promotionTract.id : null,
        }
        if (search.promotionBusinessUnit.id === 'ALL_BU') {
          searchParams.startDate = moment(search.selectedStartDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
          searchParams.endDate = moment(search.selectedEndDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        } else if (search.discountContractChannel.id === 'SUPER') {
          searchParams.startDate = moment(search.promotionTractPeriod.value.split(' - ')[0], 'DD/MM/YYYY').format('YYYY-MM-DD')
          searchParams.endDate = moment(search.promotionTractPeriod.value.split(' - ')[1], 'DD/MM/YYYY').format('YYYY-MM-DD')
        } else {
          searchParams.endDate = moment(search.promotionPeriod.value).endOf('month').format('YYYY-MM-DD')
          searchParams.startDate = moment(search.promotionPeriod.value).startOf('month').format('YYYY-MM-DD')
        }

        console.log('search params', searchParams.endDate, searchParams.startDate)

        //supprime les valeurs null
        return Object.keys(searchParams)
          .filter((key) => searchParams[key])
          .reduce((obj, key) => {
            obj[key] = searchParams[key]
            return obj
          }, {})
      },

      createFileName(params) {
        let fileName = `${DateTime.local().toFormat('yyMMdd')}_av`

        params.promotionBusinessUnit === 'ALL_BU' ? (fileName += `_${params.discountContractChannel}`) : ''
        params.discountContractChannel === 'SUPER' && params.promotionBusinessUnit !== 'SUPER'
          ? (fileName += `_${params.promotionTract}`)
          : ''
        fileName += `_${moment(params.startDate).format('YYMMDD')}_au_${moment(params.endDate).format('YYMMDD')}_${
          params.promotionBusinessUnit
        }`
        params.promotionTypeCode ? (fileName += `_${params.promotionTypeCode}`) : ''
        params.promotionPerimeter ? (fileName += `_${params.promotionPerimeter}`) : ''
        fileName += `.xlsx`
        return fileName
      },
    },
  }
</script>

<style lang="scss" scoped>
  .exportBenefits {
    padding: 20px 42px;
    &-info {
      font-size: 16px;
      line-height: 24px;
      color: $darkGrey;
      &-num {
        font-size: 18px;
        font-weight: bold;
      }
    }
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-title {
      font-weight: bold;
      font-size: 18px;
      margin: 6px 20px 10px 22px;
    }
    &-content {
      margin-top: 18px;
    }

    &-content {
      margin-top: 18px;
    }

    & .bloc {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      & .card {
        width: 48%;
      }
      & .card-subtitle {
        font-size: 12px;
        line-height: 18px;
        text-transform: uppercase;
        color: $primaryOrange;
        padding: 10px 19px 0;
        font-weight: bold;
      }
      & ul.group {
        background-color: $veryLightGrey;
        border-radius: 15px;
      }
      & ul li {
        min-height: 30px;
        display: flex;
        align-items: center;
        padding: 0 19px;
        & div {
          width: 100%;
          min-height: 30px;
        }

        &:nth-child(2n-1) {
          background-color: $veryLightGrey;
          border-radius: 15px;
        }
        & .title {
          flex: 1;
          font-weight: bold;
          text-transform: uppercase;
          margin-right: 10px;
        }
        & .type {
          color: $mediumGrey;
        }
        & span:last-child {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          line-height: 20px;
          & span {
            font-size: 9px;
            line-height: 14px;
            color: $mediumGrey;
            float: right;
          }
        }
      }
      .fa {
        background-color: $white;
      }
    }
    & .error {
      text-align: center;
      font-weight: bold;
      color: $red;
    }
  }
  .card {
    &-footer {
      justify-content: center;
      display: flex;
      padding: 20px 20px 20px;
      & button {
        &:first-child {
          margin-right: 10px;
        }
        font-size: 14px;
        line-height: 21px;
        padding: 8px;
        border-radius: 8px;
        text-transform: uppercase;
        font-weight: bold;
      }
    }
    .header-title {
      @include flex(center, space-between);
      margin-right: 19px;
      .v-svg {
        color: $primaryOrange;
        cursor: pointer;
      }
    }
  }
  select {
    border: 0.5px solid $primaryBlue;
    border-radius: 10px;
  }
  .group-field {
    position: static;
    & .field {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 22px 19px 0;

      & label {
        width: 35%;
      }

      & select {
        border: 0.5px solid $primaryBlue;
        border-radius: 10px;
        width: 40%;
        height: 28px;
        padding-left: 10px;

        &::placeholder {
          color: $mediumGrey;
        }
      }
    }
  }

  .date-container {
    display: flex;
    width: 230px;
  }

  .mx-inputs::v-deep input {
    border: 0.5px solid #5fbfc6;
    border-radius: 10px;
    height: 28px;
  }
</style>
