<template>
  <div class="billing">
    <div class="header">
      <div class="billing-title">
        <v-svg name="invoice" h="1.2rem" />
        {{ $t('billing.title') }}
      </div>
      <v-svg name="question-circle" h="3rem" @mouseover="showInfoPopup = true" @mouseleave="showInfoPopup = false" />
      <info-popup v-if="showInfoPopup" :msg="$t('billing.info')" />
    </div>
    <billing-search ref="billingSearch" @search="launchSearch" />
    <loading v-if="loading" :is-loading="true" />
    <template v-if="search && !loading">
      <div class="row">
        <div class="filters-export">
          <div class="filters">
            <div class="radio-button" @click="showWithoutTaxAmounts = !showWithoutTaxAmounts">
              <v-svg :name="showWithoutTaxAmounts ? 'checked' : 'circle-empty'" h="1.5rem" />
              <div class="radio-label">{{ $t('billing.showWithoutTaxAmounts') }}</div>
            </div>
            <div class="sep" />
            <div v-if="search?.discountContractStatusCode?.id != 3" class="radio-button" @click="checkEveryOffers">
              <v-svg :name="allOffersChecked ? 'checked' : 'circle-empty'" h="1.5rem" />
              <div class="radio-label">{{ $t('billing.checkEveryOffers') }}</div>
            </div>
          </div>
          <div class="exports">
            <v-button
              v-if="checkedOffers.length && search?.discountContractStatusCode?.id === 2"
              :label="$t('billing.buttons.generatePreInvoice')"
              active
              @click="generatePrefactFact({ type: 'prefact' })"
            />
            <v-button
              v-if="checkedOffers.length && search?.discountContractStatusCode?.id === 6"
              :label="$t('billing.buttons.generateInvoice')"
              active
              @click="generatePrefactFact({ type: 'facturation' })"
            />
            <v-button
              v-if="
                checkedOffers.length &&
                (search?.discountContractStatusCode?.id === 3 || Number($route.query.discountContractStatusCode) === 3)
              "
              :label="$t('billing.buttons.generateJustificative')"
              active
              @click="generatePrefactFact({ type: 'justificative' })"
            />
          </div>
        </div>
        <div>
          <div class="totals">
            <div class="all">
              <div class="label">Montant total des offres :</div>
              <div class="nb">{{ checkedOffersTotals.all.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' }) }}</div>
            </div>
          </div>
          <div v-if="search?.discountContractStatusCode?.id != 3" class="totals-billed">
            <div class="checked">
              <div class="label">Montant à facturer :</div>
              <div class="nb">{{
                checkedOffersTotals.checked.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })
              }}</div>
            </div>
            <div class="sub-totals">
              <div v-for="(year, i) of years" :key="i" class="year">
                <div class="label">{{ `${year}:` }}</div>
                <div class="nb">{{
                  checkedOffersTotals[year].toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })
                }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <billing-array
        :rows="rows"
        :checked-offers="checkedOffers"
        :amount-key="amountKey"
        :is-justif="search?.discountContractStatusCode?.id === 3 || Number($route.query.discountContractStatusCode) === 3"
        @offer-clicked="onOfferClicked"
      />
    </template>
  </div>
  <modal v-if="showErrorPopup" class="import-recap">
    <template #header>
      <div class="header">
        <div class="header-title">
          <v-svg name="error-detected" :h="'6rem'" />
          <span>{{ $t('billing.errors.errorDetected') }}</span>
        </div>
        <v-svg class="pointer" :name="'close'" :h="'1rem'" @click="showErrorPopup = !showErrorPopup" />
      </div>
    </template>
    <template #body>
      <div class="content">
        <p>{{ $t('billing.errors.messageErrorDetected') }}</p>
        {{ $t('billing.type') }} : <span v-for="(type, i) of errorsTypes" :key="i">{{ type.businessUnit + ', ' }}</span>
        <p>{{ $t('billing.moreDetailImportHisto') }}</p>
      </div>
    </template>
    <template #footer>
      <div class="footer-button">
        <v-button
          color="primary"
          :active="true"
          size="medium"
          :label="$t('billing.buttons.return')"
          @click="showErrorPopup = !showErrorPopup"
        />
      </div>
    </template>
  </modal>
  <confirm-dialog ref="confirmDialog" />
</template>

<script>
  import BillingArray from '@/components/billing/BillingArray'
  import BillingSearch from '@/components/billing/BillingSearch'
  import Modal from '@/components/Modal'
  import { extendWithAuthorization, httpNip } from '@/http'
  import { globalProperties } from '@/main'

  export default {
    name: 'Billing',
    components: { BillingArray, BillingSearch, Modal },
    data() {
      return {
        showWithoutTaxAmounts: false,
        allOffersChecked: false,
        rows: [],
        checkedOffers: [],
        loading: false,
        search: undefined,
        showInfoPopup: false,
        showErrorPopup: false,
        errorsTypes: [],
      }
    },
    computed: {
      checkableOffers() {
        return this.search?.discountContractStatusCode?.id === 3 || Number(this.$route.query.discountContractStatusCode) === 3
          ? this.rows.slice()
          : this.rows.slice().filter((row) => row.isPaymentSupplierExists)
      },

      amountKey() {
        return this.showWithoutTaxAmounts ? 'benefitAmountWithoutTax' : 'benefitAmountWithTax'
      },

      years() {
        return [...new Set(this.checkableOffers.map((x) => x.discountContractYear))].sort()
      },

      checkedOffersTotals() {
        const formattedYears = this.years?.reduce((a, v) => ({ ...a, [v]: 0 }), {})
        return this.checkableOffers?.reduce(
          (acc, offer) => {
            if (this.checkedOffers?.includes(offer.discountContractCode)) {
              acc.checked += offer?.[this.amountKey]
              acc[offer.discountContractYear] += offer?.[this.amountKey]
            }
            acc.all += offer?.[this.amountKey]
            return acc
          },
          { all: 0, checked: 0, ...formattedYears }
        )
      },
    },
    created() {
      if (
        this.$route.query.discountContractCode &&
        this.$route.query.discountContractStatusCode &&
        this.$route.query.perimeter &&
        this.$route.query.discountContractChannel
      ) {
        const params = {
          discountContractCode: this.$route.query.discountContractCode,
          discountContractStatusCode: { id: this.$route.query.discountContractStatusCode },
          orderSupplierDepartmentCode: null,
          perimeter: { id: this.$route.query.perimeter },
          discountContractChannel: { id: this.$route.query.discountContractChannel },
          promotionTypeCode: null,
        }
        this.launchSearch(params)
      }
    },
    mounted() {
      history.pushState(null, null, document.URL)
      let _this = this
      window.addEventListener('popstate', function (event) {
        if (event?.state?.back?.includes('dispute')) history.pushState(null, null, document.URL)
      })
    },
    methods: {
      async launchSearch(params) {
        this.allOffersChecked = false
        this.loading = true

        const searchParams = {
          ...params,
          discountContractStatusCode: params.discountContractStatusCode?.id,
          orderSupplierDepartmentCode: params.orderSupplierDepartmentCode?.orderSupplierDepartmentCode,
          perimeter: params.perimeter?.id,
          discountContractChannel: params.discountContractChannel?.id,
          promotionTypeCode: params.promotionTypeCode?.id,
        }

        Object.keys(searchParams).forEach((key) => searchParams[key] ?? delete searchParams[key])

        try {
          const extendedHttp = await extendWithAuthorization(httpNip)
          const dataSearch = await extendedHttp.get('billing/search', { searchParams }).json()
          if (dataSearch.error.length) {
            this.errorsTypes = dataSearch.error
            this.showErrorPopup = true
          } else {
            this.rows = dataSearch.data
            this.search = params
            this.checkEveryOffers()
          }
        } catch (err) {
          this.rows = []
          console.error(err)
          this.$toast.error(this.$t('billing.errors.search'))
        }
        this.loading = false
      },

      onOfferClicked(discountContractCode) {
        if (this.checkedOffers.includes(discountContractCode)) {
          this.allOffersChecked = false
          return (this.checkedOffers = this.checkedOffers.filter((offer) => offer !== discountContractCode))
        } else {
          this.checkedOffers.push(discountContractCode)
          if (this.checkedOffers?.length === this.checkableOffersCount?.length) this.allOffersChecked = true
        }
      },

      checkEveryOffers() {
        if(this.allOffersChecked){
          this.checkedOffers = []
        }else{
          this.checkedOffers = this.checkableOffers.map((row) => row.discountContractCode)
        }
        this.allOffersChecked = !this.allOffersChecked
      },

      async generatePrefactFact({ type }) {
        let confirmGeneration = false
        if(type === 'justificative'  && !this.$route.query.fromDispute ){
          confirmGeneration = await this.$refs.confirmDialog.show({  title: this.$t('billing.modal.justificativeTitle'),
          message: this.$t('billing.modal.justificativeMessage'),
         })
        }else{
            const prefactText = type === 'prefact' && !this.$route.query.fromDispute ? 'préfacturer' : 'facturer'

          confirmGeneration = await this.$refs.confirmDialog.show({ title: this.$t('billing.modal.title', { type: prefactText }), message: this.$t('billing.modal.message', {
            type: prefactText,
            nextSentence: type === 'prefact' && !this.$route.query.fromDispute ? '' : this.$t('billing.modal.billingSesame'),
          })
         })
        }
        if (!confirmGeneration) return



        const json = {
          discountContractChannel: this.search?.discountContractChannel?.id,
          perimeter: this.search?.perimeter?.id,
          discountContractEndDate: this.search?.discountContractEndDate,
          orderSupplierCnuf: this.search?.orderSupplierCnuf,
          discountContractStatusCode: this.search?.discountContractStatusCode?.id,
          discountContractCode: this.checkedOffers,
          userCode: globalProperties?.user?.uid,
        }

        Object.keys(json).forEach((key) => json[key] ?? delete json[key])

        try {
          this.rows = []
          this.$refs.billingSearch.reset()
          this.search = null
          const extendedHttp = await extendWithAuthorization(httpNip)
          extendedHttp.post(`billing/generate-${this.$route.query.fromDispute ? 'facturation' : type}`, { json }).json()
        } catch (err) {
          console.error(err)
          this.$toast.error(this.$t(`billing.errors.generate-${type}`))
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .billing {
    padding: 2rem;
    position: relative;

    .header {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 1rem;

      .billing-title {
        padding-bottom: 0.5rem;
        border-bottom: 4px solid $primaryOrange;
        display: flex;
        @extend %font-title-1;

        .svg-invoice {
          margin-right: 0.8rem;
        }
      }

      .svg-question-circle {
        cursor: pointer;
      }

      .info-popup {
        position: absolute;
        top: 3rem;
        right: 1rem;
      }
    }

    .billing-search {
      margin-top: 1rem;
    }

    .row {
      margin: 2rem 0;
      display: grid;
      grid-template-columns: 2fr 1fr;
      column-gap: 2rem;

      .filters-export {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
        border-radius: 15px;

        .filters {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 1rem 0;

          .radio-button {
            display: flex;
            align-items: center;
            cursor: pointer;
            user-select: none;
            padding: 0.5rem 0;

            .v-svg {
              color: $primaryBlue;
              margin-right: 0.5rem;
            }

            .radio-label {
              font-size: 12px;
              @extend %font-body-semi-bold;
            }
          }

          .sep {
            height: 1px;
            background-color: $secondaryBlue;
            width: 60%;
          }
        }

        .exports {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          height: 100%;

          .button {
            padding: 8px;
            border-radius: 8px;
          }
        }
      }

      .totals {
        padding: 1em;
        box-shadow: 0 4px 20px rgba(242, 175, 111, 0.5);
        border-radius: 15px;
        background-color: $primaryOrange;
        display: flex;
        flex-direction: column;
        margin-bottom: 12px;

        .all {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 1rem;
          @extend %font-body-bold;

          .label {
            font-size: 14px;
          }

          .nb {
            font-size: 18px;
          }
        }
      }

      .totals-billed {
        padding: 1em;
        box-shadow: 0 4px 20px rgba(242, 175, 111, 0.5);
        border-radius: 15px;
        background-color: $secondaryOrange;
        display: flex;
        flex-direction: column;

        .all {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid white;
          padding: 0 1rem;
          @extend %font-body-bold;

          .label {
            font-size: 14px;
          }

          .nb {
            font-size: 18px;
            line-height: 36px;
          }
        }

        .checked {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 2px solid white;
          padding: 0 1rem 0.5rem 1rem;
          @extend %font-body-bold;

          .label {
            font-size: 18px;
          }

          .nb {
            font-size: 28px;
            line-height: 15px;
          }
        }

        .sub-totals {
          margin-top: 0.5rem;
          display: grid;
          gap: 0.5rem 1rem;
          grid-template-columns: repeat(3, 1fr);
          padding: 0 1rem;

          .year {
            display: flex;

            .label {
              @extend %font-body-bold;
            }

            .nb {
              margin-left: 0.2rem;
            }
          }
        }
      }
    }
  }
  .import-recap {
    .header {
      display: flex;
      justify-content: space-between;
      &-title {
        display: flex;
        align-items: center;
        font-size: 24px;
        font-weight: bold;
      }
      & span {
        line-height: 54px;
        border-bottom: 4px solid $primaryOrange;
      }
    }
    .content {
      text-align: center;
      font-size: 15px;
      line-height: 25px;
      font-weight: bold;
      margin-bottom: 12px;
      margin-top: 30px;
    }

    .footer-button {
      text-align: -webkit-center;
      margin-top: 28px;
      button {
        border-radius: 8px;
        padding: 8px;
        font-weight: bold;
      }
    }
  }
</style>
