import TABLE_OF_VALUES from '@/utils/tableOfValues.json'

export default {
    name: 'valueMixin',
    methods: {
        getCodeValue(key, code) {
            return TABLE_OF_VALUES[key][code]
        },
    },
}
