<template>
  <div>
    <div class="input-container">
      <label class="file-label" for="file-input">{{ $t('inputFile.uploadFile') }}</label>
      <input id="file-input"
             class="hide-file-input"
             type="file"
             :accept="accept"
             @change="onFileChange($event)">
      <span class="file-name" :title="fileName">{{ trimFileName }}</span>
    </div>
  </div>
</template>


<script>
  export default {
    name: 'InputFile',
    props: {
      accept: {
        type: String,
        default: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
      },
      fileName: {
        type: String,
        default: null
      }
    },
    emits: ['file-updated'],
    computed: {
      trimFileName() {
        if (this.fileName) {
          let nameArray = this.fileName.split(".")
          let fileType = `.${nameArray.pop()}`
          let fileName = nameArray.join(" ")
          if (fileName.length >= 25) {
            fileName = fileName.substr(0, 25) + '...'
          }
          return (fileName + fileType)
        } else {
          return this.$t('inputFile.noFileSelected')
        }
      },

    },
    methods: {
      onFileChange($event) {
        const file = $event.target.files[0]
        // Check to see if there has been a file actually uploaded
        if (file) {
          this.$emit('file-updated', file)
        }
      }
    }
  }
</script>
<style scoped lang="scss">

  .hide-file-input {
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .file-label {
    background-color: $primaryBlue;
    color: $white;
    border-radius: 0.25rem;
    cursor: pointer;
    font-size: 14px;
    padding: 8px;
    gap: 10px;
    width: 97px;
    height: 37px;

  }

  .input-container {
    width: 370px;
    height: 48px;
    border: 1px solid $secondaryBlue;
    padding: 14px 10px 10px 10px;
    border-radius: 8px;
  }

  .file-name {
    font-size: 13px;
    color: $mediumGrey;
    padding: 14px 10px 10px 10px;
    cursor: default ;
  }


</style>
