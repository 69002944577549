<template>
  <div class="select-tva">
    <input-select v-if="params.node.group" :class="[{'updated': isUpdated}]" selectionKey="value" v-model="typeSelected" :values="types" secondary :placeholder="null"
      @update="updateInput" />
    <div v-else>{{ tva }}</div>
  </div>
</template>

<script>
import disputeMixin from '@/mixins/dispute'
import { txTva } from '@/utils/CONF'
export default {
  name: 'ArrayInputSelect',
  mixins: [disputeMixin],
  data() {
    return {
      tva: null,
      typeSelected: {},
      types: txTva,
      isUpdated: false
    }
  },
  methods: {
    updateInput() {
      let itemsToUpdate = []
      let groupName = this.params.node.key
      let selected = this.typeSelected.name
      let columnName = this.params.colDef.field
      this.isUpdated = true
      this.params.api.forEachLeafNode(function (rowNode) {
        if(groupName === rowNode.parent.key) {
          rowNode.data.vatRate = selected
          itemsToUpdate.push(rowNode.data)
          if (!rowNode.data.isEdited) rowNode.data.isEdited = []
          rowNode.data.isEdited.push(columnName)
          rowNode.data.benefitAmountWithoutTax = (Number(rowNode.data.benefitAmountWithTax) / (1 + (Number(rowNode.data.vatRate) / 100))).toFixed(2)
          rowNode.data.benefitTax = ((Number(rowNode.data.benefitAmountWithTax) / (Number(rowNode.data.vatRate) + 100)) * Number(rowNode.data.vatRate)).toFixed(2)
        }
      })
      this.params.api.applyTransaction({ update: itemsToUpdate })
      this.params.api.setPinnedBottomRowData([this.sum(this.params.node.parent.childrenAfterFilter.map(row => row.aggData))])
      this.params.context.componentParent.isUpdated = true
    }
  },
  beforeMount() {
    this.tva = this.params.node.group ? this.params.node.allLeafChildren[0].data.vatRate : this.params.value
    this.typeSelected = this.types.find(tva => {
      if (!this.tva) return false
      return tva.name === this.tva.toString()
    })
    this.isUpdated = this.params.node.group ? this.params?.node?.allLeafChildren[0]?.data?.isEdited?.includes(this.params.colDef.field) : false
  }
}
</script>

<style scoped lang="scss">
.select-tva {
  display: flex;
  height: 100%;
}
</style>
<style lang="scss">
.select-tva .input-select .list {
  top: 33px !important;
}
.updated .current-value{
  color: $primaryOrange;
}
</style>
