<template>
  <modal>
    <template #header>
      <div class="import-header">
        <div class="import-title">
          <v-svg name="import-file" h="6rem" />
          <div class="import-title-text">
            <span>Modification des quantités</span>
          </div>
        </div>
        <v-svg class="pointer" name="close" h="1rem" @click="$emit('close')" />
      </div>
    </template>

    <template #body>
      <h3>Importer</h3>
      <p>{{ $t('dispute.importMessage') }}</p>
      <input-file @file-updated="file = $event" :file-name="file ? file.name : null"></input-file>
    </template>

    <template #footer>
      <div class="import-buttons">
        <v-button color="secondary" :active="true" label="Annuler" size="medium" @click="$emit('close')" />
        <v-button color="primary" :isLoading="isLoadImport" :disabled="file === null || isLoadImport" :active="true" label="Valider" size="medium"
          @click="validateImport" />
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/Modal'
import { disputeColumnValueKey } from '@/utils/CONF'
import Excel from 'exceljs'
export default {
  name: 'ChickenOffer',
  components: {
    Modal
  },
  data() {
    return {
      file: null,
      isLoadImport: false
    }
  },
  emits: ['close', 'import'],
  methods: {
    async validateImport() {
      this.isLoadImport = true
      const wb = new Excel.Workbook()
      const reader = new FileReader()
      let jsonData = []
      reader.readAsArrayBuffer(this.file)
      reader.onload = () => {
        const buffer = reader.result
        wb.xlsx.load(buffer).then(workbook => {
          workbook.eachSheet((sheet, id) => {
            let firstRow = sheet.getRow(1)
            if (!firstRow.cellCount) return
            let keys = firstRow.values
            sheet.eachRow((row, rowIndex) => {
              if (rowIndex == 1) return
              let values = row.values
              let obj = {}
              for (let i = 1; i < keys.length; i++) {
                obj[disputeColumnValueKey[keys[i]]] = values[i]
              }
              jsonData.push(obj)
            })
            this.$emit('import', jsonData)
          })
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.import {
  &-header {
    display: flex;
    justify-content: space-between;
  }

  &-title {
    display: flex;
    font-size: 16px;
    align-items: center;

    &-text {
      display: flex;
      flex-direction: column;
    }

    & span:first-child {
      line-height: 44px;
      font-size: 24px;
      font-weight: bold;
      border-bottom: 4px solid $primaryOrange;
      width: fit-content;
      margin-bottom: 10px;
    }
  }

  &-buttons {
    display: flex;
    justify-content: center;

    button {
      border-radius: 8px;
      margin: 5px;
    }
  }
}</style>
