<template>
  <div class="dispute-array">
    <div class="dispute-buttons">
      <div class="dispute-billing">
        <span class="title">montant envoyé en facturation :</span>
        <span class="amount">{{ billingAmount }}€</span>
      </div>
      <v-button
        style="border-radius: 8px"
        label="Exporter"
        :disabled="!rowData.length"
        :active="true"
        size="medium"
        color="primary"
        @btn="toExport"
      />
      <v-button
        style="border-radius: 8px"
        label="Importer"
        :disabled="!rowData.length"
        :active="true"
        size="medium"
        color="secondary"
        @btn="showImport = !showImport"
      />
    </div>
    <array-import v-if="showImport" @close="showImport = !showImport" @import="toImport" />

    <ag-grid-vue
      style="width: 100%; height: 100%"
      class="ag-theme-alpine"
      :context="context"
      :columnDefs="columnDefs"
      :rowClassRules="rowClassRules"
      :row-data="rowData"
      :default-col-def="defaultColDef"
      :pagination-page-size="paginationPageSize"
      :pagination="true"
      :suppress-pagination-panel="true"
      :pinnedBottomRowData="pinnedBottomRowData"
      :columnTypes="columnTypes"
      :enableCellChangeFlash="true"
      :autoGroupColumnDef="autoGroupColumnDef"
      :suppressAggFuncInHeader="true"
      :suppressRowTransform="true"
      :singleClickEdit="true"
      :excelStyles="excelStyles"
      @first-data-rendered="firsDataRendered"
      @cell-value-changed="onCellValueChanged"
      @pagination-changed="onPaginationChanged"
      @grid-ready="onGridReady"
    />

    <div class="dispute-footer">
      <div class="pagination-container">
        <pagination :value="page" :total-visible="7" :number-pages="nbPage" @onPage="changedPage" />
        <page-size :tab="[10, 20, 50, 100]" :value="paginationPageSize" @onPageSize="changedPageSize" />
      </div>
      <div class="buttons">
        <v-button
          style="border-radius: 8px"
          label="ANNULER LES MODIFICATIONS"
          :active="true"
          size="medium"
          color="secondary"
          @btn="refreshPage"
        />
        <v-button
          style="border-radius: 8px"
          label="VALIDER LES MODIFICATIONS"
          :active="true"
          size="medium"
          color="primary"
          @btn="validateDisputeJustificative"
          :disabled="!rowData.length || isLoadingValidateDispute || !isUpdated"
          :isLoading="isLoadingValidateDispute"
        />
      </div>
    </div>
  </div>
</template>

<script>
  /* eslint-disable vue/no-unused-components */
  import { AgGridVue } from 'ag-grid-vue3'
  import { disputeStatementArrayColDefs } from '@/utils/CONF'
  import ArrayImport from './disputeArray/ArrayImport.vue'
  import ArrayButtonStake from './disputeArray/ArrayButtonStake.vue'
  import disputeMixin from '@/mixins/dispute'
  import { extendWithAuthorization, httpNip } from '@/http'
  import { DateTime } from 'luxon'
  import { globalProperties } from '@/main'

  export default {
    name: 'DisputeStatementArray',
    mixins: [disputeMixin],
    components: {
      AgGridVue,
      ArrayImport,
      ArrayButtonStake,
    },
    props: {
      data: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        isUpdated: false,
        context: {},
        isLoadingValidateDispute: false,
        showImport: false,
        page: 1,
        nbPage: 1,
        paginationPageSize: 10,
        gridApi: null,
        defaultColDef: {
          filter: 'agTextColumnFilter',
          floatingFilter: true,
          resizable: true,
          sortable: true,
        },
        columnDefs: disputeStatementArrayColDefs,
        rowData: [],
        pinnedBottomRowData: null,
        columnTypes: {
          valueColumn: {
            editable: (params) => params.node.rowPinned !== 'bottom',
          },
        },
        rowClassRules: {
          'cell-changed': function (params) {
            return params.node.rowPinned === 'bottom'
          },
        },
        autoGroupColumnDef: {
          minWidth: 300,
          field: 'product',
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          headerName: 'CUG LIBELLÉ',
        },
        excelStyles: [
          {
            id: 'greenBg',
            interior: {
              color: '#e2efda',
              pattern: 'Solid',
            },
          },
          {
            id: 'redFont',
            font: {
              color: '#ff0000',
            },
          },
        ],
        billingAmount: 0,
      }
    },
    watch: {
      data: {
        deep: true,
        handler() {
          let paymentSupplierStake = 0
          if (this.data?.discountPartnerBilling?.discountContractFirstPartnerBillingTypeCode === 'MA') {
            paymentSupplierStake = this.data.discountPartnerBilling.discountFirstPartnerBillingValue
          } else if (this.data?.discountPartnerBilling?.discountContractSecondPartnerBillingTypeCode === 'MA') {
            paymentSupplierStake = this.data.discountPartnerBilling.discountSecondPartnerBillingValue
          }

          this.rowData = this.data ? this.data.billingDetails : []

          const sumBenefitAmountWithoutTax = this.rowData.reduce((accumulator, currentValue) => {
            return currentValue.updateBenefitAmountWithTax
              ? accumulator + Number(currentValue.updateBenefitAmountWithTax)
              : accumulator + Number(currentValue.benefitAmountWithTax)
          }, 0)

          if (this.data?.discountPartnerBilling?.isUpdated) {
            this.gridApi.context.paymentSupplierStake = paymentSupplierStake
            this.rowData.forEach((row) => {
              row.paymentSupplierStake = this.gridApi.context.paymentSupplierStake
            })
            this.gridApi.setRowData(this.rowData)
            this.isUpdated = true
          }
          this.billingAmount = isNaN(sumBenefitAmountWithoutTax)
            ? 'ERR'
            : (sumBenefitAmountWithoutTax * (Number(paymentSupplierStake) / 100)).toFixed(2)
          this.pinnedBottomRowData = [this.sum(this.rowData)]
        },
      },
    },
    beforeMount() {
      this.context = {
        componentParent: this,
      }
    },
    methods: {
      showPopup() {
        this.$emit('showPopup')
      },
      refreshPage() {
        window.location.reload()
      },
      toImport(jsonData) {
        //tri des données avant de les comparer afin qu'elles soient tous les 2 dans le même ordre
        this.rowData.sort((a, b) => (a.product + a.site > b.product + b.site ? 1 : -1))
        jsonData.sort((a, b) => (a.product + a.site > b.product + b.site ? 1 : -1))

        let errorImport = false

        if (jsonData.length === this.getDisplayedRowCount()) {
          for (let i = 0; i < this.rowData.length; i++) {
            if (
              this.rowData[i].discountContractCode === jsonData[i].discountContractCode &&
              this.rowData[i].product === jsonData[i].product &&
              this.rowData[i].site === jsonData[i].site
            ) {
              if (jsonData[i].updateBenefitQuantity || jsonData[i].updateBenefitQuantity === 0)
                this.rowData[i].updateBenefitQuantity = jsonData[i].updateBenefitQuantity
              if (jsonData[i].updateSalesQuantity || jsonData[i].updateSalesQuantity === 0)
                this.rowData[i].updateSalesQuantity = jsonData[i].updateSalesQuantity
              if (jsonData[i].updateBenefitAmountWithTax || jsonData[i].updateBenefitAmountWithTax === 0)
                this.rowData[i].updateBenefitAmountWithTax = jsonData[i].updateBenefitAmountWithTax
            } else {
              errorImport = true
              break
            }
          }
        } else {
          errorImport = true
        }
        if (errorImport) {
          this.$toast.error(
            this.$t(jsonData.length !== this.getDisplayedRowCount() ? 'dispute.lengthError' : 'dispute.dataError')
          )
        } else {
          this.isUpdated = true
          let _this = this
          //Necessary to update row Data aggrid
          setTimeout(function () {
            _this.gridApi.setRowData(_this.rowData)
            _this.pinnedBottomRowData = [_this.sum(_this.rowData)]
            _this.billingAmount = isNaN(_this.pinnedBottomRowData[0].benefitAmountWithTax)
              ? 'ERR'
              : (
                  _this.pinnedBottomRowData[0].benefitAmountWithTax *
                  (Number(_this.data.discountPartnerBilling.discountFirstPartnerBillingValue) / 100)
                ).toFixed(2)
          }, 0)
        }
        this.showImport = false
      },
      toExport() {
        this.gridApi.exportDataAsExcel({
          allColumns: true,
          skipRowGroups: true,
          skipPinnedBottom: true,
          fileName: `${DateTime.local().toFormat('yyMMdd')}-${this.data.discountContractCode}.xlsx`,
        })
      },
      firsDataRendered(params) {
        this.pinnedBottomRowData = [this.sum(params.api.gridOptionsWrapper.gridOptions.rowData)]
      },
      onGridReady(params) {
        this.gridApi = params.api
        this.gridApi.sizeColumnsToFit()
      },
      onCellValueChanged(params) {
        this.pinnedBottomRowData = [this.sum(params.node.parent.parent.allLeafChildren.map((row) => row.data))]
        if (params.oldValue !== params.newValue) {
          if (!params.data.isEdited) params.data.isEdited = []
          if (!params.data.group) params.data.group = params.node.parent.key
          params.data.isEdited.push(params.colDef.field)
          this.isUpdated = true
        }
        params.api.refreshCells()

        const sumBenefitAmountWithoutTax = this.rowData.reduce((accumulator, currentValue) => {
          return currentValue.updateBenefitAmountWithTax
            ? accumulator + Number(currentValue.updateBenefitAmountWithTax)
            : accumulator + Number(currentValue.benefitAmountWithTax)
        }, 0)
        this.billingAmount = isNaN(sumBenefitAmountWithoutTax)
          ? 'ERR'
          : (
              sumBenefitAmountWithoutTax *
              (Number(this.data.discountPartnerBilling.discountFirstPartnerBillingValue) / 100)
            ).toFixed(2)
      },
      onPaginationChanged() {
        if (this.gridApi) this.nbPage = this.gridApi.paginationGetTotalPages()
      },
      changedPageSize(e) {
        this.paginationPageSize = e
        this.gridApi.paginationSetPageSize(this.paginationPageSize)
      },
      changedPage(e) {
        this.page = e
        this.gridApi.paginationGoToPage(this.page - 1)
      },
      async validateDisputeJustificative() {
        this.isLoadingValidateDispute = true
        const dataPinnedBottom = this.gridApi.getPinnedBottomRow(0).data
        if (
          isNaN(dataPinnedBottom.benefitAmountWithTax) ||
          isNaN(dataPinnedBottom.benefitAmountWithoutTax) ||
          isNaN(dataPinnedBottom.benefitQuantity) ||
          isNaN(dataPinnedBottom.benefitTax) ||
          isNaN(dataPinnedBottom.salesQuantity)
        ) {
          this.$toast.error(this.$t('dispute.globalError'))
        } else {
          let json = this.data
          json.paymentSupplierStake = this.gridApi.getRowNode(0).data.paymentSupplierStake
          json.benefitAmountWithTaxTotal = Number(dataPinnedBottom.benefitAmountWithTax).toFixed(2)
          ;(json.userMatricule = globalProperties?.user?.uid), console.log(json.benefitAmountWithTaxTotal)
          const extendedHttp = await extendWithAuthorization(httpNip)
          await extendedHttp
            .post('dispute/statement', { json })
            .then(() => {
              this.$router.push({
                name: 'Billing',
                query: {
                  discountContractCode: this.data.discountContractCode,
                  discountContractStatusCode: 3, //justificative
                  perimeter: this.data.perimeter,
                  discountContractChannel: this.data.discountContractChannel,
                  fromDispute: true,
                },
              })
            })
            .catch(() => {
              this.$toast.error(this.$t('dispute.globalError'))
            })
        }
        this.isLoadingValidateDispute = false
      },
      getDisplayedRowCount() {
        let count = 0
        this.gridApi.forEachLeafNode(() => count++)
        return count
      },
    },
  }
</script>

<style scoped lang="scss">
  .dispute-array {
    .dispute-footer {
      .pagination-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        margin-right: 19px;
        .pagination {
          margin-left: 19px;
        }
      }
      .buttons {
        margin: 10px 0;
        display: flex;
        justify-content: right;
      }
    }
    .dispute-buttons {
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;
      top: -65px;

      .dispute-billing {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 3px solid $primaryOrange;
        border-radius: 15px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
        font-size: 10px;
        padding: 0.5em 2em;
        margin: 1em;
        .title {
          font-weight: bold;
        }
        .amount {
          font-size: 1.5em;
          font-weight: bold;
          margin-top: 3px;
        }
      }
    }
    button {
      border-radius: 8px;
      margin-right: 19px;
      font-weight: bold;
      height: fit-content;
    }
  }
  .ag-theme-alpine::v-deep {
    .cell-changed {
      color: $primaryOrange !important;
    }

    .ag-cell {
      overflow: visible !important;
    }

    .ag-body-viewport {
      z-index: 1;
    }

    .ag-root,
    .ag-root-wrapper,
    .ag-body-viewport,
    .ag-body-viewport-wrapper,
    .ag-center-cols-clipper,
    .ag-center-cols-viewport,
    .ag-floating-bottom {
      overflow: visible !important;
    }
  }
</style>
