<template>
  <div class="dispute">
    <div v-if="showDiscountFinancing" class="popup-promotion-detail">
      <promotion-detail-discount-financing
        class="popup-promotion-detail-content"
        :is-edit-mode="true"
        :promotion-detail="data"
        :financing-nip="financingNip"
        :financing-types="financingTypes"
        @cancel="showDiscountFinancing = !showDiscountFinancing"
        @updateFinancing="updateFinancing"
      />
    </div>
    <div v-if="showOrderProvider" class="popup-promotion-detail">
      <promotion-detail-order-provider
        class="popup-promotion-detail-content"
        :is-loading="isLoadingPaymentSupplier"
        :is-edit-mode="true"
        :promotion-detail="data"
        :order-supplier-new-values="orderSupplierNewValues"
        :is-market-with-nullable-g-d-n="isMarketWithNullableGDN"
        @cancel="showOrderProvider = !showOrderProvider"
        @updateOrderProvider="updateOrderProvider"
      />
    </div>
    <h1>
      <title-bar class="promotionDetail-title" :title="$t('dispute.statementToRedo.title')" />
    </h1>
    <Card :is-loading="isLoadDispute">
      <template #header>
        <div class="flex items-center">
          <CardHeader
            :title="$t('dispute.statementToRedo.statementOfTheOfferOnMarket', { discountContractCode: $route.query.discountContractCode })"
          />
          <div class="supplier-info flex items-center">
            <span class="title">PAIEMENT: </span>
            <div class="flex column">
              <div class="flex">
                <div class="content">
                  RAYON <span class="result">{{ data?.paymentSupplierDepartmentCode }}</span>
                </div>
                <div class="content">
                  CNUF <span class="result">{{ data?.paymentSupplierCnuf }}</span>
                </div>
              </div>
              <div>
                GDN <span class="result"> {{ data?.paymentSupplierNegoGroupUid }} - {{ data?.paymentSupplierNegoGroupLabel }}</span>
              </div>
            </div>
          </div>
          <div class="supplier-info flex items-center">
            <span class="title">COMMANDE: </span>
            <div class="flex column">
              <div class="flex">
                <div class="content">
                  MARCHÉ <span class="result">{{ data?.orderSupplier?.orderSupplierDepartmentCode }}</span>
                </div>
                <div class="content">
                  CNUF <span class="result">{{ data?.orderSupplier?.orderSupplierCnuf }}</span>
                </div>
                <div class="content">
                  CIF <span class="result">{{ data?.orderSupplier?.orderSupplierCif }}</span>
                </div>
              </div>
              <div>
                GDN
                <span class="result">
                  {{ data?.orderSupplier?.orderSupplierNegoGroupUid }} - {{ data?.orderSupplier?.orderSupplierNegoGroupLabel }}</span
                >
              </div>
            </div>
            <v-svg class="pointer" name="edit-mode" @click="showOrderProvider = !showOrderProvider" />
          </div>
        </div>
      </template>
      <template #body>
        <DisputeStatementArray :data="data" @showPopup="showDiscountFinancing = !showDiscountFinancing" />
      </template>
    </Card>
  </div>
</template>

<script>
  import Card from '@/components/card/Card'
  import CardHeader from '@/components/card/CardHeader'
  import DisputeStatementArray from '@/components/dispute/DisputeStatementArray'
  import Modal from '@/components/Modal'
  import PromotionDetailDiscountFinancing from '@/components/promotionDetail/PromotionDetailDiscountFinancing'
  import PromotionDetailOrderProvider from '@/components/promotionDetail/PromotionDetailOrderProvider'
  import { extendWithAuthorization, httpNip } from '@/http'
  import { financingTypes } from '@/utils/CONF'
  import { mapState } from 'vuex'
  export default {
    name: 'DisputeJustificative',
    components: {
      Card,
      CardHeader,
      DisputeStatementArray,
      Modal,
      PromotionDetailDiscountFinancing,
      PromotionDetailOrderProvider
    },
    data() {
      return {
        showDiscountFinancing: false,
        showOrderProvider: false,
        data: null,
        rowData: [],
        isLoadDispute: true,

        financingTypes: [],
        financingNip: {
          firstFinancing: {
            value: 0,
            oldValue: 0,
            type: null,
            oldType: null
          },
          secondFinancing: {
            value: 0,
            oldValue: 0,
            type: null,
            oldType: null
          }
        },

        //Update order supplier
        orderSupplierNewValues: {
          cnuf: null,
          oldCnuf: null,
          cif: null,
          oldCif: null,
          gdn: null,
          oldGdn: null
        },
        isMarketWithNullableGDN: false,
        isLoadingPaymentSupplier: false
      }
    },

    computed: {
      ...mapState('referential', ['marketsWithNullableGDN'])
    },

    created() {
      this.loadDispute()
    },

    methods: {
      updateFinancing(e) {
        this.data.discountPartnerBilling.discountContractFirstPartnerBillingTypeCode = e.firstBillingTypeCode
        this.data.discountPartnerBilling.discountContractSecondPartnerBillingTypeCode = e.secondBillingTypeCode
        this.data.discountPartnerBilling.discountFirstPartnerBillingTypeLabel = e.firstBillingTypeLabel
        this.data.discountPartnerBilling.discountFirstPartnerBillingValue = e.firstBillingValue
        this.data.discountPartnerBilling.discountSecondPartnerBillingTypeLabel = e.secondBillingTypeLabel
        this.data.discountPartnerBilling.discountSecondPartnerBillingValue = e.secondBillingValue

        //OLD
        this.data.discountPartnerBilling.discountContractFirstPartnerBillingOldTypeCode = e.firstBillingOldTypeCode
        this.data.discountPartnerBilling.discountContractSecondPartnerBillingOldTypeCode = e.secondBillingOldTypeCode
        this.data.discountPartnerBilling.discountFirstPartnerBillingOldTypeLabel = e.firstBillingOldTypeLabel
        this.data.discountPartnerBilling.discountFirstPartnerBillingOldValue = e.firstBillingOldValue
        this.data.discountPartnerBilling.discountSecondPartnerBillingOldTypeLabel = e.secondBillingOldTypeLabel
        this.data.discountPartnerBilling.discountSecondPartnerBillingOldValue = e.secondBillingOldValue

        this.data.discountPartnerBilling.isUpdated = true

        this.showDiscountFinancing = !this.showDiscountFinancing
      },

      async updateOrderProvider(e) {
        const searchParams = {
          discountContractCode: e.discountContractCode,
          discountContractStartDate: e.discountContractStartDate,
          updatedOrderSupplierCnuf: e.updatedOrderSupplierCnuf,
          updatedOrderSupplierCif: e.updatedOrderSupplierCif,
          updatedOrderSupplierGdn: e.updatedOrderSupplierGdn,
          orderSupplierDepartmentCode: e.orderSupplierDepartmentCode
        }
        this.isLoadingPaymentSupplier = true
        const extendedHttp = await extendWithAuthorization(httpNip)
        const paymentSupplier = await extendedHttp
          .get('promotionDetail/paymentSupplier', { searchParams })
          .json()
          .finally(() => (this.isLoadingPaymentSupplier = false))

        if (Object.keys(paymentSupplier).length === 0 && paymentSupplier.constructor === Object) {
          this.$toast.error(this.$t('message.error.dataIncorrect'))
        } else {
          this.showOrderProvider = !this.showOrderProvider

          this.data.orderSupplier.orderSupplierCif = paymentSupplier.orderSupplierCif
          this.data.orderSupplier.orderSupplierCnuf = paymentSupplier.orderSupplierCnuf
          this.data.orderSupplier.orderSupplierDepartmentCode = paymentSupplier.orderSupplierDepartmentCode
          this.data.orderSupplier.orderSupplierDepartmentLabel = paymentSupplier.orderSupplierDepartmentLabel
          this.data.orderSupplier.orderSupplierLabel = paymentSupplier.orderSupplierLabel
          this.data.orderSupplier.orderSupplierNegoGroupLabel = paymentSupplier.orderSupplierNegoGroupLabel
          this.data.orderSupplier.orderSupplierNegoGroupUid = paymentSupplier.orderSupplierNegoGroupUid
        }
      },

      async loadDispute() {
        try {
          const extendedHttp = await extendWithAuthorization(httpNip)
          const dataDispute = await extendedHttp
            .get('dispute', { searchParams: { discountContractCode: this.$route.query.discountContractCode, channel: this.$route.query.channel } })
            .json()
          if (dataDispute.length === 1) {
            this.data = dataDispute[0]
            this.rowData = dataDispute[0].billingDetails

            //FinancingType
            if (this.financingTypes.length === 0)
              this.financingTypes = financingTypes.filter(item => item?.disabled !== this.data.promotionType.tableOfValuesCode)
            // Init for updated variables for financing mode
            this.financingNip.firstFinancing.value = this.data?.discountPartnerBilling?.discountFirstPartnerBillingValue + ''
            this.financingNip.firstFinancing.oldValue = this.financingNip.firstFinancing.value
            this.financingNip.secondFinancing.oldValue = this.financingNip.secondFinancing.value
            this.financingNip.firstFinancing.type =
              financingTypes.find(type => type.id === this.data?.discountPartnerBilling?.discountContractFirstPartnerBillingTypeCode) || null
            this.financingNip.secondFinancing.type =
              financingTypes.find(type => type.id === this.data?.discountPartnerBilling?.discountContractSecondPartnerBillingTypeCode) || null
            this.financingNip.firstFinancing.oldType = this.financingNip.firstFinancing.type
            this.financingNip.secondFinancing.oldType =
              financingTypes.find(type => type.id === this.data?.discountPartnerBilling?.discountContractSecondPartnerBillingTypeCode) || null

            // Init for updated variables for order supplier
            this.orderSupplierNewValues.cnuf = this.data?.orderSupplier?.orderSupplierCnuf
            this.orderSupplierNewValues.oldCnuf = this.data?.orderSupplier?.orderSupplierCnuf
            this.orderSupplierNewValues.cif = this.data?.orderSupplier?.orderSupplierCif
            this.orderSupplierNewValues.oldCif = this.data?.orderSupplier?.orderSupplierCif
            this.orderSupplierNewValues.gdn = this.data?.orderSupplier?.orderSupplierNegoGroupUid
            this.orderSupplierNewValues.oldGdn = this.data?.orderSupplier?.orderSupplierNegoGroupUid

            this.isMarketWithNullableGDN =
              this.marketsWithNullableGDN.find(market => market?.marketsid === this.data?.orderSupplier?.orderSupplierDepartmentCode) !== undefined
          } else {
            this.$toast.error(this.$t('dispute.errorData'))
          }
          this.isLoadDispute = false
        } catch (error) {
          this.isLoadDispute = false
          this.$toast.error(this.$t('dispute.globalError'))
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .dispute {
    padding: 2rem;
    height: 95%;

    .card {
      height: 100%;
    }
  }

  .supplier-info {
    display: flex;
    align-items: center;
    border: 1px solid $secondaryBlue;
    border-radius: 15px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    font-size: 10px;
    padding: 1em 2em;
    margin: 1em;

    .title {
      margin-right: 2em;
    }

    .result {
      font-weight: bold;
    }
    .content {
      margin-right: 2em;
    }
  }
  .popup-promotion-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease;
    .popup-promotion-detail-content {
      height: unset;
      margin: auto;
    }
  }
</style>
